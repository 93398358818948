import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Button,
  Input,
  Space,
  Select,
  message,
  Modal,
  Switch,
  Radio,
  Tooltip,
  Tag,
  Form,
  Row,
  Col,
} from "antd";
import {
  ArrowLeftOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  TagOutlined,
} from "@ant-design/icons";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { deleteData, get, post, put } from "../../services/Apiclient";
import AddWorkTag from "./AddWorkTagModal";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import EditWorkTag from "./EditWorkTagModal";

const { Sider, Content } = Layout;

const WorkTags = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Please enter Category"),
  });
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [selectedColor, setSelectedColor] = useState("#26C0E2");
  const [tags, setTags] = useState<string[]>([]);
  const [tagInputVisible, setTagInputVisible] = useState(false);
  const [tagInputValue, setTagInputValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [workTagData, setWorkTagData] = useState<any[]>([]);
  const [selectedWorkTagData, setSelectedWorkTagData] = useState<any>();
  const [outletCode, setoutletCode] = useState<any>(
    localStorage.getItem("outlet_id") ? localStorage.getItem("outlet_id") : null
  );
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const colors = [
    "#26C0E2",
    "#605BFF",
    "#FF6A77",
    "#1BAC7D",
    "#FFCC00",
    "#118098",
    "#AD5BFF",
    "#FF6D1B",
    "#65D268",
    "#EC85D5",
  ];

  const handleAddTag = () => {
    if (tagInputValue && !tags.includes(tagInputValue)) {
      setTags([...tags, tagInputValue]);
    }
    setTagInputValue("");
    setTagInputVisible(false);
  };

  useEffect(() => {
    getWorkTagData();
  }, []);

  useEffect(() => {
    if (workTagData && workTagData.length > 0) {
      setSelectedWorkTagData(workTagData[0]);
      setSelectedKey(workTagData[0]?.id);
    }
  }, [workTagData]);

  const handleSelecteWorkTag = (data: any) => {
    setSelectedWorkTagData(data);
  };

  const handleOutletChange = (value: string) => {
    setoutletCode(value);
  };

  const handleWorkTagSubmit = async () => {
    try {
      if (editingRecord) {
        // // Edit mode: Call API to update the record
        // await put(`/department/${editingRecord.key}`, values);
        // message.success("Department updated successfully!");
        // fetchDepartments(); // Refresh data after update
      } else {
        // values.outlet_id = selectedOutlet;
        // // Add mode: Call API to add a new record
        // await post("/department/adddepartment", values);
        // message.success("Department added successfully!");
        // fetchDepartments(); // Refresh data after adding
      }
      setIsModalVisible(false);
      setEditingRecord(null);
    } catch (error) {}
  };

  const getWorkTagData = async () => {
    try {
      let url = `/worktag/list/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setWorkTagData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleEditData = (item: any) => {
    setSelectedItem(item);
    setIsEditModalVisible(true);
  };

  const handleDeleteClick = (record: any) => {
    setSelectedData(record);
    setDeleteModalVisible(true);
  };

  const handleEdit = async (record: any) => {
    try {
      // Fetch the record by ID
      const response = await get(`/department/${record.key}`);
      if (response.data.success) {
        const data = response.data.data;
        const recordData = {
          key: data.id,
          code: data.code,
          departmenttype: data.departmenttype,
          status: data.status,
        };

        setEditingRecord(recordData);
        setIsModalVisible(true);
      }
    } catch (error) {
      message.error("Failed to load department details.");
      console.error(error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      if (selectedData) {
        // Call delete API
        const response = await deleteData(`/worktag/remove/${selectedData}`);
        if (response.data.success) {
          message.success("Deleted successfully!");
          setSelectedWorkTagData(null);
          getWorkTagData();
        }
      }
      setDeleteModalVisible(false);
      setSelectedData(null);
    } catch (error) {
      message.error("Failed to delete department.");
      console.error(error);
    }
  };

  const handleAddWorkTag = async (data: any) => {
    try {
      const url = "/worktag";
      data.outlet_id = localStorage.getItem("outlet_id");

      const response = await post(url, data); // Await the API call

      if (response?.data?.success) {
        message.success("Work tag added successfully!");
        await getWorkTagData(); // Refresh the work tag list after addition
      } else {
        message.error(response?.data?.message || "Failed to add work tag.");
      }

      setIsModalVisible(false);
    } catch (error) {
      console.error("Error adding work tag:", error);
      message.error("Something went wrong while adding.");
    }
  };

  const handleEditWorkTag = async (data: any) => {
    console.log("Edit data:", data);
    const payload = {
      name: data.name,
      color_code: data.color_code,
      status: data.status,
      tags: data.tags.map((tag: any) => ({ name: tag.name })),
    };
    try {
      const url = `/worktag/${data.id}`;
      const payloaddata = {
        ...payload,
        outlet_id: localStorage.getItem("outlet_id"),
      };

      const response = await put(url, payloaddata);

      if (response?.data?.success) {
        message.success("Work tag updated successfully!");
        getWorkTagData(); // Refresh list after update
      } else {
        message.error(response?.data?.message || "Failed to update work tag.");
      }
      setIsEditModalVisible(false);
    } catch (error) {
      console.error("Error updating work tag:", error);
      message.error("Something went wrong while updating.");
    }
  };

  return (
    <Layout style={{ flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 20px",
          backgroundColor: "transparent",
        }}
      >
        {/* Back Button */}
        <Button
          type="link"
          icon={
            <ArrowLeftOutlined
              style={{ fontSize: "16px", color: "#2e56f2", cursor: "pointer" }}
            />
          }
          onClick={() => navigate("/settings")}
          style={{
            color: "#000",
            fontWeight: "700",
            fontSize: "18px",
            fontFamily: "SourceHanSansCN-Bold,PingFangSC-Semibold,PingFang SC",
            cursor: "text",
          }}
        >
          Work Tags
        </Button>

        <Space>
          <Button
            icon={<PlusOutlined style={{ fontWeight: "900" }} />}
            style={{
              fontWeight: "700",
              height: "40px",
              marginLeft: "40px",
              padding: "5px 12px",
              borderColor: "#ff971e",
              color: "#fff",
              borderRadius: "6px",
              background: "#ff971e",
              minWidth: "72px",
            }}
            onClick={showModal}
          >
            Add
          </Button>
        </Space>
      </div>
      <div
        style={{
          display: "flex",
          padding: "0px 12px 16px 24px",
          height: "calc(100vh - 80px)",
        }}
      >
        <Sider
          width={350}
          style={{
            background: "#fff",
            padding: "0px",
            height: "calc(100vh - 120px)",
            borderRadius: "8px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              overflowY: "hidden",
              height: "calc(100vh - 120px)",
              width: "350px",
            }}
            className="custom-scrollbar"
            onMouseEnter={(e) => (e.currentTarget.style.overflowY = "auto")} // Show scrollbar on hover
            onMouseLeave={(e) => (e.currentTarget.style.overflowY = "hidden")} // Hide scrollbar on leave
          >
            {/* Filtered Menu Items */}
            <Menu
              mode="inline"
              selectedKeys={[selectedKey || ""]}
              onSelect={(item) => setSelectedKey(item?.key)}
              style={{ margin: "16px", width: "300px" }}
            >
              {workTagData?.map((item) => (
                <Menu.Item
                  key={item.id}
                  style={{
                    paddingRight: "16px",
                    color: item.id == selectedKey ? "#2e56f2" : "#000",
                    background: item.id == selectedKey ? "#2e56f21a" : "#fff",
                    fontWeight: "500",
                    borderRadius: item.id == selectedKey ? "6px" : "0px",
                  }}
                  onClick={() => handleSelecteWorkTag(item)}
                >
                  {item.name}
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </Sider>

        <Layout style={{ padding: "0px 16px 24px 16px" }}>
          <Content
            style={{
              padding: "24px 24px",
              background: "#fff",
              borderRadius: "8px",
              width: "calc(100vw - 410px)",
            }}
          >
            {selectedWorkTagData ? (
              <>
                <div
                  style={{
                    background: selectedWorkTagData?.color_code || "#fff",
                    padding: "14px 23px",
                    borderRadius: "12px",
                    marginBottom: "10px",
                    color: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleDeleteClick(selectedWorkTagData?.id)
                        }
                      >
                        Delete
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditData(selectedWorkTagData)}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "26px",
                    }}
                  >
                    {selectedWorkTagData.name || "ABCD"}
                  </div>
                </div>
                <h3>Tags</h3>
                <div
                  style={{
                    background: "#fff",
                    border: "1px solid #d9d9d9",
                    padding: "14px 23px",
                    display: "flex",
                    flexWrap: "wrap", // Ensure tags wrap to the next line if needed
                    gap: "10px",
                    borderRadius: "12px",
                    marginBottom: "10px",
                    color: selectedWorkTagData?.color_code || "#000",
                  }}
                >
                  {selectedWorkTagData &&
                    selectedWorkTagData?.tags?.length > 0 &&
                    selectedWorkTagData?.tags?.map((tag: any) => (
                      <div
                        key={tag.tag_id}
                        style={{
                          background: "#2e56f219",
                          color: selectedWorkTagData?.color_code || "#2e56f2",
                          borderRadius: "4px",
                          padding: "5px 12px",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          fontWeight: "500",
                          gap: "5px",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "18px",
                            height: "18px",
                            borderRadius: "50%",
                            backgroundColor: "fff",
                            color: "#fff",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <TagOutlined
                            style={{
                              color:
                                selectedWorkTagData?.color_code || "#2e56f2",
                              fontSize: "14px",
                            }}
                          />
                        </span>
                        <span>{tag.name}</span>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <p>No data found</p>
            )}
          </Content>
        </Layout>
      </div>
      {/* <Modal
        title="Add"
        open={isModalVisible}
        footer={[
          <Button key="cancel" onClick={handleWorkTagSubmit}>
            Cancel
          </Button>,
          <Button key="save" type="primary">
            Save
          </Button>,
        ]}
        onCancel={() => setIsModalVisible(false)}
        width={"900px"}
        style={{
          top: "10px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 180px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Category">
                <Input
                  placeholder="Please enter"
                  value={formValues.category}
                  onChange={(e) =>
                    handleInputChange("category", e.target.value)
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item style={{ marginBottom: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ fontWeight: "500", fontSize: "16px" }}>
                    Usage status
                  </label>
                  <Switch
                    style={{ width: "30px" }}
                    checked={formValues.status}
                    onChange={(value) => handleInputChange("status", value)}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Color">
                <Radio.Group
                  className="worktag-radiogroup"
                  style={{ display: "flex" }}
                  value={formValues.color_code}
                  onChange={(e) =>
                    handleInputChange("color_code", e.target.value)
                  }
                >
                  {colors.map((color) => (
                    <Radio.Button
                      key={color}
                      value={color}
                      style={{
                        position: "relative", // Enable positioning for child elements
                        backgroundColor: color,
                        width: 30, // Increased size for better spacing
                        height: 30,
                        borderRadius: "50%",
                        margin: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border:
                          selectedColor === color
                            ? "2px solid #000" // Highlighted border for selected color
                            : "1px solid #d9d9d9",
                        boxSizing: "border-box",
                        padding: "5px", // Space between border and content
                      }}
                    >
                      {selectedColor === color && (
                        <span
                          style={{
                            color: "#fff", // Tick color (white for better contrast)
                            fontSize: 18, // Adjust tick size
                            fontWeight: "bold",
                          }}
                        >
                          ✔
                        </span>
                      )}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <div>
                  <div style={{ display: "flex" }}>
                    <label>
                      <span className="vertical-line"></span>Tags{" "}
                      <Tooltip title="The tags will be matched in order">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </label>
                    <Button
                      type="default"
                      icon={<PlusOutlined />}
                      style={{
                        marginLeft: "40px",
                        border: "none",
                        color: "#ff971e",
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <div>
                    {formValues.tags.map((tag: any, index: any) => (
                      <Tag
                        key={index}
                        closable
                        onClose={() =>
                          setTags(tags.filter((t: any) => t.name !== tag.name))
                        }
                      >
                        <span style={{ textDecoration: "underline" }}>
                          {tag.name}
                        </span>
                      </Tag>
                    ))}
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal> */}
      {isModalVisible && (
        <AddWorkTag
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSelect={handleAddWorkTag}
        />
      )}
      {isEditModalVisible && (
        <EditWorkTag
          visible={isEditModalVisible}
          onClose={() => setIsEditModalVisible(false)}
          onSelect={handleEditWorkTag}
          selectedItem={selectedItem}
        />
      )}
      {deleteModalVisible && (
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onConfirm={handleDeleteConfirm}
          message="Are you sure to delete this tag?"
        />
      )}
    </Layout>
  );
};

export default WorkTags;
