import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Table,
  Button,
  Modal,
  Divider,
  Card,
  Typography,
  Form,
  Select,
  DatePicker,
  Input,
  Row,
  Col,
  Switch,
  Checkbox,
  Upload,
  message,
  FormInstance,
  Tooltip,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { get, post } from "../../../services/Apiclient";
import TextArea from "antd/es/input/TextArea";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import dayjs from "dayjs";
import ReferrerModal from "../customer/ReferrerModal";

const { Option } = Select;

interface AddCustomerModelProps {
  visible: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
}

interface FormValues {
  status: boolean;
  customercode: string;
  firstname: string;
  lastname: string;
  countrycode: string;
  phonenumber: string;
  email: string;
  gender: null | string;
  physicalcard: string;
  dob: dayjs.Dayjs | null | string;
  language: null | string;
  notes: string;
  source: null | string;
  referralby: string;
  firstconsultant: string;
  secondconsultant: string;
  thirdconsultant: string;
  passportid: string;
  emergency_contact: string;
  alternate_number: string;
  homeaddress: string;
  occupation: string;
  whatsapp: string;
  facebook: string;
  instagram: string;
  outlet_id?: string | null;
}

const AddCustomerModel: React.FC<AddCustomerModelProps> = ({
  visible,
  onClose,
  onSave,
}) => {
  const validationSchema = Yup.object().shape({
    customercode: Yup.string().required("Please enter customer code"),
    firstname: Yup.string().required("Please enter first name"),
    lastname: Yup.string().required("Please enter last name"),
    phonenumber: Yup.string()
      .required("Please enter phone number")
      .min(10, "Phone number must be at least 10 characters")
      .max(13, "Phone number must be at MAX 13 characters"),
  });

  const initialFormValues = {
    status: true,
    customercode: "",
    firstname: "",
    lastname: "",
    countrycode: "60",
    phonenumber: "60",
    email: "",
    gender: null,
    physicalcard: "",
    dob: null,
    language: null,
    notes: "",
    source: null,
    referralby: "",
    firstconsultant: "",
    secondconsultant: "",
    thirdconsultant: "",
    passportid: "",
    emergency_contact: "",
    alternate_number: "",
    homeaddress: "",
    occupation: "",
    facebook: "",
    instagram: "",
    whatsapp: "",
    outlet_id: localStorage.getItem("outlet_id"),
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [referralText, setReferralText] = useState("");
  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);
  const [customerSourceData, setCustomerSourceData] = useState<any[]>([]);
  const [visiblenewcustomer, setVisiblenewcustomer] = useState(false);

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const generateCustomerCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("customercode", data);
      }
    } catch (error) {
      message.error("Failed to generate customer code");
    }
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      const url = "/customer/addcustomer";
      const body = { ...formValues };
      let formattedPhoneNumber = body.phonenumber;

      // Remove country code from phone_number
      if (body.countrycode) {
        formattedPhoneNumber = body.phonenumber.replace(
          new RegExp(`^\\+?${body.countrycode}`),
          ""
        );
      }
      body.phonenumber = formattedPhoneNumber;
      body.dob = body.dob ? dayjs(body.dob).format("YYYY-MM-DD") : null;

      const response = await post(url, body);

      if (response && response.data && response.data.success) {
        setFormValues(initialFormValues);
        message.success("Successfully added!");
        setVisiblenewcustomer(false);
        await onSave(response.data.data);
        await onClose();
      } else {
        message.error(response.data.message || "Submission failed");
      }
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const requiredLabel = (label: string) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );

  const showReferralModal = () => setIsReferralModalVisible(true);
  const onReferralCancel = () => {
    setIsReferralModalVisible(false);
  };

  const getCustomerSourceDropdown = async () => {
    try {
      let url = `/customersource/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setCustomerSourceData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    if (visible) {
      generateCustomerCode();
      setReferralText("");
      setFormValues(initialFormValues);
      setVisiblenewcustomer(true);
    }
  }, [visible]);

  useEffect(() => {
    getCustomerSourceDropdown();
  }, []);

  const handleReferrerSelect = (selectedObject: any) => {
    setReferralText(selectedObject.name);
    handleInputChange("referralby", selectedObject.id);
  };

  return (
    <Modal
      title="Add New Customer"
      visible={visible && visiblenewcustomer}
      onCancel={onClose}
      footer={[
        <>
          <Divider style={{ margin: "10px 0px" }} />
          <Button key="save" type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </>,
      ]}
      centered
      width="700px"
    >
      <div
        style={{ maxHeight: "60vh", overflowY: "auto", paddingRight: "16px" }}
      >
        {" "}
        {/* Scrollable content container */}
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={null}>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <label style={{ fontWeight: "500" }}>
                    {requiredLabel("Customer Code")}
                  </label>

                  <a
                    onClick={generateCustomerCode}
                    style={{ color: "#325df2" }}
                  >
                    Generate automatically
                  </a>
                </div>
                <Input
                  placeholder="Please enter"
                  value={formValues.customercode}
                  onChange={(e) =>
                    handleInputChange("customercode", e.target.value)
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Physical card">
                <Input
                  placeholder="Enter the card code"
                  value={formValues.physicalcard}
                  onChange={(e) =>
                    handleInputChange("physicalcard", e.target.value)
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Name" required>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item>
                      <Input
                        placeholder="First name"
                        value={formValues.firstname}
                        onChange={(e) =>
                          handleInputChange("firstname", e.target.value)
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      <Input
                        placeholder="Last name"
                        value={formValues.lastname}
                        onChange={(e) =>
                          handleInputChange("lastname", e.target.value)
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Phone Number" required>
                <PhoneInput
                  country={"my"}
                  value={formValues.phonenumber}
                  enableSearch
                  countryCodeEditable={false}
                  onChange={(value: any, country: any) => {
                    handleInputChange("countrycode", country.dialCode);
                    handleInputChange("phonenumber", value);
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "46px",
                    paddingLeft: "35px", // Adjust space for the flag
                    borderColor: "#e0e0e0", // Light border color for a softer look
                    borderRadius: "5px",
                    color: "#5c5c5c", // Softer text color
                    backgroundColor: "#f8f9fd", // Light background color
                  }}
                  containerStyle={{
                    width: "100%",
                  }}
                  placeholder="Please enter"
                  // onChange={handlePhoneNumberChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email">
                <Input
                  placeholder="Enter email"
                  value={formValues.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Gender">
                <Select
                  placeholder="Select gender"
                  value={formValues.gender}
                  onChange={(value) => handleInputChange("gender", value)}
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Non-binary">Non-binary</Option>
                  <Option value="Prefer not to say">Prefer not to say</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Date of Birth">
                <DatePicker
                  style={{ width: "100%" }}
                  value={formValues.dob ? dayjs(formValues.dob) : null}
                  onChange={(date) => handleInputChange("dob", date)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Customer Source">
                <Select
                  placeholder="Please Select"
                  value={formValues.source}
                  onChange={(value) => handleInputChange("source", value)}
                >
                  {customerSourceData.length > 0 &&
                    customerSourceData.map((source: any) => (
                      <Option value={source.id} key={source.id}>
                        {source.customersourcetype}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Preferred notification language">
                <Select
                  placeholder="Select language"
                  value={formValues.language}
                  onChange={(value) => handleInputChange("language", value)}
                >
                  <Option value="English">English</Option>
                  <Option value="简体中文">简体中文</Option>
                  <Option value="繁體中文">繁體中文</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Referrer">
                <Input
                  placeholder="Please select"
                  onClick={showReferralModal}
                  readOnly
                  style={{ cursor: "pointer" }}
                  value={referralText}
                  suffix={
                    <span
                      className="textSuffix"
                      style={{ cursor: "pointer" }}
                      onClick={showReferralModal}
                    >
                      &gt;
                    </span>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Notes">
                <TextArea
                  placeholder="Please enter"
                  value={formValues.notes}
                  onChange={(e) => handleInputChange("notes", e.target.value)}
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <ReferrerModal
        visible={isReferralModalVisible}
        onClose={onReferralCancel}
        onSelect={handleReferrerSelect}
      ></ReferrerModal>
    </Modal>
  );
};

export default AddCustomerModel;
