import { PercentageOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Modal, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { currencySymbol } from "../../../constants/currencySymbol";
const dollarIcon = `${process.env.PUBLIC_URL}/images/logo/dollar.png`;
const dollarActiveIcon = `${process.env.PUBLIC_URL}/images/logo/active-dollar.png`;

const { Title, Text } = Typography;

interface EditCartDiscountModelProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (selectedObject: any) => void;
  value?: any;
  valuetype?: any;
  subTotalvalue?: any;
  totalvalue?: any;
  taxrate?: any;
  discountamount?: any;
}

const EditCartDiscountModel: React.FC<EditCartDiscountModelProps> = ({
  visible,
  onClose,
  onSubmit,
  value,
  valuetype = "%",
  subTotalvalue = 0,
  totalvalue = 0,
  taxrate = 0,
  discountamount = 0,
}) => {
  const { currencySymbol } = useCurrency();
  const [discountType, setDiscountType] = useState(
    `${currencySymbol.CURRENCY_SYMBOL}`
  );
  const [discountValue, setDiscountValue] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [totalDisplay, setTotalDisplay] = useState(0);

  const handleDiscountTypeChange = (type: any) => {
    setDiscountType(type);
    setDiscountValue(0);
  };

  const handleBlur = () => {
    if (discountType === "%" && discountValue > 100) {
      setDiscountValue(100);
    }
  };

  const handleConfirm = () => {
    let discount = {
      discountvalue: discountValue,
      discountamount: discountAmount,
      discounttype: discountType,
    };
    onSubmit(discount);
    onClose();
  };

  useEffect(() => {
    let discount = discountValue;
    let calculatedDiscountAmount = 0;
    let calculatedTotal = 0;
    let calculatedTax = 0;

    // Handle discount calculations based on discount type
    if (discountType === "%") {
      if (discount > 100) {
        discount = 100; // Cap the discount to 100%
      }

      if (discount > 0) {
        calculatedDiscountAmount = (subTotalvalue * discount) / 100;
        setDiscountAmount(calculatedDiscountAmount);

        // Update the subtotal after discount
        calculatedTotal = subTotalvalue - calculatedDiscountAmount;
      } else {
        setDiscountAmount(0);
        calculatedTotal = subTotalvalue;
      }
    } else {
      // Fixed amount discount
      if (discount > 0) {
        calculatedDiscountAmount = discount;
        setDiscountAmount(calculatedDiscountAmount);

        // Update the subtotal after discount
        calculatedTotal = subTotalvalue - calculatedDiscountAmount;
      } else {
        setDiscountAmount(0);
        calculatedTotal = subTotalvalue;
      }
    }

    // Recalculate tax based on the new subtotal (after discount)
    const taxAmount = (calculatedTotal * taxrate) / 100;

    // Round tax to two decimal places
    calculatedTax = Math.round(taxAmount * 100) / 100;

    // Final total = new subtotal after discount + recalculated tax
    const finalTotal = calculatedTotal + calculatedTax;

    // Update values
    setTotalValue(calculatedTotal);
    setTotalDisplay(finalTotal);
  }, [discountValue, discountType, subTotalvalue, taxrate]);

  useEffect(() => {
    if (visible) {
      if (totalvalue == 0) {
        setDiscountValue(0);
      } else {
        setDiscountValue(value);
      }
      setDiscountType(valuetype);
      setDiscountAmount(discountamount);
      setTotalValue(subTotalvalue);
      if (discountamount > 0) {
        let totDis = (totalvalue * taxrate) / 100;
        let totAmount = totalvalue + totDis;
        setTotalDisplay(totAmount);
      } else {
        let totDis = (totalvalue * taxrate) / 100;
        let totAmount = totalvalue + totDis - discountAmount;
        setTotalDisplay(totAmount);
      }
    }
  }, [visible]);

  return (
    <Modal
      title="Edit Cart Discount"
      visible={visible}
      centered
      width="500px"
      bodyStyle={{ height: "calc(100vh - 200px)" }}
      maskClosable={false}
      closable={true}
      onCancel={onClose}
      footer={[
        <>
          <Divider style={{ margin: "10px 0px 10px" }} />
          <Row justify="space-between">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography style={{ fontSize: "14px", marginRight: "5px" }}>
                Total after discount:
              </Typography>
              <span
                style={{
                  marginLeft: "3px",
                  fontSize: "14px",
                  color: "#f43b00",
                }}
              >
                {currencySymbol.CURRENCY_SYMBOL}
              </span>
              <span style={{ fontSize: "20px", color: "#f43b00" }}>
                {Number(totalDisplay).toFixed(2)}
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button key="save" type="primary" onClick={handleConfirm}>
                Confirm
              </Button>
            </div>
          </Row>
        </>,
      ]}
    >
      <div style={{ padding: "24px" }}>
        <div style={{ marginBottom: "40px" }}>
          <span style={{ color: "#666" }}>
            Taxes will be recalculated after the discount has been applied
          </span>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              lineHeight: "18px",
              paddingBottom: "4px",
              fontSize: "12px",
              color: "#666",
            }}
          >
            Discount
          </div>
          <div style={{ flex: 1, display: "flex" }}>
            <Input
              type="number"
              min={0}
              placeholder="Please enter"
              onChange={(e) => setDiscountValue(Number(e.target.value))}
              max={discountType === "%" ? 100 : undefined}
              onBlur={handleBlur}
              value={discountValue}
              addonAfter={
                <>
                  <Button
                    type="default"
                    style={{
                      marginRight: "10px",
                      border: "none",
                      boxShadow: "none",
                      background: `${
                        discountType === `${currencySymbol.CURRENCY_SYMBOL}`
                          ? "#fff"
                          : "transparent"
                      }`,
                    }}
                    onClick={() => {
                      handleDiscountTypeChange(
                        `${currencySymbol.CURRENCY_SYMBOL}`
                      );
                    }}
                  >
                    {currencySymbol.CURRENCY_SYMBOL}
                  </Button>
                  <Button
                    icon={<PercentageOutlined />}
                    type="default"
                    style={{
                      border: "none",
                      boxShadow: "none",
                      background: `${
                        discountType === "%" ? "#fff" : "transparent"
                      }`,
                      color: `${discountType === "%" ? "#2e56f2" : "#666"}`,
                    }}
                    onClick={() => {
                      handleDiscountTypeChange("%");
                    }}
                  />
                </>
              }
              style={{ width: "100%", height: "40px", appearance: "none" }}
            ></Input>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditCartDiscountModel;
