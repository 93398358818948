import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Segmented,
} from "antd";
import type { TabsProps } from "antd";
import { RightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import "./ModalStyles.css";
import Datatable from "../../Datatable";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { currencySymbol } from "../../../constants/currencySymbol";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const giftcardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_GiftCard.png`;
const giftcardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_GiftCard.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;

interface CustomerVoucherDetailsModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
}

interface GiftCardsData {
  key: any;
  id: string;
  name: string;
  code: string;
  giftType: string;
  ref: string;
  store: string;
  time: string;
  item: string;
  account: string;
  qutUsed: string;
  accountedAmount: string;
}

type Align = "Change History" | "Applicable Stores";

const columns = [
  {
    title: "Type",
    dataIndex: "giftType",
    key: "giftType",
    width: 150,
  },
  {
    title: "Ref#",
    dataIndex: "ref",
    key: "ref",
    width: 100,
  },
  {
    title: "Store",
    dataIndex: "store",
    key: "store",
    width: 100,
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    width: 150,
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
    width: 150,
  },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
    width: 150,
  },
  {
    title: "Qty. used",
    dataIndex: "qutUsed",
    key: "qutUsed",
    width: 150,
  },
  {
    title: "Accounted amount",
    dataIndex: "accountedAmount",
    key: "accountedAmount",
    width: 150,
  },
];

const storeColumns = [
  {
    title: "Store code",
    dataIndex: "storeCode",
    key: "storeCode",
    width: 100,
  },
  {
    title: "Store",
    dataIndex: "storeName",
    key: "storeName",
    width: 100,
  },
];

interface StoreDataProps {
  key: any;
  id: string;
}

const jsonStoreData = {
  id: "1TzSTCpC",
  key: "1TzSTCpC",
  storeCode: "001",
  storeName: "Suilady",
};

const CustomerVoucherDetailsModal: React.FC<
  CustomerVoucherDetailsModalProps
> = ({ visible, selectedItem = null, onClose }) => {
  const { currencySymbol } = useCurrency();

  const jsonVoucherData = {
    id: "1TzSTCpC",
    key: "1TzSTCpC",
    name: "giftcard new4",
    code: "GC-20241211039",
    giftType: "Card purchase",
    ref: "241212003",
    store: "Suilady",
    time: "12/12/2024 05:38pm",
    item: "PKG-2x1",
    account: "Purchase",
    qutUsed: "+1",
    accountedAmount: `${currencySymbol.CURRENCY_SYMBOL}1`,
  };
  const [toggleButtonValue, setToggleButtonValue] =
    useState<Align>("Change History");
  const [dataSource, setDataSource] = useState<GiftCardsData[]>([
    jsonVoucherData,
  ]);
  const [storeDataSource, setStoreDataSource] = useState<StoreDataProps[]>([
    jsonStoreData,
  ]);

  return (
    <>
      <Modal
        visible={visible}
        title={"Vouchers Details"}
        onCancel={onClose}
        footer={null}
        width="1000px"
        style={{
          top: "60px",
        }}
        bodyStyle={{
          height: "calc(100vh - 280px)",
          scrollbarWidth: "thin",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            // height: "calc(100vh - 300px)",
            overflowY: "auto",
          }}
        >
          {selectedItem && (
            <>
              <div
                style={{
                  color: "rgba(176, 87, 77, .8)",
                  position: "relative",
                  width: " 100%",
                  marginBottom: "16px",
                  backgroundImage: `url(${giftcardImage})`,
                  backgroundSize: "100% 100%",
                  transition: "all .2s",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <div className="gft_image-icon">
                  <div className="gft_van-image">
                    <img
                      className="gft_van-image__img"
                      src={giftcardImg}
                      alt="gift img"
                    ></img>
                  </div>
                </div>
                <div className="gft_view-bc">
                  <div className="gft_view-info">
                    <div className="gft_overflowText">
                      <span className="gft_title">{selectedItem?.name}</span>
                      <span className="gft_saleCardItem_text_code">
                        Code:{selectedItem?.code}
                      </span>
                    </div>
                    <div className="gft_view-apply">
                      <span className="gft_view-cardApply">
                        <div className="gft_bk-cardApply">
                          <span className="gft_cardApply_text_applicable">
                            Applicable to:
                          </span>
                          {selectedItem?.applicable_to}
                        </div>
                      </span>
                      <span className="gft_icon-gengduo">
                        <RightOutlined />
                      </span>
                      <div className="gft_view-singleUsageQty">
                        <span className="gft_saleCardItem_text_singleUsageQty">
                          Single usage qty.：
                        </span>
                        <div className="gft_display-def">
                          {selectedItem?.singleusageqty}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gft_view-bottom">
                  <div className="gft_view-left">
                    <div className="gft_bk-validDateText">
                      <span className="gft_saleCardItem_text_valid">
                        Valid for:{" "}
                      </span>
                      <span className="gtf_bk-date">
                        {selectedItem?.validfor}
                      </span>
                    </div>
                    <div className=""></div>
                  </div>
                  <div className="gft_view-right">
                    <div className="gft_view-price">
                      <span className="gft_saleCardItem_text_price">Price</span>
                      <span className="gft_color-def">
                        {currencySymbol.CURRENCY_SYMBOL}
                        {selectedItem?.sellprice}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="gft_dash"></div>
                <div className="gft_view-giftCardValue">
                  {selectedItem?.type === "Value" ? (
                    <div className="gft_view-value">
                      <span className="gft_color-def_bg">
                        {currencySymbol.CURRENCY_SYMBOL}
                        {selectedItem?.sessionvalue}
                      </span>
                    </div>
                  ) : null}
                  {selectedItem?.type === "Session" ? (
                    <>
                      <div className="gft_view-times">
                        {selectedItem?.sessionvalue}
                      </div>
                      <div className="gft_view-tip">Sessions</div>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          )}

          {!selectedItem && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={<span>No data available</span>}
              />
            </>
          )}
        </div>
        <div>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Purchase date: 12/12/2024</Col>
            <Col span={12}>Paid: {currencySymbol.CURRENCY_SYMBOL}1</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Purchase store: Suilady</Col>
            <Col span={12}>Purchase ref#: 241212003</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Debt: -</Col>
            <Col span={12}>Frozen: -</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Notes:</Col>
          </Row>
        </div>
        <div
          style={{
            borderBottom: "1px solid #e0e7fe",
            marginTop: "10px",
          }}
        />
        <div
          style={{
            marginTop: "15px",
          }}
        >
          <Segmented
            value={toggleButtonValue}
            style={{ marginBottom: 8 }}
            onChange={setToggleButtonValue}
            options={["Change History", "Applicable Stores"]}
          />
        </div>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {toggleButtonValue == "Change History" ? (
            <>
              {/* Table  */}
              <Datatable<GiftCardsData>
                columns={columns}
                dataSource={dataSource}
                scroll={{ y: "calc(100vh - 280px)" }}
                showCheckbox={false}
                //   rowSelection={rowSelection}
                showPagination={false}
                dynamicTableHeight="auto"
              />
            </>
          ) : (
            <>
              <Datatable<StoreDataProps>
                columns={storeColumns}
                dataSource={storeDataSource}
                scroll={{ y: "calc(100vh - 280px)" }}
                showCheckbox={false}
                //   rowSelection={rowSelection}
                showPagination={false}
                dynamicTableHeight="auto"
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CustomerVoucherDetailsModal;
