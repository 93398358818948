import React, { useEffect, useState } from "react";
import { Empty, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../../services/Apiclient";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const KIVSection: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tempMembershipList, setTempMembershipList] = useState<any[]>([]);

  useEffect(() => {
    fetchKIVItem();
  }, []);

  const fetchKIVItem = async () => {
    const outletId = localStorage.getItem("outlet_id");
    try {
      const url = `/customer/privilege-course/${outletId}/${id}/kiv`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        // console.log("response....", data.data);
        setTempMembershipList(data.data);
      }
    } catch (error) {
      console.error("Error in fetching membership list", error);
    }
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#f5f7fa",
          borderRadius: "8px",
        }}
      >
        <div>
          {tempMembershipList &&
            tempMembershipList.length > 0 &&
            tempMembershipList.map((onlinesalesitem: any, index: any) => (
              <>
                {onlinesalesitem.remaining_balance > 0 && (
                  <div
                    key={index}
                    style={{
                      color: "#588c66",
                      border: "2px solid #588c66",
                      position: "relative",
                      cursor: "pointer",
                      // height: "110px",
                      marginBottom: "16px",
                      background: "rgba(204,222,200,255)",
                      transition: "all .2s",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                    onClick={() => {
                      if (parseFloat(onlinesalesitem?.remaining_balance) > 0) {
                        navigate(
                          `/sales/billing?customerid=${id}&kiv=${onlinesalesitem?.remaining_balance}&itemid=${onlinesalesitem?.id}&refno=${onlinesalesitem?.ref_no}&name=${onlinesalesitem?.item_name}`
                        );
                      }
                    }}
                  >
                    <Row gutter={24} style={{ padding: "10px 24px 0 16px" }}>
                      <Col span={20}>
                        <span>{onlinesalesitem?.item_name}</span>
                      </Col>
                      <Col span={4}>
                        <span>Course Balance</span>
                      </Col>
                    </Row>
                    <Row
                      gutter={24}
                      style={{
                        padding: "0px 24px 10px 24px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Col span={20}>
                        <span>Ref#:{onlinesalesitem?.ref_no}</span>
                      </Col>
                      <Col span={4}>
                        <span>{onlinesalesitem?.remaining_balance}</span>
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            ))}
          {tempMembershipList && tempMembershipList.length == 0 && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={<span>No data available</span>}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default KIVSection;
