import { Modal, Select, DatePicker, Empty } from "antd";
import { RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./ModalStyles.css";
import { get } from "../../../services/Apiclient";
import ApplicableModel from "./ApplicablePackageModal";
import CustomerPackageDetailsModal from "./CustomerPackageDetailsModal";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { currencySymbol } from "../../../constants/currencySymbol";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const packagecardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_PackageCard.png`;
const packageCardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Package.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;

interface CustomerPackageModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
  onSelect?: (selectedObject: any) => void;
}

const tempPackagesItems = [
  {
    id: "LNyJjHJm",
    name: "Premium dhoom package-1234",
    code: "PKG123458",
    sellprice: "1010.00",
    validfor: "- ~ -",
    typeof_validity: "Custom",
    valid_count: "10",
    valid_countperiod: "Year",
    applicable_type: "By store",
    applicable_to: "3 Products / 3 Services / 0 store",
  },
];

const CustomerPackageModal: React.FC<CustomerPackageModalProps> = ({
  visible,
  selectedItem = null,
  onClose,
  onSelect,
}) => {
  const { currencySymbol } = useCurrency();
  const [customerPackageData, setCustomerPackageData] = useState<any[]>([]);
  const [showPackageDetailsModal, setShowPackagedetailsModal] = useState(false);
  const [selectedPackageItems, setSelectedPackageItems] = useState("");
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const [selectedApplicableType, setSelectedApplicableType] =
    useState<string>("");
  const [applicableModelVisible, setApplicableModelVisible] =
    useState<boolean>(false);
  useEffect(() => {
    fetchCustomerGiftCard();
  }, [selectedItem]);

  const fetchCustomerGiftCard = async () => {
    try {
      const url = `/billing/customer/packagedetails/${
        selectedItem?.id
      }/${localStorage.getItem("outlet_id")}`;
      const response = await get(url);

      if (response?.data?.success) {
        const customerGiftCard = response?.data?.data;
        setCustomerPackageData(customerGiftCard);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  const handlePackageDetailModal = (item: any) => {
    setShowPackagedetailsModal(true);
    setSelectedPackageItems(item);
  };

  const handleApplicableProductModel = (id: any, type: string) => {
    setSelectedProductId(id);
    setSelectedApplicableType(type);
    setApplicableModelVisible(true);
  };
  const onCloseApplicableModel = () => {
    setApplicableModelVisible(false);
    setSelectedProductId("");
    setSelectedApplicableType("");
  };

  return (
    <>
      <Modal
        open={visible}
        title={"Package Details"}
        onCancel={onClose}
        footer={null}
        width="900px"
        style={{
          top: "60px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 280px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
      >
        <div
          style={{
            height: "calc(100vh - 300px)",
            overflowY: "auto",
          }}
        >
          {customerPackageData &&
            customerPackageData.length > 0 &&
            customerPackageData.map((item: any, index: any) => (
              <>
                <div
                  key={index}
                  style={{
                    color: "rgba(176, 87, 77, .8)",
                    position: "relative",
                    width: " 100%",
                    marginBottom: "16px",
                    backgroundImage: `url(${packagecardImage})`,
                    backgroundSize: "100% 100%",
                    transition: "all .2s",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  //   onClick={() => {
                  //     handlePackageDetailModal(item);
                  //   }}
                >
                  <div className="package_image-icon">
                    <div className="package_van-image">
                      <img
                        className="package_van-image__img"
                        src={packageCardImg}
                        alt="package img"
                      ></img>
                    </div>
                  </div>
                  <div className="package_view-bc">
                    <div className="package_view-info">
                      <div className="package_overflowText">
                        <span className="package_title">
                          {item.package_name}
                        </span>
                        <span className="package_saleCardItem_text_code">
                          Code:{item.package_code}
                        </span>
                      </div>
                      <div className="package_view-apply">
                        {/* {item.applicable_type == "All store" && ( */}
                        <>
                          <span
                            className="package_view-cardApply"
                            // onClick={(e) => {
                            //   e.stopPropagation(); // Prevent parent div's click event
                            //   handleApplicableProductModel(
                            //     item.item_id,
                            //     "package"
                            //   );
                            // }}
                          >
                            <div className="package_bk-cardApply">
                              <span className="package_cardApply_text_applicable">
                                Applicable to:
                              </span>
                              {item.applicable_to}
                            </div>
                          </span>
                        </>
                        {/* )} */}
                        <span className="package_icon-gengduo">
                          <RightOutlined />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="package_view-bottom">
                    <div className="package_view-left">
                      <div className="package_bk-validDateText">
                        <span className="package_saleCardItem_text_valid">
                          Valid for:{" "}
                        </span>
                        {item.typeof_validity == "Custom" ? (
                          <span className="package_bk-date">
                            {/* {item.valid_startdate} ~ {item.valid_enddate} */}
                            {item.item_validfor}
                          </span>
                        ) : (
                          <span className="package_bk-date">
                            {/* {item.valid_count} {item.valid_countperiod}{" "} */}
                            {item.item_validfor?.split(" ~ ")[0]}{" "}
                            {item.item_validfor?.split(" ~ ")[1]}{" "}
                            {item.typeof_validity}
                          </span>
                        )}
                      </div>
                      <div className=""></div>
                    </div>
                    <div className="package_view-right">
                      <div className="package_view-price">
                        <span className="package_saleCardItem_text_price">
                          Price
                        </span>
                        <span className="package_color-def">
                          {currencySymbol.CURRENCY_SYMBOL}{Math.trunc(item.total_amount)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}

          {customerPackageData && customerPackageData.length == 0 && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={
                  <span>There is currently no package card available</span>
                }
              />
            </>
          )}
        </div>
      </Modal>
      <CustomerPackageDetailsModal
        visible={showPackageDetailsModal}
        selectedItem={selectedPackageItems}
        onClose={() => {
          setShowPackagedetailsModal(false);
        }}
      />
      {applicableModelVisible && (
        <ApplicableModel
          visible={applicableModelVisible}
          onClose={onCloseApplicableModel}
          type={selectedApplicableType}
          id={selectedProductId}
        />
      )}
    </>
  );
};

export default CustomerPackageModal;
