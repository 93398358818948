import React from "react";
import { Modal, Table, Select, Input, Button, Row, Col } from "antd";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { currencySymbol } from '../../../constants/currencySymbol';

const { Option } = Select;

interface ProductExcPriceProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedItems: any) => void; // Modify onSave to accept selectedItems
}

const ProductExcPrice: React.FC<ProductExcPriceProps> = ({
  visible,
  onCancel,
  onSave,
}) => {
  const { currencySymbol } = useCurrency();

  // Sample data for table
  const data = [
    {
      key: "1",
      productCode: "241017001",
      productName: "Face Cleanser 300ml",
      category: "Care",
      price: `${currencySymbol.CURRENCY_SYMBOL}199`,
      unit: "Bottle",
      supplier: "chung wang",
    },
    {
      key: "2",
      productCode: "241017006",
      productName: "Bottle",
      category: "Beauty",
      price: `${currencySymbol.CURRENCY_SYMBOL}12`,
      unit: "Bottle",
      supplier: "chung wang",
    },
    {
      key: "3",
      productCode: "241017007",
      productName: "Product",
      category: "Beauty",
      price: `${currencySymbol.CURRENCY_SYMBOL}12`,
      unit: "Bottle",
      supplier: "chung wang",
    },
    {
      key: "4",
      productCode: "241017008",
      productName: "Product",
      category: "Beauty",
      price: `${currencySymbol.CURRENCY_SYMBOL}23`,
      unit: "Bottle",
      supplier: "chung wang",
    },
  ];

  const columns = [
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
    },
  ];

  return (
    <Modal
      title="Product"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={onSave}>
          Save
        </Button>,
      ]}
      width={900} // Adjust the width as needed
      bodyStyle={{ padding: "24px" }} // Adjust padding for a cleaner look
    >
      {/* Filters Row */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Select
            placeholder="All categories"
            style={{ width: "100%" }}
            dropdownStyle={{ textAlign: "center" }}
          >
            <Option value="category1">Category 1</Option>
            <Option value="category2">Category 2</Option>
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="All suppliers"
            style={{ width: "100%" }}
            dropdownStyle={{ textAlign: "center" }}
          >
            <Option value="supplier1">Supplier 1</Option>
            <Option value="supplier2">Supplier 2</Option>
          </Select>
        </Col>
        <Col span={8}>
          <Input placeholder="Select by product name / code" />
        </Col>
      </Row>

      {/* Product Table */}
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowSelection={{
          type: "checkbox",
        }}
        style={{ marginTop: "16px" }}
        scroll={{ y: 240 }} // Allow vertical scroll if data exceeds 240px height
      />

      {/* Selected Items Count */}
      <div style={{ marginTop: "16px" }}>
        <p>Selected: 0 items</p>
      </div>
    </Modal>
  );
};

export default ProductExcPrice;
