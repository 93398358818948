import React, { useEffect, useState } from "react";
import {
  Tabs,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Form,
  Button,
  Input,
  Card,
  Typography,
  Empty,
  Space,
  message,
  Menu,
  Dropdown,
  Avatar,
  MenuProps,
  Tooltip,
  Modal,
} from "antd";
import {
  SearchOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  MoreOutlined,
  MailFilled,
  EyeInvisibleOutlined,
  PhoneFilled,
  UserOutlined,
  ClearOutlined,
  CloseOutlined,
  FileExcelOutlined,
  DownOutlined,
  UpOutlined,
  RightOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import SelectServiceStaff from "../../components/model/billing/SelectServiceStaff";
import SingleDateSelectionModal from "../../components/model/SingleDateSelectionModal";
import dayjs, { Dayjs } from "dayjs";
import { get, post, put } from "../../services/Apiclient";
import SelectProductStaff from "../../components/model/billing/SelectProductStaff";
import RefNumModal from "../../components/model/billing/RefNumModal";
import BillingInfoModel from "../../components/model/billing/BillingInfoModel";
import SearchCustomerModel from "../../components/model/billing/SearchCustomerModel";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import ServiceEditProductEditModel from "../../components/model/billing/ServiceEditProductEditModel";
import ProductEditModel from "../../components/model/billing/ProductEditModel";
import NotesModal from "../../components/model/billing/NotesModal";
import EditCartDiscountModel from "../../components/model/billing/EditCartDiscountModel";
import { nanoid } from "nanoid";
import GiftcardAreaOfApplicationModel from "../../components/model/billing/GiftcardAreaOfApplicationModel";
import GiftcardEditModel from "../../components/model/billing/GiftcardEditModel";
import ApplicableModel from "../../components/model/billing/ApplicablePackageModal";
import PackageEditModel from "../../components/model/billing/PackageEditModal";
import CustomerVoucherModal from "../../components/model/billing/CustomerVoucherModal";
import CustomerPackageModal from "../../components/model/billing/CustomerPackageModal";
import PackageEditModelBilling from "../../components/model/billing/PackageEditModalBilling";
import MembershipEditModel from "../../components/model/billing/MembershipEditModel";
import CustomerMembershipVisitBaseModal from "../../components/model/billing/CustomerMembershipVisitBaseModal";
import CustomerMembershipDiscountModal from "../../components/model/billing/CustomerMembershipDiscountModal";
import CustomerMembershipPrepaidModal from "../../components/model/billing/CustomerMembershipPrepaidModal";
import { useLocation } from "react-router-dom";
// import { currencySymbol } from "../../constants/currencySymbol";
import CustomerDebtModal from "../../components/model/billing/CustomerDebtModal";
import { useCurrency } from "../../contexts/CurrencyContext";

const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const memberinfo = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_MemberInfo_Empty.png`;
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const emptyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noResults.png`;
const maleIcon = `${process.env.PUBLIC_URL}/images/logo/male-icon.png`;
const femaleIcon = `${process.env.PUBLIC_URL}/images/logo/female-icon.png`;
const exchangeIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Exchange.png`;
const sortIcon = `${process.env.PUBLIC_URL}/images/logo/sorticon.png`;
const sortActiveIcon = `${process.env.PUBLIC_URL}/images/logo/sorticon-active.png`;
const imageIcon = `${process.env.PUBLIC_URL}/images/logo/imageicon.png`;
const imageActiveIcon = `${process.env.PUBLIC_URL}/images/logo/imageicon-active.png`;
const emptyImageIcon = `${process.env.PUBLIC_URL}/images/logo/Def_BaseInfo_noCoverImg.png`;
const giftcardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_GiftCard.png`;
const packagecardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_PackageCard.png`;
const giftcardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_GiftCard.png`;
const packageCardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Package.png`;
const memberInfoGiftCard = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoGiftCard.png`;
const memberInfoPrivilege = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoPrivilege.png`;
const MemberInfoPackage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoPackage.png`;

const customizeIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Customize.png`;
const membershipbgdDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Discount.png`;
const membershipbgdPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Store.png`;
const membershipbgdVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Times.png`;
const membershipimgDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Discount.png`;
const membershipimgPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Store.png`;
const membershipimgVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Times.png`;
const bellIcon = `${process.env.PUBLIC_URL}/images/logo/bell-icon.png`;
const BillingPage: React.FC = () => {
  let { id } = useParams();
  const { currencySymbol } = useCurrency();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const onlinesalescustomerid = queryParams.get("customerid");
  const debt = queryParams.get("debt");
  const course = queryParams.get("course");
  const kiv = queryParams.get("kiv");
  const itemid = queryParams.get("itemid");
  const refno = queryParams.get("refno");
  const itemname = queryParams.get("name");
  const navigate = useNavigate();

  const [activeMainTab, setActiveMainTab] = useState("Services");
  const [activeSubTab, setActiveSubTab] = useState("All");
  const [activeProductSubTab, setActiveProductSubTab] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [maleCount, setMaleCount] = useState("1"); //for show male count
  const [femaleCount, setFemaleCount] = useState("0"); //for show female count
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [showSingleDateSelectionModal, setShowSingleDateSelectionModal] =
    useState(false);
  const [isDeleteMemberModalVisible, setIsDeleteMemberModalVisible] =
    useState(false);
  const [isDeleteServiceModalVisible, setIsDeleteServiceModalVisible] =
    useState(false);
  const [debtamount, setdebtamount] = useState(0);
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [billInfoModalVisible, setBillInfoModalVisible] = useState(false);
  const [refNum, setRefNum] = useState("");
  const [selectedCustomerSource, setSelectedCustomerSource] = useState("");
  const [selectedReferrer, setSelectedReferrer] = useState("");
  const [selectedReferralType, setSelectedReferralType] = useState("");
  const [selectedReferrerId, setSelectedReferrerId] = useState(null);
  const [serviceCategory, setServiceCategory] = useState<any[]>([]);
  const [selectedServiceTab, setSelectedServiceTab] = useState<any>("null");
  const [selectedProductTab, setSelectedProductTab] = useState<any>("null");
  const [productCategory, setProductCategory] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState("Product");
  const [productItems, setProductItems] = useState<any[]>([]);
  const [serviceItems, setServiceItems] = useState<any[]>([]);
  const [giftcardsItems, setGiftcardsItems] = useState<any[]>([]);
  const [tempgiftcardItems, setTempGiftcardItems] = useState<any[]>([]);
  const [packagesItems, setPackagesItems] = useState<any[]>([]);
  const [tempPackagesItems, setTempPackagesItems] = useState<any[]>([]);
  const [tempProductItems, setTempProductItems] = useState<any[]>([]);
  const [tempServiceItems, setTempServiceItems] = useState<any[]>([]);
  const [customerGiftCardData, setCustomerGiftCardData] = useState<any[]>([]);
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [tempCartItems, setTempCartItems] = useState<any[]>([]);
  const [taxesItems, setTaxesItems] = useState<any[]>([]);
  const [customerSources, setCustomerSources] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedCartItem, setSelectedCartItem] = useState<any>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountType, setDiscountType] = useState("%");
  const [cartItemValue, setcartItemValue] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const [sortServiceCol, setSortServiceCol] = useState("Default");
  const [sortProductCol, setSortProductCol] = useState("Default");
  const [total, setTotal] = useState(0);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [customerGiftCardDataUpdate, setCustomerGiftCardDataUpdate] =
    useState(false);
  const [customerPackageDataDataUpdate, setCustomerPackageDataDataUpdate] =
    useState(false);
  const [applicableModelVisible, setApplicableModelVisible] =
    useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const [selectedApplicableType, setSelectedApplicableType] =
    useState<string>("");
  const [isCustomerPrivilegeVisible, setIsCustomerPrivilegeVisible] =
    useState(false);
  const [showCustomerGiftCardModal, setShowCustomerGiftCardModal] =
    useState(false);
  const [showCustomerDebtModal, setShowCustomerDebtModal] = useState(false);
  const [showCustomerPackageModal, setShowCustomerPackageModal] =
    useState(false);
  const [showCustomerPrepaidModal, setShowCustomerPrepaidModal] =
    useState(false);
  const [debtModalvisible, setDebtModalvisible] = useState(false);
  const [showCustomerDiscountModal, setShowCustomerDiscountModal] =
    useState(false);
  const [showCustomerVisitBasedModal, setShowCustomerVisitBasedModal] =
    useState(false);
  const [customerCount, setCustomerCount] = useState<any[]>([]);
  const [customerCountChange, setCustomerCountChanges] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<any>([]);
  const [myItemData, setMyItemData] = useState<any[]>([]);
  const [onlinesalesData, setonlinesalesData] = useState<any[]>([]);
  const [kivData, setkivData] = useState<any[]>([]);
  const [courseData, setcourseData] = useState<any[]>([]);
  const [membershipItems, setMembershipItems] = useState<any[]>([]);
  const [tempMembershipItems, setTempMembershipItems] = useState<any[]>([]);
  const [searchPackageValue, setSearchPackageValue] = useState("");
  const [searchMembershipValue, setSearchMembershipValue] = useState("");
  const [customerMembershipDiscountData, setCustomerMembershipDiscountData] =
    useState<any[]>([]);
  const [customerMembershipPrepaidData, setCustomerMembershipPrepaidData] =
    useState<any>([]);
  const [customerMemberDebtData, setCustomerMemberDebtData] = useState<any>([]);
  const [
    customerMembershipVisitBasedData,
    setCustomerMembershipVisitBasedData,
  ] = useState<any>([]);

  const handleClearPackageSearch = () => {
    setSearchPackageValue("");
  };

  const handleClearMembershipSearch = () => {
    setSearchMembershipValue("");
  };

  const handlePackageItemSearch = (e: any) => {
    setSearchPackageValue(e.target.value);
    let filteredPackage = [];

    if (e.target.value !== "") {
      filteredPackage = packagesItems.filter(
        (packages) =>
          packages.name.includes(e.target.value) ||
          packages.code.includes(e.target.value) ||
          packages.sellprice.toString().includes(e.target.value)
      );
    } else {
      filteredPackage = packagesItems;
    }

    setTempPackagesItems(filteredPackage);
  };

  const handleMembershipItemSearch = (e: any) => {
    setSearchMembershipValue(e.target.value);
    let filteredMembership = [];

    if (e.target.value !== "") {
      filteredMembership = membershipItems.filter(
        (membership) =>
          membership.name.includes(e.target.value) ||
          membership.code.includes(e.target.value)
        // ||
        // membership.sellprice.toString().includes(e.target.value)
      );
    } else {
      filteredMembership = membershipItems;
    }

    setTempMembershipItems(filteredMembership);
  };

  const toggleDetails = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  const handleCustomerToggle = () => {
    // setIsCustomerPrivilegeVisible(!isCustomerPrivilegeVisible);
    setIsCustomerPrivilegeVisible((prevState) => {
      const newState = !prevState;
      // Call the API only when the toggle is opened (newState becomes true)
      return newState;
    });
  };

  const closeDeleteMemberModal = () => {
    setIsDeleteMemberModalVisible(false);
  };

  const handleConfirmMember = () => {
    setIsDeleteMemberModalVisible(false);
    setSelectedCustomer(null);
    setCustomerGiftCardData([]);
    setCustomerGiftCardDataUpdate(!customerGiftCardDataUpdate);
    setCustomerPackageDataDataUpdate(!customerPackageDataDataUpdate);
    setMyItemData([]);
    setCustomerCount([]);
    setCustomerMembershipPrepaidData([]);
    setCustomerMembershipDiscountData([]);
    setCustomerMembershipVisitBasedData([]);
    setonlinesalesData([]);
    setCartItems((prevItems) =>
      prevItems.filter(
        (item) =>
          item.item_type !== "Debt" &&
          item.item_type !== "kiv" &&
          item.item_type !== "course"
      )
    );

    setTempCartItems((prevItems) =>
      prevItems.filter(
        (item) =>
          item.item_type !== "Debt" &&
          item.item_type !== "kiv" &&
          item.item_type !== "course"
      )
    );
    setkivData([]);
    setcourseData([]);
    setdebtamount(0);
    setIsCustomerPrivilegeVisible(false);
    setCustomerCountChanges(!customerCountChange);
  };

  const closeDeleteServiceModal = () => {
    setIsDeleteServiceModalVisible(false);
  };

  const handleConfirmService = () => {
    setIsDeleteServiceModalVisible(false);
    setCartItems(
      cartItems.filter(
        (item) => item.item_unique_id !== selectedItem.item_unique_id
      )
    );
    setTempCartItems(
      tempCartItems.filter(
        (item) => item.item_unique_id !== selectedItem.item_unique_id
      )
    );
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <div>
            <Button
              icon={
                <EditOutlined style={{ marginRight: "4px", color: "blue" }} />
              }
              style={{
                color: "blue",
                backgroundColor: "#eoeoef",
                padding: "4px 8px",
                borderRadius: "4px",
                textDecoration: "none",
                border: "none",
              }}
              onClick={() => {
                setShowDiscountModal(true);
              }}
            >
              Edit cart discount
            </Button>
          </div>
        </>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <>
    //       <FileExcelOutlined style={{ marginRight: "4px", color: "red" }} />
    //       <a
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         href="/"
    //         style={{
    //           color: "red",
    //           // backgroundColor: ,
    //           padding: "4px 8px",
    //           borderRadius: "4px",
    //           textDecoration: "none",
    //         }}
    //       >
    //         Cancel sale
    //       </a>
    //     </>
    //   ),
    // },
  ];

  const handleProductMenuClick = (e: any) => {
    setSortProductCol(e.key);

    let filteredProducts = [...productItems];

    // Filter and sort based on selected menu item
    if (e.key === "priceHighToLow") {
      filteredProducts.sort((a, b) => b.sellprice - a.sellprice);
    } else if (e.key === "priceLowToHigh") {
      filteredProducts.sort((a, b) => a.sellprice - b.sellprice);
    } else if (e.key === "codeASC") {
      filteredProducts.sort((a, b) => a.code.localeCompare(b.code));
    } else if (e.key === "codeDESC") {
      filteredProducts.sort((a, b) => b.code.localeCompare(a.code));
    } else if (e.key === "nameAZ") {
      filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
    } else if (e.key === "nameZA") {
      filteredProducts.sort((a, b) => b.name.localeCompare(a.name));
    } else if (e.key === "createdFirst") {
      filteredProducts.sort((a, b) => {
        const dateA = new Date(b.created_at);
        const dateB = new Date(a.created_at);

        return dateB.getTime() - dateA.getTime();
      });
    } else if (e.key === "createdLast") {
      filteredProducts.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        return dateB.getTime() - dateA.getTime();
      });
    } else if (e.key === "categoryAZ") {
      filteredProducts.sort((a, b) =>
        a.categoryname.localeCompare(b.categoryname)
      );
    } else if (e.key === "categoryZA") {
      filteredProducts.sort((a, b) =>
        b.categoryname.localeCompare(a.categoryname)
      );
    } else {
      filteredProducts = productItems;
    }

    setTempProductItems(filteredProducts);
  };

  const handleServiceMenuClick = (e: any) => {
    setSortServiceCol(e.key);

    let filteredServices = [...serviceItems];

    if (e.key === "priceHighToLow") {
      filteredServices.sort((a, b) => b.sellprice - a.sellprice);
    } else if (e.key === "priceLowToHigh") {
      filteredServices.sort((a, b) => a.sellprice - b.sellprice);
    } else if (e.key === "codeASC") {
      filteredServices.sort((a, b) => a.code.localeCompare(b.code));
    } else if (e.key === "codeDESC") {
      filteredServices.sort((a, b) => b.code.localeCompare(a.code));
    } else if (e.key === "nameAZ") {
      filteredServices.sort((a, b) => a.name.localeCompare(b.name));
    } else if (e.key === "nameZA") {
      filteredServices.sort((a, b) => b.name.localeCompare(a.name));
    } else if (e.key === "createdFirst") {
      filteredServices.sort((a, b) => {
        const dateA = new Date(b.created_at);
        const dateB = new Date(a.created_at);
        return dateB.getTime() - dateA.getTime();
      });
    } else if (e.key === "createdLast") {
      filteredServices.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        return dateB.getTime() - dateA.getTime();
      });
    } else if (e.key === "categoryAZ") {
      filteredServices.sort((a, b) =>
        a.categoryname.localeCompare(b.categoryname)
      );
    } else if (e.key === "categoryZA") {
      filteredServices.sort((a, b) =>
        b.categoryname.localeCompare(a.categoryname)
      );
    } else {
      filteredServices = serviceItems;
    }

    setTempServiceItems(filteredServices);
  };

  const productMenu = (
    <Menu onClick={handleProductMenuClick} selectedKeys={[sortProductCol]}>
      <Menu.Item key="Default">Default</Menu.Item>
      <Menu.Item key="priceHighToLow">Price (high to low)</Menu.Item>
      <Menu.Item key="priceLowToHigh">Price (low to high)</Menu.Item>
      <Menu.Item key="codeASC">Code (ascending)</Menu.Item>
      <Menu.Item key="codeDESC">Code (descending)</Menu.Item>
      <Menu.Item key="nameAZ">Name (A-Z)</Menu.Item>
      <Menu.Item key="nameZA">Name (Z-A)</Menu.Item>
      <Menu.Item key="createdFirst">Created (newest first)</Menu.Item>
      <Menu.Item key="createdLast">Created (oldest first)</Menu.Item>
      <Menu.Item key="categoryAZ">Category (A-Z)</Menu.Item>
      <Menu.Item key="categoryZA">Category (Z-A)</Menu.Item>
    </Menu>
  );
  const serviceMenu = (
    <Menu onClick={handleServiceMenuClick} selectedKeys={[sortServiceCol]}>
      <Menu.Item key="Default">Default</Menu.Item>
      <Menu.Item key="priceHighToLow">Price (high to low)</Menu.Item>
      <Menu.Item key="priceLowToHigh">Price (low to high)</Menu.Item>
      <Menu.Item key="codeASC">Code (ascending)</Menu.Item>
      <Menu.Item key="codeDESC">Code (descending)</Menu.Item>
      <Menu.Item key="nameAZ">Name (A-Z)</Menu.Item>
      <Menu.Item key="nameZA">Name (Z-A)</Menu.Item>
      <Menu.Item key="createdFirst">Created (newest first)</Menu.Item>
      <Menu.Item key="createdLast">Created (oldest first)</Menu.Item>
      <Menu.Item key="categoryAZ">Category (A-Z)</Menu.Item>
      <Menu.Item key="categoryZA">Category (Z-A)</Menu.Item>
    </Menu>
  );

  const handleDateSelected = (date: Dayjs) => {
    let futuredate = dayjs(date).isAfter(dayjs());
    if (!futuredate) {
      setSelectedDate(date);
    } else {
      message.error(
        "Affected by parameter settings - bill date does not allow selection of future dates."
      );
    }
  };

  const handleRefNumSelection = () => {
    setShowRefNumModal(true);
  };

  const handleSingleDateSelection = () => {
    setShowSingleDateSelectionModal(true);
  };

  const handleSingleDateSelectionClose = () => {
    setShowSingleDateSelectionModal(false);
  };

  const handleDiscountValue = (data: any) => {
    setDiscountValue(data.discountvalue);
    setDiscountAmount(data.discountamount);
    setDiscountType(data.discounttype);
    setShowDiscountModal(false);
  };

  const handledebtCancel = () => {
    setDebtModalvisible(false);
  };

  useEffect(() => {
    if (customerMemberDebtData.length > 0) {
      // Update cartItems with the updated debt details in debtdetails
      setCartItems((prevItems) => {
        return prevItems.map((item) => {
          if (item.item_name === "Debt") {
            // Update the debtdetails for debt items in the cart
            return {
              ...item,
              debtdetails: customerMemberDebtData,
            };
          }
          return item;
        });
      });
    }
  }, [customerMemberDebtData]);

  const handledebtClear = async () => {
    if (customerMemberDebtData.length == 0) {
      await getCustomerDebt(selectedCustomer.id);
    }
    if (debtamount > 0) {
      await setDebtModalvisible(false);
      const debtItemExists = cartItems.some(
        (item) => item.item_type === "Debt"
      );

      // If a debt item already exists, don't add it again
      if (debtItemExists) {
        message.warning("Debt already exists in the cart!");
        return; // Exit the function early
      }
      const item = {
        item_unique_id: generateUniqueId(),
        item_id: generateUniqueId(),
        item_code: generateUniqueId(),
        item_categorycode: null,
        item_name: "Debt",
        item_note: "",
        item_tax: "",
        item_tax_rate: "",
        item_discount_percentage: "0",
        item_qty: "1",
        item_privilege_id: null,
        item_privilege_name: null,
        deduction_name: "",
        item_deduction: false,
        item_type: "Debt",
        staff: [],
        item_price: debtamount,
        item_pay_amount: debtamount * 1,
        total: debtamount * 1,
        debtdetails: customerMemberDebtData,
      };
      message.success("The new item added successfully!");
      await setCartItems((prevItems) => [...prevItems, item]);
      await setTempCartItems((prevItems) => [...prevItems, item]);
      await setShowCustomerDebtModal(false);
    }
  };

  // Handle main tab changes
  const handleMainTabChange = (key: string) => {
    setActiveMainTab(key);
    if (key == "Services") {
      setActiveSubTab("All");
      if (serviceCategory && serviceCategory.length == 0) {
        fetchServiceCategory();
      }
      if (serviceItems && serviceItems.length == 0) {
        fetchServices();
      }
    }
    if (key == "Products") {
      setActiveProductSubTab("All");
      if (productCategory && productCategory.length == 0) {
        fetchProductCategory();
      }
      if (productItems && productItems.length == 0) {
        fetchProducts();
      }
    }
    if (key == "Giftcard") {
      if (giftcardsItems && giftcardsItems.length == 0) {
        fetchGiftcards();
      }
    }
    if (key == "Packages") {
      if (packagesItems && packagesItems.length == 0) {
        fetchPackages();
      }
    }
    if (key == "Memberships") {
      if (membershipItems && membershipItems.length == 0) {
        fetchMembership();
      }
    }
    if (key == "Onlinesales") {
      if (onlinesalesData && onlinesalesData.length == 0) {
        fetchMembership();
      }
    }
  };
  // Handle sub-tab changes for Services
  const handleSubTabChange = (key: string) => {
    setActiveSubTab(key);
  };

  // Handle sub-tab changes for Products
  const handleProductSubTabChange = (key: string) => {
    setActiveProductSubTab(key);
  };

  // Handle search input changes
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const [showServiceSearchInput, setShowServiceSearchInput] = useState(false);
  const [searchServiceValue, setSearchServiceValue] = useState("");

  const [showProductSearchInput, setShowProductSearchInput] = useState(false);
  const [searchProductValue, setSearchProductValue] = useState("");
  const [searchGiftcardValue, setSearchGiftcardValue] = useState("");
  const [showServiceImage, setShowServiceImage] = useState(false);
  const [showProductImage, setShowProductImage] = useState(false);

  const [showProductModal, setShowProductModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);

  const [showMemberEditModal, setShowMembershipEditModal] = useState(false);
  const [showProductEditModal, setShowProductEditModal] = useState(false);
  const [showServiceEditModal, setShowServiceEditModal] = useState(false);
  const [showGiftcardEditModal, setShowGiftcardEditModal] = useState(false);
  const [showPackageEditModal, setShowPackageEditModal] = useState(false);
  const [showPackageEditModalBilling, setShowPackageEditModalBilling] =
    useState(false);

  const [showNotesEditModal, setShowNotesEditModal] = useState(false);
  const [ShowProductStaffEditModal, setShowProductStaffEditModal] =
    useState(false);
  const [ShowServiceStaffEditModal, setShowServiceStaffEditModal] =
    useState(false);

  const [showDiscountModal, setShowDiscountModal] = useState(false);

  const [showRefNumModal, setShowRefNumModal] = useState(false);

  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const [notesValue, setNotesValue] = useState("");

  const [payNowClick, setPayNowClick] = useState(false);

  const handleShowCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const handleShowCustomerCancelModal = () => {
    setShowCustomerModal(false);
  };

  const selectShowProductModal = (item: any) => {
    setShowProductModal(true);
    setSelectedItem(item);
    setSelectedType("Product");
  };

  const selectShowMembershipModal = (item: any) => {
    setShowProductModal(true);
    setSelectedItem(item);
    setSelectedType("Membership");
  };

  const selectShowOnlinesales = (selecteditem: any) => {
    const onlinesalesItem = cartItems.filter(
      (item) => item.item_id === selecteditem.id
    );
    if (onlinesalesItem && onlinesalesItem.length == 0) {
      const item = {
        item_unique_id: generateUniqueId(),
        item_id: selecteditem.id,
        item_code: selecteditem.ref_no,
        item_categorycode: null,
        item_name: "onlinesales",
        item_note: "",
        item_tax: "",
        item_tax_rate: "",
        item_discount_percentage: 0,
        item_qty: 1,
        item_privilege_id: null,
        item_privilege_name: null,
        deduction_name: "",
        item_deduction: false,
        item_type: "onlinesales",
        staff: selectedStaff,
        item_price: selecteditem.remainamt,
        item_pay_amount: selecteditem.remainamt * 1,
        total: selecteditem.remainamt * 1,
      };
      setCartItems((prevItems) => [...prevItems, item]);
      setTempCartItems((prevItems) => [...prevItems, item]);
      message.success("The new item added successfully!");
    } else {
      message.error("Already added!");
    }
    // setSelectedItem(item);
    // addToCart("onlinesales", [], item);
  };

  const selectShowCourse = (selecteditem: any) => {
    const onlinesalesItem = cartItems.filter(
      (item) => item.item_id === selecteditem.id
    );
    if (onlinesalesItem && onlinesalesItem.length == 0) {
      const item = {
        item_unique_id: generateUniqueId(),
        item_id: selecteditem.id,
        item_code: selecteditem.ref_no,
        item_categorycode: null,
        item_name: selecteditem.item_name,
        item_note: "",
        item_tax: "",
        item_tax_rate: "",
        item_discount_percentage: 0,
        item_qty: selecteditem.remaining_balance,
        item_privilege_id: null,
        item_privilege_name: null,
        deduction_name: "",
        item_deduction: true,
        item_type: "course",
        staff: selectedStaff,
        item_price: "0",
        item_pay_amount: "0",
        total: "0",
      };
      setCartItems((prevItems) => [...prevItems, item]);
      setTempCartItems((prevItems) => [...prevItems, item]);
      message.success("The new item added successfully!");
    } else {
      message.error("Already added!");
    }
    // setSelectedItem(item);
    // addToCart("course", [], item);
  };

  const selectShowKIV = (selecteditem: any) => {
    const onlinesalesItem = cartItems.filter(
      (item) => item.item_id === selecteditem.id
    );
    if (onlinesalesItem && onlinesalesItem.length == 0) {
      const item = {
        item_unique_id: generateUniqueId(),
        item_id: selecteditem.id,
        item_code: selecteditem.ref_no,
        item_categorycode: null,
        item_name: selecteditem.item_name,
        item_note: "",
        item_tax: "",
        item_tax_rate: "",
        item_discount_percentage: 0,
        item_qty: selecteditem.remaining_balance,
        item_privilege_id: null,
        item_privilege_name: null,
        deduction_name: "",
        item_deduction: true,
        item_type: "kiv",
        staff: selectedStaff,
        item_price: "0",
        item_pay_amount: "0",
        total: "0",
      };
      setCartItems((prevItems) => [...prevItems, item]);
      setTempCartItems((prevItems) => [...prevItems, item]);
      message.success("The new item added successfully!");
    } else {
      message.error("Already added!");
    }
  };

  const selectShowGiftcardModal = (item: any) => {
    setShowProductModal(true);
    setSelectedItem(item);
    setSelectedType("Giftcard");
  };

  const selectShowPackageModal = (item: any) => {
    // setShowPackageEditModal(true);
    setShowPackageEditModal(true);
    // setTimeout(() => {
    //   setShowProductModal(true);
    // }, 100);
    setSelectedItem(item);
    setSelectedType("Package");
  };

  const selectShowServiceModal = (item: any) => {
    setShowServiceModal(true);
    setSelectedItem(item);
  };

  const generateUniqueId = () => {
    const uniqueId = nanoid(8); // Generates a unique 8-character string
    return uniqueId;
  };

  //This is common function to call for add item like service, product,giftcard, etc. to cartdata for buy
  const addToCart = (type: string, selectedStaff: any, data?: any) => {
    if (selectedItem && selectedStaff) {
      if (type == "Package") {
        const newCartItem = {
          item_unique_id: generateUniqueId(),
          item_id: data.id,
          item_code: data.package_code,
          item_categorycode: null,
          item_name: data.package_name,
          item_price: data.total_amount,
          item_note: data.note,
          item_tax: "",
          item_tax_rate: "",
          item_discount_percentage: 0,
          item_pay_amount: data.total_amount * 1,
          item_qty: 1,
          item_type_validity: data.typeof_validity,
          ...(data.typeof_validity === "Custom" && {
            item_validfor: `${data.valid_startdate} ~ ${data.valid_enddate}`,
          }),
          ...(data.typeof_validity !== "Custom" && {
            item_validfor: `${data.valid_count} ~ ${data.valid_countperiod}`,
          }),
          item_privilege_id: null,
          item_privilege_name: null,
          deduction_name: "",
          item_deduction: false,
          total: data.total_amount * 1,
          item_type: type,
          staff: selectedStaff.map((staffItem: any) => ({
            staff_id: staffItem.id,
            staff_name: staffItem.name,
            staff_code: staffItem.staffcode,
            department_id: staffItem.department,
            department_name: staffItem.department_name,
            staff_percentage: staffItem.percentage,
          })),
          products: data.products.map((item: any) => {
            if (item.combo) {
              return {
                ...item,
                combo: item.combo.map((comboItem: any) => ({
                  ...comboItem,
                  qty: "1",
                })),
              };
            }
            return item;
          }),
          services: data.services.map((item: any) => {
            if (item.combo) {
              return {
                ...item,
                combo: item.combo.map((comboItem: any) => ({
                  ...comboItem,
                  qty: "1",
                })),
              };
            }
            return item;
          }),
        };
        setCartItems([...cartItems, newCartItem]);
        setTempCartItems([...tempCartItems, newCartItem]);
        setCustomerGiftCardDataUpdate(!customerGiftCardDataUpdate);
        setCustomerPackageDataDataUpdate(!customerPackageDataDataUpdate);
        message.success("The new item added successfully!");
        setSelectedItem(null);
        setShowPackageEditModal(false);
      } else {
        if (type == "onlinesales") {
          const onlinesalesItem = cartItems.filter(
            (item) => item.item_id === data.id
          );
          if (onlinesalesItem && onlinesalesItem.length == 0) {
            const newCartItem = {
              item_unique_id: generateUniqueId(),
              item_id: data.id,
              item_code: data.ref_no,
              item_categorycode: null,
              item_name: type,
              item_note: "",
              item_tax: "",
              item_tax_rate: "",
              item_discount_percentage: 0,
              item_qty: 1,
              item_privilege_id: null,
              item_privilege_name: null,
              deduction_name: "",
              item_deduction: false,
              item_type: type,
              staff: selectedStaff,
              item_price: data.remainamt,
              item_pay_amount: data.remainamt * 1,
              total: data.remainamt * 1,
            };
            setCartItems([...cartItems, newCartItem]);
            setTempCartItems([...tempCartItems, newCartItem]);
            message.success("The new item added successfully!");
            setSelectedItem(null);
          } else {
            message.error("Already added!");
            setSelectedItem(null);
          }
        } else {
          const newCartItem = {
            item_unique_id: generateUniqueId(),
            item_id: selectedItem.id,
            item_code: selectedItem.code,
            item_categorycode: selectedItem.categorycode || null,
            item_name: selectedItem.name,
            item_note: "",
            item_tax: selectedItem.taxunit || "",
            item_tax_rate: selectedItem.tax || "",
            item_discount_percentage: 0,
            item_qty: 1,
            item_privilege_id: null,
            item_privilege_name: null,
            deduction_name: "",
            item_deduction: false,
            item_type: type,
            staff: selectedStaff
              .filter(
                (staffItem: any) =>
                  staffItem.selectedStaff ||
                  type == "Product" ||
                  type == "Giftcard" ||
                  type == "Package" ||
                  type == "Membership"
              ) // Filter out items where staff is null
              .map((staffItem: any) => ({
                staff_id:
                  type === "Service"
                    ? staffItem.selectedStaff.id
                    : staffItem.id,
                staff_name:
                  type === "Service"
                    ? staffItem.selectedStaff.name
                    : staffItem.name,
                staff_code:
                  type === "Service"
                    ? staffItem.selectedStaff.staffcode
                    : staffItem.staffcode,
                department_id:
                  type === "Service"
                    ? staffItem.selectedStaff.department
                    : staffItem.department,
                department_name:
                  type === "Service"
                    ? staffItem.selectedStaff.department_name
                    : staffItem.department_name,
                ...(type === "Product" && {
                  staff_percentage: staffItem.percentage,
                }),
                ...(type === "Giftcard" && {
                  staff_percentage: staffItem.percentage,
                }),
                ...(type === "Package" && {
                  staff_percentage: staffItem.percentage,
                }),
                ...(type === "Membership" && {
                  staff_percentage: staffItem.percentage,
                }),
                ...(type === "Service" && {
                  staff_service_id: staffItem.id,
                  service_category_id: staffItem.selectedStaff.staffcategory,
                  staff_percentage: staffItem.selectedStaff.percentage,
                }),
              })),
            ...(type === "Package" && {
              item_price: selectedItem.sellprice,
              item_pay_amount: selectedItem.sellprice * 1,
              total: selectedItem.sellprice * 1,
            }),
            ...(type === "Giftcard" && {
              item_price: selectedItem.sellprice,
              item_pay_amount: selectedItem.sellprice * 1,
              total: selectedItem.sellprice * 1,
            }),
            ...(type === "Product" && {
              item_price: selectedItem.sellprice,
              item_pay_amount: selectedItem.sellprice * 1,
              total: selectedItem.sellprice * 1,
            }),
            ...(type === "Service" && {
              item_price: selectedItem.sellprice,
              item_pay_amount: selectedItem.sellprice * 1,
              total: selectedItem.sellprice * 1,
              service_consumption: [], // Add this line for Service type
            }),
            ...(type === "Membership" && {
              item_details_type: selectedItem.membership_type,
              prepaid_type: selectedItem.type || "",
              selling_price: selectedItem.sellprice,
              serviceid: selectedItem.serviceid,
              servicename: selectedItem.servicename,
              value: selectedItem.prepaid_value || 0,
              bonuse: selectedItem.prepaid_bonuse || 0,
              item_membership_discount: "0",
              item_price:
                selectedItem.membership_type == "Prepaid"
                  ? Number(selectedItem.sellprice)
                  : selectedItem.membership_type == "Discount"
                  ? selectedItem.sellprice
                  : selectedItem.totalprice,
              item_pay_amount:
                selectedItem.membership_type == "Prepaid"
                  ? Number(selectedItem.sellprice) * 1
                  : selectedItem.membership_type == "Discount"
                  ? selectedItem.sellprice * 1
                  : selectedItem.totalprice * 1,
              total:
                selectedItem.membership_type == "Prepaid"
                  ? Number(selectedItem.sellprice) * 1
                  : selectedItem.membership_type == "Discount"
                  ? selectedItem.sellprice * 1
                  : selectedItem.totalprice * 1,
              product_discount:
                selectedItem.membership_type == "Prepaid"
                  ? selectedItem.pre_product_discount
                  : selectedItem.membership_type == "Discount"
                  ? selectedItem.product_discount
                  : 0,
              service_discount:
                selectedItem.membership_type == "Prepaid"
                  ? selectedItem.pre_service_discount
                  : selectedItem.membership_type == "Discount"
                  ? selectedItem.service_discount
                  : 0,
              privilege_discount:
                selectedItem.membership_type == "Prepaid"
                  ? selectedItem.pre_privilege_discount
                  : selectedItem.membership_type == "Discount"
                  ? selectedItem.privilege_discount
                  : 0,
              typeof_validity: selectedItem.typeof_validity || "",
              valid_startdate:
                selectedItem?.valid_startdate !== null
                  ? selectedItem?.valid_startdate
                  : "",
              valid_enddate:
                selectedItem?.valid_startdate !== null
                  ? selectedItem.valid_enddate
                  : "",
              valid_count: selectedItem.valid_count || 0,
              valid_countperiod: selectedItem.valid_countperiod || "",
              usage_limit: selectedItem.usage_limit || false,
              buyprice: selectedItem.buyprice,
              giveaway: selectedItem.giveaway,
              max_usage: selectedItem.max_usage || false,
              limited_use: selectedItem.limited_use || false,
            }),
            ...(type === "Giftcard" && {
              item_giftcard_type: selectedItem.type,
              item_value: selectedItem.sessionvalue,
              item_singleqty: selectedItem.singleusageqty,
              item_validfor: selectedItem.validfor,
              item_type_validity: selectedItem.typeof_validity,
              item_valid_count: selectedItem.valid_count,
              item_valid_countperiod: selectedItem.valid_countperiod,
            }),
          };
          setCartItems([...cartItems, newCartItem]);
          setTempCartItems([...tempCartItems, newCartItem]);
          setCustomerGiftCardDataUpdate(!customerGiftCardDataUpdate);
          setCustomerPackageDataDataUpdate(!customerPackageDataDataUpdate);
          message.success("The new item added successfully!");
          setSelectedItem(null);
        }
      }
    }
  };

  const handleServiceStaffSelection = (staff: any) => {
    // setSelectedStaff(staff);
    setShowProductModal(false);
    addToCart("Service", staff);
  };

  const getGiftcardData = async (id: any) => {
    try {
      const url = `/billing/customer/giftcarddetails/${id}/${localStorage.getItem(
        "outlet_id"
      )}`;
      const response = await get(url);

      if (response?.data?.success) {
        const customerGiftCard = response?.data?.data;
        setCustomerGiftCardData(customerGiftCard);
        // setCustomerGiftCardDataUpdate(!customerGiftCardDataUpdate);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  const getCustomerPackageMyItem = async (id: any) => {
    try {
      const url = `/billing/customer/myitems/${id}`;
      const response = await get(url);

      if (response?.data?.success) {
        const myItem = response?.data?.data;
        setMyItemData(myItem);
        setCustomerPackageDataDataUpdate(!customerPackageDataDataUpdate);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error while fetching my items: ", error);
    }
  };

  const getCustomerDebt = async (id: any) => {
    try {
      const url = `/billing/customer/debtdetails/${id}/${localStorage.getItem(
        "outlet_id"
      )}`;
      const response = await get(url);

      if (response?.data?.success) {
        const Data = response?.data?.debts;
        setCustomerMemberDebtData(Data);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error while fetching my items: ", error);
    }
  };
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      // if (myItemData && myItemData.length > 0) {
      //   const myItemPackages = myItemData.filter(
      //     (item) => item.billing_type === "Package"
      //   );
      //   const visitBased = myItemData.filter(
      //     (item) => item.billing_type === "Visit-based"
      //   );

      //   if (myItemPackages.length > 0) {
      //     const updatedCartData = [...cartItems];
      //     const itemUsageTracker: any = {}; // To track used quantities for items.

      //     const getAllServices = (pkg: any) => [
      //       ...(pkg.services?.single || []),
      //       ...(pkg.services?.combo || []),
      //     ];
      //     const getAllProducts = (pkg: any) => [
      //       ...(pkg.products?.single || []),
      //       ...(pkg.products?.combo || []),
      //     ];

      //     updatedCartData.forEach((cartItem, index) => {
      //       let matchedPackage: any = null;
      //       let remainingQty = 0;

      //       // Check for services
      //       myItemPackages.forEach((pkg) => {
      //         getAllServices(pkg).forEach((service) => {
      //           if (service.service_id === cartItem.item_id) {
      //             const maxQty = parseInt(service.item_wise_qty || "0", 10);
      //             const usedQty = itemUsageTracker[cartItem.item_id] || 0;
      //             remainingQty += Math.max(0, maxQty - usedQty); // Accumulate remaining qty
      //           }
      //         });
      //       });

      //       if (remainingQty > 0) {
      //         matchedPackage = myItemPackages.find((pkg) =>
      //           getAllServices(pkg).some(
      //             (service) => service.service_id === cartItem.item_id
      //           )
      //         );
      //       } else {
      //         // Check for products if no service match
      //         myItemPackages.forEach((pkg) => {
      //           getAllProducts(pkg).forEach((product) => {
      //             if (product.product_id === cartItem.item_id) {
      //               const maxQty = parseInt(product.item_wise_qty || "0", 10);
      //               const usedQty = itemUsageTracker[cartItem.item_id] || 0;
      //               remainingQty += Math.max(0, maxQty - usedQty); // Accumulate remaining qty
      //             }
      //           });
      //         });

      //         if (remainingQty > 0) {
      //           matchedPackage = myItemPackages.find((pkg) =>
      //             getAllProducts(pkg).some(
      //               (product) => product.product_id === cartItem.item_id
      //             )
      //           );
      //         }
      //       }

      //       console.log(updatedCartData, "updatedCartData");

      //       // Update cart item
      //       if (matchedPackage && remainingQty > 0) {
      //         if (cartItem.item_qty <= remainingQty) {
      //           updatedCartData[index] = {
      //             ...cartItem,
      //             item_privilege_id: matchedPackage.pkg_id || "",
      //             item_privilege_name: "Package",
      //             item_deduction: true,
      //           };
      //           // Update usage tracker
      //           itemUsageTracker[cartItem.item_id] =
      //             (itemUsageTracker[cartItem.item_id] || 0) + cartItem.item_qty;
      //         }
      //         // else {
      //         //   // Quantity exceeds available, reset privilege
      //         //   updatedCartData[index] = {
      //         //     ...cartItem,
      //         //     item_privilege_id: null,
      //         //     item_privilege_name: null,
      //         //     item_deduction: false,
      //         //   };
      //         // }
      //       } else {
      //         updatedCartData[index] = {
      //           ...cartItem,
      //           item_privilege_id: null,
      //           item_privilege_name: null,
      //           item_deduction: false,
      //         };
      //       }
      //     });

      //     if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
      //       setCartItems(updatedCartData);
      //     }
      //   }
      // }
      // if (myItemData && myItemData.length > 0) {
      //   const myItemPackages = myItemData.filter(
      //     (item) => item.billing_type === "Package"
      //   );
      //   const visitBased = myItemData.filter(
      //     (item) => item.billing_type === "Visit-based"
      //   );

      //   if (myItemPackages.length > 0) {
      //     const updatedCartData = [...cartItems];
      //     const itemUsageTracker: any = {}; // To track used quantities for items.

      //     const getAllServices = (pkg: any) => [
      //       ...(pkg.services?.single || []),
      //       ...(pkg.services?.combo || []),
      //     ];
      //     const getAllProducts = (pkg: any) => [
      //       ...(pkg.products?.single || []),
      //       ...(pkg.products?.combo || []),
      //     ];

      //     updatedCartData.forEach((cartItem, index) => {
      //       let matchedPackage: any = null;
      //       let remainingQty = 0;

      //       // Check for services in packages
      //       myItemPackages.forEach((pkg) => {
      //         getAllServices(pkg).forEach((service) => {
      //           if (service.service_id === cartItem.item_id) {
      //             const maxQty = parseInt(service.item_wise_qty || "0", 10);
      //             const usedQty = itemUsageTracker[cartItem.item_id] || 0;
      //             remainingQty += Math.max(0, maxQty - usedQty); // Accumulate remaining qty
      //           }
      //         });
      //       });

      //       if (remainingQty > 0) {
      //         matchedPackage = myItemPackages.find((pkg) =>
      //           getAllServices(pkg).some(
      //             (service) => service.service_id === cartItem.item_id
      //           )
      //         );
      //       } else {
      //         // Check for products in packages if no service match
      //         myItemPackages.forEach((pkg) => {
      //           getAllProducts(pkg).forEach((product) => {
      //             if (product.product_id === cartItem.item_id) {
      //               const maxQty = parseInt(product.item_wise_qty || "0", 10);
      //               const usedQty = itemUsageTracker[cartItem.item_id] || 0;
      //               remainingQty += Math.max(0, maxQty - usedQty); // Accumulate remaining qty
      //             }
      //           });

      //           if (remainingQty > 0) {
      //             matchedPackage = myItemPackages.find((pkg) =>
      //               getAllProducts(pkg).some(
      //                 (product) => product.product_id === cartItem.item_id
      //               )
      //             );
      //           }
      //         });
      //       }

      //       // Update cart item if package match is found
      //       if (matchedPackage && remainingQty > 0) {
      //         updatedCartData[index] = {
      //           ...cartItem,
      //           item_privilege_id: matchedPackage.pkg_id || "", // Set the privilege ID for the matched package
      //           item_privilege_name: "Package", // Set privilege name to 'Package'
      //           item_deduction: true, // Mark item for deduction
      //           // item_qty: remainingQty, // Set the remaining quantity based on logic
      //         };
      //         // Update usage tracker
      //         itemUsageTracker[cartItem.item_id] =
      //           (itemUsageTracker[cartItem.item_id] || 0) + cartItem.item_qty;
      //       } else {
      //         console.log("this is calling - membership prepaid apply");
      //         // Handle the case where no package is matched (else condition)

      //         let membershipValue = customerMembershipPrepaidData.map(
      //           (card: any) => ({
      //             id: card.id,
      //             remainingValue:
      //               parseFloat(card.remaining_store_balance) +
      //               parseFloat(card.remaining_bounce),
      //           })
      //         );

      //         let isExclusiveProduct = false;
      //         let isExclusiveService = false;

      //         updatedCartData[index] = {
      //           ...cartItem,
      //           item_tax: "", // Reset tax value
      //           item_tax_rate: "", // Reset tax rate
      //         };

      //         // Check for matching memberships or exclusive items
      //         let matchedMembership = null;
      //         let isApplicable = false;
      //         for (let i = 0; i < customerMembershipPrepaidData.length; i++) {
      //           const membership = customerMembershipPrepaidData[i];
      //           const membershipSession = membershipValue.find(
      //             (g: any) => g.id === membership.id
      //           );

      //           if (!membershipSession || membershipSession.remainingValue <= 0)
      //             continue;

      //           // Exclusive item handling
      //           isExclusiveProduct = membership.exclusive_product.some(
      //             (product: any) => {
      //               if (product.productid === cartItem.item_id) {
      //                 isApplicable = true;
      //                 return true;
      //               }
      //               return false;
      //             }
      //           );

      //           isExclusiveService = membership.exclusive_service.some(
      //             (service: any) => {
      //               if (service.serviceid === cartItem.item_id) {
      //                 isApplicable = true;
      //                 return true;
      //               }
      //               return false;
      //             }
      //           );

      //           if (isExclusiveProduct || isExclusiveService) {
      //             matchedMembership = membership;
      //             break;
      //           }

      //           // If item is not exclusive, check other membership rules
      //           if (
      //             (cartItem.item_type === "Product" &&
      //               membership.product_item_details.some(
      //                 (product: any) => product.id === cartItem.item_id
      //               )) ||
      //             (cartItem.item_type === "Service" &&
      //               membership.service_item_details.some(
      //                 (service: any) => service.id === cartItem.item_id
      //               ))
      //           ) {
      //             matchedMembership = membership;
      //             isApplicable = true;
      //             break;
      //           }
      //         }

      //         if (isApplicable && matchedMembership) {
      //           // Apply membership or exclusive price to item
      //           updatedCartData[index] = {
      //             ...updatedCartData[index],
      //             item_privilege_id: matchedMembership.id,
      //             item_privilege_name: `Prepaid-${matchedMembership.name}`, // Set privilege name
      //             item_membership_discount: matchedMembership.discount || "", // Set discount value
      //             item_pay_amount:
      //               cartItem.item_pay_amount -
      //               (matchedMembership.discount || 0), // Apply discount
      //           };
      //         } else {
      //           console.log("this is calling - membership discount apply");
      //           // Handle Membership Discount
      //           let matchedDiscount = null;
      //           let membershipDiscountValues =
      //             customerMembershipDiscountData.map((card: any) => ({
      //               id: card.id,
      //               remainingValue: parseFloat(card.remaining_session),
      //             }));

      //           for (
      //             let i = 0;
      //             i < customerMembershipDiscountData.length;
      //             i++
      //           ) {
      //             const membershipDiscount = customerMembershipDiscountData[i];
      //             const membershipDiscountSession =
      //               membershipDiscountValues.find(
      //                 (g: any) => g.id === membershipDiscount.id
      //               );

      //             if (
      //               !membershipDiscountSession ||
      //               membershipDiscountSession.remainingValue <= 0
      //             )
      //               continue;

      //             const isApplicable =
      //               cartItem.item_type === "Product"
      //                 ? !membershipDiscount.productnot_applicable &&
      //                   (membershipDiscount.allproduct_applicable ||
      //                     membershipDiscount.product_category_details.some(
      //                       (category: any) =>
      //                         category.id === cartItem.item_categorycode
      //                     ) ||
      //                     membershipDiscount.product_item_details.some(
      //                       (product: any) => product.id === cartItem.item_id
      //                     ))
      //                 : cartItem.item_type === "Service"
      //                 ? !membershipDiscount.servicenot_applicable &&
      //                   (membershipDiscount.allservice_applicable ||
      //                     membershipDiscount.service_category_details.some(
      //                       (category: any) =>
      //                         category.id === cartItem.item_categorycode
      //                     ) ||
      //                     membershipDiscount.service_item_details.some(
      //                       (service: any) => service.id === cartItem.item_id
      //                     ))
      //                 : null;

      //             if (isApplicable) {
      //               matchedDiscount = membershipDiscount;
      //               break;
      //             }
      //           }

      //           if (matchedDiscount) {
      //             const membershipDiscountAmount =
      //               cartItem.item_type === "Product"
      //                 ? parseFloat(matchedDiscount.product_discount)
      //                 : cartItem.item_type === "Service"
      //                 ? parseFloat(matchedDiscount.service_discount)
      //                 : 0;

      //             updatedCartData[index] = {
      //               ...updatedCartData[index],
      //               item_tax: "",
      //               item_tax_rate: "",
      //               item_privilege_id: matchedDiscount.item_id,
      //               item_privilege_name: `Discount`,
      //               item_membership_discount: membershipDiscountAmount,
      //             };
      //           } else {
      //             console.log("this is calling - giftcard apply");
      //             // Handle GiftCard logic if no membership discount found
      //             let deducted = false;
      //             let matchedGiftCard = null;

      //             let giftCardSessionValues = customerGiftCardData.map(
      //               (card) => ({
      //                 id: card.item_id,
      //                 remainingValue: parseFloat(card.sessionvalue),
      //                 type: card.type,
      //               })
      //             );

      //             for (let i = 0; i < customerGiftCardData.length; i++) {
      //               matchedGiftCard = null;
      //               const giftcard = customerGiftCardData[i];
      //               const giftCardSession = giftCardSessionValues.find(
      //                 (g) => g.id === giftcard.item_id
      //               );

      //               if (!giftCardSession || giftCardSession.remainingValue <= 0)
      //                 continue;

      //               const isApplicable =
      //                 cartItem.item_type === "Product"
      //                   ? !giftcard.productnot_applicable &&
      //                     (giftcard.allproduct_applicable ||
      //                       giftcard.product_category_details.some(
      //                         (category: any) =>
      //                           category.id === cartItem.item_categorycode
      //                       ) ||
      //                       giftcard.product_item_details.some(
      //                         (product: any) => product.id === cartItem.item_id
      //                       ))
      //                   : cartItem.item_type === "Service"
      //                   ? !giftcard.servicenot_applicable &&
      //                     (giftcard.allservice_applicable ||
      //                       giftcard.service_category_details.some(
      //                         (category: any) =>
      //                           category.id === cartItem.item_categorycode
      //                       ) ||
      //                       giftcard.service_item_details.some(
      //                         (service: any) => service.id === cartItem.item_id
      //                       ))
      //                   : null;

      //               if (
      //                 isApplicable &&
      //                 cartItem.item_pay_amount <=
      //                   giftCardSession.remainingValue &&
      //                 giftCardSession.type == "Value"
      //               ) {
      //                 matchedGiftCard = giftcard;
      //                 giftCardSession.remainingValue -=
      //                   cartItem.item_pay_amount;
      //                 break;
      //               } else if (
      //                 isApplicable &&
      //                 giftCardSession.remainingValue > 0 &&
      //                 giftCardSession.type == "Session"
      //               ) {
      //                 matchedGiftCard = giftCardSession;
      //                 giftCardSession.remainingValue -= 1;
      //                 deducted = true;
      //                 break;
      //               }
      //             }

      //             if (matchedGiftCard) {
      //               updatedCartData[index] = {
      //                 ...updatedCartData[index],
      //                 item_tax: "",
      //                 item_tax_rate: "",
      //                 item_privilege_id: matchedGiftCard.item_id,
      //                 item_privilege_name: "Giftcard",
      //                 previlege_name: matchedGiftCard.name,
      //                 deduction_name: deducted ? "1 deduction" : "",
      //                 item_pay_amount: deducted ? 0 : cartItem.item_pay_amount,
      //                 item_deduction: deducted ? true : false,
      //               };
      //             } else {
      //               // No gift card match found
      //               updatedCartData[index] = {
      //                 ...updatedCartData[index],
      //                 item_tax: cartItem.item_tax,
      //                 item_tax_rate: cartItem.item_tax_rate,
      //                 item_privilege_id: null,
      //                 item_privilege_name: null,
      //                 deduction_name: "",
      //                 item_deduction: false,
      //                 item_pay_amount: cartItem.item_pay_amount, // Keep original price
      //               };
      //             }
      //           }
      //         }
      //       }
      //     });

      //     // Final check if the cart data has been updated
      //     if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
      //       setCartItems(updatedCartData); // Update cart if modified
      //     }
      //   }
      // }
      if (myItemData && myItemData.length > 0) {
        const myItemPackages = myItemData.filter(
          (item) => item.billing_type === "Package"
        );
        const visitBased = myItemData.filter(
          (item) => item.billing_type === "Visit-based"
        );

        if (myItemPackages.length > 0 || visitBased.length > 0) {
          const updatedCartData = [...cartItems];
          const itemUsageTracker: any = {}; // To track used quantities for items.

          const getAllServices = (pkg: any) => [
            ...(pkg.services?.single || []),
            ...(pkg.services?.combo || []),
          ];
          const getAllProducts = (pkg: any) => [
            ...(pkg.products?.single || []),
            ...(pkg.products?.combo || []),
          ];

          updatedCartData.forEach((cartItem, index) => {
            let matchedPackage: any = null;
            let matchedPrepaidMembership: any = null;
            let matchedVisitBasedMembership: any = null;
            let matchedDiscountMembership: any = null; // New variable to store matched discount membership
            let remainingQty = 0;

            // Step 1: Check for Package (priority 1)
            myItemPackages.forEach((pkg) => {
              getAllServices(pkg).forEach((service) => {
                if (service.service_id === cartItem.item_id) {
                  const maxQty = parseInt(service.item_wise_qty || "0", 10);
                  const usedQty = itemUsageTracker[cartItem.item_id] || 0;
                  remainingQty += Math.max(0, maxQty - usedQty); // Accumulate remaining qty
                }
              });
            });

            if (remainingQty > 0) {
              matchedPackage = myItemPackages.find((pkg) =>
                getAllServices(pkg).some(
                  (service) => service.service_id === cartItem.item_id
                )
              );
            } else {
              // Check for products in packages if no service match
              myItemPackages.forEach((pkg) => {
                getAllProducts(pkg).forEach((product) => {
                  if (product.product_id === cartItem.item_id) {
                    const maxQty = parseInt(product.item_wise_qty || "0", 10);
                    const usedQty = itemUsageTracker[cartItem.item_id] || 0;
                    remainingQty += Math.max(0, maxQty - usedQty); // Accumulate remaining qty
                  }
                });

                if (remainingQty > 0) {
                  matchedPackage = myItemPackages.find((pkg) =>
                    getAllProducts(pkg).some(
                      (product) => product.product_id === cartItem.item_id
                    )
                  );
                }
              });
            }

            // Apply Package if matched
            if (matchedPackage && remainingQty > 0) {
              updatedCartData[index] = {
                ...cartItem,
                item_privilege_id: matchedPackage.pkg_id || "",
                item_privilege_name: "Package",
                item_deduction: true,
              };
              itemUsageTracker[cartItem.item_id] =
                (itemUsageTracker[cartItem.item_id] || 0) + cartItem.item_qty;
            } else {
              // Step 2: Check for Prepaid Membership (priority 2)
              console.log("this is calling - membership prepaid apply");
              let membershipValue = customerMembershipPrepaidData.map(
                (card: any) => ({
                  id: card.id,
                  remainingValue:
                    parseFloat(card.remaining_store_balance) +
                    parseFloat(card.remaining_bounce),
                })
              );

              updatedCartData[index] = {
                ...cartItem,
                item_tax: "", // Reset tax value
                item_tax_rate: "", // Reset tax rate
              };

              // Check for matching prepaid memberships
              let matchedMembership = null;
              let isApplicable = false;
              for (let i = 0; i < customerMembershipPrepaidData.length; i++) {
                const membership = customerMembershipPrepaidData[i];
                const membershipSession = membershipValue.find(
                  (g: any) => g.id === membership.id
                );

                if (!membershipSession || membershipSession.remainingValue <= 0)
                  continue;

                let exclusivePrice = null;

                // Check if membership applies to the item (exclusive or general)
                if (
                  (cartItem.item_type === "Product" &&
                    membership.product_item_details.some(
                      (product: any) => product.id === cartItem.item_id
                    )) ||
                  (cartItem.item_type === "Service" &&
                    membership.service_item_details.some(
                      (service: any) => service.id === cartItem.item_id
                    ))
                ) {
                  matchedMembership = membership;
                  isApplicable = true;
                  break;
                }
                if (isApplicable) {
                  matchedMembership = membership;

                  if (exclusivePrice !== null) {
                    cartItem.item_pay_amount = exclusivePrice; // Set the exclusive price if applicable
                  } else {
                    // Calculate membership discount
                    const discount =
                      cartItem.item_type === "Product"
                        ? matchedMembership.product_discount
                        : matchedMembership.service_discount;

                    let amount = cartItem.item_pay_amount;

                    console.log(id, "id");

                    if (id) {
                      amount = cartItem.item_price;
                    }

                    if (discount) {
                      const discountAmount =
                        (parseFloat(amount) * parseFloat(discount)) / 100;
                      cartItem.item_pay_amount -= discountAmount; // Apply discount to item pay amount
                      cartItem.item_membership_discount = discount.toString(); // Set the membership discount
                    }
                  }

                  membershipSession.remainingValue -= cartItem.item_pay_amount;
                  break;
                }
              }

              if (matchedMembership) {
                updatedCartData[index] = {
                  ...updatedCartData[index],
                  item_privilege_id: matchedMembership.id,
                  item_privilege_name: `Prepaid-${matchedMembership.name}`,
                  item_membership_discount: matchedMembership.discount || "",
                  item_pay_amount:
                    cartItem.item_price - (matchedMembership.discount || 0),
                };
              } else {
                // Step 3: Check for Visit-Based Membership (priority 3)
                let membershipValue = customerMembershipVisitBasedData.map(
                  (card: any) => ({
                    id: card.id,
                    remainingValue:
                      parseFloat(card.buyprice) + parseFloat(card.giveaway),
                  })
                );

                console.log(membershipValue, "membershipValue");

                let matchedVisitBasedMembership = null;

                for (
                  let i = 0;
                  i < customerMembershipVisitBasedData.length;
                  i++
                ) {
                  const membership = customerMembershipVisitBasedData[i];
                  const membershipSession = membershipValue.find(
                    (g: any) => g.id === membership.id
                  );

                  if (
                    !membershipSession ||
                    membershipSession.remainingValue <= 0
                  )
                    continue;

                  let isApplicable = false;
                  if (cartItem.item_type === "Service") {
                    if (cartItem.item_id === membership.serviceid) {
                      isApplicable = true;
                    }
                  }

                  if (
                    isApplicable &&
                    membershipSession.remainingValue >=
                      parseFloat(cartItem.item_qty)
                  ) {
                    matchedVisitBasedMembership = membership;

                    // Deduct buyprice first
                    let remainingAmount = parseFloat(cartItem.item_qty);
                    if (remainingAmount >= parseFloat(membership.buyprice)) {
                      remainingAmount -= parseFloat(membership.buyprice);
                      membershipSession.remainingValue -= parseFloat(
                        membership.buyprice
                      );
                    } else {
                      membershipSession.remainingValue -= remainingAmount;
                      remainingAmount = 0;
                    }

                    // Deduct giveaway if there's remaining amount
                    if (
                      remainingAmount > 0 &&
                      remainingAmount >= parseFloat(membership.giveaway)
                    ) {
                      remainingAmount -= parseFloat(membership.giveaway);
                      membershipSession.remainingValue -= parseFloat(
                        membership.giveaway
                      );
                    } else if (remainingAmount > 0) {
                      membershipSession.remainingValue -= remainingAmount;
                      remainingAmount = 0;
                    }
                    break;
                  }
                }

                if (matchedVisitBasedMembership) {
                  updatedCartData[index] = {
                    ...cartItem,
                    item_tax: "",
                    item_tax_rate: "",
                    item_privilege_id: matchedVisitBasedMembership.item_id,
                    item_privilege_name: "Visit-based",
                    previlege_name: matchedVisitBasedMembership.name,
                    item_deduction: true,
                  };
                } else {
                  // Step 4: Check for Discount Membership (priority 4)
                  console.log("this is calling - membership discount apply");
                  let matchedDiscount = null;
                  let membershipDiscountValues =
                    customerMembershipDiscountData.map((card: any) => ({
                      id: card.id,
                      remainingValue: parseFloat(card.remaining_session),
                    }));

                  for (
                    let i = 0;
                    i < customerMembershipDiscountData.length;
                    i++
                  ) {
                    const membershipDiscount =
                      customerMembershipDiscountData[i];
                    const membershipDiscountSession =
                      membershipDiscountValues.find(
                        (g: any) => g.id === membershipDiscount.id
                      );

                    if (
                      !membershipDiscountSession ||
                      membershipDiscountSession.remainingValue <= 0
                    )
                      continue;

                    const isApplicable =
                      cartItem.item_type === "Product"
                        ? !membershipDiscount.productnot_applicable &&
                          (membershipDiscount.allproduct_applicable ||
                            membershipDiscount.product_category_details.some(
                              (category: any) =>
                                category.id === cartItem.item_categorycode
                            ) ||
                            membershipDiscount.product_item_details.some(
                              (product: any) => product.id === cartItem.item_id
                            ))
                        : cartItem.item_type === "Service"
                        ? !membershipDiscount.servicenot_applicable &&
                          (membershipDiscount.allservice_applicable ||
                            membershipDiscount.service_category_details.some(
                              (category: any) =>
                                category.id === cartItem.item_categorycode
                            ) ||
                            membershipDiscount.service_item_details.some(
                              (service: any) => service.id === cartItem.item_id
                            ))
                        : null;

                    if (isApplicable) {
                      matchedDiscount = membershipDiscount;
                      break;
                    }
                  }

                  if (matchedDiscount) {
                    const membershipDiscountAmount =
                      cartItem.item_type === "Product"
                        ? parseFloat(matchedDiscount.product_discount)
                        : cartItem.item_type === "Service"
                        ? parseFloat(matchedDiscount.service_discount)
                        : 0;

                    updatedCartData[index] = {
                      ...updatedCartData[index],
                      item_tax: "",
                      item_tax_rate: "",
                      item_privilege_id: matchedDiscount.item_id,
                      item_privilege_name: `Discount`,
                      item_membership_discount: membershipDiscountAmount,
                      item_pay_amount:
                        cartItem.item_pay_amount - membershipDiscountAmount, // Apply discount to pay amount
                    };
                  } else {
                    // Step 5: Handle GiftCard logic if no discount found
                    console.log("this is calling - giftcard apply");

                    let deducted = false;
                    let matchedGiftCard = null;

                    let giftCardSessionValues = customerGiftCardData.map(
                      (card) => ({
                        id: card.item_id,
                        remainingValue: parseFloat(card.sessionvalue),
                        type: card.type,
                      })
                    );

                    for (let i = 0; i < customerGiftCardData.length; i++) {
                      matchedGiftCard = null;
                      const giftcard = customerGiftCardData[i];
                      const giftCardSession = giftCardSessionValues.find(
                        (g) => g.id === giftcard.item_id
                      );

                      if (
                        !giftCardSession ||
                        giftCardSession.remainingValue <= 0
                      )
                        continue;

                      const isApplicable =
                        cartItem.item_type === "Product"
                          ? !giftcard.productnot_applicable &&
                            (giftcard.allproduct_applicable ||
                              giftcard.product_category_details.some(
                                (category: any) =>
                                  category.id === cartItem.item_categorycode
                              ) ||
                              giftcard.product_item_details.some(
                                (product: any) =>
                                  product.id === cartItem.item_id
                              ))
                          : cartItem.item_type === "Service"
                          ? !giftcard.servicenot_applicable &&
                            (giftcard.allservice_applicable ||
                              giftcard.service_category_details.some(
                                (category: any) =>
                                  category.id === cartItem.item_categorycode
                              ) ||
                              giftcard.service_item_details.some(
                                (service: any) =>
                                  service.id === cartItem.item_id
                              ))
                          : null;

                      if (
                        isApplicable &&
                        cartItem.item_pay_amount <=
                          giftCardSession.remainingValue &&
                        giftCardSession.type == "Value"
                      ) {
                        matchedGiftCard = giftcard;
                        giftCardSession.remainingValue -=
                          cartItem.item_pay_amount;
                        break;
                      } else if (
                        isApplicable &&
                        giftCardSession.remainingValue > 0 &&
                        giftCardSession.type == "Session"
                      ) {
                        matchedGiftCard = giftCardSession;
                        giftCardSession.remainingValue -= 1;
                        deducted = true;
                        break;
                      }
                    }

                    if (matchedGiftCard) {
                      updatedCartData[index] = {
                        ...updatedCartData[index],
                        item_tax: "",
                        item_tax_rate: "",
                        item_privilege_id: matchedGiftCard.item_id,
                        item_privilege_name: "Giftcard",
                        previlege_name: matchedGiftCard.name,
                        deduction_name: deducted ? "1 deduction" : "",
                        item_pay_amount: deducted
                          ? 0
                          : cartItem.item_pay_amount,
                        item_deduction: deducted ? true : false,
                      };
                    } else {
                      // No gift card match found, retain original item details
                      updatedCartData[index] = {
                        ...updatedCartData[index],
                        item_tax: cartItem.item_tax,
                        item_tax_rate: cartItem.item_tax_rate,
                        item_privilege_id: null,
                        item_privilege_name: null,
                        deduction_name: "",
                        item_deduction: false,
                        item_pay_amount: cartItem.item_pay_amount,
                      };
                    }
                  }
                }
              }
            }
          });

          // Final check if the cart data has been updated
          if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
            setCartItems(updatedCartData); // Update cart if modified
          }
        }
      } else if (
        customerMembershipPrepaidData &&
        customerMembershipPrepaidData.length > 0
      ) {
        let membershipValue = customerMembershipPrepaidData.map(
          (card: any) => ({
            id: card.id,
            remainingValue:
              parseFloat(card.remaining_store_balance) +
              parseFloat(card.remaining_bounce),
          })
        );

        let isExclusiveProduct = false;
        let isExclusiveService = false;

        const updatedCartData = cartItems.map((item) => {
          let matchedMembership = null;

          for (let i = 0; i < customerMembershipPrepaidData.length; i++) {
            const membership = customerMembershipPrepaidData[i];
            const membershipSession = membershipValue.find(
              (g: any) => g.id === membership.id
            );

            if (!membershipSession || membershipSession.remainingValue <= 0)
              continue;

            let exclusivePrice = null;

            isExclusiveProduct = membership.exclusive_product.some(
              (product: any) => {
                if (product.productid === item.item_id) {
                  exclusivePrice = product.exclusiveprice;
                  return true;
                }
                return false;
              }
            );

            isExclusiveService = membership.exclusive_service.some(
              (service: any) => {
                if (service.serviceid === item.item_id) {
                  exclusivePrice = service.exclusiveprice;
                  return true;
                }
                return false;
              }
            );

            const isApplicable =
              item.item_type === "Product"
                ? isExclusiveProduct ||
                  (!membership.productnot_applicable &&
                    (membership.allproduct_applicable ||
                      membership.product_category_details.some(
                        (category: any) =>
                          category.id === item.item_categorycode
                      ) ||
                      membership.product_item_details.some(
                        (product: any) => product.id === item.item_id
                      )))
                : item.item_type === "Service"
                ? isExclusiveService ||
                  (!membership.servicenot_applicable &&
                    (membership.allservice_applicable ||
                      membership.service_category_details.some(
                        (category: any) =>
                          category.id === item.item_categorycode
                      ) ||
                      membership.service_item_details.some(
                        (service: any) => service.id === item.item_id
                      )))
                : null;

            if (isApplicable) {
              matchedMembership = membership;

              if (exclusivePrice !== null) {
                item.item_pay_amount = exclusivePrice; // Set the exclusive price if applicable
              } else {
                // Calculate membership discount
                const discount =
                  item.item_type === "Product"
                    ? matchedMembership.product_discount
                    : matchedMembership.service_discount;

                let amount = item.item_pay_amount;

                console.log(item.item_price, id, "id without myitem");
                if (id) {
                  amount = item.item_price;
                }

                console.log(amount, "amount");

                if (discount) {
                  const discountAmount =
                    (parseFloat(amount) * parseFloat(discount)) / 100;
                  item.item_pay_amount -= discountAmount; // Apply discount to item pay amount
                  item.item_membership_discount = discount.toString(); // Set the membership discount
                }
              }

              membershipSession.remainingValue -= item.item_pay_amount;
              break;
            }
          }

          // Discount Membership Logic
          if (matchedMembership) {
            return {
              ...item,
              item_tax: "",
              item_tax_rate: "",
              item_privilege_id: matchedMembership.item_id,
              item_privilege_name: "Prepaid-" + matchedMembership.name,
              previlege_name: matchedMembership.name,
            };
          } else {
            // Check for Discount Membership after Prepaid Membership
            let matchedDiscount = null;

            let membershipDiscountValues = customerMembershipDiscountData.map(
              (card: any) => ({
                id: card.id,
                remainingValue: parseFloat(card.remaining_session),
              })
            );

            for (let i = 0; i < customerMembershipDiscountData.length; i++) {
              const membershipDiscount = customerMembershipDiscountData[i];
              const membershipDiscountSession = membershipDiscountValues.find(
                (g: any) => g.id === membershipDiscount.id
              );

              if (
                !membershipDiscountSession ||
                membershipDiscountSession.remainingValue <= 0
              )
                continue;

              const isApplicable =
                item.item_type === "Product"
                  ? !membershipDiscount.productnot_applicable &&
                    (membershipDiscount.allproduct_applicable ||
                      membershipDiscount.product_category_details.some(
                        (category: any) =>
                          category.id === item.item_categorycode
                      ) ||
                      membershipDiscount.product_item_details.some(
                        (product: any) => product.id === item.item_id
                      ))
                  : item.item_type === "Service"
                  ? !membershipDiscount.servicenot_applicable &&
                    (membershipDiscount.allservice_applicable ||
                      membershipDiscount.service_category_details.some(
                        (category: any) =>
                          category.id === item.item_categorycode
                      ) ||
                      membershipDiscount.service_item_details.some(
                        (service: any) => service.id === item.item_id
                      ))
                  : null;

              if (isApplicable) {
                matchedDiscount = membershipDiscount;
                break;
              }
            }

            // If Discount Membership is matched, apply discount
            if (matchedDiscount) {
              const membershipDiscountAmount =
                item.item_type === "Product"
                  ? parseFloat(matchedDiscount.product_discount)
                  : item.item_type === "Service"
                  ? parseFloat(matchedDiscount.service_discount)
                  : 0;

              return {
                ...item,
                item_tax: "",
                item_tax_rate: "",
                item_privilege_id: matchedDiscount.item_id,
                item_privilege_name: `Discount`,
                item_membership_discount: membershipDiscountAmount,
              };
            } else {
              // Step 3: Check for Visit-Based Membership after Discount Membership
              let matchedVisitBasedMembership = null;

              let membershipVisitBasedValues =
                customerMembershipVisitBasedData.map((card: any) => ({
                  id: card.id,
                  remainingValue:
                    parseFloat(card.buyprice) + parseFloat(card.giveaway),
                }));

              for (
                let i = 0;
                i < customerMembershipVisitBasedData.length;
                i++
              ) {
                const membership = customerMembershipVisitBasedData[i];
                const membershipVisitBasedSession =
                  membershipVisitBasedValues.find(
                    (g: any) => g.id === membership.id
                  );

                if (
                  !membershipVisitBasedSession ||
                  membershipVisitBasedSession.remainingValue <= 0
                )
                  continue;

                let isApplicable = false;
                if (item.item_type === "Service") {
                  if (item.item_id === membership.serviceid) {
                    isApplicable = true;
                  }
                }

                if (
                  isApplicable &&
                  membershipVisitBasedSession.remainingValue >=
                    parseFloat(item.item_qty)
                ) {
                  matchedVisitBasedMembership = membership;

                  // Deduct buyprice first
                  let remainingAmount = parseFloat(item.item_pay_amount);
                  if (remainingAmount >= parseFloat(membership.buyprice)) {
                    remainingAmount -= parseFloat(membership.buyprice);
                    membershipVisitBasedSession.remainingValue -= parseFloat(
                      membership.buyprice
                    );
                  } else {
                    membershipVisitBasedSession.remainingValue -=
                      remainingAmount;
                    remainingAmount = 0;
                  }

                  // Deduct giveaway if there's remaining amount
                  if (
                    remainingAmount > 0 &&
                    remainingAmount >= parseFloat(membership.giveaway)
                  ) {
                    remainingAmount -= parseFloat(membership.giveaway);
                    membershipVisitBasedSession.remainingValue -= parseFloat(
                      membership.giveaway
                    );
                  } else if (remainingAmount > 0) {
                    membershipVisitBasedSession.remainingValue -=
                      remainingAmount;
                    remainingAmount = 0;
                  }
                  break;
                }
              }

              // If matched, apply Visit-Based Membership
              if (matchedVisitBasedMembership) {
                return {
                  ...item,
                  item_tax: "",
                  item_tax_rate: "",
                  item_privilege_id: matchedVisitBasedMembership.id,
                  item_privilege_name: "Visit-based",
                  previlege_name: matchedVisitBasedMembership.name,
                };
              } else {
                // Step 4: If no Visit-Based Membership, check for GiftCard
                let deducted = false;
                let matchedGiftCard = null;

                let giftCardSessionValues = customerGiftCardData.map(
                  (card) => ({
                    id: card.item_id,
                    remainingValue: parseFloat(card.sessionvalue),
                    type: card.type,
                  })
                );

                // GiftCard Logic
                for (let i = 0; i < customerGiftCardData.length; i++) {
                  matchedGiftCard = null;
                  const giftcard = customerGiftCardData[i];
                  const giftCardSession = giftCardSessionValues.find(
                    (g) => g.id === giftcard.item_id
                  );

                  if (!giftCardSession || giftCardSession.remainingValue <= 0)
                    continue;

                  const isApplicable =
                    item.item_type === "Product"
                      ? !giftcard.productnot_applicable &&
                        (giftcard.allproduct_applicable ||
                          giftcard.product_category_details.some(
                            (category: any) =>
                              category.id === item.item_categorycode
                          ) ||
                          giftcard.product_item_details.some(
                            (product: any) => product.id === item.item_id
                          ))
                      : item.item_type === "Service"
                      ? !giftcard.servicenot_applicable &&
                        (giftcard.allservice_applicable ||
                          giftcard.service_category_details.some(
                            (category: any) =>
                              category.id === item.item_categorycode
                          ) ||
                          giftcard.service_item_details.some(
                            (service: any) => service.id === item.item_id
                          ))
                      : null;

                  if (
                    isApplicable &&
                    item.item_pay_amount <= giftCardSession.remainingValue &&
                    giftCardSession.type === "Value"
                  ) {
                    matchedGiftCard = giftcard;
                    giftCardSession.remainingValue -= item.item_pay_amount;
                    break;
                  } else if (
                    isApplicable &&
                    giftCardSession.remainingValue > 0 &&
                    giftCardSession.type === "Session"
                  ) {
                    matchedGiftCard = giftCardSession;
                    giftCardSession.remainingValue -= 1;
                    deducted = true;
                    break;
                  }
                }

                if (matchedGiftCard) {
                  return {
                    ...item,
                    item_tax: "",
                    item_tax_rate: "",
                    item_privilege_id: matchedGiftCard.item_id,
                    item_privilege_name: "Giftcard",
                    previlege_name: matchedGiftCard.name,
                    deduction_name: deducted ? "1 deduction" : "",
                    item_pay_amount: deducted ? 0 : item.item_pay_amount,
                    item_deduction: deducted ? true : false,
                  };
                } else {
                  // If no match for GiftCard, return the default item
                  return {
                    ...item,
                    item_tax: item.item_tax,
                    item_tax_rate: item.item_tax_rate,
                    item_privilege_id: null,
                    item_privilege_name: null,
                    previlege_name: "",
                    deduction_name: "",
                    item_pay_amount: item.item_pay_amount,
                    item_deduction: false,
                  };
                }
              }
            }
          }
        });

        if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
          setCartItems(updatedCartData);
        }
      }

      // else if (
      //   customerMembershipPrepaidData &&
      //   customerMembershipPrepaidData.length > 0
      // ) {
      //   let membershipValue = customerMembershipPrepaidData.map(
      //     (card: any) => ({
      //       id: card.id,
      //       remainingValue:
      //         parseFloat(card.remaining_store_balance) +
      //         parseFloat(card.remaining_bounce),
      //     })
      //   );

      //   let isExclusiveProduct = false;
      //   let isExclusiveService = false;

      //   const updatedCartData = cartItems.map((item) => {
      //     let matchedMembership = null;

      //     for (let i = 0; i < customerMembershipPrepaidData.length; i++) {
      //       const membership = customerMembershipPrepaidData[i];
      //       const membershipSession = membershipValue.find(
      //         (g: any) => g.id === membership.id
      //       );

      //       if (!membershipSession || membershipSession.remainingValue <= 0)
      //         continue;

      //       let exclusivePrice = null;

      //       isExclusiveProduct = membership.exclusive_product.some(
      //         (product: any) => {
      //           if (product.productid === item.item_id) {
      //             exclusivePrice = product.exclusiveprice;
      //             return true;
      //           }
      //           return false;
      //         }
      //       );

      //       isExclusiveService = membership.exclusive_service.some(
      //         (service: any) => {
      //           if (service.serviceid === item.item_id) {
      //             exclusivePrice = service.exclusiveprice;
      //             return true;
      //           }
      //           return false;
      //         }
      //       );

      //       const isApplicable =
      //         item.item_type === "Product"
      //           ? isExclusiveProduct ||
      //             (!membership.productnot_applicable &&
      //               (membership.allproduct_applicable ||
      //                 membership.product_category_details.some(
      //                   (category: any) =>
      //                     category.id === item.item_categorycode
      //                 ) ||
      //                 membership.product_item_details.some(
      //                   (product: any) => product.id === item.item_id
      //                 )))
      //           : item.item_type === "Service"
      //           ? isExclusiveService ||
      //             (!membership.servicenot_applicable &&
      //               (membership.allservice_applicable ||
      //                 membership.service_category_details.some(
      //                   (category: any) =>
      //                     category.id === item.item_categorycode
      //                 ) ||
      //                 membership.service_item_details.some(
      //                   (service: any) => service.id === item.item_id
      //                 )))
      //           : null;

      //       if (isApplicable) {
      //         matchedMembership = membership;

      //         if (exclusivePrice !== null) {
      //           item.item_pay_amount = exclusivePrice; // Set the exclusive price if applicable
      //         } else {
      //           // Calculate membership discount
      //           const discount =
      //             item.item_type === "Product"
      //               ? matchedMembership.product_discount
      //               : matchedMembership.service_discount;

      //           if (discount) {
      //             const discountAmount =
      //               (parseFloat(item.item_pay_amount) * parseFloat(discount)) /
      //               100;
      //             item.item_pay_amount -= discountAmount; // Apply discount to item pay amount
      //             item.item_membership_discount = discount.toString(); // Set the membership discount
      //           }
      //         }

      //         membershipSession.remainingValue -= item.item_pay_amount;
      //         break;
      //       }
      //     }

      //     // if (matchedMembership) {
      //     //   return {
      //     //     ...item,
      //     //     item_tax: "",
      //     //     item_tax_rate: "",
      //     //     item_privilege_id: matchedMembership.item_id,
      //     //     item_privilege_name: "Prepaid-" + matchedMembership.name,
      //     //     previlege_name: matchedMembership.name,
      //     //   };
      //     // }
      //     if (matchedMembership) {
      //       return {
      //         ...item,
      //         item_tax: "",
      //         item_tax_rate: "",
      //         item_privilege_id: matchedMembership.item_id,
      //         item_privilege_name: "Prepaid-" + matchedMembership.name,
      //         previlege_name: matchedMembership.name,
      //       };
      //     } else {
      //       // If matchedMembership is not found, check customerMembershipDiscountData
      //       let matchedDiscount = null;

      //       let membershipDiscountValues = customerMembershipDiscountData.map(
      //         (card: any) => ({
      //           id: card.id,
      //           remainingValue: parseFloat(card.remaining_session),
      //         })
      //       );

      //       for (let i = 0; i < customerMembershipDiscountData.length; i++) {
      //         const membershipDiscount = customerMembershipDiscountData[i];
      //         const membershipDiscountSession = membershipDiscountValues.find(
      //           (g: any) => g.id === membershipDiscount.id
      //         );

      //         if (
      //           !membershipDiscountSession ||
      //           membershipDiscountSession.remainingValue <= 0
      //         )
      //           continue;

      //         const isApplicable =
      //           item.item_type === "Product"
      //             ? !membershipDiscount.productnot_applicable &&
      //               (membershipDiscount.allproduct_applicable ||
      //                 membershipDiscount.product_category_details.some(
      //                   (category: any) =>
      //                     category.id === item.item_categorycode
      //                 ) ||
      //                 membershipDiscount.product_item_details.some(
      //                   (product: any) => product.id === item.item_id
      //                 ))
      //             : item.item_type === "Service"
      //             ? !membershipDiscount.servicenot_applicable &&
      //               (membershipDiscount.allservice_applicable ||
      //                 membershipDiscount.service_category_details.some(
      //                   (category: any) =>
      //                     category.id === item.item_categorycode
      //                 ) ||
      //                 membershipDiscount.service_item_details.some(
      //                   (service: any) => service.id === item.item_id
      //                 ))
      //             : null;

      //         if (isApplicable) {
      //           matchedDiscount = membershipDiscount;
      //           break;
      //         }
      //       }

      //       if (matchedDiscount) {
      //         const membershipDiscountAmount =
      //           item.item_type === "Product"
      //             ? parseFloat(matchedDiscount.product_discount)
      //             : item.item_type === "Service"
      //             ? parseFloat(matchedDiscount.service_discount)
      //             : 0;

      //         return {
      //           ...item,
      //           item_tax: "",
      //           item_tax_rate: "",
      //           item_privilege_id: matchedDiscount.item_id,
      //           item_privilege_name: `Discount`,
      //           item_membership_discount: membershipDiscountAmount,
      //         };
      //       } else {
      //         let deducted = false;
      //         let giftCardSessionValues = customerGiftCardData.map((card) => ({
      //           id: card.item_id,
      //           remainingValue: parseFloat(card.sessionvalue),
      //           type: card.type,
      //         }));
      //         // If matchedDiscount is not found, check customerGiftCardData
      //         let matchedGiftCard = null;

      //         for (let i = 0; i < customerGiftCardData.length; i++) {
      //           matchedGiftCard = null;
      //           const giftcard = customerGiftCardData[i];
      //           const giftCardSession = giftCardSessionValues.find(
      //             (g) => g.id === giftcard.item_id
      //           );

      //           if (!giftCardSession || giftCardSession.remainingValue <= 0)
      //             continue;

      //           const isApplicable =
      //             item.item_type === "Product"
      //               ? !giftcard.productnot_applicable &&
      //                 (giftcard.allproduct_applicable ||
      //                   giftcard.product_category_details.some(
      //                     (category: any) =>
      //                       category.id === item.item_categorycode
      //                   ) ||
      //                   giftcard.product_item_details.some(
      //                     (product: any) => product.id === item.item_id
      //                   ))
      //               : item.item_type === "Service"
      //               ? !giftcard.servicenot_applicable &&
      //                 (giftcard.allservice_applicable ||
      //                   giftcard.service_category_details.some(
      //                     (category: any) =>
      //                       category.id === item.item_categorycode
      //                   ) ||
      //                   giftcard.service_item_details.some(
      //                     (service: any) => service.id === item.item_id
      //                   ))
      //               : null;

      //           if (
      //             isApplicable &&
      //             item.item_pay_amount <= giftCardSession.remainingValue &&
      //             giftCardSession.type == "Value"
      //           ) {
      //             matchedGiftCard = giftcard;
      //             giftCardSession.remainingValue -= item.item_pay_amount;
      //             break;
      //           } else if (
      //             isApplicable &&
      //             giftCardSession.remainingValue > 0 &&
      //             giftCardSession.type == "Session"
      //           ) {
      //             matchedGiftCard = giftCardSession;
      //             giftCardSession.remainingValue -= 1;
      //             deducted = true;
      //             break;
      //           }
      //         }

      //         if (matchedGiftCard) {
      //           return {
      //             ...item,
      //             item_tax: "",
      //             item_tax_rate: "",
      //             item_privilege_id: matchedGiftCard.item_id,
      //             item_privilege_name: "Giftcard",
      //             previlege_name: matchedGiftCard.name,
      //             deduction_name: deducted ? "1 deduction" : "",
      //             item_pay_amount: deducted ? 0 : item.item_pay_amount,
      //             item_deduction: deducted ? true : false,
      //           };
      //         } else {
      //           // If no match found for GiftCard, set default or reset
      //           return {
      //             ...item,
      //             item_tax: item.item_tax,
      //             item_tax_rate: item.item_tax_rate,
      //             item_privilege_id: null,
      //             item_privilege_name: null,
      //             previlege_name: "",
      //             deduction_name: "",
      //             item_pay_amount: item.item_pay_amount,
      //             item_deduction: false,
      //           };
      //         }
      //       }
      //     }
      //   });

      //   if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
      //     setCartItems(updatedCartData);
      //   }
      //   //   else {
      //   //     return {
      //   //       ...item,
      //   //       item_tax: "",
      //   //       item_tax_rate: "",
      //   //       item_privilege_id: null,
      //   //       item_privilege_name: null,
      //   //       previlege_name: "",
      //   //     };
      //   //   }
      //   // });

      //   // if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
      //   //   setCartItems(updatedCartData);
      //   // }
      //   // console.log("cartItems....", cartItems);
      // }
      // else if (
      //   customerMembershipDiscountData &&
      //   customerMembershipDiscountData.length > 0
      // ) {
      //   let membershipDiscountValues = customerMembershipDiscountData.map(
      //     (card: any) => ({
      //       id: card.id,
      //       remainingValue: parseFloat(card.remaining_session),
      //     })
      //   );

      //   const updatedCartData = cartItems.map((item) => {
      //     let matchedDiscount = null;

      //     for (let i = 0; i < customerMembershipDiscountData.length; i++) {
      //       const membershipDiscount = customerMembershipDiscountData[i];
      //       const membershipDiscountSession = membershipDiscountValues.find(
      //         (g: any) => g.id === membershipDiscount.id
      //       );

      //       if (
      //         !membershipDiscountSession ||
      //         membershipDiscountSession.remainingValue <= 0
      //       )
      //         continue;

      //       const isApplicable =
      //         item.item_type === "Product"
      //           ? !membershipDiscount.productnot_applicable &&
      //             (membershipDiscount.allproduct_applicable ||
      //               membershipDiscount.product_category_details.some(
      //                 (category: any) => category.id === item.item_categorycode
      //               ) ||
      //               membershipDiscount.product_item_details.some(
      //                 (product: any) => product.id === item.item_id
      //               ))
      //           : item.item_type === "Service"
      //           ? !membershipDiscount.servicenot_applicable &&
      //             (membershipDiscount.allservice_applicable ||
      //               membershipDiscount.service_category_details.some(
      //                 (category: any) => category.id === item.item_categorycode
      //               ) ||
      //               membershipDiscount.service_item_details.some(
      //                 (service: any) => service.id === item.item_id
      //               ))
      //           : null;

      //       if (
      //         isApplicable
      //         // item.item_pay_amount <= membershipDiscountSession.remainingValue
      //       ) {
      //         matchedDiscount = membershipDiscount;
      //         // membershipDiscountSession.remainingValue -= item.item_pay_amount;
      //         break;
      //       }
      //     }
      //     if (matchedDiscount) {
      //       const membershipDiscountAmount =
      //         item.item_type === "Product"
      //           ? parseFloat(matchedDiscount.product_discount)
      //           : item.item_type === "Service"
      //           ? parseFloat(matchedDiscount.service_discount)
      //           : 0;

      //       return {
      //         ...item,
      //         item_tax: "",
      //         item_tax_rate: "",
      //         item_privilege_id: matchedDiscount.item_id,
      //         item_privilege_name: `Discount`,
      //         item_membership_discount: membershipDiscountAmount,
      //       };
      //     } else {
      //       let deducted = false;
      //       let giftCardSessionValues = customerGiftCardData.map((card) => ({
      //         id: card.item_id,
      //         remainingValue: parseFloat(card.sessionvalue),
      //         type: card.type,
      //       }));
      //       // If matchedDiscount is not found, check customerGiftCardData
      //       let matchedGiftCard = null;

      //       for (let i = 0; i < customerGiftCardData.length; i++) {
      //         matchedGiftCard = null;
      //         const giftcard = customerGiftCardData[i];
      //         const giftCardSession = giftCardSessionValues.find(
      //           (g) => g.id === giftcard.item_id
      //         );

      //         if (!giftCardSession || giftCardSession.remainingValue <= 0)
      //           continue;

      //         const isApplicable =
      //           item.item_type === "Product"
      //             ? !giftcard.productnot_applicable &&
      //               (giftcard.allproduct_applicable ||
      //                 giftcard.product_category_details.some(
      //                   (category: any) =>
      //                     category.id === item.item_categorycode
      //                 ) ||
      //                 giftcard.product_item_details.some(
      //                   (product: any) => product.id === item.item_id
      //                 ))
      //             : item.item_type === "Service"
      //             ? !giftcard.servicenot_applicable &&
      //               (giftcard.allservice_applicable ||
      //                 giftcard.service_category_details.some(
      //                   (category: any) =>
      //                     category.id === item.item_categorycode
      //                 ) ||
      //                 giftcard.service_item_details.some(
      //                   (service: any) => service.id === item.item_id
      //                 ))
      //             : null;

      //         if (
      //           isApplicable &&
      //           item.item_pay_amount <= giftCardSession.remainingValue &&
      //           giftCardSession.type == "Value"
      //         ) {
      //           matchedGiftCard = giftcard;
      //           giftCardSession.remainingValue -= item.item_pay_amount;
      //           break;
      //         } else if (
      //           isApplicable &&
      //           giftCardSession.remainingValue > 0 &&
      //           giftCardSession.type == "Session"
      //         ) {
      //           matchedGiftCard = giftcard;
      //           giftCardSession.remainingValue -= 1;
      //           deducted = true;
      //           break;
      //         }
      //       }

      //       if (matchedGiftCard) {
      //         return {
      //           ...item,
      //           item_tax: "",
      //           item_tax_rate: "",
      //           item_privilege_id: matchedGiftCard.item_id,
      //           item_privilege_name: "Giftcard",
      //           previlege_name: matchedGiftCard.name,
      //           deduction_name: deducted ? "1 deduction" : "",
      //           item_pay_amount: deducted ? 0 : item.item_pay_amount,
      //           item_deduction: deducted ? true : false,
      //         };
      //       } else {
      //         // If no match found for GiftCard, set default or reset
      //         return {
      //           ...item,
      //           item_tax: item.item_tax,
      //           item_tax_rate: item.item_tax_rate,
      //           item_privilege_id: null,
      //           item_privilege_name: null,
      //           previlege_name: "",
      //           deduction_name: "",
      //           item_pay_amount: item.item_pay_amount,
      //           item_deduction: false,
      //         };
      //       }
      //     }
      //   });

      //   if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
      //     setCartItems(updatedCartData);
      //   }
      //   // console.log("cartItems....", cartItems);
      // }
      else if (
        customerMembershipDiscountData &&
        customerMembershipDiscountData.length > 0
      ) {
        let membershipDiscountValues = customerMembershipDiscountData.map(
          (card: any) => ({
            id: card.id,
            remainingValue: parseFloat(card.remaining_session),
          })
        );

        const updatedCartData = cartItems.map((item) => {
          let matchedDiscount = null;

          for (let i = 0; i < customerMembershipDiscountData.length; i++) {
            const membershipDiscount = customerMembershipDiscountData[i];
            const membershipDiscountSession = membershipDiscountValues.find(
              (g: any) => g.id === membershipDiscount.id
            );

            if (
              !membershipDiscountSession ||
              membershipDiscountSession.remainingValue <= 0
            )
              continue;

            const isApplicable =
              item.item_type === "Product"
                ? !membershipDiscount.productnot_applicable &&
                  (membershipDiscount.allproduct_applicable ||
                    membershipDiscount.product_category_details.some(
                      (category: any) => category.id === item.item_categorycode
                    ) ||
                    membershipDiscount.product_item_details.some(
                      (product: any) => product.id === item.item_id
                    ))
                : item.item_type === "Service"
                ? !membershipDiscount.servicenot_applicable &&
                  (membershipDiscount.allservice_applicable ||
                    membershipDiscount.service_category_details.some(
                      (category: any) => category.id === item.item_categorycode
                    ) ||
                    membershipDiscount.service_item_details.some(
                      (service: any) => service.id === item.item_id
                    ))
                : null;

            if (isApplicable) {
              matchedDiscount = membershipDiscount;
              break;
            }
          }

          // If Discount Membership matched
          if (matchedDiscount) {
            const membershipDiscountAmount =
              item.item_type === "Product"
                ? parseFloat(matchedDiscount.product_discount)
                : item.item_type === "Service"
                ? parseFloat(matchedDiscount.service_discount)
                : 0;

            return {
              ...item,
              item_tax: "",
              item_tax_rate: "",
              item_privilege_id: matchedDiscount.item_id,
              item_privilege_name: `Discount`,
              item_membership_discount: membershipDiscountAmount,
            };
          } else {
            // Step 2: If no Discount, check Visit-Based Membership
            let matchedVisitBasedMembership = null;

            let membershipVisitBasedValues =
              customerMembershipVisitBasedData.map((card: any) => ({
                id: card.id,
                remainingValue:
                  parseFloat(card.buyprice) + parseFloat(card.giveaway),
              }));

            for (let i = 0; i < customerMembershipVisitBasedData.length; i++) {
              const membership = customerMembershipVisitBasedData[i];
              const membershipVisitBasedSession =
                membershipVisitBasedValues.find(
                  (g: any) => g.id === membership.id
                );

              if (
                !membershipVisitBasedSession ||
                membershipVisitBasedSession.remainingValue <= 0
              )
                continue;

              let isApplicable = false;
              if (item.item_type === "Service") {
                if (item.item_id === membership.serviceid) {
                  isApplicable = true;
                }
              }

              if (
                isApplicable &&
                membershipVisitBasedSession.remainingValue >=
                  parseFloat(item.item_qty)
              ) {
                matchedVisitBasedMembership = membership;

                // Deduct buyprice first
                let remainingAmount = parseFloat(item.item_pay_amount);
                if (remainingAmount >= parseFloat(membership.buyprice)) {
                  remainingAmount -= parseFloat(membership.buyprice);
                  membershipVisitBasedSession.remainingValue -= parseFloat(
                    membership.buyprice
                  );
                } else {
                  membershipVisitBasedSession.remainingValue -= remainingAmount;
                  remainingAmount = 0;
                }

                // Deduct giveaway if there's remaining amount
                if (
                  remainingAmount > 0 &&
                  remainingAmount >= parseFloat(membership.giveaway)
                ) {
                  remainingAmount -= parseFloat(membership.giveaway);
                  membershipVisitBasedSession.remainingValue -= parseFloat(
                    membership.giveaway
                  );
                } else if (remainingAmount > 0) {
                  membershipVisitBasedSession.remainingValue -= remainingAmount;
                  remainingAmount = 0;
                }
                break;
              }
            }

            // If matched Visit-Based Membership
            if (matchedVisitBasedMembership) {
              return {
                ...item,
                item_tax: "",
                item_tax_rate: "",
                item_privilege_id: matchedVisitBasedMembership.id,
                item_privilege_name: "Visit-based",
                previlege_name: matchedVisitBasedMembership.name,
              };
            } else {
              // Step 3: If no Visit-Based Membership, check GiftCard
              let deducted = false;
              let giftCardSessionValues = customerGiftCardData.map((card) => ({
                id: card.item_id,
                remainingValue: parseFloat(card.sessionvalue),
                type: card.type,
              }));

              let matchedGiftCard = null;

              // GiftCard Logic
              for (let i = 0; i < customerGiftCardData.length; i++) {
                matchedGiftCard = null;
                const giftcard = customerGiftCardData[i];
                const giftCardSession = giftCardSessionValues.find(
                  (g) => g.id === giftcard.item_id
                );

                if (!giftCardSession || giftCardSession.remainingValue <= 0)
                  continue;

                const isApplicable =
                  item.item_type === "Product"
                    ? !giftcard.productnot_applicable &&
                      (giftcard.allproduct_applicable ||
                        giftcard.product_category_details.some(
                          (category: any) =>
                            category.id === item.item_categorycode
                        ) ||
                        giftcard.product_item_details.some(
                          (product: any) => product.id === item.item_id
                        ))
                    : item.item_type === "Service"
                    ? !giftcard.servicenot_applicable &&
                      (giftcard.allservice_applicable ||
                        giftcard.service_category_details.some(
                          (category: any) =>
                            category.id === item.item_categorycode
                        ) ||
                        giftcard.service_item_details.some(
                          (service: any) => service.id === item.item_id
                        ))
                    : null;

                if (
                  isApplicable &&
                  item.item_pay_amount <= giftCardSession.remainingValue &&
                  giftCardSession.type == "Value"
                ) {
                  matchedGiftCard = giftcard;
                  giftCardSession.remainingValue -= item.item_pay_amount;
                  break;
                } else if (
                  isApplicable &&
                  giftCardSession.remainingValue > 0 &&
                  giftCardSession.type == "Session"
                ) {
                  matchedGiftCard = giftcard;
                  giftCardSession.remainingValue -= 1;
                  deducted = true;
                  break;
                }
              }

              // If matched GiftCard
              if (matchedGiftCard) {
                return {
                  ...item,
                  item_tax: "",
                  item_tax_rate: "",
                  item_privilege_id: matchedGiftCard.item_id,
                  item_privilege_name: "Giftcard",
                  previlege_name: matchedGiftCard.name,
                  deduction_name: deducted ? "1 deduction" : "",
                  item_pay_amount: deducted ? 0 : item.item_pay_amount,
                  item_deduction: deducted ? true : false,
                };
              } else {
                // If no match found for GiftCard, reset item details
                return {
                  ...item,
                  item_tax: item.item_tax,
                  item_tax_rate: item.item_tax_rate,
                  item_privilege_id: null,
                  item_privilege_name: null,
                  previlege_name: "",
                  deduction_name: "",
                  item_pay_amount: item.item_pay_amount,
                  item_deduction: false,
                };
              }
            }
          }
        });

        if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
          setCartItems(updatedCartData);
        }
      } else if (
        customerMembershipVisitBasedData &&
        customerMembershipVisitBasedData.length > 0
      ) {
        let membershipValue = customerMembershipVisitBasedData.map(
          (card: any) => ({
            id: card.id,
            remainingValue:
              parseFloat(card.buyprice) + parseFloat(card.giveaway),
          })
        );

        let isExclusiveProduct = false;
        let isExclusiveService = false;

        const updatedCartData = cartItems.map((item) => {
          let matchedMembership = null;

          for (let i = 0; i < customerMembershipVisitBasedData.length; i++) {
            const membership = customerMembershipVisitBasedData[i];
            const membershipSession = membershipValue.find(
              (g: any) => g.id === membership.id
            );

            if (!membershipSession || membershipSession.remainingValue <= 0)
              continue;

            let isApplicable = false;
            if (item.item_type === "Service") {
              if (item.item_id === membership.serviceid) {
                isApplicable = true;
              }
            }

            if (
              isApplicable &&
              membershipSession.remainingValue >= parseFloat(item.item_qty)
            ) {
              matchedMembership = membership;

              // Deduct `buyprice` first
              let remainingAmount = parseFloat(item.item_pay_amount);
              if (remainingAmount >= parseFloat(membership.buyprice)) {
                remainingAmount -= parseFloat(membership.buyprice);
                membershipSession.remainingValue -= parseFloat(
                  membership.buyprice
                ); // Deduct buyprice
              } else {
                membershipSession.remainingValue -= remainingAmount; // Deduct remaining amount
                remainingAmount = 0;
              }

              // Deduct `giveaway` next if there's any remaining amount
              if (
                remainingAmount > 0 &&
                remainingAmount >= parseFloat(membership.giveaway)
              ) {
                remainingAmount -= parseFloat(membership.giveaway);
                membershipSession.remainingValue -= parseFloat(
                  membership.giveaway
                ); // Deduct giveaway
              } else if (remainingAmount > 0) {
                membershipSession.remainingValue -= remainingAmount;
                remainingAmount = 0;
              }
              break;
            }
          }
          if (matchedMembership) {
            return {
              ...item,
              item_tax: "",
              item_tax_rate: "",
              item_privilege_id: matchedMembership.item_id,
              item_privilege_name: "Visit-based",
              previlege_name: matchedMembership.name,
            };
          } else {
            let deducted = false;
            let giftCardSessionValues = customerGiftCardData.map((card) => ({
              id: card.item_id,
              remainingValue: parseFloat(card.sessionvalue),
              type: card.type,
            }));
            // If matchedDiscount is not found, check customerGiftCardData
            let matchedGiftCard = null;

            for (let i = 0; i < customerGiftCardData.length; i++) {
              matchedGiftCard = null;
              const giftcard = customerGiftCardData[i];
              const giftCardSession = giftCardSessionValues.find(
                (g) => g.id === giftcard.item_id
              );

              if (!giftCardSession || giftCardSession.remainingValue <= 0)
                continue;

              const isApplicable =
                item.item_type === "Product"
                  ? !giftcard.productnot_applicable &&
                    (giftcard.allproduct_applicable ||
                      giftcard.product_category_details.some(
                        (category: any) =>
                          category.id === item.item_categorycode
                      ) ||
                      giftcard.product_item_details.some(
                        (product: any) => product.id === item.item_id
                      ))
                  : item.item_type === "Service"
                  ? !giftcard.servicenot_applicable &&
                    (giftcard.allservice_applicable ||
                      giftcard.service_category_details.some(
                        (category: any) =>
                          category.id === item.item_categorycode
                      ) ||
                      giftcard.service_item_details.some(
                        (service: any) => service.id === item.item_id
                      ))
                  : null;

              if (
                isApplicable &&
                item.item_pay_amount <= giftCardSession.remainingValue &&
                giftCardSession.type == "Value"
              ) {
                matchedGiftCard = giftcard;
                giftCardSession.remainingValue -= item.item_pay_amount;
                break;
              } else if (
                isApplicable &&
                giftCardSession.remainingValue > 0 &&
                giftCardSession.type == "Session"
              ) {
                matchedGiftCard = giftCardSession;
                giftCardSession.remainingValue -= 1;
                deducted = true;
                break;
              }
            }

            if (matchedGiftCard) {
              return {
                ...item,
                item_tax: "",
                item_tax_rate: "",
                item_privilege_id: matchedGiftCard.item_id,
                item_privilege_name: "Giftcard",
                previlege_name: matchedGiftCard.name,
                deduction_name: deducted ? "1 deduction" : "",
                item_pay_amount: deducted ? 0 : item.item_pay_amount,
                item_deduction: deducted ? true : false,
              };
            } else {
              // If no match found for GiftCard, set default or reset
              return {
                ...item,
                item_tax: item.item_tax,
                item_tax_rate: item.item_tax_rate,
                item_privilege_id: null,
                item_privilege_name: null,
                previlege_name: "",
                deduction_name: "",
                item_pay_amount: item.item_pay_amount,
                item_deduction: false,
              };
            }
          }
        });

        if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
          setCartItems(updatedCartData);
        }
        //   else {
        //     return {
        //       ...item,
        //       item_tax: "",
        //       item_tax_rate: "",
        //       item_privilege_id: null,
        //       item_privilege_name: null,
        //       previlege_name: "",
        //     };
        //   }
        // });

        // if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
        //   setCartItems(updatedCartData);
        // }
        // console.log("cartItems....", cartItems);
      } else if (customerGiftCardData && customerGiftCardData.length > 0) {
        // console.log("customerGiftCardData.....", customerGiftCardData);
        // console.log("cartItems....", cartItems);
        let deducted = false;
        let giftCardSessionValues = customerGiftCardData.map((card) => ({
          id: card.item_id,
          remainingValue: parseFloat(card.sessionvalue),
          type: card.type,
        }));

        const updatedCartData = cartItems.map((item) => {
          let matchedGiftCard = null;

          for (let i = 0; i < customerGiftCardData.length; i++) {
            matchedGiftCard = null;
            const giftcard = customerGiftCardData[i];
            const giftCardSession = giftCardSessionValues.find(
              (g) => g.id === giftcard.item_id
            );

            if (!giftCardSession || giftCardSession.remainingValue <= 0)
              continue;

            const isApplicable =
              item.item_type === "Product"
                ? !giftcard.productnot_applicable &&
                  (giftcard.allproduct_applicable ||
                    giftcard.product_category_details.some(
                      (category: any) => category.id === item.item_categorycode
                    ) ||
                    giftcard.product_item_details.some(
                      (product: any) => product.id === item.item_id
                    ))
                : item.item_type === "Service"
                ? !giftcard.servicenot_applicable &&
                  (giftcard.allservice_applicable ||
                    giftcard.service_category_details.some(
                      (category: any) => category.id === item.item_categorycode
                    ) ||
                    giftcard.service_item_details.some(
                      (service: any) => service.id === item.item_id
                    ))
                : null;

            if (
              isApplicable &&
              item.item_pay_amount <= giftCardSession.remainingValue &&
              giftCardSession.type == "Value"
            ) {
              matchedGiftCard = giftcard;
              giftCardSession.remainingValue -= item.item_pay_amount;
              break;
            } else if (
              isApplicable &&
              giftCardSession.remainingValue > 0 &&
              giftCardSession.type == "Session"
            ) {
              matchedGiftCard = giftcard;
              giftCardSession.remainingValue -= 1;
              deducted = true;
              break;
            }
          }

          if (matchedGiftCard) {
            return {
              ...item,
              item_tax: "",
              item_tax_rate: "",
              item_privilege_id: matchedGiftCard.item_id,
              item_privilege_name: "Giftcard",
              previlege_name: matchedGiftCard.name,
              deduction_name: deducted ? "1 deduction" : "",
              item_pay_amount: deducted ? 0 : item.item_pay_amount,
              item_deduction: deducted ? true : false,
            };
          } else {
            return {
              ...item,
              item_tax: item.item_tax,
              item_tax_rate: item.item_tax_rate,
              item_privilege_id: null,
              item_privilege_name: null,
              previlege_name: "",
              deduction_name: "",
              item_pay_amount: item.item_pay_amount,
              item_deduction: false,
            };
          }
        });

        if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
          setCartItems(updatedCartData);
        }
      } else {
        const resetCartData = cartItems.map((item) => {
          const tempItem = tempCartItems.find(
            (temp) => temp.item_unique_id === item.item_unique_id
          );
          const { item_tax, item_tax_rate } = tempItem || {};

          return {
            ...item,
            item_tax: item_tax || "",
            item_tax_rate: item_tax_rate || "",
            item_privilege_id: null,
            item_privilege_name: null,
            previlege_name: "",
            item_pay_amount: item.item_price * item.item_qty,
          };
        });

        if (JSON.stringify(cartItems) !== JSON.stringify(resetCartData)) {
          setCartItems(resetCartData);
        }
      }
      // customerMembershipVisitBasedData
    } else {
      const resetCartData = cartItems.map((item) => {
        const tempItem = tempCartItems.find(
          (temp) => temp.item_unique_id === item.item_unique_id
        );
        const { item_tax, item_tax_rate } = tempItem || {};

        return {
          ...item,
          item_tax: item_tax || "",
          item_tax_rate: item_tax_rate || "",
          item_privilege_id: null,
          item_privilege_name: null,
          previlege_name: "",
        };
      });

      if (JSON.stringify(cartItems) !== JSON.stringify(resetCartData)) {
        setCartItems(resetCartData);
      }
    }
  }, [customerGiftCardDataUpdate]);

  // useEffect(() => {
  //   if (
  //     cartItems &&
  //     myItemData &&
  //     cartItems.length > 0 &&
  //     myItemData.length > 0
  //   ) {
  //     // console.log("myItemData....", myItemData);

  //     // const updatedCartData = cartItems.map((cartItem) => {
  //     //   let matchedPackage: any = null;

  //     //   // Check for match in services
  //     //   myItemData.forEach((pkg) => {
  //     //     if (!matchedPackage) {
  //     //       const allServices = [
  //     //         ...(pkg.services?.single || []),
  //     //         ...(pkg.services?.combo || []),
  //     //       ];
  //     //       matchedPackage = allServices.find(
  //     //         (service) => service.service_id === cartItem.item_id
  //     //       );
  //     //     }
  //     //   });

  //     //   // Check for match in products if no match in services
  //     //   if (!matchedPackage) {
  //     //     myItemData.forEach((pkg) => {
  //     //       if (!matchedPackage) {
  //     //         const allProducts = [
  //     //           ...(pkg.products?.single || []),
  //     //           ...(pkg.products?.combo || []),
  //     //         ];
  //     //         matchedPackage = allProducts.find(
  //     //           (product) => product.product_id === cartItem.item_id
  //     //         );
  //     //       }
  //     //     });
  //     //   }

  //     //   console.log("matchedPackage", matchedPackage);

  //     //   if (matchedPackage) {
  //     //     return {
  //     //       ...cartItem,
  //     //       item_privilege_id:
  //     //         matchedPackage.service_id || matchedPackage.product_id || "",
  //     //       item_privilege_name: "Package",
  //     //     };
  //     //   } else {
  //     //     return {
  //     //       ...cartItem,
  //     //       item_privilege_id: null,
  //     //       item_privilege_name: null,
  //     //     };
  //     //   }
  //     // });

  //     // const updatedCartData = [...cartItems]; // Create a copy of cartItems to avoid mutating the original array.

  //     // const itemUsageTracker:any = {}; // To track used quantities for items in myItemData.

  //     // updatedCartData.forEach((cartItem, index) => {
  //     //   let matchedPackage:any = null;
  //     //   let remainingQty = 0;

  //     //   // Check for match in services
  //     //   myItemData.forEach((pkg) => {
  //     //     if (!matchedPackage) {
  //     //       const allServices = [
  //     //         ...(pkg.services?.single || []),
  //     //         ...(pkg.services?.combo || []),
  //     //       ];
  //     //       const match = allServices.find(
  //     //         (service) => service.service_id === cartItem.item_id
  //     //       );

  //     //       if (match) {
  //     //         const maxQty = parseInt(match.item_qty || "0");
  //     //         const usedQty = itemUsageTracker[cartItem.item_id] || 0;
  //     //         remainingQty = maxQty - usedQty;

  //     //         if (remainingQty > 0) {
  //     //           matchedPackage = match;
  //     //         }
  //     //       }
  //     //     }
  //     //   });

  //     //   // Check for match in products if no match in services
  //     //   if (!matchedPackage) {
  //     //     myItemData.forEach((pkg) => {
  //     //       if (!matchedPackage) {
  //     //         const allProducts = [
  //     //           ...(pkg.products?.single || []),
  //     //           ...(pkg.products?.combo || []),
  //     //         ];
  //     //         const match = allProducts.find(
  //     //           (product) => product.product_id === cartItem.item_id
  //     //         );

  //     //         if (match) {
  //     //           const maxQty = parseInt(match.item_qty || "0");
  //     //           const usedQty = itemUsageTracker[cartItem.item_id] || 0;
  //     //           remainingQty = maxQty - usedQty;

  //     //           if (remainingQty > 0) {
  //     //             matchedPackage = match;
  //     //           }
  //     //         }
  //     //       }
  //     //     });
  //     //   }

  //     //   if (matchedPackage && remainingQty > 0) {
  //     //     updatedCartData[index] = {
  //     //       ...cartItem,
  //     //       item_privilege_id: matchedPackage.combo_id || matchedPackage.bill_id || "",
  //     //       item_privilege_name: "Package",
  //     //     };

  //     //     // Update the usage tracker for this item_id
  //     //     itemUsageTracker[cartItem.item_id] =
  //     //       (itemUsageTracker[cartItem.item_id] || 0) + cartItem.item_qty;
  //     //   } else {
  //     //     updatedCartData[index] = {
  //     //       ...cartItem,
  //     //       item_privilege_id: null,
  //     //       item_privilege_name: null,
  //     //     };
  //     //   }
  //     // });

  //     const updatedCartData = [...cartItems];
  //     const itemUsageTracker: any = {}; // To track used quantities for items.

  //     const getAllServices = (pkg: any) => [
  //       ...(pkg.services?.single || []),
  //       ...(pkg.services?.combo || []),
  //     ];
  //     const getAllProducts = (pkg: any) => [
  //       ...(pkg.products?.single || []),
  //       ...(pkg.products?.combo || []),
  //     ];

  //     updatedCartData.forEach((cartItem, index) => {
  //       let matchedPackage: any = null;
  //       let remainingQty = 0;

  //       // Match in services
  //       myItemData.forEach((pkg) => {
  //         if (!matchedPackage) {
  //           const match = getAllServices(pkg).find(
  //             (service) => service.service_id === cartItem.item_id
  //           );

  //           if (match) {
  //             const maxQty = parseInt(match.item_wise_qty || "0", 10);
  //             const usedQty = itemUsageTracker[cartItem.item_id] || 0;
  //             remainingQty = maxQty - usedQty;

  //             if (remainingQty > 0) {
  //               matchedPackage = match;
  //             }
  //           }
  //         }
  //       });

  //       // Match in products if no service match
  //       if (!matchedPackage) {
  //         myItemData.forEach((pkg) => {
  //           if (!matchedPackage) {
  //             const match = getAllProducts(pkg).find(
  //               (product) => product.product_id === cartItem.item_id
  //             );

  //             if (match) {
  //               const maxQty = parseInt(match.item_wise_qty || "0", 10);
  //               const usedQty = itemUsageTracker[cartItem.item_id] || 0;
  //               remainingQty = maxQty - usedQty;

  //               if (remainingQty > 0) {
  //                 matchedPackage = match;
  //               }
  //             }
  //           }
  //         });
  //       }

  //       // Update cart item
  //       if (matchedPackage && remainingQty > 0) {
  //         if (cartItem.item_qty <= remainingQty) {
  //           updatedCartData[index] = {
  //             ...cartItem,
  //             item_privilege_id: matchedPackage.pkg_id || "",
  //             item_privilege_name: "Package",
  //             item_deduction: true,
  //           };
  //           // Update usage tracker
  //           itemUsageTracker[cartItem.item_id] =
  //             (itemUsageTracker[cartItem.item_id] || 0) + cartItem.item_qty;
  //         } else {
  //           // Quantity exceeds available, reset privilege
  //           updatedCartData[index] = {
  //             ...cartItem,
  //             item_privilege_id: null,
  //             item_privilege_name: null,
  //             item_deduction: false,
  //           };
  //         }
  //       } else {
  //         updatedCartData[index] = {
  //           ...cartItem,
  //           item_privilege_id: null,
  //           item_privilege_name: null,
  //           item_deduction: false,
  //         };
  //       }
  //     });

  //     if (JSON.stringify(cartItems) !== JSON.stringify(updatedCartData)) {
  //       setCartItems(updatedCartData);
  //     }
  //   } else {
  //     const resetCartData = cartItems.map((item) => {
  //       const tempItem = tempCartItems.find(
  //         (temp) => temp.item_unique_id === item.item_unique_id
  //       );
  //       const { item_tax, item_tax_rate } = tempItem || {};

  //       return {
  //         ...item,
  //         item_tax: item_tax || "",
  //         item_tax_rate: item_tax_rate || "",
  //         item_privilege_id: null,
  //         item_privilege_name: null,
  //       };
  //     });

  //     if (JSON.stringify(cartItems) !== JSON.stringify(resetCartData)) {
  //       setCartItems(resetCartData);
  //     }
  //   }
  // }, [customerPackageDataDataUpdate, myItemData, cartItems]);

  // Set information of customer
  const handleCustomerSelection = async (data: any) => {
    // setSelectedStaff(staff);
    await setShowCustomerModal(false);
    await setSelectedCustomer(data);
    if (data) {
      if (data.debt > 0) {
        setDebtModalvisible(true);
        setdebtamount(data.debt);
      }
      await fetchCoustomerCountData(data.id);
      await getCustomerCourse(data.id);
      await getCustomerKIV(data.id);
      await setCustomerCountChanges(!customerCountChange);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        customerCount?.find((item) => item.type === "Prepaid")
          ?.remaining_balance > 0 ||
        customerCount?.find((item) => item.type === "Prepaid")
          ?.remaining_bouns > 0
      ) {
        await getCustomerMembershipPrepaid(selectedCustomer.id);
      }

      if (
        customerCount?.find((item) => item.type === "Discount")?.total_count > 0
      ) {
        await getCustomerMembershipDiscount(selectedCustomer.id);
      }

      if (
        customerCount?.find((item) => item.type === "Giftcard")?.total_count > 0
      ) {
        await getGiftcardData(selectedCustomer.id);
      }

      if (
        customerCount?.find((item) => item.type === "Package")?.total_count > 0
      ) {
        await getCustomerPackageMyItem(selectedCustomer.id);
      }
      if (
        customerCount?.find((item) => item.type === "Visit-based")
          ?.total_count > 0
      ) {
        await getCustomerMembershipVisitBased(selectedCustomer.id);
        if (myItemData && myItemData.length == 0) {
          await getCustomerPackageMyItem(selectedCustomer.id);
        }
      }
      if (
        customerCount?.find((item) => item.type === "Debt")?.total_count > 0
      ) {
        if (customerMemberDebtData && customerMemberDebtData.length == 0) {
          await getCustomerDebt(selectedCustomer.id);
        }
      }
      await setCustomerGiftCardDataUpdate(!customerGiftCardDataUpdate);
      if (onlinesalesData && onlinesalesData.length == 0 && selectedCustomer) {
        await getCustomerOnlinesales(selectedCustomer.id);
      }
    };
    fetchData();
  }, [customerCountChange]);

  // await getCustomerPackageMyItem(data.id);
  //     await getCustomerMembershipPrepaid(data.id); //get Prepaid store/value data for specific customer
  //     await getCustomerMembershipDiscount(data.id);
  //     await getGiftcardData(data.id);
  //     await setCustomerGiftCardDataUpdate(!customerGiftCardDataUpdate);

  const handleProductStaffSelection = (staff: any) => {
    // setSelectedStaff(staff);
    if (selectedType == "Product") {
      setShowProductModal(false);
      addToCart("Product", staff);
    } else if (selectedType == "Package") {
      setSelectedStaff(staff);
    } else if (selectedType == "Membership") {
      addToCart("Membership", staff);
    } else {
      addToCart("Giftcard", staff);
    }
    setShowProductModal(false);
  };

  const handleRefSelection = (refnum: any) => {
    setShowRefNumModal(false);
    setRefNum(refnum);
  };

  useEffect(() => {
    setcartItemValue(
      cartItems.reduce((accum, item) => accum + item.item_pay_amount, 0)
    );
  }, [cartItems]);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      // let cartItemTotal = cartItems.reduce(
      //   (accum, item) => accum + item.item_pay_amount,
      //   0
      // );
      let cartItemTotals = cartItems
        .filter((item) => !item.item_deduction == true)
        .reduce((accum, item) => accum + item.item_pay_amount, 0);
      // console.log("cart total....", cartItemTotals);

      let subtotalValue = cartItemTotals - discountAmount;
      setSubTotal(subtotalValue);
    } else {
      setDiscountType("%");
      setDiscountAmount(0);
      setSubTotal(0);
    }
  }, [cartItems, discountAmount]);

  useEffect(() => {
    if (subTotal > 0) {
      const hasPrivilegeName = cartItems.some(
        (item) =>
          "item_privilege_name" in item &&
          (item.item_privilege_name === "Giftcard" ||
            (item.item_privilege_name &&
              item.item_privilege_name.includes("Prepaid-")))
      );

      if (!hasPrivilegeName) {
        const cartTax = cartItems.reduce((sum, item) => {
          if (item.item_tax_rate !== null && item.item_tax_rate !== "") {
            return (
              sum +
              (parseFloat(item.item_pay_amount) *
                parseFloat(item.item_tax_rate)) /
                100
            );
          }
          return sum;
        }, 0);

        let taxSum = taxesItems.reduce(
          (sum, item) => sum + Number(item.taxrate),
          0
        );

        const subtotalTax = (subTotal * taxSum) / 100;

        const totalTax = cartTax + subtotalTax;

        // const perTax = (totalTax* )

        // setTaxRate(totalTax);
        setTaxValue(totalTax);

        let taxRateSum = taxesItems.reduce(
          (sum, item) => sum + Number(item.taxrate),
          0
        );
        const totalTaxRate =
          cartItems.reduce((sum, item) => {
            if (item.item_tax_rate !== null && item.item_tax_rate !== "") {
              return sum + parseFloat(item.item_tax_rate);
            }
            return sum;
          }, 0) + taxRateSum;

        setTaxRate(totalTaxRate);
      } else {
        setTaxRate(0);
        setTaxValue(0);
      }
    } else {
      setTaxRate(0);
      setTaxValue(0);
    }
  }, [subTotal, taxesItems, cartItems, discountAmount]);

  useEffect(() => {
    let total = subTotal;
    total = total + taxValue;
    setTotal(total);
  }, [subTotal, cartItems, taxValue, discountAmount]);

  const handleRemoveDiscount = () => {
    setDiscountValue(0);
    setDiscountAmount(0);
  };

  const calculateGrandTotal = () => {
    return cartItems.reduce((accum, item) => accum + item.total, 0);
  };

  const handleSearchServiceIconClick = () => {
    setShowServiceSearchInput(!showServiceSearchInput);
  };

  const handleSearchProductIconClick = () => {
    setShowProductSearchInput(!showProductSearchInput);
  };

  const handleClearServiceSearch = () => {
    setSearchServiceValue("");
  };

  const handleClearProductSearch = () => {
    setSearchProductValue("");
  };

  const handleClearGiftcardsSearch = () => {
    setSearchGiftcardValue("");
  };

  const handleSwapCount = () => {
    setMaleCount(femaleCount);
    setFemaleCount(maleCount);
  };

  const fetchServiceCategory = async () => {
    try {
      const response = await get(
        `/category/getlistwithoutpagination/${localStorage.getItem(
          "outlet_id"
        )}/Service`
      );
      if (response.data.success) {
        let data = response.data.data;
        data.unshift({
          id: "null",
          name: "All ",
        });
        setServiceCategory(data);
        setSelectedServiceTab("null");
      } else {
        message.error("Failed to fetch service category");
      }
    } catch (error) {
      console.error("Error fetching service category: ", error);
      message.error("Error fetching service category");
    }
  };

  const fetchOutletCustomerCount = async () => {
    try {
      const response = await get(
        `/billing/customeroutetinfo/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        let data = response.data.data;
        setMaleCount(data.maleCount);
        setFemaleCount(data.femaleCount);
      } else {
        message.error("Failed to fetch service category");
      }
    } catch (error) {
      console.error("Error fetching service category: ", error);
      message.error("Error fetching service category");
    }
  };

  const fetchProductCategory = async () => {
    try {
      const response = await get(
        `/category/getlistwithoutpagination/${localStorage.getItem(
          "outlet_id"
        )}/Product`
      );
      if (response.data.success) {
        let data = response.data.data;
        data.unshift({
          id: "null",
          name: "All ",
        });
        setProductCategory(data);
        setSelectedProductTab("null");
      } else {
        message.error("Failed to fetch brands");
      }
    } catch (error) {
      console.error("Error fetching product category: ", error);
      message.error("Error fetching product category");
    }
  };

  const fetchMembership = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Membership&categoryid=null`
      );
      if (response.data.success) {
        setMembershipItems(response.data.data);
        setTempMembershipItems(response.data.data);
      } else {
        message.error("Failed to fetch Giftcards");
      }
    } catch (error) {
      console.error("Error fetching Giftcards: ", error);
      message.error("Error fetching Giftcards ");
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Product&categoryid=null`
      );
      if (response.data.success) {
        setProductItems(response.data.data);
        setTempProductItems(response.data.data);
      } else {
        message.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error fetching products: ", error);
      message.error("Error fetching products");
    }
  };

  const fetchServices = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Service&categoryid=null`
      );
      if (response.data.success) {
        setServiceItems(response.data.data);
        setTempServiceItems(response.data.data);
      } else {
        message.error("Failed to fetch services");
      }
    } catch (error) {
      console.error("Error fetching services: ", error);
      message.error("Error fetching services");
    }
  };

  const fetchGiftcards = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Giftcard&categoryid=null`
      );
      if (response.data.success) {
        setGiftcardsItems(response.data.data);
        setTempGiftcardItems(response.data.data);
      } else {
        message.error("Failed to fetch Giftcards");
      }
    } catch (error) {
      console.error("Error fetching Giftcards: ", error);
      message.error("Error fetching Giftcards ");
    }
  };

  const fetchPackages = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Package&categoryid=null`
      );
      if (response.data.success) {
        setPackagesItems(response.data.data);
        setTempPackagesItems(response.data.data);
      } else {
        message.error("Failed to fetch Packages");
      }
    } catch (error) {
      console.error("Error fetching Packages: ", error);
      message.error("Error fetching Packages ");
    }
  };

  const getCustomerMembershipDiscount = async (id: any) => {
    const outlateid = localStorage.getItem("outlet_id");
    if (id && outlateid) {
      try {
        const url = `/billing/customer/discountdetails/${id}/${outlateid}`;
        const response = await get(url);
        if (response.data.success) {
          const discountData = response.data.data;
          // console.log("discountData...", discountData);
          setCustomerMembershipDiscountData(discountData);
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  const getCustomerMembershipPrepaid = async (id: any) => {
    const outlateid = localStorage.getItem("outlet_id");
    if (id && outlateid) {
      try {
        const url = `/billing/customer/storedetails/${id}/${outlateid}`;
        const response = await get(url);
        if (response.data.success) {
          const discountData = response.data.data;
          setCustomerMembershipPrepaidData(discountData);
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  const getCustomerOnlinesales = async (id: any) => {
    const outlateid = localStorage.getItem("outlet_id");
    if (id && outlateid) {
      try {
        const url = `/billing/onlinesalesitems/${outlateid}?customerid=${id}`;
        const response = await get(url);
        if (response.data.success) {
          const data = response.data.data;
          setonlinesalesData(data);

          if (
            onlinesalescustomerid !== undefined &&
            onlinesalescustomerid !== null &&
            debt == undefined &&
            response.data.data.length > 0
          ) {
            await setActiveMainTab("Onlinesales");
          }
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  const getCustomerKIV = async (id: any) => {
    const outlateid = localStorage.getItem("outlet_id");
    if (id && outlateid) {
      try {
        const url = `/customer/privilege-course/${outlateid}/${id}/kiv`;
        const response = await get(url);
        if (response.data.success) {
          const data = response.data.data;
          setkivData(data);
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  const getCustomerCourse = async (id: any) => {
    const outlateid = localStorage.getItem("outlet_id");
    if (id && outlateid) {
      try {
        const url = `/customer/privilege-course/${outlateid}/${id}/course`;
        const response = await get(url);
        if (response.data.success) {
          const data = response.data.data;
          setcourseData(data);
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  const getCustomerMembershipVisitBased = async (id: any) => {
    const outlateid = localStorage.getItem("outlet_id");
    if (id && outlateid) {
      try {
        const url = `/billing/customer/visitdetails/${id}/${outlateid}`;
        const response = await get(url);
        if (response.data.success) {
          const visitData = response.data.data;
          setCustomerMembershipVisitBasedData(visitData);
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  const fetchTaxes = async () => {
    try {
      const response = await get(
        `/tax/addmanuallylist/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        setTaxesItems(response.data.data);
      } else {
        message.error("Failed to fetch taxess");
      }
    } catch (error) {
      console.error("Error fetching taxes: ", error);
      message.error("Error fetching taxes");
    }
  };

  const fetchCustomerSource = async () => {
    try {
      const response = await get(
        `/customersource/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        setCustomerSources(response.data.data);
        if (response.data.data && response.data.data.length > 0) {
          setSelectedCustomerSource(response.data.data[0].id);
        }
      } else {
        message.error("Failed to fetch taxess");
      }
    } catch (error) {
      console.error("Error fetching taxes: ", error);
      message.error("Error fetching taxes");
    }
  };

  const fetchRefNum = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        setRefNum(data);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to load refnum");
    }
  };

  const fetchCoustomerCountData = async (id: any) => {
    const outlateid = localStorage.getItem("outlet_id");
    // const id = selectedCustomer?.id;
    if (outlateid && id) {
      try {
        const url = `/customer/privilege/${outlateid}/${id}`;
        const response = await get(url);
        if (response.data.success) {
          const countData = response.data.data;
          await setCustomerCount(countData);
        } else {
          console.error("Failed to fetch customer count.");
        }
      } catch (error) {
        console.error("Error fetching customer count: ", error);
      }
    }
  };

  const handleProductTabChange = (key: any) => {
    setSelectedProductTab(key);
    let filteredProducts =
      key == "null"
        ? productItems
        : productItems.filter((product) => product.categorycode === key);

    setTempProductItems(filteredProducts);
  };

  const handleServiceTabChange = (key: any) => {
    setSelectedServiceTab(key);
    let filteredServices =
      key == "null"
        ? serviceItems
        : serviceItems.filter((service) => service.categorycode === key);

    setTempServiceItems(filteredServices);
  };

  const handleServiceItemSearch = (e: any) => {
    setSearchServiceValue(e.target.value);
    let filteredServices = [];

    if (e.target.value !== "") {
      filteredServices = serviceItems.filter((service) =>
        service.name.includes(e.target.value)
      );
    } else {
      filteredServices = serviceItems;
    }

    setTempServiceItems(filteredServices);
  };

  const handleProductItemSearch = (e: any) => {
    setSearchProductValue(e.target.value);
    let filteredProducts = [];

    if (e.target.value !== "") {
      filteredProducts = productItems.filter((product) =>
        product.name.includes(e.target.value)
      );
    } else {
      filteredProducts = productItems;
    }
    setTempProductItems(filteredProducts);
  };

  const handleGiftcardsItemSearch = (e: any) => {
    setSearchGiftcardValue(e.target.value);
    let filteredGiftcards = [];

    if (e.target.value !== "") {
      filteredGiftcards = giftcardsItems.filter(
        (giftcards) =>
          giftcards.name.includes(e.target.value) ||
          giftcards.code.includes(e.target.value) ||
          giftcards.sellprice.toString().includes(e.target.value)
      );
    } else {
      filteredGiftcards = giftcardsItems;
    }

    setTempGiftcardItems(filteredGiftcards);
  };

  const formattedStaffName = (Staff: any) => {
    let staffText = "";
    const staffCount = Staff.length;

    if (staffCount === 0) {
      staffText = "";
    } else if (staffCount === 1) {
      staffText = Staff[0].staff_name;
    } else {
      const staffNames = Staff.slice(0, 2).map(
        (staffMember: any) => staffMember.staff_name
      );
      const othersCount = staffCount - 2;
      staffText = `${staffNames.join("、")}${
        othersCount > 0 ? ` and ${othersCount} others` : ""
      }`;
    }
    return staffText;
  };
  const formattedText = () => {
    return notesValue.replace(/\n/g, " ");
  };

  const formatToTwoDecimalPlaces = (value: any) => {
    if (!isNaN(value)) {
      const num = parseFloat(value);
      if (!Number.isInteger(num)) {
        return num.toFixed(1);
      }
    }
    return value;
  };

  const handleProductImageShow = () => {
    setShowProductImage(!showProductImage);
  };

  const handleServiceImageShow = () => {
    setShowServiceImage(!showServiceImage);
  };

  const handleDeleteCartItem = (item: any) => {
    setSelectedItem(item);
    setIsDeleteServiceModalVisible(true);
  };

  const handlePayNow = async () => {
    if (cartItems && cartItems.length == 0) {
      message.error("Please select the content of the bill!");
    } else {
      const hasGiftcard = cartItems.some(
        (item) => item.item_type === "Giftcard"
      );
      const hasMembership = cartItems.some(
        (item) => item.item_type === "Membership"
      );
      const hasPackage = cartItems.some((item) => item.item_type === "Package");
      if (hasGiftcard && !selectedCustomer) {
        message.error(
          "Please select a customer to sell memberships / gift cards /packages"
        );
        setShowCustomerModal(true);
        return;
      }
      if (hasPackage && !selectedCustomer) {
        message.error(
          "Please select a customer to sell memberships / gift cards /packages"
        );
        setShowCustomerModal(true);
        return;
      }
      if (hasMembership && !selectedCustomer) {
        message.error(
          "Please select a customer to sell memberships / gift cards /packages"
        );
        setShowCustomerModal(true);
        return;
      }
      try {
        setPayNowClick(true);

        const url = "/billing/create";
        let payload = {
          order_date: selectedDate.format("DD-MM-YYYY"),
          outlet_id: localStorage.getItem("outlet_id"),
          ref_no: refNum.trim(),
          customer_source:
            selectedCustomer && selectedCustomer?.source
              ? selectedCustomer?.source
              : selectedCustomerSource,
          refer_type: selectedReferralType,
          customer_refer: selectedReferrerId,
          male_count: maleCount,
          female_count: femaleCount,
          customer_id: selectedCustomer ? selectedCustomer.id : "",
          customer_gender: selectedCustomer ? selectedCustomer.gender : "",
          customer_gift_card_count: selectedCustomer?.all_giftcard,
          note: notesValue,
          note_images: [],
          bill_status: "unpaid",
          discount: discountAmount.toString(),
          discount_type: discountType,
          taxes: Number(taxValue).toFixed(2).toString(),
          sub_total: subTotal.toString(),
          final_total: total.toString(),
          // items: cartItems.map((item) => ({
          //   ...item,
          //   item_price: item.item_price.toString(),
          //   item_discount_percentage: item.item_discount_percentage.toString(),
          //   item_pay_amount: item.item_pay_amount.toString(),
          //   item_qty: item.item_qty.toString(),
          //   total: item.total.toString(),
          // })),
          items: cartItems.map((item) => {
            if (item.item_type === "Package") {
              item.products = item.products
                .map((product: any) => {
                  if (product.combo) {
                    product.combo = product.combo.filter(
                      (comboProduct: any) => comboProduct.is_checked === true
                    );
                  }
                  return product;
                })
                .filter((product: any) => {
                  if (product.combo) {
                    return product.combo.length > 0;
                  }
                  return true;
                });

              item.services = item.services
                .map((service: any) => {
                  if (service.combo) {
                    service.combo = service.combo.filter(
                      (comboService: any) => comboService.is_checked === true
                    );
                  }
                  return service;
                })
                .filter((service: any) => {
                  if (service.combo) {
                    return service.combo.length > 0;
                  }
                  return true;
                });
            }

            // Return the item with updated products and services
            return {
              ...item,
              item_price: item.item_price.toString(),
              item_discount: item.item_discount_percentage.toString(),
              item_pay_amount: item.item_pay_amount.toString(),
              item_qty: item.item_qty.toString(),
              total: item.total.toString(),
            };
          }),
        };

        if (id) {
          const url = `/billing/${id}`;
          const response = await put(url, payload);

          if (response && response.data && response.data.success) {
            message.success("Successfully added!");
            setPayNowClick(false);
            navigate(`/sales/pay/${id}`);
          } else {
            setPayNowClick(false);
            message.error(response.data.message || "Submission failed");
          }
        } else {
          const url = "/billing/create";
          const response = await post(url, payload);

          if (response && response.data && response.data.success) {
            message.success("Successfully added!");
            setPayNowClick(false);
            navigate(`/sales/pay/${response.data.data}`);
          } else {
            setPayNowClick(false);
            message.error(response.data.message || "Submission failed");
          }
        }
      } catch (error: any) {
        setPayNowClick(false);

        console.log(error);

        // Safely access the error message or use a default fallback
        const errorMessage =
          error?.message || error?.toString() || "Something went wrong";
        message.error(errorMessage);
      }
    }
  };

  const handleSaveUnpaid = async () => {
    if (cartItems && cartItems.length == 0) {
      message.error("Please select the content of the bill!");
      return;
    } else {
      try {
        const hasGiftcard = cartItems.some(
          (item) => item.item_type === "Giftcard"
        );
        const hasPackage = cartItems.some(
          (item) => item.item_type === "Package"
        );
        if (hasGiftcard && !selectedCustomer) {
          message.error(
            "Please select a customer to sell memberships / gift cards /packages"
          );
          setShowCustomerModal(true);
          return;
        }
        if (hasPackage && !selectedCustomer) {
          message.error(
            "Please select a customer to sell memberships / gift cards /packages"
          );
          setShowCustomerModal(true);
          return;
        }
        setPayNowClick(true);
        let payload = {
          order_date: selectedDate.format("DD-MM-YYYY"),
          outlet_id: localStorage.getItem("outlet_id"),
          ref_no: refNum.trim(),
          male_count: maleCount,
          female_count: femaleCount,
          refer_type: selectedReferralType,
          customer_refer: selectedReferrerId,
          customer_source: selectedCustomerSource,
          customer_id: selectedCustomer ? selectedCustomer.id : "",
          customer_gender: selectedCustomer ? selectedCustomer.gender : "",
          note: notesValue,
          note_images: [],
          bill_status: "unpaid",
          discount: discountAmount.toString(),
          discount_type: discountType,
          taxes: taxValue.toString(),
          sub_total: subTotal.toString(),
          final_total: total.toString(),
          items: cartItems,
        };

        if (id) {
          const url = `/billing/${id}`;
          const response = await put(url, payload);
          if (response && response.data && response.data.success) {
            message.success("Successfully added!");
            setPayNowClick(false);
            navigate(`/sales`);
          } else {
            setPayNowClick(false);
            message.error(response.data.message || "Submission failed");
          }
        } else {
          const url = "/billing/create";
          const response = await post(url, payload);
          if (response && response.data && response.data.success) {
            message.success("Successfully added!");
            setPayNowClick(false);
            navigate(`/sales`);
          } else {
            setPayNowClick(false);
            message.error(response.data.message || "Submission failed");
          }
        }
      } catch (error: any) {
        setPayNowClick(false);
        message.error(error || "Something went wrong");
      }
    }
  };

  const handleItemEdit = (item: any, type: any) => {
    setSelectedItem(item);
    if (type == "Service") {
      setShowServiceEditModal(true);
    } else if (type == "Product") {
      setShowProductEditModal(true);
    } else if (type == "Membership") {
      setShowMembershipEditModal(true);
    } else if (type == "Giftcard") {
      setShowGiftcardEditModal(true);
    } else if (type == "Package") {
      setShowPackageEditModalBilling(true);
      setSelectedStaff(item?.staff);
    }
  };

  const handleNotesEdit = () => {
    setShowNotesEditModal(true);
  };

  const handleStaffEdit = (item: any, type: any) => {
    setSelectedItem(item);
    if (type == "Service") {
      setShowServiceStaffEditModal(true);
    } else {
      setShowProductStaffEditModal(true);
    }
  };

  const fetchBilldata = async (id: string) => {
    try {
      const url = `/billing/${id}`;
      const response = await get(url);
      if (response.data.success) {
        const countData = response.data.data;

        //add customer selection by customer id
        if (response.data.data.items) {
          const updatedItems = response.data.data.items.map((item: any) => ({
            ...item,
            item_unique_id: generateUniqueId(),
            item_qty: Number(item.item_qty),
            item_price: Number(item.item_price),
            item_tax_rate: Number(item.item_tax_rate),
            item_discount: Number(item.item_discount),
            item_pay_amount: Number(item.item_pay_amount),
            total: Number(item.item_qty) * Number(item.item_price),
            item_discount_percentage: Number(item.item_discount),
          }));
          setCartItems(updatedItems);
        }

        if (response.data.data.items) {
          const updatedStaff = response.data.data.items.flatMap((item: any) =>
            item.staff.map((staff: any) => ({
              staff_name: staff.staff_name,
              staff_code: staff.staff_code,
            }))
          );

          setSelectedStaff(updatedStaff);
        }

        if (response.data.data) {
          const updateData = response.data.data;
          // console.log(...updateData,"...updateData");
          const updatedCustomer = {
            id: updateData.customer_id,
            all_giftcard: updateData.count,
            customercode: updateData.customer_code,
            gender: updateData.customer_gender,
            name: updateData.customer_name,
            countrycode: updateData.customer_countrycode,
            phonenumber: updateData.customer_phone,
            email: updateData.customer_email,
          };

          setSelectedCustomerSource(updateData.customer_source_name);
          setSelectedReferrer(updateData.refer_name);
          setRefNum(updateData.ref_no);
          if (response.data.data.customer_code !== null) {
            setSelectedCustomer(updatedCustomer);
            await fetchCoustomerCountData(response.data.data.customer_id);
            await getCustomerCourse(response.data.data.customer_id);
            await getCustomerKIV(response.data.data.customer_id);
            await setCustomerCountChanges(!customerCountChange);
          } else {
            setSelectedCustomer(null);
          }
        }
      } else {
        console.error("Failed to fetch customer count.");
      }
    } catch (error) {
      console.error("Error fetching customer count: ", error);
    }
  };

  const fetchCoustomerData = async (id: string | null) => {
    try {
      if (id !== null && id !== undefined) {
        const url = `/customer/${id}?outletfield=${localStorage.getItem(
          "outlet_id"
        )}`;
        const response = await get(url);
        if (response.data.success) {
          const updateData = response.data.data;
          const updatedCustomer = {
            id: updateData.id,
            customercode: updateData.customercode,
            gender: updateData.gender,
            name: updateData.firstname + " " + updateData.lastname,
            countrycode: updateData.countrycode,
            phonenumber: updateData.phonenumber,
            email: updateData.email,
          };

          setSelectedCustomerSource(updateData.source);
          setSelectedReferrer(updateData.referralid);
          if (response.data.data.customer_code !== null) {
            setSelectedCustomer(updatedCustomer);
          } else {
            setSelectedCustomer(null);
          }
        } else {
          console.error("Failed to fetch customer count.");
        }
      }
    } catch (error) {
      console.error("Error fetching customer count: ", error);
    }
  };

  const fetchOnlineCustomer = async () => {
    await fetchCoustomerData(onlinesalescustomerid);
    await getCustomerOnlinesales(onlinesalescustomerid);
    await fetchCoustomerCountData(onlinesalescustomerid);
    await setCustomerCountChanges(!customerCountChange);
    await getCustomerDebt(onlinesalescustomerid);
    if (debt !== undefined && debt !== null) {
      let item = {
        item_unique_id: generateUniqueId(),
        item_id: generateUniqueId(),
        item_code: generateUniqueId(),
        item_categorycode: null,
        item_name: "Debt",
        item_note: "",
        item_tax: "",
        item_tax_rate: "",
        item_discount_percentage: "0",
        item_qty: "1",
        item_privilege_id: null,
        item_privilege_name: null,
        deduction_name: "",
        item_deduction: false,
        item_type: "Debt",
        staff: selectedStaff,
        item_price: parseFloat(debt),
        item_pay_amount: parseFloat(debt) * 1,
        total: parseFloat(debt) * 1,
        debtdetails: customerMemberDebtData,
      };
      setCartItems((prevItems) => [...prevItems, item]);
      setTempCartItems((prevItems) => [...prevItems, item]);
    }
    if (
      course !== undefined &&
      course !== null &&
      itemid !== null &&
      itemid !== undefined &&
      refno !== undefined &&
      refno !== null &&
      itemname !== undefined &&
      itemname !== null
    ) {
      let item = {
        item_unique_id: generateUniqueId(),
        item_id: itemid,
        item_code: refno,
        item_categorycode: null,
        item_name: itemname,
        item_note: "",
        item_tax: "",
        item_tax_rate: "",
        item_discount_percentage: 0,
        item_qty: course,
        item_privilege_id: null,
        item_privilege_name: null,
        deduction_name: "",
        item_deduction: true,
        item_type: "course",
        staff: [],
        item_price: "0",
        item_pay_amount: "0",
        total: "0",
      };
      setCartItems((prevItems) => [...prevItems, item]);
      setTempCartItems((prevItems) => [...prevItems, item]);
    }
    if (
      kiv !== undefined &&
      kiv !== null &&
      itemid !== null &&
      itemid !== undefined &&
      refno !== undefined &&
      refno !== null &&
      itemname !== undefined &&
      itemname !== null
    ) {
      let item = {
        item_unique_id: generateUniqueId(),
        item_id: itemid,
        item_code: refno,
        item_categorycode: null,
        item_name: itemname,
        item_note: "",
        item_tax: "",
        item_tax_rate: "",
        item_discount_percentage: 0,
        item_qty: kiv,
        item_privilege_id: null,
        item_privilege_name: null,
        deduction_name: "",
        item_deduction: true,
        item_type: "kiv",
        staff: [],
        item_price: "0",
        item_pay_amount: "0",
        total: "0",
      };
      setCartItems((prevItems) => [...prevItems, item]);
      setTempCartItems((prevItems) => [...prevItems, item]);
    }
  };

  const fetchOutletCount = () => {};

  useEffect(() => {
    setPayNowClick(false);
    fetchServiceCategory();
    fetchOutletCustomerCount();
    // fetchProductCategory();
    // fetchProducts();
    // fetchMembership();
    fetchServices();
    // fetchGiftcards();
    // fetchPackages();
    fetchRefNum();
    fetchTaxes();
    fetchCustomerSource();
    if (onlinesalescustomerid !== undefined && onlinesalescustomerid !== null) {
      fetchOnlineCustomer();
    }
    if (id) {
      fetchBilldata(id);
    }
    fetchOutletCount();
  }, []);

  const handleCartMembershipUpdate = (data: any) => {
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, ...data }
        : item
    );
    setCartItems(updatedCartItems);
    setTempCartItems(updatedCartItems);
    setShowMembershipEditModal(false);
  };

  const handleCartProductUpdate = (data: any) => {
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, ...data }
        : item
    );
    setCartItems(updatedCartItems);
    setTempCartItems(updatedCartItems);
    setShowProductEditModal(false);
    setCustomerGiftCardDataUpdate(!customerGiftCardDataUpdate);
    setCustomerPackageDataDataUpdate(!customerPackageDataDataUpdate);
  };

  const handlePackageUpdate = (data: any) => {
    // console.log("pacakage data in billing.....", data);
    addToCart("Package", selectedStaff, data);
  };

  const handlePackageEditUpdate = (data: any) => {
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, ...data }
        : item
    );
    setCartItems(updatedCartItems);
    setTempCartItems(updatedCartItems);
    setShowPackageEditModalBilling(false);
  };
  const handleBillInfoData = (data: any) => {
    let futuredate = dayjs(data.selectedDate).isAfter(dayjs());
    if (!futuredate) {
      setSelectedDate(data.selectedDate);
    }
    setRefNum(data.refNum);
    setSelectedReferralType(data.selectedReferralType);
    setSelectedReferrer(data.selectedReferrer);
    setSelectedReferrerId(data.selectedReferrerId);
    setSelectedCustomerSource(data.selectedCustomerSource);
    setBillInfoModalVisible(false);
  };

  const handleNoteAdd = (data: any) => {
    setNotesValue(data);
    setShowNotesEditModal(false);
  };

  const handleServiceStaffChange = (data: any) => {
    let newStaffArray = data
      .filter((staffItem: any) => staffItem.selectedStaff) // Filter out items where staff is null
      .map((staffItem: any) => ({
        staff_service_id: staffItem.id,
        service_category_id: staffItem.selectedStaff.staffcategory,
        staff_id: staffItem.selectedStaff.id,
        staff_name: staffItem.selectedStaff.name,
        staff_code: staffItem.selectedStaff.staffcode,
        department_id: staffItem.selectedStaff.department,
        department_name: staffItem.selectedStaff.department_name,
        staff_percentage: staffItem.selectedStaff.percentage,
      }));
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, staff: newStaffArray } // Only update the "quantity" field
        : item
    );
    setCartItems(updatedCartItems);
    setTempCartItems(updatedCartItems);
    setShowServiceStaffEditModal(false);
  };

  const handleProductStaffChange = (data: any) => {
    let newStaffArray = data.map((staffItem: any) => ({
      staff_service_id: staffItem.id,
      service_category_id: staffItem.staffcategory,
      staff_id: staffItem.id,
      staff_name: staffItem.name,
      staff_code: staffItem.staffcode,
      department_id: staffItem.department,
      department_name: staffItem.department_name,
      staff_percentage: staffItem.percentage,
    }));
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, staff: newStaffArray }
        : item
    );
    setCartItems(updatedCartItems);
    setTempCartItems(updatedCartItems);
    setShowProductStaffEditModal(false);
  };

  const handleCartServiceUpdate = (data: any) => {
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, ...data }
        : item
    );
    setCartItems(updatedCartItems);
    setTempCartItems(updatedCartItems);
    setShowServiceEditModal(false);
    setCustomerGiftCardDataUpdate(!customerGiftCardDataUpdate);
    setCustomerPackageDataDataUpdate(!customerPackageDataDataUpdate);
  };

  const handleApplicableProductModel = (id: any, type: string) => {
    setSelectedProductId(id);
    setSelectedApplicableType(type);
    setApplicableModelVisible(true);
  };

  const onCloseApplicableModel = () => {
    setApplicableModelVisible(false);
    setSelectedProductId("");
    setSelectedApplicableType("");
  };

  const handleCustomerPrepaidModal = () => {
    setShowCustomerPrepaidModal(true);
  };

  const handleCustomerDiscountModal = () => {
    setShowCustomerDiscountModal(true);
  };

  const handleCustomerVistbasedModal = () => {
    setShowCustomerVisitBasedModal(true);
  };

  const handleCustomerGiftCardModal = () => {
    setShowCustomerGiftCardModal(true);
  };

  const handleCustomerDebtModal = () => {
    setShowCustomerDebtModal(true);
  };

  const handleCustomerPackageModal = () => {
    setShowCustomerPackageModal(true);
  };

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/sales")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Billing</h2>
          </div>
        </Col>
      </Row>

      {/* Main Layout */}
      <Row
        gutter={[24, 24]}
        style={{ height: "calc(100vh - 90px)", padding: "10px" }}
      >
        <Col
          span={16}
          style={{
            border: "1px solid #E6E6E6",
            padding: "12px 24px 12px 32px",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
          }}
        >
          <div className="section-box">
            <Tabs
              activeKey={activeMainTab}
              onChange={handleMainTabChange}
              tabBarGutter={20}
              size="large"
              type="line"
              style={{ marginBottom: "15px" }}
            >
              <TabPane tab="Services" key="Services">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Tabs or Search Input Section */}
                  {!showServiceSearchInput ? (
                    <Tabs
                      type="line"
                      activeKey={selectedServiceTab}
                      onChange={handleServiceTabChange}
                      tabBarGutter={25}
                    >
                      {serviceCategory &&
                        serviceCategory.length > 0 &&
                        serviceCategory.map((category) => (
                          <>
                            <TabPane tab={category.name} key={category.id} />
                          </>
                        ))}
                    </Tabs>
                  ) : (
                    <Search
                      placeholder="Please enter"
                      allowClear
                      enterButton="Search"
                      size="large"
                      value={searchServiceValue}
                      onChange={handleServiceItemSearch}
                      onClear={handleClearServiceSearch}
                      prefix={
                        <SearchOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                            marginRight: "5px",
                            alignItems: "center",
                          }}
                        />
                      }
                      style={{
                        width: "85%",
                        height: "40px",
                        alignItems: "center",
                      }}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <SearchOutlined
                      style={{
                        fontSize: "20px",
                        color: showServiceSearchInput ? "blue" : "#c2cadd", // Change color when active
                        cursor: "pointer",
                      }}
                      onClick={handleSearchServiceIconClick}
                    />
                    <Dropdown overlay={serviceMenu} trigger={["click"]}>
                      <img
                        src={
                          sortServiceCol !== "Default"
                            ? sortActiveIcon
                            : sortIcon
                        }
                        alt={`Uploaded image`}
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Dropdown>
                    <img
                      src={showServiceImage ? imageActiveIcon : imageIcon}
                      alt={`Uploaded image`}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleServiceImageShow}
                    />
                  </div>
                </div>

                <div
                  style={{ height: "calc(100vh - 300px)", overflowY: "auto" }}
                >
                  {tempServiceItems &&
                    tempServiceItems.length > 0 &&
                    tempServiceItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            width: "142px",
                            minHeight: "110px",
                            border: ".5px solid #e0e7fe",
                            background: "#fff",
                            marginRight: "12px",
                            marginBottom: "12px",
                            display: "inline-block",
                            verticalAlign: "top",
                            cursor: "pointer",
                            transition: "all .2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.background = "#e0e7fe")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.background = "#fff")
                          }
                          onClick={() => {
                            selectShowServiceModal(item);
                          }}
                        >
                          {showServiceImage && (
                            <img
                              src={
                                item.imageUrl ? item.imageUrl : emptyImageIcon
                              }
                              height={"142px"}
                              width={"100%"}
                              alt="Product"
                            />
                          )}
                          <div style={{ padding: "10px" }}>
                            <div style={{ padding: "10px 0" }}>
                              <span
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  textOverflow: "ellipsis",
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: "vertical",
                                  lineHeight: "18px",
                                  height: "54px",
                                  fontSize: "14px",
                                }}
                              >
                                {item.name}
                              </span>
                            </div>
                            <span
                              style={{
                                color: "#f43b00",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              {currencySymbol.CURRENCY_SYMBOL}
                              {item.sellprice}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}

                  {tempServiceItems && tempServiceItems.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
                </div>
              </TabPane>

              <TabPane tab="Products" key="Products">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Tabs or Search Input Section */}
                  {!showProductSearchInput ? (
                    <Tabs
                      type="line"
                      activeKey={selectedProductTab}
                      onChange={handleProductTabChange}
                      tabBarGutter={25}
                    >
                      {productCategory &&
                        productCategory.length > 0 &&
                        productCategory.map((category) => (
                          <>
                            <TabPane tab={category.name} key={category.id} />
                          </>
                        ))}
                    </Tabs>
                  ) : (
                    <Search
                      placeholder="Please enter"
                      allowClear
                      enterButton="Search"
                      size="large"
                      value={searchProductValue}
                      onChange={handleProductItemSearch}
                      onClear={handleClearProductSearch}
                      prefix={
                        <SearchOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                            marginRight: "5px",
                            alignItems: "center",
                          }}
                        />
                      }
                      style={{
                        width: "85%",
                        height: "40px",
                        alignItems: "center",
                      }}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <SearchOutlined
                      style={{
                        fontSize: "20px",
                        color: showProductSearchInput ? "blue" : "#c2cadd",
                        cursor: "pointer",
                      }}
                      onClick={handleSearchProductIconClick}
                    />
                    <Dropdown overlay={productMenu} trigger={["click"]}>
                      <img
                        src={
                          sortProductCol !== "Default"
                            ? sortActiveIcon
                            : sortIcon
                        }
                        alt={`Uploaded image`}
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Dropdown>
                    <img
                      src={showProductImage ? imageActiveIcon : imageIcon}
                      alt={`Uploaded image`}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      onClick={handleProductImageShow}
                    />
                  </div>
                </div>

                <div
                  style={{ height: "calc(100vh - 300px)", overflowY: "auto" }}
                >
                  {tempProductItems &&
                    tempProductItems.length > 0 &&
                    tempProductItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            width: "142px",
                            minHeight: "110px",
                            border: ".5px solid #e0e7fe",
                            background: "#fff",
                            marginRight: "12px",
                            marginBottom: "12px",
                            display: "inline-block",
                            verticalAlign: "top",
                            cursor: "pointer",
                            transition: "all .2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.background = "#e0e7fe")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.background = "#fff")
                          }
                          onClick={() => {
                            selectShowProductModal(item);
                          }}
                        >
                          {showProductImage && (
                            <img
                              src={
                                item.imageUrl ? item.imageUrl : emptyImageIcon
                              }
                              height={"142px"}
                              width={"100%"}
                              alt="Product"
                            />
                          )}
                          <div style={{ padding: "0 5px 0 10px" }}>
                            <div style={{ padding: "10px 0" }}>
                              <span
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  textOverflow: "ellipsis",
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: "vertical",
                                  lineHeight: "18px",
                                  height: "54px",
                                  fontSize: "14px",
                                }}
                              >
                                {item.name}
                              </span>
                            </div>
                            <span
                              style={{
                                color: "#f43b00",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              {currencySymbol.CURRENCY_SYMBOL}
                              {item.sellprice}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}

                  {tempProductItems && tempProductItems.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
                </div>
              </TabPane>

              {/* membership */}

              <TabPane tab="Memberships" key="Memberships">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <Input
                    placeholder="Search by name / code / price"
                    value={searchMembershipValue}
                    onChange={handleMembershipItemSearch}
                    onClear={handleClearMembershipSearch}
                    allowClear
                    prefix={
                      <SearchOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          marginRight: "2px",
                          alignItems: "center",
                        }}
                      />
                    }
                    style={{
                      width: "90%",
                      height: "42px",
                      alignItems: "center",
                    }}
                  />

                  <Button
                    type="primary"
                    onClick={() => {}}
                    style={{
                      background: "#2e56f2",
                      color: "#fff",
                      borderRadius: "6px",
                      width: "81px",
                      height: "42px",
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                  >
                    Search
                  </Button>

                  {/* <div>
                    <img
                      src={customizeIcon}
                      alt={`CustomizeIcon`}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "4px",
                      }}
                    />
                    <span
                      style={{
                        color: "#ff971e",
                        fontWeight: 700,
                        cursor: "pointer",
                        verticalAlign: "top",
                      }}
                    >
                      Customize
                    </span>
                  </div> */}
                </div>

                <div
                  style={{
                    height: "calc(100vh - 300px)",
                    overflowY: "auto",
                  }}
                >
                  {tempMembershipItems &&
                    tempMembershipItems.length > 0 &&
                    tempMembershipItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            color: `${
                              item?.membership_type == "Prepaid"
                                ? "rgba(93, 74, 140, 0.8)"
                                : item?.membership_type == "Discount"
                                ? "rgba(45,73,127,0.8)"
                                : item?.membership_type == "Visit-based"
                                ? "rgba(139, 88, 59, .8)"
                                : null
                            } `,
                            position: "relative",
                            width: " 100%",
                            // height: "110px",
                            marginBottom: "16px",
                            backgroundImage: `url(${
                              item?.membership_type === "Prepaid"
                                ? membershipbgdPrepaid
                                : item?.membership_type === "Discount"
                                ? membershipbgdDiscount
                                : item?.membership_type === "Visit-based"
                                ? membershipbgdVisitBased
                                : null
                            })`,
                            backgroundSize: "100% 100%",
                            transition: "all .2s",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                          onClick={() => {
                            selectShowMembershipModal(item);
                          }}
                        >
                          <div className="gft_image-icon">
                            <div className="gft_van-image">
                              <img
                                className="gft_van-image__img"
                                src={
                                  item?.membership_type === "Prepaid"
                                    ? membershipimgPrepaid
                                    : item?.membership_type === "Discount"
                                    ? membershipimgDiscount
                                    : item?.membership_type === "Visit-based"
                                    ? membershipimgVisitBased
                                    : undefined
                                }
                                alt="membership"
                              ></img>
                            </div>
                          </div>
                          <div className="ms_view-bc">
                            <div className="ms_view-info">
                              <div className="ms_overflowText">
                                <span className="ms_title">{item?.name}</span>
                                <span className="ms_saleCardItem_text_code">
                                  Code:{item?.code}
                                </span>
                              </div>
                              <div className="ms_view-apply">
                                <span className="ms_view-cardApply">
                                  <div className="ms_bk-cardApply">
                                    <span className="ms_cardApply_text_applicable">
                                      Applicable to:
                                    </span>
                                    {item?.applicable_to}
                                  </div>
                                </span>
                                <span className="ms_icon-gengduo">
                                  <RightOutlined />
                                </span>
                              </div>
                            </div>
                            {item?.membership_type == "Prepaid" && (
                              <div className="ms_display-def_view-item">
                                <div className="ms_view-tip">Stored value</div>
                                <div className="ms_view-value">
                                  {currencySymbol.CURRENCY_SYMBOL}
                                  {item?.prepaid_value}
                                </div>
                              </div>
                            )}
                            {item?.membership_type == "Prepaid" && (
                              <div className="ms_display-def_view-item">
                                <div className="ms_view-tip">Bonus value</div>
                                <div className="ms_view-value">
                                  {item?.prepaid_bonuse}
                                </div>
                              </div>
                            )}

                            {item?.membership_type == "Discount" && (
                              <div className="ms_display-def_view-item_session">
                                <div className="ms_view-tip">Sessions</div>
                                <div className="ms_view-value">
                                  {item?.max_usage}
                                </div>
                              </div>
                            )}

                            {item?.membership_type == "Visit-based" && (
                              <div className="ms_display-def_view-item_session">
                                <div className="ms_view-tip">Sessions</div>
                                <div className="ms_view-value">
                                  {item?.buyprice} + {item?.giveaway}
                                </div>
                              </div>
                            )}

                            {(item?.membership_type == "Discount" ||
                              item?.membership_type == "Prepaid") && (
                              <div className="ms_display-def_view-item_disc">
                                <div className="ms_view-tip">
                                  <span className="ms_view-tip">Discount</span>
                                  <span style={{ marginLeft: "3px" }}>
                                    <Tooltip
                                      placement="topRight"
                                      title="Service discount / Product discount / Privilege discount"
                                    >
                                      <InfoCircleOutlined
                                        style={{
                                          // fontSize: "16px",
                                          cursor: "pointer",
                                          fontFamily: "iconfont !important",
                                          fontStyle: "normal",
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </div>
                                {item?.membership_type == "Discount" && (
                                  <div className="ms_view-value">
                                    {item?.service_discount}%/
                                    {item?.product_discount}%/
                                    {item?.privilege_discount}%
                                  </div>
                                )}
                                {item?.membership_type == "Prepaid" && (
                                  <div className="ms_view-value">
                                    {item?.pre_service_discount}%/
                                    {item?.pre_product_discount}%/
                                    {item?.pre_privilege_discount}%
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="ms_view-bottom">
                            <div className="ms_view-left">
                              <div className="ms_bk-validDateText">
                                <span className="ms_saleCardItem_text_valid">
                                  Valid for:{" "}
                                </span>
                                <span className="ms_bk-date">
                                  {item?.typeof_validity === "Custom" ? (
                                    <>
                                      {item?.valid_startdate}~
                                      {item?.valid_enddate}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {item?.typeof_validity === "Immediately" ? (
                                    <>
                                      {item?.valid_count}
                                      {item?.valid_countperiod}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {item?.typeof_validity ===
                                  "Effective on consumption day" ? (
                                    <>
                                      {item?.valid_count}{" "}
                                      {item?.valid_countperiod}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                {/* <span>~</span>
                                <span className="gtf_bk-date">17/11/2024</span> */}
                              </div>
                              <div className=""></div>
                            </div>
                            <div className="ms_view-right">
                              <div className="ms_view-price">
                                <span className="ms_saleCardItem_text_price">
                                  Price
                                </span>
                                {item?.membership_type == "Prepaid" && (
                                  <span className="ms_color-def">
                                    {currencySymbol.CURRENCY_SYMBOL}{" "}
                                    {item?.selling_price}
                                  </span>
                                )}
                                {item?.membership_type == "Visit-based" && (
                                  <span className="ms_color-def">
                                    {currencySymbol.CURRENCY_SYMBOL}
                                    {item?.totalprice}
                                  </span>
                                )}
                                {item?.membership_type == "Discount" && (
                                  <span className="ms_color-def">
                                    {currencySymbol.CURRENCY_SYMBOL}
                                    {item?.sellprice}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  {tempMembershipItems && tempMembershipItems.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                          top: 400,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
                </div>
              </TabPane>

              <TabPane tab="Giftcard" key="Giftcard">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <Input
                    placeholder="Search by name / code / price"
                    value={searchGiftcardValue}
                    onChange={handleGiftcardsItemSearch}
                    onClear={handleClearGiftcardsSearch}
                    allowClear
                    prefix={
                      <SearchOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          marginRight: "2px",
                          alignItems: "center",
                        }}
                      />
                    }
                    style={{
                      width: "90%",
                      height: "42px",
                      alignItems: "center",
                    }}
                  />

                  <Button
                    type="primary"
                    onClick={() => {}}
                    style={{
                      background: "#2e56f2",
                      color: "#fff",
                      borderRadius: "6px",
                      width: "81px",
                      height: "42px",
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                  >
                    Search
                  </Button>
                </div>

                <div
                  style={{
                    height: "calc(100vh - 300px)",
                    overflowY: "auto",
                    // padding: "10px 24px 24px 24px"
                  }}
                >
                  {tempgiftcardItems &&
                    tempgiftcardItems.length > 0 &&
                    tempgiftcardItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            color: "rgba(176, 87, 77, .8)",
                            // paddingRight: "20%",
                            position: "relative",
                            width: " 100%",
                            // height: "110px",
                            marginBottom: "16px",
                            backgroundImage: `url(${giftcardImage})`,
                            backgroundSize: "100% 100%",
                            transition: "all .2s",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                          onClick={() => {
                            selectShowGiftcardModal(item);
                          }}
                        >
                          <div className="gft_image-icon">
                            <div className="gft_van-image">
                              <img
                                className="gft_van-image__img"
                                src={giftcardImg}
                                alt="gift img"
                              ></img>
                            </div>
                          </div>
                          <div className="gft_view-bc">
                            <div className="gft_view-info">
                              <div className="gft_overflowText">
                                <span className="gft_title">{item.name}</span>
                                <span className="gft_saleCardItem_text_code">
                                  Code:{item.code}
                                </span>
                              </div>
                              <div className="gft_view-apply">
                                <span className="gft_view-cardApply">
                                  <div className="gft_bk-cardApply">
                                    <span className="gft_cardApply_text_applicable">
                                      Applicable to:
                                    </span>
                                    {item.applicable_to}
                                  </div>
                                </span>
                                <span className="gft_icon-gengduo">
                                  <RightOutlined />
                                </span>
                                <div className="gft_view-singleUsageQty">
                                  <span className="gft_saleCardItem_text_singleUsageQty">
                                    Single usage qty.：
                                  </span>
                                  <div className="gft_display-def">
                                    {item.singleusageqty}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="gft_view-bottom">
                            <div className="gft_view-left">
                              <div className="gft_bk-validDateText">
                                <span className="gft_saleCardItem_text_valid">
                                  Valid for:{" "}
                                </span>
                                <span className="gtf_bk-date">
                                  {item.validfor}
                                </span>
                                {/* <span>~</span>
                                <span className="gtf_bk-date">17/11/2024</span> */}
                              </div>
                              <div className=""></div>
                            </div>
                            <div className="gft_view-right">
                              <div className="gft_view-price">
                                <span className="gft_saleCardItem_text_price">
                                  Price
                                </span>
                                <span className="gft_color-def">
                                  {currencySymbol.CURRENCY_SYMBOL}
                                  {item.sellprice}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="gft_dash"></div>
                          <div className="gft_view-giftCardValue">
                            {item.type === "Value" ? (
                              <div className="gft_view-value">
                                <span className="gft_color-def_bg">
                                  {currencySymbol.CURRENCY_SYMBOL}
                                  {item?.sessionvalue}
                                </span>
                              </div>
                            ) : null}
                            {item.type === "Session" ? (
                              <>
                                <div className="gft_view-times">
                                  {item?.sessionvalue}
                                </div>
                                <div className="gft_view-tip">Sessions</div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ))}

                  {tempgiftcardItems && tempgiftcardItems.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                          top: 400,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
                </div>
              </TabPane>

              {/* Package tab pane  */}
              <TabPane tab="Packages" key="Packages">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <Input
                    placeholder="Search by name / code / price"
                    value={searchPackageValue}
                    onChange={handlePackageItemSearch}
                    onClear={handleClearPackageSearch}
                    allowClear
                    prefix={
                      <SearchOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          marginRight: "2px",
                          alignItems: "center",
                        }}
                      />
                    }
                    style={{
                      width: "90%",
                      height: "42px",
                      alignItems: "center",
                    }}
                  />

                  <Button
                    type="primary"
                    onClick={() => {}}
                    style={{
                      background: "#2e56f2",
                      color: "#fff",
                      borderRadius: "6px",
                      width: "81px",
                      height: "42px",
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                  >
                    Search
                  </Button>
                </div>

                <div
                  style={{
                    height: "calc(100vh - 300px)",
                    overflowY: "auto",
                  }}
                >
                  {tempPackagesItems &&
                    tempPackagesItems.length > 0 &&
                    tempPackagesItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            color: "rgba(176, 87, 77, .8)",
                            position: "relative",
                            width: " 100%",
                            marginBottom: "16px",
                            backgroundImage: `url(${packagecardImage})`,
                            backgroundSize: "100% 100%",
                            transition: "all .2s",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                          onClick={() => {
                            selectShowPackageModal(item);
                          }}
                        >
                          <div className="package_image-icon">
                            <div className="package_van-image">
                              <img
                                className="package_van-image__img"
                                src={packageCardImg}
                                alt="package img"
                              ></img>
                            </div>
                          </div>
                          <div className="package_view-bc">
                            <div className="package_view-info">
                              <div className="package_overflowText">
                                <span className="package_title">
                                  {item.name}
                                </span>
                                <span className="package_saleCardItem_text_code">
                                  Code:{item.code}
                                </span>
                              </div>
                              <div className="package_view-apply">
                                {/* {item.applicable_type == "All store" && ( */}
                                <>
                                  <span
                                    className="package_view-cardApply"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent parent div's click event
                                      handleApplicableProductModel(
                                        item.id,
                                        "package"
                                      );
                                    }}
                                  >
                                    <div className="package_bk-cardApply">
                                      <span className="package_cardApply_text_applicable">
                                        Applicable to:
                                      </span>
                                      {item.applicable_to}
                                    </div>
                                  </span>
                                </>
                                {/* )} */}
                                <span className="package_icon-gengduo">
                                  <RightOutlined />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="package_view-bottom">
                            <div className="package_view-left">
                              <div className="package_bk-validDateText">
                                <span className="package_saleCardItem_text_valid">
                                  Valid for:{" "}
                                </span>
                                {item.typeof_validity == "Custom" ? (
                                  <span className="package_bk-date">
                                    {item.validfor}
                                  </span>
                                ) : (
                                  <span className="package_bk-date">
                                    {item.valid_count} {item.valid_countperiod}{" "}
                                    {item.typeof_validity}
                                  </span>
                                )}
                              </div>
                              <div className=""></div>
                            </div>
                            <div className="package_view-right">
                              <div className="package_view-price">
                                <span className="package_saleCardItem_text_price">
                                  Price
                                </span>
                                <span className="package_color-def">
                                  {currencySymbol.CURRENCY_SYMBOL}
                                  {Math.trunc(item.sellprice)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  {tempPackagesItems && tempPackagesItems.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                          top: 400,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
                </div>
              </TabPane>

              {/* My item tab pane  */}
              {myItemData && myItemData?.length > 0 && (
                <>
                  <TabPane tab="My Item" key="My Item">
                    <div
                      style={{
                        height: "calc(100vh - 200px)",
                        overflowY: "auto",
                      }}
                    >
                      {/* Header Row */}
                      <div
                        style={{
                          backgroundColor: "white",
                          border: "1px solid #e0e7fe",
                          marginTop: "10px",
                          borderRadius: "6px",
                          marginBottom: "8px",
                          padding: "6px 30px",
                          fontSize: "12px",
                          fontFamily: "PingFang SC-Medium, PingFang SC",
                          color: "#999",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ flex: 2 }}>Items</div>
                        <div style={{ flex: 1 }}>Type</div>
                        <div style={{ flex: 1 }}>Amount received</div>
                        <div style={{ flex: 1 }}>Total quantity</div>
                        <div style={{ flex: 1 }}>Remaining</div>
                      </div>

                      {myItemData?.length > 0 &&
                        myItemData?.map((item) =>
                          item?.billing_type == "Package" ? (
                            <>
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "16px",
                                  borderRadius: "6px",
                                  border: "1px solid #e0e7fe",
                                  marginBottom: "10px",
                                }}
                              >
                                <Row gutter={24}>
                                  <Col span={8}>
                                    <span style={{ color: "#999" }}>
                                      Package Name:
                                    </span>{" "}
                                    {item.package_name}
                                  </Col>
                                  <Col span={8}>
                                    <span style={{ color: "#999" }}>Code:</span>{" "}
                                    {item.package_code}
                                  </Col>
                                </Row>
                                <div
                                  style={{
                                    borderBottom: "1px solid #e0e7fe",
                                    marginTop: "16px",
                                  }}
                                />

                                {/* ------- single service ------ */}
                                {item?.services?.single &&
                                  item?.services?.single.length > 0 && (
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      {item?.services?.single.map(
                                        (service: any, idx: any) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ width: "50%" }}>
                                                <div
                                                  key={idx}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: "10px",
                                                    position: "relative",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginBottom: "6px",
                                                      padding: "8px 16px",
                                                    }}
                                                  >
                                                    <Text
                                                      style={{ width: "65%" }}
                                                    >
                                                      {service?.service_name}
                                                    </Text>
                                                    <Text
                                                      style={{ width: "26%" }}
                                                    >
                                                      Service
                                                    </Text>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  width: "45%",
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    width: "35%",
                                                    textAlign: "center",
                                                    marginRight: "2px",
                                                  }}
                                                >
                                                  {/* $200 */}
                                                </Text>
                                                <Text
                                                  style={{
                                                    width: "30%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {service?.item_qty}
                                                </Text>
                                                <Text
                                                  style={{
                                                    width: "30%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {service?.remaining_qty}
                                                </Text>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                {/* ------- combo service ------ */}
                                {item.services.combo &&
                                  item.services.combo.length > 0 && (
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        {item.services.combo.length > 1 && (
                                          <>
                                            <div
                                              style={{
                                                position: "absolute",
                                                left: "46%",
                                                top: "15px",
                                                bottom: "20px",
                                                width: "2px",
                                                backgroundColor: "#2e56f2",
                                              }}
                                            />
                                          </>
                                        )}
                                        {item.services.combo.length > 1 && (
                                          <>
                                            <div
                                              style={{
                                                position: "absolute",
                                                left: "46%",
                                                top: "46%",
                                                height: "2px",
                                                width: "15px",
                                                backgroundColor: "#2e56f2",
                                              }}
                                            />
                                          </>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                          }}
                                        >
                                          <div style={{ width: "50%" }}>
                                            {item.services.combo.map(
                                              (combo: any, idx: any) => (
                                                <div
                                                  key={idx}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom:
                                                      idx ===
                                                      item.services.combo
                                                        .length -
                                                        1
                                                        ? "0"
                                                        : "10px",
                                                    position: "relative",
                                                  }}
                                                >
                                                  {idx === 0 &&
                                                    idx !==
                                                      item.services.combo
                                                        .length -
                                                        1 &&
                                                    item.services.combo.length >
                                                      1 && (
                                                      <div
                                                        style={{
                                                          position: "absolute",
                                                          left: "88.5%",
                                                          top: "15px",
                                                          height: "2px",
                                                          width: "15px",
                                                          backgroundColor:
                                                            "#2e56f2",
                                                        }}
                                                      />
                                                    )}
                                                  {idx ===
                                                    item.services.combo.length -
                                                      1 &&
                                                    item.services.combo.length >
                                                      1 && (
                                                      <div
                                                        style={{
                                                          position: "absolute",
                                                          left: "88.5%",
                                                          bottom: "20px",
                                                          height: "2px",
                                                          width: "15px",
                                                          backgroundColor:
                                                            "#2e56f2",
                                                        }}
                                                      />
                                                    )}
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginBottom: "6px",
                                                      padding: "8px 16px",
                                                    }}
                                                  >
                                                    <Text
                                                      style={{ width: "65%" }}
                                                    >
                                                      {combo.service_name}
                                                    </Text>
                                                    <Text
                                                      style={{ width: "26%" }}
                                                    >
                                                      {combo.type_pkg}
                                                    </Text>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              width: "45%",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                width: "35%",
                                                textAlign: "center",
                                                marginRight: "2px",
                                              }}
                                            >
                                              {currencySymbol.CURRENCY_SYMBOL}
                                              {
                                                item.services.combo[0]
                                                  .item_sell_price
                                              }
                                            </Text>
                                            <Text
                                              style={{
                                                width: "30%",
                                                textAlign: "center",
                                              }}
                                            >
                                              {item.services.combo[0].item_qty}
                                            </Text>
                                            <Text
                                              style={{
                                                width: "30%",
                                                textAlign: "center",
                                              }}
                                            >
                                              {
                                                item.services.combo[0]
                                                  .remaining_qty
                                              }
                                            </Text>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {/* ------- single product ------ */}
                                {item?.products?.single &&
                                  item?.products?.single.length > 0 && (
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      {item?.products?.single.map(
                                        (product: any, idx: any) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ width: "50%" }}>
                                                <div
                                                  key={idx}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: "10px",
                                                    position: "relative",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginBottom: "6px",
                                                      padding: "8px 16px",
                                                    }}
                                                  >
                                                    <Text
                                                      style={{ width: "65%" }}
                                                    >
                                                      {product?.product_name}
                                                    </Text>
                                                    <Text
                                                      style={{ width: "26%" }}
                                                    >
                                                      Product
                                                    </Text>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  width: "45%",
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    width: "35%",
                                                    textAlign: "center",
                                                    marginRight: "2px",
                                                  }}
                                                >
                                                  {/* $200 */}
                                                </Text>
                                                <Text
                                                  style={{
                                                    width: "30%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {product?.item_qty}
                                                </Text>
                                                <Text
                                                  style={{
                                                    width: "30%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {product?.remaining_qty}
                                                </Text>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}

                                {/* ------- combo product ------ */}
                                {item.products.combo &&
                                  item.products.combo.length > 0 && (
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        {item.products.combo.length > 1 && (
                                          <>
                                            <div
                                              style={{
                                                position: "absolute",
                                                left: "46%",
                                                top: "15px",
                                                bottom: "20px",
                                                width: "2px",
                                                backgroundColor: "#2e56f2",
                                              }}
                                            />
                                          </>
                                        )}
                                        {item.products.combo.length > 1 && (
                                          <>
                                            <div
                                              style={{
                                                position: "absolute",
                                                left: "46%",
                                                top: "46%",
                                                height: "2px",
                                                width: "15px",
                                                backgroundColor: "#2e56f2",
                                              }}
                                            />
                                          </>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                          }}
                                        >
                                          <div style={{ width: "50%" }}>
                                            {item.products.combo.map(
                                              (combo: any, idx: any) => (
                                                <div
                                                  key={idx}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom:
                                                      idx ===
                                                      item.products.combo
                                                        .length -
                                                        1
                                                        ? "0"
                                                        : "10px",
                                                    position: "relative",
                                                  }}
                                                >
                                                  {idx === 0 &&
                                                    idx !==
                                                      item.products.combo
                                                        .length -
                                                        1 &&
                                                    item.products.combo.length >
                                                      1 && (
                                                      <div
                                                        style={{
                                                          position: "absolute",
                                                          left: "88.5%",
                                                          top: "15px",
                                                          height: "2px",
                                                          width: "15px",
                                                          backgroundColor:
                                                            "#2e56f2",
                                                        }}
                                                      />
                                                    )}
                                                  {idx ===
                                                    item.products.combo.length -
                                                      1 &&
                                                    item.products.combo.length >
                                                      1 && (
                                                      <div
                                                        style={{
                                                          position: "absolute",
                                                          left: "88.5%",
                                                          bottom: "20px",
                                                          height: "2px",
                                                          width: "15px",
                                                          backgroundColor:
                                                            "#2e56f2",
                                                        }}
                                                      />
                                                    )}
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginBottom: "6px",
                                                      padding: "8px 16px",
                                                    }}
                                                  >
                                                    <Text
                                                      style={{ width: "65%" }}
                                                    >
                                                      {combo.product_name}
                                                    </Text>
                                                    <Text
                                                      style={{ width: "26%" }}
                                                    >
                                                      {combo.type_pkg}
                                                    </Text>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              width: "45%",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                width: "35%",
                                                textAlign: "center",
                                                marginRight: "2px",
                                              }}
                                            >
                                              {currencySymbol.CURRENCY_SYMBOL}
                                              {
                                                item.products.combo[0]
                                                  .item_sell_price
                                              }
                                            </Text>
                                            <Text
                                              style={{
                                                width: "30%",
                                                textAlign: "center",
                                              }}
                                            >
                                              {item.products.combo[0].item_qty}
                                            </Text>
                                            <Text
                                              style={{
                                                width: "30%",
                                                textAlign: "center",
                                              }}
                                            >
                                              {
                                                item.products.combo[0]
                                                  .remaining_qty
                                              }
                                            </Text>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </>
                          ) : item?.billing_type == "Visit-based" ? (
                            <>
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "16px",
                                  borderRadius: "6px",
                                  border: "1px solid #e0e7fe",
                                  marginBottom: "10px",
                                }}
                              >
                                <Row gutter={24}>
                                  <Col span={10}>
                                    <span style={{ color: "#999" }}>
                                      Membership Name:
                                    </span>{" "}
                                    {item.membership_name}
                                  </Col>
                                  <Col span={8}>
                                    <span style={{ color: "#999" }}>Code:</span>{" "}
                                    {item.membership_code}
                                  </Col>
                                </Row>
                                <div
                                  style={{
                                    borderBottom: "1px solid #e0e7fe",
                                    marginTop: "16px",
                                  }}
                                />
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                      }}
                                    >
                                      <div style={{ width: "50%" }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                            position: "relative",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              marginBottom: "6px",
                                              padding: "8px 16px",
                                            }}
                                          >
                                            <Text style={{ width: "65%" }}>
                                              {item?.service_name}
                                            </Text>
                                            <Text style={{ width: "26%" }}>
                                              Service
                                            </Text>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "45%",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            width: "35%",
                                            textAlign: "center",
                                            marginRight: "2px",
                                          }}
                                        >
                                          {item?.selling_price}
                                        </Text>
                                        <Text
                                          style={{
                                            width: "30%",
                                            textAlign: "center",
                                          }}
                                        >
                                          {item?.item_qty}
                                        </Text>
                                        <Text
                                          style={{
                                            width: "30%",
                                            textAlign: "center",
                                          }}
                                        >
                                          {item?.remaining_qty}
                                        </Text>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )
                        )}
                    </div>
                  </TabPane>
                </>
              )}
              {kivData && kivData?.length > 0 && (
                <TabPane tab="KIV" key="KIV">
                  <div
                    style={{
                      height: "calc(100vh - 200px)",
                      overflowY: "auto",
                    }}
                  >
                    {kivData &&
                      kivData.length > 0 &&
                      kivData.map((onlinesalesitem: any, index: any) => (
                        <>
                          {onlinesalesitem.remaining_balance > 0 && (
                            <div
                              key={index}
                              style={{
                                color: "#588c66",
                                border: "2px solid #588c66",
                                position: "relative",
                                cursor: "pointer",
                                // height: "110px",
                                marginBottom: "16px",
                                background: "rgba(204,222,200,255)",
                                transition: "all .2s",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                selectShowKIV(onlinesalesitem);
                              }}
                            >
                              <Row
                                gutter={24}
                                style={{ padding: "10px 24px 0 16px" }}
                              >
                                <Col span={20}>
                                  <span>{onlinesalesitem?.item_name}</span>
                                </Col>
                                <Col span={4}>
                                  <span>KIV Balance</span>
                                </Col>
                              </Row>
                              <Row
                                gutter={24}
                                style={{
                                  padding: "0px 24px 10px 16px",
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Col span={20}>
                                  <span>Ref#:{onlinesalesitem?.ref_no}</span>
                                </Col>
                                <Col span={4}>
                                  <span>
                                    {onlinesalesitem?.remaining_balance}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </TabPane>
              )}
              {courseData && courseData?.length > 0 && (
                <TabPane tab="Course" key="Course">
                  <div
                    style={{
                      height: "calc(100vh - 200px)",
                      overflowY: "auto",
                    }}
                  >
                    {courseData &&
                      courseData.length > 0 &&
                      courseData.map((onlinesalesitem: any, index: any) => (
                        <>
                          {onlinesalesitem.remaining_balance > 0 && (
                            <div
                              key={index}
                              style={{
                                color: "#8a4d63",
                                border: "2px solid #8a4d63",
                                position: "relative",
                                cursor: "pointer",
                                // height: "110px",
                                marginBottom: "16px",
                                background: "rgba(221,199,209,255)",
                                transition: "all .2s",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                selectShowCourse(onlinesalesitem);
                              }}
                            >
                              <Row
                                gutter={24}
                                style={{ padding: "10px 24px 0 16px" }}
                              >
                                <Col span={20}>
                                  <span>{onlinesalesitem?.item_name}</span>
                                </Col>
                                <Col span={4}>
                                  <span>Course Balance</span>
                                </Col>
                              </Row>
                              <Row
                                gutter={24}
                                style={{
                                  padding: "0px 24px 10px 24px",
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Col span={20}>
                                  <span>Ref#:{onlinesalesitem?.ref_no}</span>
                                </Col>
                                <Col span={4}>
                                  <span>
                                    {onlinesalesitem?.remaining_balance}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </TabPane>
              )}
              {onlinesalesData && onlinesalesData?.length > 0 && (
                <TabPane tab="Onlinesales" key="Onlinesales">
                  <div
                    style={{
                      height: "calc(100vh - 200px)",
                      overflowY: "auto",
                    }}
                  >
                    {onlinesalesData &&
                      onlinesalesData.length > 0 &&
                      onlinesalesData.map(
                        (onlinesalesitem: any, index: any) => (
                          <>
                            <div
                              key={index}
                              style={{
                                color: "rgba(93, 74, 140, 0.8)",
                                position: "relative",
                                width: " 100%",
                                cursor: "pointer",
                                // height: "110px",
                                marginBottom: "16px",
                                backgroundImage: `url(${membershipbgdPrepaid})`,
                                backgroundSize: "100% 100%",
                                transition: "all .2s",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                selectShowOnlinesales(onlinesalesitem);
                              }}
                            >
                              <Row
                                gutter={24}
                                style={{ padding: "16px 24px 0 16px" }}
                              >
                                <Col span={8}>
                                  <span>Ref#:{onlinesalesitem?.ref_no}</span>
                                </Col>
                                <Col span={6}>
                                  <span>Products</span>
                                </Col>
                                <Col span={2}>
                                  <span>Qty</span>
                                </Col>
                                <Col span={2}>
                                  <span>KIV</span>
                                </Col>
                                <Col span={2}>
                                  <span>Taken</span>
                                </Col>
                              </Row>
                              {onlinesalesitem.items &&
                                onlinesalesitem.items.length > 0 &&
                                onlinesalesitem.items.map(
                                  (productitem: any, index: any) => (
                                    <Row
                                      gutter={24}
                                      style={{ padding: "5px 24px 0 16px" }}
                                    >
                                      <Col span={8}></Col>
                                      <Col span={6}>
                                        <span>{productitem.name}</span>
                                      </Col>
                                      <Col span={2}>
                                        <span>{productitem.productqty}</span>
                                      </Col>
                                      <Col span={2}>
                                        <span>{productitem.storeqty}</span>
                                      </Col>
                                      <Col span={2}>
                                        <span>{productitem.takenqty}</span>
                                      </Col>
                                    </Row>
                                  )
                                )}
                              <Row
                                gutter={24}
                                style={{
                                  padding: "16px 24px 0 16px",
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <span>
                                  Total : RM{" "}
                                  {parseFloat(
                                    onlinesalesitem.remainamt
                                  ).toFixed(2)}
                                </span>
                              </Row>
                            </div>
                          </>
                        )
                      )}
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </Col>
        <Col
          span={8}
          style={{
            border: "1px solid #E6E6E6",
            paddingLeft: "15px",
            paddingTop: "12px",
            paddingRight: "15px",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
          }}
        >
          <div className="section-box">
            <Form layout="vertical">
              <Row style={{ position: "relative" }}>
                <Col span={8}>
                  <Space.Compact
                    size="large"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text
                      style={{
                        backgroundColor: "transparent",
                        marginRight: "10px",
                        width: "60px",
                      }}
                    >
                      Date:
                    </Text>
                    <Input
                      style={{ cursor: "pointer" }}
                      className="billing-input"
                      value={selectedDate.format("DD/MM/YYYY")}
                      readOnly={true}
                      onClick={handleSingleDateSelection}
                    />
                  </Space.Compact>
                </Col>
                <Col span={16}>
                  <Space.Compact
                    size="large"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        backgroundColor: "transparent",
                        marginRight: "10px",
                        marginLeft: "10px",
                        width: "60px",
                      }}
                    >
                      Ref#:
                    </Text>
                    <Input
                      style={{ cursor: "pointer" }}
                      className="billing-input"
                      value={refNum}
                      readOnly={true}
                      onClick={handleRefNumSelection}
                    />
                  </Space.Compact>
                </Col>
                <div
                  style={{
                    zIndex: "10",
                    background: "#f6f7fa",
                    border: ".5px solid #e0e7fe",
                    borderRadius: "0px 0px 0px 20px",
                    position: "absolute",
                    top: "-15px",
                    right: "-15px",
                    width: "45px",
                    height: "45px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EditOutlined
                    onClick={() => setBillInfoModalVisible(true)}
                    style={{ color: "#2e56f2" }}
                  />
                </div>
              </Row>
              <Row style={{ marginTop: "8px" }}>
                <Space.Compact
                  size="large"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Text
                    style={{
                      backgroundColor: "transparent",
                      marginRight: "10px",
                      width: "100px",
                    }}
                  >
                    Count:
                  </Text>
                  <Input
                    addonBefore={
                      <>
                        <img
                          src={maleIcon}
                          alt="Before"
                          style={{ width: 12, height: 12 }}
                        />
                      </>
                    }
                    addonAfter={
                      <img
                        src={exchangeIcon}
                        alt="After"
                        style={{ width: 20, height: 20, cursor: "pointer" }}
                        onClick={handleSwapCount}
                      />
                    }
                    onChange={(e) => {
                      setMaleCount(e.target.value);
                    }}
                    className="billing-input"
                    value={maleCount}
                  />
                  <Input
                    addonBefore={
                      <img
                        src={femaleIcon}
                        alt="Before"
                        style={{ width: 12, height: 12 }}
                      />
                    }
                    onChange={(e) => {
                      setFemaleCount(e.target.value);
                    }}
                    className="billing-input"
                    value={femaleCount}
                  />
                </Space.Compact>
              </Row>
              {selectedCustomer && (
                <Card
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#6e7687",
                    borderRadius: "20px",
                    padding: "10px",
                    color: "#fff",
                    display: "flex",
                    position: "relative",
                    // height: "150px",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundImage:
                      "radial-gradient(circle, rgba(126,130,153,1) 0%, rgba(85,90,110,1) 100%)",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  }}
                  bodyStyle={{ padding: "0px", position: "relative" }}
                >
                  <Button
                    icon={<CloseOutlined />}
                    type="default"
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      background: "transparent",
                      border: "none",
                      color: "#c2cadd",
                    }}
                    onClick={() => {
                      setIsDeleteMemberModalVisible(true);
                    }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      size={48}
                      icon={<UserOutlined />}
                      style={{ backgroundColor: "#8a92a5" }}
                    />
                    <div style={{ marginLeft: "15px", textAlign: "justify" }}>
                      <Text strong style={{ color: "#fff", fontSize: "16px" }}>
                        {selectedCustomer?.name}
                        {selectedCustomer?.lastname
                          ? selectedCustomer?.lastname
                          : ""}
                      </Text>
                      <br />
                      <Text style={{ color: "#c0c5cf", fontSize: "14px" }}>
                        {selectedCustomer?.customercode}
                      </Text>
                    </div>
                  </div>

                  {/* Contact Information */}
                  <div style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#c0c5cf",
                        marginBottom: "8px",
                      }}
                    >
                      <PhoneFilled />
                      <Text
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          color: "#fff",
                          fontWeight: "700",
                        }}
                      >
                        + {selectedCustomer.countrycode}{" "}
                        {selectedCustomer?.phonenumber}
                      </Text>
                      <EyeInvisibleOutlined
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      />
                      {/* <CopyOutlined style={{ cursor: "pointer" }} /> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#c0c5cf",
                        marginBottom: "8px",
                        fontWeight: "700",
                      }}
                    >
                      <MailFilled />
                      <Text
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          color: "#fff",
                        }}
                      >
                        {selectedCustomer?.email
                          ? selectedCustomer?.email
                          : "-"}
                      </Text>
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        borderTop: ".5px solid hsla(0,0%,100%,.3)",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                      onClick={handleCustomerToggle}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className=""
                          style={{
                            width: "25%",
                            height: "25%",
                            objectFit: "contain",
                          }}
                          src={memberInfoPrivilege}
                          alt="img"
                        ></img>
                        <div>
                          <p
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            {!isCustomerPrivilegeVisible
                              ? "Privilege"
                              : "Membership"}
                          </p>
                        </div>
                      </div>

                      {!isCustomerPrivilegeVisible ? (
                        <DownOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <UpOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            cursor: "pointer",
                            position: "absolute",
                            right: "5px",
                            bottom: "0px",
                          }}
                        />
                      )}
                    </div>
                    {isCustomerPrivilegeVisible && (
                      <>
                        {/* ------ Membership details ------ */}
                        <Row
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          <Col span={10}>
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "hsla(0,0%,100%,.65)",
                              }}
                            >
                              Stored / Bonus balance:
                            </Text>
                          </Col>
                          <Col span={8}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#c0c5cf",
                                gap: "2px",
                                cursor: "pointer",
                              }}
                              onClick={handleCustomerPrepaidModal}
                            >
                              <Text
                                style={{
                                  fontSize: "14px",
                                  color: "#fff",
                                  borderBottom: "1px solid #fff",
                                  fontWeight: 600,
                                }}
                              >
                                {currencySymbol.CURRENCY_SYMBOL}
                                {customerCount?.find(
                                  (item) => item.type === "Prepaid"
                                )?.remaining_balance || 0}
                                /{currencySymbol.CURRENCY_SYMBOL}
                                {customerCount?.find(
                                  (item) => item.type === "Prepaid"
                                )?.remaining_bouns || 0}
                              </Text>
                              <RightOutlined
                                style={{
                                  fontWeight: "700",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                          }}
                        >
                          <Col span={6}>
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "hsla(0,0%,100%,.65)",
                              }}
                            >
                              Visit-based:
                            </Text>
                          </Col>
                          <Col span={4}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#c0c5cf",
                                gap: "2px",
                                cursor: "pointer",
                              }}
                              onClick={handleCustomerVistbasedModal}
                            >
                              <Text
                                style={{
                                  fontSize: "14px",
                                  color: "#fff",
                                  borderBottom: "1px solid #fff",
                                }}
                              >
                                {customerCount?.find(
                                  (item) => item.type === "Visit-based"
                                )?.total_count || 0}
                              </Text>
                              <RightOutlined
                                style={{
                                  fontWeight: "700",
                                }}
                              />
                            </div>
                          </Col>
                          <Col span={6}>
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "hsla(0,0%,100%,.65)",
                              }}
                            >
                              Discount:
                            </Text>
                          </Col>
                          <Col span={4}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#c0c5cf",
                                gap: "2px",
                                cursor: "pointer",
                              }}
                              onClick={handleCustomerDiscountModal}
                            >
                              <Text
                                style={{
                                  fontSize: "14px",
                                  color: "#fff",
                                  borderBottom: "1px solid #fff",
                                }}
                              >
                                {customerCount?.find(
                                  (item) => item.type === "Discount"
                                )?.total_count || 0}
                              </Text>
                              <RightOutlined
                                style={{
                                  fontWeight: "700",
                                }}
                              />
                            </div>
                          </Col>
                        </div>

                        {/* ------ Debt details ------ */}
                        <Row
                          gutter={16}
                          style={{
                            marginTop: "10px",
                            marginLeft: "1px",
                          }}
                        >
                          <Col span={8}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className=""
                                style={{
                                  width: "12%",
                                  height: "12%",
                                  objectFit: "contain",
                                }}
                                src={memberInfoGiftCard}
                                alt="img"
                              ></img>
                              <div>
                                <Text
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "14px",
                                    color: "#fff",
                                    fontWeight: "700",
                                  }}
                                >
                                  Gift Card
                                </Text>
                              </div>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#c0c5cf",
                                gap: "2px",
                                cursor: "pointer",
                              }}
                              onClick={handleCustomerGiftCardModal}
                            >
                              <Text
                                style={{
                                  fontSize: "14px",
                                  color: "#fff",
                                  borderBottom: "1px solid #fff",
                                }}
                              >
                                {/* {selectedCustomer?.all_giftcard} */}
                                {customerCount?.find(
                                  (item) => item.type === "Giftcard"
                                )?.total_count || 0}
                              </Text>
                              <RightOutlined
                                style={{
                                  fontWeight: "700",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* ------ Package details ------ */}
                        <Row
                          gutter={16}
                          style={{
                            marginLeft: "1px",
                          }}
                        >
                          <Col span={8}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className=""
                                style={{
                                  width: "12%",
                                  height: "12%",
                                  objectFit: "contain",
                                }}
                                src={MemberInfoPackage}
                                alt="img"
                              ></img>
                              <div>
                                <Text
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "14px",
                                    color: "#fff",
                                    fontWeight: "700",
                                  }}
                                >
                                  Package
                                </Text>
                              </div>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#c0c5cf",
                                gap: "2px",
                                cursor: "pointer",
                              }}
                              onClick={handleCustomerPackageModal}
                            >
                              <Text
                                style={{
                                  fontSize: "14px",
                                  color: "#fff",
                                  borderBottom: "1px solid #fff",
                                }}
                              >
                                {customerCount?.find(
                                  (item) => item.type === "Package"
                                )?.total_count || 0}
                              </Text>
                              <RightOutlined
                                style={{
                                  fontWeight: "700",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* ------ Gift card details ------ */}
                        <Row
                          gutter={16}
                          style={{
                            marginLeft: "1px",
                          }}
                        >
                          <Col span={8}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className=""
                                style={{
                                  width: "12%",
                                  height: "12%",
                                  objectFit: "contain",
                                }}
                                src={memberInfoGiftCard}
                                alt="img"
                              ></img>
                              <div>
                                <Text
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "14px",
                                    color: "#fff",
                                    fontWeight: "700",
                                  }}
                                >
                                  Debt
                                </Text>
                              </div>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#c0c5cf",
                                gap: "2px",
                                cursor: "pointer",
                              }}
                              onClick={handleCustomerDebtModal}
                            >
                              <Text
                                style={{
                                  fontSize: "14px",
                                  color: "#fff",
                                  borderBottom: "1px solid #fff",
                                }}
                              >
                                {/* {selectedCustomer?.all_giftcard} */}
                                {customerCount?.find(
                                  (item) => item.type === "Debt"
                                )?.total_count || 0}
                              </Text>
                              <RightOutlined
                                style={{
                                  fontWeight: "700",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </Card>
              )}
              {!selectedCustomer && (
                <Form.Item>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "150px",
                      background: "#f5f7fa",
                      border: "1px dashed #e6e6e6",
                      borderRadius: "8px",
                      textAlign: "center",
                      color: "#bfbfbf",
                      flexDirection: "column",
                      cursor: "pointer",
                      transition: "all 0.2s ease",
                    }}
                    onClick={handleShowCustomerModal}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        background: `rgba(194, 202, 221, 0.1) url(${memberinfo}) center / 44px no-repeat`,
                        borderRadius: "50%",
                        marginBottom: "8px",
                      }}
                    ></div>
                    <span style={{ fontSize: "14px", color: "#7d7d7d" }}>
                      Select a customer or leave empty for walk-in
                    </span>
                  </div>
                </Form.Item>
              )}
              <div
                // style={{
                //   height: isCustomerPrivilegeVisible
                //     ? "calc(100vh - 620px)"
                //     : "calc(100vh - 490px)",
                //   overflowY: "auto",
                // }}
                style={{
                  height:
                    selectedCustomer == null
                      ? "calc(100vh - 470px)"
                      : selectedCustomer !== null && isCustomerPrivilegeVisible
                      ? isDetailsVisible
                        ? "calc(100vh - 680px)" // Both are true
                        : "calc(100vh - 620px)" // isCustomerPrivilegeVisible is true, isDetailsVisible is false
                      : isDetailsVisible
                      ? "calc(100vh - 550px)" // isCustomerPrivilegeVisible is false, isDetailsVisible is true
                      : "calc(100vh - 490px)", // Both are false
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    borderBottom: "1px solid #E6E6E6",
                    marginBottom: "10px",
                  }}
                >
                  {cartItems && cartItems.length > 0 && (
                    <Row
                      gutter={0}
                      style={{
                        borderTop: "1px solid #E6E6E6",
                        padding: "16px 30px 8px 8px",
                      }}
                    >
                      <Col span={12}>
                        <div
                          style={{
                            color: "#333",
                            fontWeight: 700,
                            fontSize: "12px",
                          }}
                        >
                          Item
                        </div>
                      </Col>
                      <Col span={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "#333",
                            fontWeight: 700,
                            fontSize: "12px",
                          }}
                        >
                          Amount
                        </div>
                      </Col>
                    </Row>
                  )}
                  {cartItems &&
                    cartItems.length > 0 &&
                    cartItems.map((item: any, index: any) => (
                      <>
                        {item.item_type == "Debt" && (
                          <>
                            <Row
                              gutter={0}
                              key={index}
                              style={{
                                borderTop: "1px solid #E6E6E6",
                                padding: "12px 0px 0px 8px",
                                marginBottom: "5px",
                              }}
                            >
                              <Col span={15} style={{ padding: "0px" }}>
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    padding: "0px",
                                    color: "#2E56F2",
                                    fontWeight: 700,
                                  }}
                                >
                                  {item.item_name}
                                </span>
                              </Col>
                              <Col span={2} style={{ padding: "0px" }}>
                                <div
                                  style={{ color: "#999", fontSize: "13px" }}
                                >
                                  x{item.item_qty}
                                </div>
                              </Col>
                              <Col
                                span={7}
                                style={{ padding: "0px", textAlign: "right" }}
                              >
                                <span
                                  style={{
                                    color: "#F43B00",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.item_privilege_name == "Package" ||
                                  item.item_privilege_name == "Visit-based" ? (
                                    <span>{item.item_qty} Deduction</span>
                                  ) : item.deduction_name == "1 deduction" &&
                                    item.item_privilege_name == "Giftcard" ? (
                                    <span>1 Deduction</span>
                                  ) : (
                                    <>
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {formatToTwoDecimalPlaces(
                                        item.item_pay_amount
                                      )}
                                    </>
                                  )}
                                </span>
                                <Button
                                  type="text"
                                  icon={<DeleteOutlined />}
                                  style={{ fontSize: "16px" }}
                                  onClick={() => {
                                    handleDeleteCartItem(item);
                                  }}
                                />
                              </Col>
                            </Row>
                            {item.debtdetails &&
                              item.debtdetails.length > 0 &&
                              item.debtdetails.map(
                                (debtitem: any, index: any) => (
                                  <Row
                                    gutter={0}
                                    key={index}
                                    style={{
                                      padding: "0px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <Col span={8} style={{ padding: "0px" }}>
                                      <span
                                        style={{
                                          padding: "0px",
                                          color: "#999",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {debtitem.order_date}
                                      </span>
                                    </Col>
                                    <Col span={8} style={{ padding: "0px" }}>
                                      <div
                                        style={{
                                          color: "#999",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {debtitem.ref_no}
                                      </div>
                                    </Col>
                                    <Col
                                      span={8}
                                      style={{
                                        padding: "0px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#F43B00",
                                          fontWeight: 700,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {currencySymbol.CURRENCY_SYMBOL}{" "}
                                        {debtitem.item_price}
                                      </span>
                                    </Col>
                                  </Row>
                                )
                              )}
                          </>
                        )}
                        {item.item_type == "onlinesales" && (
                          <>
                            <Row
                              key={index}
                              gutter={0}
                              style={{
                                borderTop: "1px solid #E6E6E6",
                                padding: "12px 0px 0px 8px",
                                marginBottom: "5px",
                              }}
                            >
                              <Col span={15} style={{ padding: "0px" }}>
                                <span>{item.item_code}</span>
                              </Col>
                              <Col span={2} style={{ padding: "0px" }}>
                                <div
                                  style={{ color: "#999", fontSize: "13px" }}
                                >
                                  x{item.item_qty}
                                </div>
                              </Col>
                              <Col
                                span={7}
                                style={{ padding: "0px", textAlign: "right" }}
                              >
                                <span
                                  style={{
                                    color: "#F43B00",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.item_privilege_name == "Package" ||
                                  item.item_privilege_name == "Visit-based" ? (
                                    <span>{item.item_qty} Deduction</span>
                                  ) : item.deduction_name == "1 deduction" &&
                                    item.item_privilege_name == "Giftcard" ? (
                                    <span>1 Deduction</span>
                                  ) : (
                                    <>
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {formatToTwoDecimalPlaces(
                                        item.item_pay_amount
                                      )}
                                    </>
                                  )}
                                </span>
                                <Button
                                  type="text"
                                  icon={<DeleteOutlined />}
                                  style={{ fontSize: "16px" }}
                                  onClick={() => {
                                    handleDeleteCartItem(item);
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        {(item.item_type == "kiv" ||
                          item.item_type == "course") && (
                          <>
                            <Row
                              key={index}
                              gutter={0}
                              style={{
                                borderTop: "1px solid #E6E6E6",
                                padding: "12px 0px 0px 8px",
                                marginBottom: "5px",
                              }}
                            >
                              <Col span={15} style={{ padding: "0px" }}>
                                <Button
                                  type="link"
                                  style={{
                                    textDecoration: "underline",
                                    padding: "0px",
                                    color: "#2E56F2",
                                    fontWeight: 700,
                                  }}
                                  // onClick={() => {
                                  //   handleItemEdit(item, item.item_type);
                                  // }}
                                >
                                  {item.item_name}
                                </Button>
                              </Col>
                              <Col span={2} style={{ padding: "0px" }}>
                                <div
                                  style={{ color: "#999", fontSize: "13px" }}
                                  // onClick={() => {
                                  //   handleItemEdit(item, item.item_type);
                                  // }}
                                >
                                  x{item.item_qty}
                                </div>
                              </Col>
                              <Col
                                span={7}
                                style={{ padding: "0px", textAlign: "right" }}
                              >
                                <span
                                  style={{
                                    color: "#F43B00",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                  }}
                                >
                                  <span>{item.item_qty} Deduction</span>
                                </span>
                                <Button
                                  type="text"
                                  icon={<DeleteOutlined />}
                                  style={{ fontSize: "16px" }}
                                  onClick={() => {
                                    handleDeleteCartItem(item);
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        {(item.item_type == "kiv" ||
                          item.item_type == "course") && (
                          <Row
                            gutter={0}
                            style={{
                              padding: "1px 0 12px 8px",
                              marginBottom: "5px",
                            }}
                          >
                            <Col
                              span={24}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0px",
                              }}
                            >
                              {item.staff && item.staff.length == 0 && (
                                <Button
                                  type="link"
                                  style={{
                                    textDecoration: "none",
                                    padding: "0px",
                                    color: "#ffb800",
                                    fontWeight: 700,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "normal",
                                  }}
                                  // onClick={() => {
                                  //   handleStaffEdit(item, item.item_type);
                                  // }}
                                >
                                  Staff unassigned
                                </Button>
                              )}
                            </Col>
                            <Col span={24} style={{ padding: "0px" }}>
                              {item.item_type == "kiv" && (
                                <div
                                  style={{
                                    color: "#588c66",
                                    border: "2px solid #588c66",
                                    background: "rgba(204,222,200,255)",
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                    width: "max-content",
                                  }}
                                  // onClick={() => {
                                  //   handleItemEdit(item, item.item_type);
                                  // }}
                                >
                                  {"KIV"}
                                </div>
                              )}
                              {item.item_type == "course" && (
                                <div
                                  style={{
                                    color: "#8a4d63",
                                    border: "2px solid #8a4d63",
                                    background: "rgba(221,199,209,255)",
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                    width: "max-content",
                                  }}
                                  // onClick={() => {
                                  //   handleItemEdit(item, item.item_type);
                                  // }}
                                >
                                  {"COURSE"}
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                        {item.item_type !== "onlinesales" &&
                          item.item_type !== "Debt" &&
                          item.item_type !== "kiv" &&
                          item.item_type !== "course" && (
                            <>
                              <Row
                                gutter={0}
                                key={index}
                                style={{
                                  borderTop: "1px solid #E6E6E6",
                                  padding: "12px 0px 0px 8px",
                                  marginBottom: "5px",
                                }}
                              >
                                <Col span={15} style={{ padding: "0px" }}>
                                  <Button
                                    type="link"
                                    style={{
                                      textDecoration: "underline",
                                      padding: "0px",
                                      color: "#2E56F2",
                                      fontWeight: 700,
                                      overflow: "auto",
                                      height: "auto",
                                      textWrap: "wrap",
                                      textAlign: "left",
                                    }}
                                    onClick={() => {
                                      handleItemEdit(item, item.item_type);
                                    }}
                                  >
                                    {item.item_name}
                                  </Button>
                                </Col>
                                <Col span={2} style={{ padding: "0px" }}>
                                  <div
                                    style={{
                                      color: "#999",
                                      fontSize: "13px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleItemEdit(item, item.item_type);
                                    }}
                                  >
                                    x{item.item_qty}
                                  </div>
                                </Col>
                                <Col
                                  span={7}
                                  style={{ padding: "0px", textAlign: "right" }}
                                >
                                  <span
                                    onClick={() => {
                                      handleItemEdit(item, item.item_type);
                                    }}
                                    style={{
                                      color: "#F43B00",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {item.item_privilege_name == "Package" ||
                                    item.item_privilege_name ==
                                      "Visit-based" ? (
                                      <span>{item.item_qty} Deduction</span>
                                    ) : item.deduction_name == "1 deduction" &&
                                      item.item_privilege_name == "Giftcard" ? (
                                      <span>1 Deduction</span>
                                    ) : (
                                      <>
                                        {currencySymbol.CURRENCY_SYMBOL}
                                        {formatToTwoDecimalPlaces(
                                          item.item_pay_amount
                                        )}
                                      </>
                                    )}
                                  </span>
                                  <Button
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    style={{ fontSize: "16px" }}
                                    onClick={() => {
                                      handleDeleteCartItem(item);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                        {item.item_type !== "onlinesales" &&
                          item.item_type !== "Debt" &&
                          item.item_type !== "kiv" &&
                          item.item_type !== "course" && (
                            <Row
                              gutter={0}
                              style={{
                                padding: "1px 0 12px 8px",
                                marginBottom: "5px",
                              }}
                            >
                              <Col
                                span={12}
                                style={{
                                  padding: "0px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {item.staff && item.staff.length == 0 && (
                                  <Button
                                    type="link"
                                    style={{
                                      textDecoration: "none",
                                      padding: "0px",
                                      color: "#ffb800",
                                      fontWeight: 700,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "normal",
                                    }}
                                    onClick={() => {
                                      handleStaffEdit(item, item.item_type);
                                    }}
                                  >
                                    Staff unassigned
                                  </Button>
                                )}
                                {item.staff && item.staff.length > 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#666",
                                        fontSize: "12px",
                                        cursor: "pointer",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "normal",
                                      }}
                                      onClick={() => {
                                        handleStaffEdit(item, item.item_type);
                                      }}
                                    >
                                      {item.item_type == "Service"
                                        ? "Served"
                                        : "Sold"}{" "}
                                      by: {formattedStaffName(item.staff)}
                                    </span>
                                  </div>
                                )}
                                {item.item_privilege_name == "Package" && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      textDecoration: "line-through",
                                      color: "rgba(0,0,0,.45)",
                                    }}
                                  >
                                    {currencySymbol.CURRENCY_SYMBOL}
                                    {item.item_pay_amount}
                                  </span>
                                )}
                              </Col>
                              <Col span={12} style={{ padding: "0px" }}>
                                {item.item_pay_amount < item.total &&
                                  item.item_privilege_name !== null &&
                                  item.item_privilege_name.includes(
                                    "Prepaid-"
                                  ) &&
                                  item.item_membership_discount !== "0" && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#fff",
                                          fontSize: "12px",
                                          cursor: "pointer",
                                          fontWeight: "700",
                                          padding: "0px 4px",
                                          background: "#ffb800",
                                          borderRadius: "3px",
                                        }}
                                        onClick={() => {
                                          handleItemEdit(item, item.item_type);
                                        }}
                                      >
                                        {item.item_membership_discount} % off
                                      </span>
                                    </div>
                                  )}
                                {item.item_pay_amount < item.total && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#666",
                                        fontSize: "12px",
                                        cursor: "pointer",
                                        textDecoration: "line-through",
                                        marginRight: "30px",
                                      }}
                                      onClick={() => {
                                        handleItemEdit(item, item.item_type);
                                      }}
                                    >
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {formatToTwoDecimalPlaces(item.total)}
                                    </span>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          )}
                        {item.item_type == "Service" &&
                          item.service_consumption &&
                          item.service_consumption.length > 0 && (
                            <>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  padding: "1px 0 2px 8px",
                                  marginBottom: "5px",
                                }}
                                onClick={() => {
                                  handleItemEdit(item, item.item_type);
                                }}
                              >
                                <span
                                  style={{
                                    color: "#000",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    fontWeight: 700,
                                  }}
                                >
                                  Consumed products
                                </span>
                                <span
                                  style={{
                                    width: "100px",
                                    height: "10px",
                                    content: "",
                                    position: "absolute",
                                    left: 0,
                                    right: "auto",
                                    bottom: 0,
                                    top: "auto",
                                    margin: "auto",
                                    background:
                                      "linear-gradient(90deg, rgba(46, 86, 242, .2), rgba(46, 86, 242, 0))",
                                  }}
                                ></span>
                              </div>
                            </>
                          )}
                        {item.item_type == "Service" &&
                          item.service_consumption &&
                          item.service_consumption.length > 0 &&
                          item.service_consumption.map(
                            (serviceitem: any, index: any) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "1px 0 12px 8px",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    handleItemEdit(item, item.item_type);
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#333",
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {serviceitem.product_name}
                                  </span>
                                  <span
                                    style={{
                                      color: "#333",
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {serviceitem.product_qty}{" "}
                                    {serviceitem.product_unit_name}
                                  </span>
                                </div>
                              </>
                            )
                          )}
                        <Col span={24}>
                          {item.item_privilege_name &&
                          !item.item_privilege_name.includes("Discount") ? (
                            <>
                              {item.item_privilege_name.includes("Prepaid-") ? (
                                <div
                                  style={{
                                    color: "#20b8fa",
                                    border: "1px solid #20b8fa",
                                    background: "rgba(32,184,250,.1)",
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                    width: "max-content",
                                  }}
                                  onClick={() => {
                                    handleItemEdit(item, item.item_type);
                                  }}
                                >
                                  {"Prepaid"}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    color: "#833fd9",
                                    border: "1px solid #833fd9",
                                    background: "rgba(131,63,217,.1)",
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    borderRadius: "4px",
                                    padding: "2px 8px",
                                    width: "max-content",
                                  }}
                                  onClick={() => {
                                    handleItemEdit(item, item.item_type);
                                  }}
                                >
                                  {item.item_privilege_name}
                                </div>
                              )}
                            </>
                          ) : null}
                        </Col>
                        <Col span={24}>
                          {item.item_note && item.item_note.length > 0 ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <span
                                style={{
                                  color: "#666",
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  marginRight: "30px",
                                }}
                                onClick={() => {
                                  handleItemEdit(item, item.item_type);
                                }}
                              >
                                Note : {item.item_note}
                              </span>
                            </div>
                          ) : null}
                        </Col>
                      </>
                    ))}
                </div>

                {cartItems && cartItems.length == 0 && (
                  <Form.Item>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#333",
                        fontSize: "16px",
                        margin: "16px 0",
                        fontWeight: 500,
                      }}
                    >
                      Your cart is empty
                      <br />
                      <span style={{ fontSize: "14px", color: "#bfbfbf" }}>
                        Please choose at least one item for checkout
                      </span>
                    </div>
                  </Form.Item>
                )}
                <Form.Item>
                  <div
                    style={{
                      margin: "auto",
                      padding: "4px 8px",
                      background: "rgba(255, 151, 30, .1)",
                      borderRadius: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={handleNotesEdit}
                  >
                    <div
                      style={{
                        display: "flex",
                        verticalAlign: "top",
                        gap: "5px",
                      }}
                    >
                      <Typography.Text
                        style={{
                          color: "#595959",
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Note:
                      </Typography.Text>
                      {notesValue ? (
                        <Typography.Text
                          style={{
                            color: "rgba(0, 0, 0, .45)",
                            fontWeight: 500,
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {formattedText()}
                        </Typography.Text>
                      ) : (
                        <Typography.Text
                          style={{
                            color: "rgba(0, 0, 0, .45)",
                            fontWeight: 500,
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          Write a note here
                        </Typography.Text>
                      )}
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <EditOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "18px",
                          lineHeight: "30px",
                          color: "rgba(46, 86, 242, .5)",
                        }}
                      />
                    </div>
                  </div>
                </Form.Item>
              </div>

              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: "auto",
                  margin: "auto",
                  zIndex: 1,
                  padding: "8px 18px 8px 18px",
                  background: "#fff",
                  borderRadius: "0 0 12px 12px",
                  textAlign: "right",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {isDetailsVisible && (
                  <>
                    {discountAmount !== 0 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: "#2e56f2",
                                fontWeight: 700,
                                fontSize: "12px",
                                textDecoration: "underline",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowDiscountModal(true);
                              }}
                            >
                              Cart Discount
                            </span>
                            <span>
                              <DeleteOutlined onClick={handleRemoveDiscount} />
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <span
                              style={{
                                color: "#F43B00",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              -{currencySymbol.CURRENCY_SYMBOL}
                              {formatToTwoDecimalPlaces(discountAmount)}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            color: "#999",
                            fontWeight: 700,
                            fontSize: "12px",
                          }}
                        >
                          Subtotal
                        </span>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <span
                          style={{
                            color: "#F43B00",
                            fontSize: "14px",
                            fontWeight: 700,
                          }}
                        >
                          {currencySymbol.CURRENCY_SYMBOL}
                          {formatToTwoDecimalPlaces(subTotal)}
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            color: " #999",
                            fontWeight: 700,
                            fontSize: "12px",
                          }}
                        >
                          Taxes
                        </span>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <span
                          style={{
                            color: "#F43B00",
                            fontSize: "14px",
                            fontWeight: 700,
                          }}
                        >
                          {currencySymbol.CURRENCY_SYMBOL}
                          {formatToTwoDecimalPlaces(taxValue)}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {cartItems && cartItems.length > 0 && (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#333",
                          fontWeight: 700,
                          fontSize: "12px",
                        }}
                      >
                        Total
                      </span>
                      <div onClick={toggleDetails}>
                        {isDetailsVisible ? (
                          <DownOutlined
                            style={{
                              fontSize: "12px",
                              color: "#666",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <UpOutlined
                            style={{
                              fontSize: "12px",
                              color: "#666",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        style={{
                          color: "#F43B00",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        {currencySymbol.CURRENCY_SYMBOL}
                        {formatToTwoDecimalPlaces(total)}
                      </span>
                    </div>
                  </div>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Dropdown
                    menu={{ items }}
                    placement="topLeft"
                    arrow={{ pointAtCenter: true }}
                  >
                    <Button
                      icon={<MoreOutlined />}
                      type="default"
                      // onClick={handleYearPrevious}
                    />
                  </Dropdown>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{
                        background: "rgba(46, 86, 242, .1)",
                        color: "#2E56F2",
                        minWidth: "72px",
                        padding: "9px 12px",
                        fontWeight: 700,
                        marginRight: "12px",
                        lineHeight: "20px",
                        height: "40px",
                        borderRadius: "6px",
                        display: "inline-block",
                        cursor: "pointer",
                        border: "none",
                      }}
                      disabled={payNowClick}
                      onClick={handleSaveUnpaid}
                    >
                      Save as unpaid
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        marginRight: 0,
                        fontWeight: 700,
                        lineHeight: "20px",
                        padding: "9px 18px",
                        height: "40px",
                        background: "#2E56F2",
                        color: "#fff",
                        borderRadius: "6px",
                        borderColor: "transparent !important",
                        cursor: "pointer",
                      }}
                      disabled={payNowClick}
                      onClick={handlePayNow}
                    >
                      Pay now
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      <EditCartDiscountModel
        visible={showDiscountModal}
        value={discountValue}
        discountamount={discountAmount}
        valuetype={discountType}
        totalvalue={subTotal}
        taxrate={taxRate}
        subTotalvalue={subTotal}
        onClose={() => {
          setShowDiscountModal(false);
        }}
        onSubmit={handleDiscountValue}
      />
      <SingleDateSelectionModal
        visible={showSingleDateSelectionModal}
        initialSelectedDate={selectedDate} // Pass the selected date from parent to modal
        onDateSelected={handleDateSelected}
        onCancel={handleSingleDateSelectionClose}
      />
      <SelectServiceStaff
        visible={ShowServiceStaffEditModal}
        servicename={
          selectedItem ? selectedItem.name || selectedItem.item_name : ""
        }
        onClose={() => {
          setShowServiceStaffEditModal(false);
        }}
        staffArray={selectedItem ? selectedItem.staff : []}
        onSelect={handleServiceStaffChange}
      />
      <SelectProductStaff
        visible={ShowProductStaffEditModal}
        servicename={
          selectedItem ? selectedItem.name || selectedItem.item_name : ""
        }
        onClose={() => {
          setShowProductStaffEditModal(false);
        }}
        staffArray={selectedItem ? selectedItem.staff : []}
        onSelect={handleProductStaffChange}
      />
      <SelectServiceStaff
        visible={showServiceModal}
        servicename={
          selectedItem ? selectedItem.name || selectedItem.item_name : ""
        }
        onClose={() => {
          setShowServiceModal(false);
        }}
        onSelect={handleServiceStaffSelection}
      />
      <SelectProductStaff
        visible={showProductModal}
        servicename={
          selectedItem ? selectedItem.name || selectedItem.item_name : ""
        }
        onClose={() => {
          setShowProductModal(false);
        }}
        onSelect={handleProductStaffSelection}
      />
      <RefNumModal
        visible={showRefNumModal}
        onClose={() => {
          setShowRefNumModal(false);
        }}
        onSelect={handleRefSelection}
      />
      <BillingInfoModel
        visible={billInfoModalVisible}
        selectedData={{
          selecteddate: selectedDate,
          selectedRef: refNum,
          selectedCustomerSource: selectedCustomerSource,
          electedReferralType: selectedReferralType,
          selectedReferrer: selectedReferrer,
          selectedReferrerId: selectedReferrerId,
        }}
        customerSources={customerSources}
        onCancel={() => {
          setBillInfoModalVisible(false);
        }}
        onSubmit={handleBillInfoData}
      />
      <NotesModal
        visible={showNotesEditModal}
        onClose={() => {
          setShowNotesEditModal(false);
        }}
        onConfirm={handleNoteAdd}
        value={notesValue}
      />
      <ProductEditModel
        visible={showProductEditModal}
        selectedItem={selectedItem}
        servicename={selectedItem ? selectedItem.item_name : ""}
        onClose={() => {
          setShowProductEditModal(false);
        }}
        onSelect={handleCartProductUpdate}
      />
      <GiftcardEditModel
        visible={showGiftcardEditModal}
        selectedItem={selectedItem}
        servicename={selectedItem ? selectedItem.item_name : ""}
        onClose={() => {
          setShowGiftcardEditModal(false);
        }}
        onSelect={handleCartProductUpdate}
      />
      {showPackageEditModal && (
        <PackageEditModel
          visible={showPackageEditModal}
          selectedItem={selectedItem}
          servicename={selectedItem ? selectedItem.item_name : ""}
          onClose={() => {
            setShowPackageEditModal(false);
          }}
          onSelect={handlePackageUpdate}
          selectedStaff={selectedStaff}
        />
      )}
      {showPackageEditModalBilling && (
        <PackageEditModelBilling
          visible={showPackageEditModalBilling}
          selectedItem={selectedItem}
          onClose={() => {
            setShowPackageEditModalBilling(false);
          }}
          onSelect={handlePackageEditUpdate}
          selectedStaff={selectedStaff}
        />
      )}
      <ServiceEditProductEditModel
        visible={showServiceEditModal}
        selectedItem={selectedItem}
        servicename={selectedItem ? selectedItem.item_name : ""}
        onClose={() => {
          setShowServiceEditModal(false);
        }}
        onSelect={handleCartServiceUpdate}
      />
      <MembershipEditModel
        visible={showMemberEditModal}
        selectedItem={selectedItem}
        servicename={selectedItem ? selectedItem.item_name : ""}
        onClose={() => {
          setShowMembershipEditModal(false);
        }}
        onSelect={handleCartMembershipUpdate}
      />
      <SearchCustomerModel
        visible={showCustomerModal}
        onClose={handleShowCustomerCancelModal}
        onSelect={handleCustomerSelection}
      />
      <ConfirmDeleteModal
        visible={isDeleteMemberModalVisible}
        onCancel={closeDeleteMemberModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirmMember}
        message="Are you sure you want to remove the member?"
      />
      <ConfirmDeleteModal
        visible={isDeleteServiceModalVisible}
        onCancel={closeDeleteServiceModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirmService}
        header={selectedItem ? selectedItem.item_name : ""}
        message="Are you sure you want to delete?"
      />
      {applicableModelVisible && (
        <ApplicableModel
          visible={applicableModelVisible}
          onClose={onCloseApplicableModel}
          type={selectedApplicableType}
          id={selectedProductId}
        />
      )}
      {showCustomerGiftCardModal && (
        <CustomerVoucherModal
          visible={showCustomerGiftCardModal}
          selectedItem={selectedCustomer}
          customerGiftCardData={customerGiftCardData}
          onClose={() => {
            setShowCustomerGiftCardModal(false);
          }}
          onSelect={() => {}}
        />
      )}
      {showCustomerPackageModal && (
        <CustomerPackageModal
          visible={showCustomerPackageModal}
          selectedItem={selectedCustomer}
          onClose={() => {
            setShowCustomerPackageModal(false);
          }}
          onSelect={handleCartProductUpdate}
        />
      )}
      {showCustomerVisitBasedModal && (
        <CustomerMembershipVisitBaseModal
          visible={showCustomerVisitBasedModal}
          onClose={() => {
            setShowCustomerVisitBasedModal(false);
          }}
          selectedItem={customerMembershipVisitBasedData}
        />
      )}
      {showCustomerDiscountModal && (
        <CustomerMembershipDiscountModal
          selectedItem={customerMembershipDiscountData}
          visible={showCustomerDiscountModal}
          onClose={() => {
            setShowCustomerDiscountModal(false);
          }}
        />
      )}
      <CustomerMembershipPrepaidModal
        visible={showCustomerPrepaidModal}
        customerPrepaidData={customerMembershipPrepaidData}
        onClose={() => {
          setShowCustomerPrepaidModal(false);
        }}
      />
      <CustomerDebtModal
        visible={showCustomerDebtModal}
        customerDebtData={customerMemberDebtData}
        onClose={() => {
          setShowCustomerDebtModal(false);
        }}
        onSubmit={() => {
          handledebtClear();
        }}
      />
      <Modal
        visible={debtModalvisible}
        title={""}
        closable={false}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              key="cancel"
              style={{
                color: "#3454d9",
                background: "#eaeefe",
                borderRadius: "2px",
              }}
              onClick={handledebtCancel}
            >
              Ignore
            </Button>
            <Button
              key="save"
              type="primary"
              onClick={() => {
                handledebtClear();
              }}
              style={{
                background: "#d20a15",
                color: "#fff",
                borderRadius: "2px",
              }}
            >
              Clear Balance
            </Button>
          </div>,
        ]}
        width="400px"
        style={{
          top: "60px",
        }}
        bodyStyle={{
          height: "calc(100vh - 280px)",
          scrollbarWidth: "thin",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            height: "calc(100vh - 300px)",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <img src={bellIcon} alt="bell" height={120} width={120} />
            <span
              style={{
                color: "#000",
                fontSize: "20px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Debt Balance Reminder
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "50px",
              gap: "10px",
            }}
          >
            <span style={{ fontSize: "20px", textAlign: "center" }}>
              The customer currently has a debt balance of:
            </span>
            <span
              style={{
                fontSize: "20px",
                textAlign: "center",
                fontWeight: "700",
                color: "#2e56f2",
              }}
            >
              RM{" "}
              <span
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: "4px",
                }}
              >
                {debtamount}
              </span>
            </span>
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default BillingPage;
