import {
  Button,
  Card,
  Col,
  Divider,
  message,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, put } from "../../services/Apiclient";
import Footer from "../headerFooter/footer";
import Header from "../headerFooter/Header";
const { Title, Text } = Typography;
const { Option } = Select;

const cartItemStyle: React.CSSProperties = {
  padding: "16px",
  paddingTop: "72px",
};

interface cartitems {
  id: number;
  productname: string;
  sellprice: number;
  quantity: number;
  subtotal: number;
  image: string;
  privilegesid: string;
  privilegesname: string;
  privilegestype: string;
  privilegesamt: string;
  takenqty: number;
  storeqty?: number;
}

interface PrivilegeDetail {
  id: string;
  name: string;
  item_value?: string;
  item_qty?: string;
  item_type_validity?: string;
  item_validfor?: string;
  item_singleqty?: string;
  typeof_giftcard?: "Value" | "Session";
  allproduct_applicable?: boolean;
  productitem_applicable?: boolean;
  productcategory_applicable?: boolean;
  productnot_applicable?: boolean;
  product_discount?: string;
  service_discount?: string;
  totaldiscount?: string;
  buyprice?: number;
  giveaway?: number;
  value?: string;
  bonuse?: string;
  total_amount?: string;
  applicable_to?: string;
  products?: {
    product_id: string;
    product_name: string;
    remaining_qty: number;
    item_wise_qty: number;
  }[];
}

const ProductCheckout: React.FC = () => {
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const { merchantId, id } = useParams();
  const outletId = localStorage.getItem("outlet_id");
  const customerId = localStorage.getItem("customer_id");
  const navigate = useNavigate();
  const [privilegeOptions, setPrivilegeOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [subTotal, setSubTotal] = useState(0);
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [postalcode, setPostalcode] = useState<string>("");
  const [addresses, setAddresses] = useState<any[]>([]);
  const isAllFieldsNull = !address && !city && !country && !postalcode;
  const [cartItems, setCartItems] = useState<cartitems[]>([]);
  const [selectShipping, setSelectShipping] = useState<any[]>([]);
  const [selectOutlet, setSelectOutlet] = useState<any[]>([]);
  const [selectedShippingId, setSelectedShippingId] = useState<string | null>(
    null
  );
  const [shippingPrice, setShippingPrice] = useState<string>("0");
  const [outletAddress, setOutletAddress] = useState<string>();
  const [selectedOption, setSelectedOption] = useState("outlet");

  const handleRadioChange = (e: any) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== "shipping") {
      setSelectedAddress(null);
    }
  };

  const fetchCartDetails = async () => {
    try {
      const response = await get(`/cartmobile/mobile/cart/${merchantId}/${id}`);
      if (response.data.success) {
        const { items, totalqty, totalamt } = response.data.data;

        const mappedItems = items.map((item: any) => ({
          id: item.productid,
          productname: item.name,
          sellprice: parseFloat(item.amt) / parseInt(item.productqty, 10),
          quantity: parseInt(item.productqty, 10),
          subtotal: parseFloat(item.amt),
          image: item.productimg || "https://via.placeholder.com/100", // Fallback image
          privilege: item.privilegesid || null,
        }));

        setCartItems(mappedItems);
      } else {
        message.error("Failed to fetch cart details.");
      }
    } catch (error) {
      console.error("Error fetching cart details:", error);
      message.error("An error occurred while fetching cart details.");
    }
  };

  const fetchAddress = async () => {
    try {
      const response = await get(
        `/customerprofilemob/mobile/getaddress/${merchantId}/${customerId}`
      );
      if (response.data.success) {
        const { address, city, country, state, postalcode } =
          response.data.data;
        setAddress(address || "");
        setCity(city || "");
        setCountry(country || "");
        setState(state || "");
        setPostalcode(postalcode || "");
        setAddresses(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching promotion", error);
    }
  };

  const fetchShippingMethods = async () => {
    try {
      const response = await get(`/shippingmethod/mobilelist/${merchantId}`);
      if (response.data.success) {
        setSelectShipping(response.data.data);
      } else {
        message.error("Failed to fetch shipping details.");
      }
    } catch (error) {
      console.error("Error fetching shipping details:", error);
      message.error("An error occurred while fetching shipping details.");
    }
  };

  const handleShippingChange = (value: string) => {
    const selectedMethod = selectShipping.find((method) => method.id === value);
    if (selectedMethod) {
      setShippingPrice(selectedMethod.shippingprice);
      setSelectedShippingId(selectedMethod.id); // Store selected shipping ID
      setSelectedAddress(
        `${address}, ${city}, ${state}, ${country}, ${postalcode}`
      );
      setOutletAddress(""); // Clear outlet selection when shipping is selected
    }
  };

  const fetchOutlet = async () => {
    try {
      const response = await get(`/outlet/alloutletlist/${merchantId}`);
      if (response.data.success) {
        setSelectOutlet(response.data.data);
      } else {
        message.error("Failed to fetch outlet details.");
      }
    } catch (error) {
      console.error("Error fetching outlet details:", error);
      message.error("An error occurred while fetching outlet details.");
    }
  };

  const handleOutletChange = (value: string) => {
    const selectedMethod = selectOutlet.find((method) => method.id === value);
    if (selectedMethod) {
      setOutletAddress(selectedMethod.full_address);
      setSelectedShippingId(null); // Clear shipping selection
      setSelectedAddress(null); // Clear shipping selection when outlet is selected
    }
  };

  const fetchPrivilegeDetails = async () => {
    try {
      const response = await get(
        `/cartmobile/mobile/privilegedetails/${outletId}/${merchantId}/${customerId}`
      );
      if (response.data.success) {
        const privileges = response.data.data.flatMap(
          (category: any) =>
            category.details?.map((detail: any) => ({
              id: detail.id,
              name: detail.name,
            })) || []
        );
        setPrivilegeOptions(privileges);
      } else {
        message.error("Failed to fetch privilege details.");
      }
    } catch (error) {
      console.error("Error fetching privilege details:", error);
      message.error("An error occurred while fetching privilege details.");
    }
  };

  useEffect(() => {
    fetchShippingMethods();
    fetchOutlet();
  }, [merchantId]);

  useEffect(() => {
    // Scroll to the top of the page with smooth behavior on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
    fetchPrivilegeDetails();
  }, []);

  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      const parsedItems = JSON.parse(storedCartItems) as cartitems[];
      const initializedItems = parsedItems.map((item) => ({
        ...item,
        subtotal: item.sellprice ?? 0, // Default to 0 if subtotal is undefined
      }));
      setCartItems(initializedItems);
    }
  }, []);

  useEffect(() => {
    fetchAddress();
  }, [merchantId, customerId]);

  useEffect(() => {
    fetchCartDetails();
  }, [merchantId, id]);

  const totalOfItems = cartItems.reduce(
    (total, item) => total + item.sellprice * item.quantity,
    0
  );

  const handlePrivilegeChange = async (itemId: number, privilegeId: string) => {
    const selectedPrivilege = privilegeOptions.find(
      (priv) => priv.id === privilegeId
    );
    if (!selectedPrivilege) return;

    const response = await get(
      `/cartmobile/mobile/privilegedetails/${outletId}/${merchantId}/${customerId}`
    );

    // const privilegeDetails = response.data.data.find(
    //   (privilege: any) =>
    //     privilege.type === selectedPrivilege.name.split(" - ")[0]
    // );
    // Find the category that contains the selected privilege, ensuring `details` exists
    const privilegeDetails = response.data.data.find(
      (category: any) =>
        category.details &&
        category.details.some((detail: any) => detail.id === privilegeId)
    );

    if (!privilegeDetails) {
      console.error("Privilege details not found for:", selectedPrivilege);
      message.error("Selected privilege is not applicable.");
      return;
    }

    // Find the current item in cartItems
    const currentItemIndex = cartItems.findIndex((item) => item.id === itemId);
    const currentItem = cartItems[currentItemIndex];

    if (!currentItem) return;

    let newSubtotal = currentItem.subtotal; // Start with current subtotal
    let privilegesamt = "0"; // Default value for privilege amount

    switch (privilegeDetails.type) {
      case "Giftcard":
        const giftcardDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (giftcardDetail) {
          const itemValue = parseFloat(giftcardDetail.item_value);
          if (
            giftcardDetail.typeof_giftcard === "Value"
            //  && itemValue < newSubtotal
          ) {
            newSubtotal -= itemValue; // Deduct gift card value from subtotal
            newSubtotal = Math.max(0, newSubtotal);
            privilegesamt = itemValue.toString();
            message.success(`${giftcardDetail.name} applied.`);
          } else if (
            giftcardDetail.typeof_giftcard === "Session"
            // && parseFloat(giftcardDetail.item_singleqty) < newSubtotal
          ) {
            newSubtotal -= parseFloat(giftcardDetail.item_singleqty); // Deduct session value from subtotal
            privilegesamt = giftcardDetail.item_singleqty;
            message.success(`${giftcardDetail.name} applied.`);
          } else {
            message.error("This privilege cannot be applied.");
          }
        }
        break;

      // case "Discount":
      //   const discountDetail = privilegeDetails.details.find(
      //     (detail: any) => detail.id === selectedPrivilege.id
      //   );
      //   if (discountDetail) {
      //     const productDiscount = parseFloat(discountDetail.product_discount);
      //     let discountAmount: number;
      //     // if (productDiscount < 1) {
      //     //   // discountAmount = newSubtotal * productDiscount;
      //     //   discountAmount = (newSubtotal * productDiscount) / 100;
      //     //   console.log("discount", discountAmount)
      //     // } else {
      //     discountAmount = (newSubtotal * productDiscount) / 100;
      //     // }
      //     // if (discountAmount < newSubtotal) {
      //     newSubtotal -= discountAmount;
      //     newSubtotal = Math.max(0, newSubtotal);
      //     privilegesamt = discountAmount.toString();
      //     message.success(`${discountDetail.name} applied.`);
      //     // } else {
      //     //   message.error("This privilege cannot be applied.");
      //     // }
      //   }
      //   break;
      case "Discount":
        const discountDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (discountDetail) {
          const productDiscount = parseFloat(discountDetail.product_discount);

          if (productDiscount > 0) {
            let discountAmount = (newSubtotal * productDiscount) / 100;

            newSubtotal -= discountAmount;
            newSubtotal = Math.max(0, newSubtotal);
            privilegesamt = discountAmount.toString();

            message.success(`${discountDetail.name} applied.`);
          } else {
            message.error("This privilege cannot be applied (discount is 0).");
          }
        }
        break;

      case "Visit-based":
        const visitBasedDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (visitBasedDetail) {
          const totalPrice =
            visitBasedDetail.buyprice + visitBasedDetail.giveaway;
          // if (totalPrice < newSubtotal) {
          newSubtotal -= totalPrice;
          newSubtotal = Math.max(0, newSubtotal);
          privilegesamt = totalPrice.toString();
          message.success(`${visitBasedDetail.name} applied.`);
          // } else {
          //   message.error("This privilege cannot be applied.");
          // }
        }
        break;

      case "Prepaid":
        const prepaidDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (prepaidDetail) {
          const value = parseFloat(prepaidDetail.value);
          const bonus = parseFloat(prepaidDetail.bonuse);
          // if (value + bonus < newSubtotal) {
          newSubtotal -= value + bonus; // Deduct prepaid values from subtotal
          newSubtotal = Math.max(0, newSubtotal);
          privilegesamt = (value + bonus).toString();
          message.success(`${prepaidDetail.name} applied.`);
          // } else {
          //   message.error("This privilege cannot be applied.");
          // }
        }
        break;

      case "Package":
        const packageDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );

        if (packageDetail) {
          const isAllProductsApplicable =
            packageDetail.applicable_to.includes("All products");

          if (isAllProductsApplicable) {
            // Apply discount to all cart items
            cartItems.forEach((cartItem: any) => {
              newSubtotal -= cartItem.quantity * cartItem.sellprice;
            });
            message.success(`${packageDetail.name} applied.`);
          } else {
            // Apply only to specific products in packageDetail.products
            const applicableProduct = packageDetail.products.find(
              (product: any) => product.product_id === itemId
            );

            if (applicableProduct) {
              const { remaining_qty } = applicableProduct;
              const itemQty = currentItem.quantity; // Quantity in cart

              if (remaining_qty > 0) {
                // Deduct min(remaining_qty, itemQty) * price
                const applicableQty = Math.min(remaining_qty, itemQty);
                newSubtotal -= applicableQty * currentItem.sellprice;
                newSubtotal = Math.max(0, newSubtotal);
                privilegesamt = applicableQty.toString();

                message.success(
                  `${packageDetail.name} applied to ${applicableProduct.product_name} for ${applicableQty} item(s).`
                );
              } else {
                message.error(
                  `${packageDetail.name} cannot be applied (remaining quantity is 0).`
                );
              }
            } else {
              message.error(`${packageDetail.name} cannot be applied.`);
            }
          }
        }
        break;

      default:
        message.error("Invalid privilege type.");
    }

    // Update cart items with the new subtotal and show the message
    setCartItems((prevItems) =>
      prevItems.map((item, index) =>
        index === currentItemIndex
          ? {
              ...item,
              subtotal: newSubtotal,
              privilegesamt,
              privilegestype: privilegeDetails.type,
              privilegesname: selectedPrivilege.name,
              privilegesid: selectedPrivilege.id,
            }
          : item
      )
    );

    setSubTotal(newSubtotal); // Update the overall subtotal in state
  };

  const handleTakenChange = (id: number, newTakenQty: number) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.id === id
          ? {
              ...item,
              takenqty: newTakenQty,
              storeqty: item.quantity - newTakenQty, // Auto adjust stored
            }
          : item
      )
    );
  };

  const handleStoredChange = (id: number, newStoredQty: number) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.id === id
          ? {
              ...item,
              storeqty: newStoredQty,
              takenqty: item.quantity - newStoredQty, // Auto adjust taken
            }
          : item
      )
    );
  };

  // Ensure default values are assigned in the cartItems state
  const updatedCartItems = cartItems.map((item) => ({
    ...item,
    takenqty: item.takenqty ?? item.quantity, // Default: all taken
    storeqty: item.storeqty ?? 0, // Default: none stored
  }));

  const shippingFee = parseFloat(shippingPrice);

  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // const calculatedSubtotal = cartItems.reduce(
  //   (total, item) => total + item.subtotal,
  //   0
  // );
  // console.log("calculatedSubtotal", calculatedSubtotal);

  const privilegeDiscount = cartItems.reduce(
    (total, item) =>
      total + (item.privilegesamt ? parseFloat(item.privilegesamt) : 0),
    0
  );

  // const totalPrice = totalOfItems + shippingFee - privilegeDiscount;
  // const totalPrice = Math.max(
  //   0,
  //   totalOfItems + shippingFee - privilegeDiscount
  // );
  // Ensure privilegeDiscount is not more than totalOfItems
  // const adjustedPrivilegeDiscount = Math.min(privilegeDiscount, totalOfItems);
  // const adjustedPrivilegeDiscount = selectedPrivilegeItem
  //   ? Math.min(
  //       privilegeDiscount,
  //       selectedPrivilegeItem.sellprice * selectedPrivilegeItem.quantity
  //     )
  //   : Math.min(privilegeDiscount, totalOfItems);

  const displayedPrivilegeDiscount = cartItems.reduce((total, item) => {
    const itemPrivilegeDiscount = item.privilegesamt
      ? parseFloat(item.privilegesamt)
      : 0;
    return (
      total + Math.min(itemPrivilegeDiscount, item.sellprice * item.quantity)
    );
  }, 0);

  const storedQtyTotal = cartItems.reduce((total, item) => {
    const stored = item.storeqty ? (item.subtotal * item.storeqty) / item.quantity : 0;
    return  total + stored;
  }, 0);

  // Calculate total price with adjusted discount
  const totalPrice = Math.max(
    0,
    totalOfItems + shippingFee - displayedPrivilegeDiscount - storedQtyTotal
  );

  // const paymentmethodname =
  //   paymentMethod === "credit"
  //     ? "Credit Card"
  //     : paymentMethod === "fpx"
  //     ? "FPX"
  //     : paymentMethod === "ewallet"
  //     ? "eWallet"
  //     : "";
  const paymentmethodname = paymentMethod === "cash" ? "Cash" : "Credit Card";
  const isshipping =
    typeof selectedAddress === "string" &&
    selectedAddress.startsWith("outlet") === false;
  const shippingid = isshipping ? selectedAddress : null;
  const selfcollect = !isshipping;

  const handlePay = async () => {
    if (!selectedAddress && !outletAddress) {
      message.error("Please select a shipping method or outlet.");
      return;
    }

    if (selectedAddress && isAllFieldsNull) {
      message.error("Please add a shipping address.");
      return;
    }

    try {
      const payload = {
        customer_id: customerId,
        outlet_id: outletId,
        payment_method: paymentMethod,
        // shipping_method: selectedAddress || outletAddress,
        shipping_method:
          selectedOption === "shipping" ? selectedShippingId : outletAddress,
        items: cartItems.map((item) => ({
          productid: item.id,
          productqty: item.quantity.toString(),
          amt: (item.sellprice * item.quantity).toString(),
          privilegesid: item.privilegesid || "",
          privilegesname: item.privilegesname || "",
          privilegestype: item.privilegestype || "",
          privilegesamt:
            parseFloat(item.privilegesamt || "0") >
            parseFloat((item.sellprice * item.quantity).toString())
              ? (item.sellprice * item.quantity).toString()
              : item.privilegesamt || "0",
          takenqty: (item.takenqty ?? item.quantity).toString(), // Make takenqty compulsory
          storeqty: item.storeqty?.toString() || "0",
        })),
        paymentid: nanoid(8),
        paymentmethodname,
        // isshipping,
        // shippingid,
        isshipping: selectedOption === "shipping",
        shippingid: selectedOption === "shipping" ? selectedShippingId : null, // Pass shipping ID
        selfcollect,
        address: selectedAddress || outletAddress || "",
        shipping_price: shippingPrice,
        totalqty: totalQuantity.toString(),
        // subtotal: calculatedSubtotal,
        totalamt: totalOfItems.toString(),
        totaldiscount: privilegeDiscount.toString(),
        remainamt: storedQtyTotal.toString(),
        // total_price: totalPrice,
        finaltotal: totalPrice.toString(),
        ispaid: true,
      };

      const response = await put(
        `/cartmobile/mobile/cart/${outletId}/${merchantId}/${id}`,
        payload
      );
      if (response.data.success) {
        navigate(`/app/success/${merchantId}`);
        message.success("Payment successful and order updated!");
        localStorage.removeItem("cartItems");
        localStorage.removeItem("cartCount");
      } else {
        message.error("Payment failed. Please try again.");
      }
      console.log(payload, "payload");
    } catch (error) {
      console.error("Error during payment:", error);
      message.error("An error occurred during payment processing.");
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "50px",
        }}
      >
        <Header
          title="Chackout"
          onBack={() => window.history.back()}
          backgroundColor={"#fff"}
        />
        {/* Cart Items */}
        <div style={cartItemStyle}>
          {updatedCartItems.map((item) => (
            <>
              <Card
                key={item.id}
                style={{
                  // marginBottom: "16px",
                  borderRadius: "12px",
                  overflow: "hidden",
                  border: "none",
                }}
                bodyStyle={{ padding: "10px" }}
              >
                <Row gutter={24} align="middle">
                  <Col span={10}>
                    <img
                      src="https://via.placeholder.com/100"
                      alt={item.productname}
                      onError={(e) => {
                        e.currentTarget.src =
                          "https://dummyimage.com/100x100/cccccc/000000.png&text=Image";
                      }}
                      style={{
                        // width: "100%",
                        borderRadius: "8px",
                        bottom: "50px",
                      }}
                    />
                  </Col>
                  <Col span={14}>
                    <Paragraph
                      ellipsis={{ rows: 2 }}
                      style={{
                        marginBottom: 8,
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {item.productname}
                    </Paragraph>
                    <div
                      style={{
                        // marginTop: "16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Text>Qty: {item.quantity}</Text>
                    </div>
                    <Row
                      justify="space-between"
                      style={{ marginTop: "16px", alignItems: "center" }}
                    >
                      <Title level={5} style={{ margin: 0 }}>
                        Subtotal:
                      </Title>
                      <div>
                        {item.privilegesid &&
                        item.sellprice * item.quantity !== item.subtotal ? (
                          <Text
                            style={{
                              display: "block",
                              color: "#999",
                              fontSize: "13px",
                            }}
                            delete
                          >
                            RM {item.sellprice * item.quantity}
                          </Text>
                        ) : null}
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: "#FFA500",
                            marginLeft: "5px",
                          }}
                        >
                          RM {item.subtotal.toFixed(2)}
                        </Text>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Select
                placeholder="Select Privilege"
                style={{ width: "100%" }}
                onChange={(value) => handlePrivilegeChange(item.id, value)}
                value={item.privilegesid || undefined}
              >
                {privilegeOptions &&
                  privilegeOptions.map((priv) => (
                    <Option key={priv.id} value={priv.id}>
                      {priv.name}
                    </Option>
                  ))}
              </Select>
              {/* Taken Quantity */}
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text>Taken</Text>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <Button
                    onClick={() =>
                      handleTakenChange(
                        item.id,
                        Math.max(0, (item.takenqty ?? item.quantity) - 1)
                      )
                    }
                    disabled={item.takenqty === 0}
                    style={{ width: "32px", height: "32px" }}
                  >
                    -
                  </Button>
                  <Text>{item.takenqty}</Text>
                  <Button
                    onClick={() =>
                      handleTakenChange(
                        item.id,
                        Math.min(item.quantity, (item.takenqty ?? 0) + 1)
                      )
                    }
                    disabled={item.takenqty === item.quantity}
                    style={{ width: "32px", height: "32px" }}
                  >
                    +
                  </Button>
                </div>
              </div>

              {/* Stored Quantity */}
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text>Stored</Text>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <Button
                    onClick={() =>
                      handleStoredChange(
                        item.id,
                        Math.max(0, (item.storeqty ?? 0) - 1)
                      )
                    }
                    disabled={item.storeqty === 0}
                    style={{ width: "32px", height: "32px" }}
                  >
                    -
                  </Button>
                  <Text>{item.storeqty}</Text>
                  <Button
                    onClick={() =>
                      handleStoredChange(
                        item.id,
                        Math.min(item.quantity, (item.storeqty ?? 0) + 1)
                      )
                    }
                    disabled={item.storeqty === item.quantity}
                    style={{ width: "32px", height: "32px" }}
                  >
                    +
                  </Button>
                </div>
              </div>

              <Divider />
            </>
          ))}

          {/* Shipping Address */}
          <Radio.Group
            onChange={handleRadioChange}
            // defaultValue="shipping"
            value={selectedOption}
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Card style={{ marginBottom: "16px", borderRadius: "12px" }}>
              <Radio value="shipping" style={{ width: "100%" }}>
                <div>
                  <Title level={5} style={{ margin: 0 }}>
                    Shipping to address
                  </Title>
                </div>
              </Radio>
              <Divider />
              {/* Display and select shipping addresses */}
              {selectedOption === "shipping" && (
                <>
                  <Select
                    placeholder="Select Shipping Method"
                    style={{ width: "100%", marginBottom: "16px" }}
                    // onChange={handleShippingChange}
                    // value={selectedAddress || undefined}
                    onChange={(value) => handleShippingChange(value)}
                    value={selectedShippingId || undefined}
                  >
                    {selectShipping.map((ship) => (
                      <Option key={ship.id} value={ship.id}>
                        {ship.shippingmethodtype}
                      </Option>
                    ))}
                  </Select>
                  <div style={{ marginLeft: "24px", marginTop: "16px" }}>
                    <Text></Text>
                    <Text
                      style={{
                        display: "block",
                        marginTop: "4px",
                        width: "100%",
                      }}
                    >
                      Shipping fee: RM {shippingPrice}
                      <p>
                        {address &&
                          `${address}, ${city}, ${state}, ${country}, ${postalcode}`}
                      </p>
                    </Text>
                    {isAllFieldsNull ? (
                      <Button
                        style={{ float: "right", marginTop: "8px" }}
                        onClick={() =>
                          navigate(
                            `/app/setting/addaddress/${merchantId}/${customerId}`
                          )
                        }
                        type="link"
                      >
                        Add Address
                      </Button>
                    ) : (
                      <Button
                        style={{
                          display: "block",
                          marginTop: "16px",
                          float: "right",
                        }}
                        onClick={() =>
                          navigate(
                            `/app/setting/addaddress/${merchantId}/${customerId}`
                          )
                        }
                        type="link"
                      >
                        Edit Address
                      </Button>
                    )}
                  </div>
                </>
              )}
            </Card>
            <Card
              style={{
                marginBottom: "16px",
                borderRadius: "12px",
              }}
            >
              <Radio
                value="outlet"
                style={{ marginTop: "16px", width: "100%" }}
              >
                <Title level={5} style={{ margin: 0 }}>
                  Self-collect
                </Title>
              </Radio>
              <Divider />
              {selectedOption === "outlet" && (
                <>
                  <Select
                    placeholder="Select Outlet"
                    style={{ width: "100%", marginBottom: "16px" }}
                    onChange={handleOutletChange}
                    value={selectedAddress || undefined}
                  >
                    {selectOutlet.map((outlet) => (
                      <Option key={outlet.id} value={outlet.id}>
                        {outlet.name}
                      </Option>
                    ))}
                  </Select>
                  <div style={{ marginLeft: "24px", marginTop: "16px" }}>
                    <Text>{outletAddress}</Text>
                  </div>
                </>
              )}
            </Card>
          </Radio.Group>
          <Divider />

          {/* Payment Method */}
          <Title level={5}>Payment method</Title>
          <Radio.Group
            defaultValue={paymentMethod}
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Radio
              value="cash"
              style={{
                marginBottom: "16px",
                marginLeft: "24px",
                marginTop: "8px",
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                Case on Delivery
              </Title>
            </Radio>
          </Radio.Group>

          {/* <Radio.Group
            defaultValue={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="credit">
                <Title level={5} style={{ margin: 0 }}>
                  Credit / Debit card
                </Title>
                {paymentMethod === "credit" && (
                  <div style={{ marginLeft: "24px", marginTop: "8px" }}>
                    <Form.Item
                      label="Card number *"
                      style={{ margin: 0, padding: 0 }}
                      name="cardNumber"
                    >
                      <Input
                        placeholder="Card number"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Expiration date *"
                      style={{ margin: 0, padding: 0 }}
                      name="expirationDate"
                    >
                      <Input
                        placeholder="Expiration date"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Security Code *"
                      style={{ margin: 0, padding: 0 }}
                      name="securityCode"
                    >
                      <Input
                        placeholder="Security code"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Full Name *"
                      style={{ margin: 0, padding: 0 }}
                      name="fullName"
                    >
                      <Input
                        placeholder="Full name"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                  </div>
                )}
              </Radio>
            </Card>

            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="fpx">
                <Title level={5} style={{ margin: 0 }}>
                  FPX
                </Title>
              </Radio>
            </Card>
            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="ewallet">
                <Title level={5} style={{ margin: 0 }}>
                  eWallet
                </Title>
              </Radio>
            </Card>
          </Radio.Group> */}
          <Divider />
          {/* Order Summary */}
          <Card style={{ borderRadius: "12px" }}>
            <Title style={{ marginTop: 0 }} level={5}>
              Order Summary
            </Title>
            <Divider />
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Total item qty</Text>
              <Text style={{ fontWeight: "bold" }}>{totalQuantity}</Text>
            </Row>
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Subtotal</Text>
              <Text style={{ fontWeight: "bold" }}>
                RM {totalOfItems.toFixed(2)}
              </Text>
            </Row>
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Privilege Discount</Text>
              <Text style={{ color: "#f43b00", fontWeight: "bold" }}>
                - RM {displayedPrivilegeDiscount.toFixed(2)}
              </Text>
            </Row>
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Remaining Stored qty Total</Text>
              <Text style={{ color: "#f43b00", fontWeight: "bold" }}>
                - RM {storedQtyTotal.toFixed(2)}
              </Text>
            </Row>
            <Row justify="space-between">
              <Text>Shipping fee</Text>
              <Text style={{ fontWeight: "bold" }}>RM {shippingPrice}</Text>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Title style={{ margin: 0 }} level={5}>
                Total
              </Title>
              <Title
                level={5}
                style={{ color: "#FFA500", fontWeight: "bold", margin: 0 }}
              >
                RM {totalPrice}
              </Title>
            </Row>
          </Card>
          {/* Action Buttons */}
          <div style={{ marginTop: "16px", textAlign: "center" }}>
            <Button
              type="primary"
              style={{
                width: "100%",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#FFA500",
                border: "none",
              }}
              onClick={handlePay}
            >
              Pay
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductCheckout;
