import React, { useEffect, useState } from "react";
import { Select, Tabs, Typography, Empty } from "antd";
import { RightOutlined } from "@ant-design/icons";
import VoucherDetailsModal from "./Modals/voucherModal/VoucherDetailModal";
import { get } from "../../../services/Apiclient";
import { useParams } from "react-router-dom";
import ApplicableVoucherModel from "./Modals/voucherModal/ApplicableVoucherModal";
// import { currencySymbol } from "../../../constants/currencySymbol";
import { useCurrency } from "../../../contexts/CurrencyContext";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const giftcardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_GiftCard.png`;
const giftcardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_GiftCard.png`;

const { TabPane } = Tabs;
const { Text } = Typography;

const tempgiftcardItems = [
  {
    id: "1TzSTCpC",
    name: "giftcard new4",
    code: "GC-20241211039",
    sellprice: 12,
    type: "Value",
    singleusageqty: 1,
    typeof_validity: "Custom",
    valid_count: "10",
    valid_countperiod: "Year",
    sessionvalue: "11",
    validfor: "2024-12-01 ~ 2024-12-31",
    record_type: "Giftcard",
    applicable_to: "All Services",
  },
];

const GiftcardSection: React.FC = () => {
  const { currencySymbol } = useCurrency();
  const { id } = useParams();
  const [voucherList, setVoucherList] = useState<any[]>([]);
  const [tempVoucherList, setTempVoucherList] = useState<any[]>([]);
  const [showVoucherDetailsModal, setShowVoucherdetailsModal] = useState(false);
  const [selectedVoucherItems, setSelectedVoucherItems] = useState("");
  const [statusCount, setStatusCount] = useState({
    active: 0,
    inactive: 0,
    expired: 0,
    exhausted: 0,
  });
  const [selectedType, setSelectedType] = useState<string>("All");
  const [selectedStatus, setSelectedStatus] = useState<string>("Active");
  const [applicableModal, setApplicableModal] = useState(false);

  useEffect(() => {
    fetchVoucherData();
  }, []);

  useEffect(() => {
    filterVoucherList();
    calculateStatusCounts();
  }, [voucherList, selectedType, selectedStatus]);

  const fetchVoucherData = async () => {
    const outletId = localStorage.getItem("outlet_id");
    try {
      const url = `/giftcard/bycustomer/${id}/${outletId}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        setVoucherList(data.data);
      }
    } catch (error) {
      console.error("Error in fetching membership list", error);
    }
  };

  const filterVoucherList = () => {
    let filteredList = voucherList;

    // Filter by membership type
    if (selectedType !== "All") {
      filteredList = filteredList.filter((item) => item.type === selectedType);
    }

    // Filter by valid status
    if (selectedStatus) {
      filteredList = filteredList.filter(
        (item) => item.valid_status === selectedStatus.toLowerCase()
      );
    }

    setTempVoucherList(filteredList);
  };

  const calculateStatusCounts = () => {
    const counts = {
      active: 0,
      inactive: 0,
      expired: 0,
      exhausted: 0,
    };

    // Filter vouchers based on the selected type
    let filteredList = voucherList;
    if (selectedType !== "All") {
      filteredList = filteredList.filter((item) => item.type === selectedType);
    }

    // Count statuses in the filtered list
    filteredList.forEach((voucher) => {
      const status = voucher.valid_status.toLowerCase();
      if (status === "active") counts.active++;
      else if (status === "inactive") counts.inactive++;
      else if (status === "expired") counts.expired++;
      else if (status === "exhausted") counts.exhausted++;
    });

    setStatusCount(counts);
  };

  const handleVoucherDetailModal = (item: any) => {
    setShowVoucherdetailsModal(true);
    setSelectedVoucherItems(item);
  };

  const handleApplicableModel = (item: any) => {
    setApplicableModal(true);
    setSelectedVoucherItems(item);
  };
  return (
    <div
      style={{
        backgroundColor: "#f5f7fa",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Select
          defaultValue="All"
          style={{ width: 120, marginBottom: "20px" }}
          onChange={(value) => setSelectedType(value)}
        >
          <Select.Option value="All">All</Select.Option>
          <Select.Option value="Value">Value</Select.Option>
          <Select.Option value="Session">Session</Select.Option>
        </Select>

        {/* Tabs for Status */}
        <Tabs
          defaultActiveKey="Active"
          type="card"
          style={{ marginBottom: "20px" }}
          onChange={(key) => setSelectedStatus(key)}
        >
          <TabPane tab={`Active (${statusCount.active})`} key="Active" />
          <TabPane tab={`Inactive (${statusCount.inactive})`} key="Inactive" />
          <TabPane
            tab={`Exhausted (${statusCount.exhausted})`}
            key="Exhausted"
          />
          <TabPane tab={`Expired (${statusCount.expired})`} key="Expired" />
        </Tabs>
      </div>

      <div>
        {tempVoucherList &&
          tempVoucherList.length > 0 &&
          tempVoucherList.map((item: any, index: any) => (
            <>
              <div
                key={index}
                style={{
                  color: "rgba(176, 87, 77, .8)",
                  position: "relative",
                  width: " 100%",
                  marginBottom: "16px",
                  backgroundImage: `url(${giftcardImage})`,
                  backgroundSize: "100% 100%",
                  transition: "all .2s",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
                onClick={() => {
                  handleVoucherDetailModal(item);
                }}
              >
                <div className="gft_image-icon">
                  <div className="gft_van-image">
                    <img
                      className="gft_van-image__img"
                      src={giftcardImg}
                      alt="gift img"
                    ></img>
                  </div>
                </div>
                <div className="gft_view-bc">
                  <div className="gft_view-info">
                    <div className="gft_overflowText">
                      <span className="gft_title">{item.name}</span>
                      <span className="gft_saleCardItem_text_code">
                        Code:{item.code}
                      </span>
                    </div>
                    <div className="gft_view-apply">
                      <span
                        className="gft_view-cardApply"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent parent div's click event
                          handleApplicableModel(item);
                        }}
                      >
                        <div className="gft_bk-cardApply">
                          <span className="gft_cardApply_text_applicable">
                            Applicable to:
                          </span>
                          {item.applicable_to}
                        </div>
                      </span>
                      <span className="gft_icon-gengduo">
                        <RightOutlined />
                      </span>
                      <div className="gft_view-singleUsageQty">
                        <span className="gft_saleCardItem_text_singleUsageQty">
                          Single usage qty.：
                        </span>
                        <div className="gft_display-def">
                          {item.singleusageqty}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gft_view-bottom">
                  <div className="gft_view-left">
                    <div className="gft_bk-validDateText">
                      <span className="gft_saleCardItem_text_valid">
                        Valid for:{" "}
                      </span>
                      {item.typeof_validity == "Custom" ? (
                        <>
                          <span className="gtf_bk-date">
                            {item.valid_startdate}
                          </span>{" "}
                          ~{" "}
                          <span className="gtf_bk-date">
                            {item.valid_enddate}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="gtf_bk-date">
                            {item.valid_count}
                          </span>{" "}
                          <span className="gtf_bk-date">
                            {item.valid_countperiod}
                          </span>
                        </>
                      )}
                    </div>
                    <div className=""></div>
                  </div>
                  {/* <div className="gft_view-right">
                    <div className="gft_view-price">
                      <span className="gft_saleCardItem_text_price">Price</span>
                      <span className="gft_color-def">${item.sellprice}</span>
                    </div>
                  </div> */}
                </div>
                <div className="gft_dash"></div>
                <div className="gft_view-giftCardValue">
                  {item.type === "Value" ? (
                    <div className="gft_view-value">
                      <span className="gft_color-def_bg">
                        {currencySymbol.CURRENCY_SYMBOL}{item?.sessionvalue}
                      </span>
                    </div>
                  ) : null}
                  {item.type === "Session" ? (
                    <>
                      <div className="gft_view-times">{item?.sessionvalue}</div>
                      <div className="gft_view-tip">Sessions</div>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          ))}

        {tempVoucherList && tempVoucherList.length == 0 && (
          <>
            <Empty
              image={emptyImageUrl}
              imageStyle={{
                height: 120,
                top: 400,
              }}
              description={
                <span>There is currently no gift card available</span>
              }
            />
          </>
        )}
      </div>
      {showVoucherDetailsModal && (
        <VoucherDetailsModal
          visible={showVoucherDetailsModal}
          selectedItem={selectedVoucherItems}
          onClose={() => {
            setShowVoucherdetailsModal(false);
          }}
        />
      )}
      {applicableModal && (
        <ApplicableVoucherModel
          visible={applicableModal}
          selectedItem={selectedVoucherItems}
          onClose={() => {
            setApplicableModal(false);
          }}
        />
      )}
    </div>
  );
};

export default GiftcardSection;
