import React, { useEffect, useState } from "react";
import { Typography, Input, Button, Select, message, Row, Col } from "antd";
import { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../services/Apiclient";
import { Formik, Form, Field, ErrorMessage, FieldProps } from "formik";
import * as Yup from "yup";

const { Title } = Typography;
const { Option } = Select;

const headerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "16px",
  backgroundColor: "#ffffff",
  color: "#fff",
};

const backButtonStyle: React.CSSProperties = {
  background: "none",
  border: "none",
  color: "#FFA500",
  fontSize: "16px",
  cursor: "pointer",
};

const formItemStyle: React.CSSProperties = {
  marginBottom: "16px",
};

const saveButtonStyle: React.CSSProperties = {
  position: "fixed",
  bottom: "16px",
  left: "16px",
  right: "16px",
  backgroundColor: "#FFA500",
  color: "#fff",
  border: "none",
  height: "48px",
  fontSize: "16px",
  borderRadius: "8px",
};

interface FormValues {
  address: string;
  country: string | null;
  state: string | null;
  city: string;
  postal_code: string;
}

const validationSchema = Yup.object().shape({
  state: Yup.string().required("State / Province is required"),
  city: Yup.string().required("City / District is required"),
  country: Yup.string().required("Country / Region is required"),
  postal_code: Yup.string().required("Postal Code is required"),
  address: Yup.string().required("Address is required"),
});

const AddAddress: React.FC = () => {
  const initialValues: FormValues = {
    address: "",
    country: null,
    postal_code: "",
    city: "",
    state: null,
  };
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]); // Store the state options
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const { id } = useParams<{ id: string }>();
  const handleSave = () => {
    console.log("Address saved!");
  };

  const handleBack = () => {
    navigate(`/app/setting/myaddress/${merchantId}`);
  };

  const fetchCustomerDetails = async (id: string) => {
    try {
      const response = await get(
        `/customerprofilemob/mobile/address/${merchantId}/${localStorage.getItem(
          "customer_id"
        )}`
      );
      if (response?.data?.success) {
        const customerData = response.data.data;

        // Populate form with outlet data
        // Update form values with fetched data
        setFormValues({
          address: customerData.address || "",
          country: customerData.country || "",
          state: customerData.state || "",
          city: customerData.city || "",
          postal_code: customerData.postalcode || "",
        });

        setIsEditMode(true); // We're now in edit mode
      }
    } catch (error) {
      console.error("Error fetching outlet details:", error);
    }
  };

  const fetchCountryList = async () => {
    try {
      const response = await get(
        `/customerprofilemob/mobile/countrylist/${merchantId}`
      );
      if (response.data.success) {
        const options = response.data.data.map((country: any) => ({
          label: country.name,
          value: country.name,
          code: country.code,
        }));
        setCountryOptions(options);
      } else {
        console.error("Failed to fetch country options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching country options:", error);
    }
  };

  const fetchStateList = async (selectedCountry: string) => {
    if (!selectedCountry) return;
    try {
      const selectCode: any = countryOptions
        .filter((x: any) => x.label == selectedCountry)
        .map((x: any) => {
          return x;
        })[0];
      const response = await get(`/merchant/statelist/${selectCode.code}`);
      if (response.data.success) {
        if (response.data.data.length > 0) {
          const options = response.data.data.map((state: any) => ({
            label: state.name,
            value: state.name,
          }));
          setStateOptions(options);
          setFormValues((prevValues) => ({
            ...prevValues,
            state: null, // Set to the field value
          }));
        } else {
          setFormValues((prevValues) => ({
            ...prevValues,
            state: "", // Set to the field value
          }));
        }
      } else {
        setStateOptions([]);
        setFormValues((prevValues) => ({
          ...prevValues,
          state: "", // Set to the field value
        }));
        console.error("Failed to fetch state options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching state options:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCustomerDetails(id);
    }
  }, [id]);

  useEffect(() => {
    // Scroll to the top of the page with smooth behavior on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchStateList(selectedCountry);
    } else {
      setStateOptions([]); // Clear state options if no country selected
    }
  }, [selectedCountry]);

  const handleSubmit = async (values: any) => {
    const formData = {
      address: values.address,
      country: values.country,
      state: values.state,
      city: values.city,
      postalcode: values.postal_code,
    };

    try {
      let response;
      // Use the put method for updating
      response = await put(
        `/customerprofilemob/mobile/address/${merchantId}/${localStorage.getItem(
          "customer_id"
        )}`,
        formData
      );
      if (response.status === 201 || response.status === 200) {
        console.log("Form submitted successfully:", response.data);
        navigate(`/app/setting/myaddress/${merchantId}`);
      } else {
        message.error(response.data.message);
        console.error("Form submission failed:", response.data);
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  return (
    <div
      style={{ minHeight: "100vh", backgroundColor: "#fff", padding: "16px" }}
    >
      {/* Header */}
      <div style={headerStyle}>
        <Button
          style={backButtonStyle}
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
        />
        <Title level={5} style={{ margin: 0, color: "#000" }}>
          Add
        </Title>
      </div>

      {/* Form */}
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Row
              gutter={16}
              style={{
                borderBottom: "1px solid #d2d2d2",
                paddingBottom: "16px",
              }}
            >
              <Col
                span={16}
                xs={12}
                sm={12}
                lg={16}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label>Country / Region</label>
              </Col>
              <Col span={8} xs={12} sm={12} lg={8}>
                <Select
                  value={values.country}
                  className="mobile-dropdown"
                  dropdownStyle={{ textAlign: "right" }}
                  suffixIcon={<RightOutlined />}
                  onChange={(value) => {
                    setFieldValue("country", value);
                    setSelectedCountry(value);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      country: value, // Set to the field value
                    }));
                  }}
                  placeholder="Please Select"
                >
                  {countryOptions.map((option: any) => (
                    <Option
                      key={`country-${option.value}`}
                      value={option.value}
                    >
                      {option.label}
                    </Option>
                  ))}
                </Select>
                <ErrorMessage name="country">
                  {(msg) => (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "right",
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                borderBottom: "1px solid #d2d2d2",
                marginTop: "16px",
                paddingBottom: "16px",
              }}
            >
              <Col
                span={16}
                xs={12}
                sm={12}
                lg={16}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label>State / Province</label>
              </Col>
              <Col span={8} xs={12} sm={12} lg={8}>
                {stateOptions && stateOptions.length > 0 ? (
                  <Select
                    value={values.state}
                    className="mobile-dropdown"
                    dropdownStyle={{ textAlign: "right" }}
                    suffixIcon={<RightOutlined />}
                    onChange={(value) => {
                      setFieldValue("state", value);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        state: value, // Set to the field value
                      }));
                    }}
                    placeholder="Please select"
                  >
                    {stateOptions.map((option: any) => (
                      <Option
                        key={`state-${option.value}`}
                        value={option.value}
                      >
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Field name="state">
                    {({ field }: any) => (
                      <Input
                        {...field}
                        className="mobile-inputtext"
                        placeholder="Please enter"
                        onChange={(e) => {
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            state: e.target.value, // Set to the field value
                          }));
                        }}
                      />
                    )}
                  </Field>
                )}
                <ErrorMessage name="state">
                  {(msg) => (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "right",
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                borderBottom: "1px solid #d2d2d2",
                marginTop: "16px",
                paddingBottom: "16px",
              }}
            >
              <Col
                span={16}
                xs={24}
                sm={12}
                lg={16}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label>City / District</label>
              </Col>
              <Col span={8} xs={24} sm={12} lg={8}>
                <Field name="city">
                  {({ field }: any) => (
                    <Input
                      {...field}
                      className="mobile-inputtext"
                      placeholder="Please enter"
                      onChange={(e) => {
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          city: e.target.value, // Set to the field value
                        }));
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="city">
                  {(msg) => (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "right",
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                borderBottom: "1px solid #d2d2d2",
                marginTop: "16px",
                paddingBottom: "16px",
              }}
            >
              <Col
                span={16}
                xs={24}
                sm={12}
                lg={16}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label>Postal code</label>
              </Col>
              <Col span={8} xs={24} sm={12} lg={8}>
                <Field name="postal_code">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      className="mobile-inputtext"
                      placeholder="Please enter"
                      onChange={(e) => {
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          postal_code: e.target.value, // Set to the field value
                        }));
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="postal_code">
                  {(msg) => (
                    <div style={{ color: "red", textAlign: "right" }}>
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                borderBottom: "1px solid #d2d2d2",
                marginTop: "16px",
                paddingBottom: "16px",
              }}
            >
              <Col
                span={16}
                xs={24}
                sm={12}
                lg={16}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label>Address</label>
              </Col>
              <Col span={8} xs={24} sm={12} lg={8}>
                <Field name="address">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      className="mobile-inputtext"
                      placeholder="Please enter"
                      onChange={(e) => {
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          address: e.target.value, // Set to the field value
                        }));
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="address">
                  {(msg) => (
                    <div style={{ color: "red", textAlign: "right" }}>
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </Col>
            </Row>
            <Button type="primary" style={saveButtonStyle} htmlType="submit">
              Save
            </Button>
          </Form>
        )}
      </Formik>

      {/* Save Button */}
    </div>
  );
};

export default AddAddress;
