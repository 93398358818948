import {
  BellOutlined,
  HomeOutlined,
  ScheduleOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "./Footer";

const ParentComponentFooter: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantId } = useParams();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  // Determine the active index based on the current path
  useEffect(() => {
    let newIndex = 0; // Default to Home

    if (location.pathname === `/app/merchant/details/${merchantId}`) {
      newIndex = 0;
    } else if (
      location.pathname === `/app/news/${merchantId}` ||
      location.pathname.startsWith(`/app/news/details/${merchantId}`)
    ) {
      newIndex = 1;
    } else if (
      [
        `/app/products/${merchantId}`,
        `/app/allproducts/${merchantId}`,
        `/app/allpromotion/${merchantId}`,
        `/app/cart/${merchantId}`,
        `/app/success/${merchantId}`,
      ].includes(location.pathname) ||
      location.pathname.startsWith(`/app/productCategory/${merchantId}`) ||
      location.pathname.startsWith(`/app/productDetails/${merchantId}`) ||
      location.pathname.startsWith(`/app/checkout/${merchantId}`)
    ) {
      newIndex = 2; // Products, Categories, Details, Checkout, Cart, etc.
    } else if (location.pathname === `/app/profile/${merchantId}`) {
      newIndex = 4;
    }
    setActiveIndex(newIndex);
  }, [location.pathname, merchantId]);

  const handleNavigation = (path: string, index: number) => {
    navigate(path); // Perform navigation
  };

  const footerItems = [
    {
      label: "Home",
      icon: <HomeOutlined />,
      // onClick: () => handleNavigation(`/app/merchant/details/${merchantId}`),
      onClick: () => handleNavigation(`/app/merchant/details/${merchantId}`, 0),
    },
    {
      label: "Noticeboard",
      icon: <BellOutlined />,
      onClick: () => handleNavigation(`/app/news/${merchantId}`, 1),
    },
    {
      label: "Products",
      icon: <ShoppingCartOutlined style={{ fontSize: "24px" }} />,
      onClick: () => handleNavigation(`/app/products/${merchantId}`, 2),
    },
    {
      label: "Booking",
      icon: <ScheduleOutlined />,
      onClick: () => setActiveIndex(3),
    },
    {
      label: "Profile",
      icon: <UserOutlined />,
      // onClick: () => handleNavigation(`/app/profile/${merchantId}`),
      onClick: () => handleNavigation(`/app/profile/${merchantId}`, 4),
    },
  ];

  return (
    <>
      {/* <Footer items={footerItems} /> */}
      <Footer
        items={footerItems}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </>
  );
};

export default ParentComponentFooter;
