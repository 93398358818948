import React, { useEffect, useState } from "react";
import { Select, Tabs, Typography, Empty, Tooltip } from "antd";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import MembershipDetailModal from "./Modals/membershipModals/MembershipDetailModal";
import { useParams } from "react-router-dom";
import { get } from "../../../services/Apiclient";
import ApplicableMembershipModel from "./Modals/membershipModals/ApplicableMembershipModal";
// import { currencySymbol } from "../../../constants/currencySymbol";
import { useCurrency } from "../../../contexts/CurrencyContext";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const membershipbgdPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Store.png`;
const membershipbgdVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Times.png`;
const membershipimgDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Discount.png`;
const membershipimgPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Store.png`;
const membershipimgVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Times.png`;
const membershipbgdDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Discount.png`;

const { TabPane } = Tabs;
const { Text } = Typography;

const tempMembershipItem = [
  {
    id: "dNhSVnxI",
    name: "Membership prepaid with category",
    code: "20241230209",
    typeof_validity: "Custom",
    membership_type: "Prepaid",
    valid_count: "10",
    valid_countperiod: "Year",
    valid_startdate: "2024-12-01",
    valid_enddate: "2024-12-31",
    sellprice: "119.00",
    product_discount: null,
    service_discount: null,
    privilege_discount: null,
    pre_product_discount: 10,
    pre_service_discount: 20,
    pre_privilege_discount: 30,
    prepaid_value: 0,
    prepaid_bonuse: 0,
    selling_price: "119.00",
    limited_use: null,
    type: "Values",
    usage_limit: null,
    totalprice: null,
    buyprice: null,
    giveaway: null,
    serviceid: null,
    servicename: null,
    max_usage: null,
    applicable_to:
      "All Privileges/ All Stores/ 1 Service Categories/ 2 Product Categories",
  },
  {
    id: "eshUmsd7",
    name: "visit based membership",
    code: "20241221598",
    typeof_validity: "Custom",
    membership_type: "Visit-based",
    valid_count: "10",
    valid_countperiod: "Year",
    valid_startdate: "2024-12-01",
    valid_enddate: "2024-12-31",
    sellprice: "40",
    product_discount: null,
    service_discount: null,
    privilege_discount: null,
    pre_product_discount: null,
    pre_service_discount: null,
    pre_privilege_discount: null,
    prepaid_value: null,
    prepaid_bonuse: null,
    selling_price: null,
    limited_use: null,
    type: null,
    usage_limit: 1,
    totalprice: 40,
    buyprice: 4,
    giveaway: 2,
    serviceid: "ViihaUtA",
    servicename: "service 2 1",
    max_usage: null,
    applicable_to: "All Stores",
  },
  {
    id: "EuqLRBTW",
    name: "discount-categorywise",
    code: "20241230434",
    typeof_validity: "Immediately",
    membership_type: "Discount",
    valid_count: "10",
    valid_countperiod: "Year",
    valid_startdate: "",
    valid_enddate: "",
    sellprice: "50",
    product_discount: 10,
    service_discount: 20,
    privilege_discount: 10,
    pre_product_discount: null,
    pre_service_discount: null,
    pre_privilege_discount: null,
    prepaid_value: null,
    prepaid_bonuse: null,
    selling_price: null,
    limited_use: 1,
    type: null,
    usage_limit: null,
    totalprice: null,
    buyprice: null,
    giveaway: null,
    serviceid: null,
    servicename: null,
    max_usage: 10,
    applicable_to:
      "All Privileges/ All Stores/ 1 Service Categories/ 1 Product Categories",
  },
];

const MembershipSection: React.FC = () => {
  const { currencySymbol } = useCurrency();
  const { id } = useParams();
  const [membershipList, setMembershipList] = useState<any[]>([]);
  const [tempMembershipList, setTempMembershipList] = useState<any[]>([]);
  const [membershipDetailModalVisible, setMembershipDetailModalVisible] =
    useState(false);
  const [selectedMembershipItem, setSelectedMembershipItem] =
    useState<any>(null);
  const [statusCount, setStatusCount] = useState({
    active: 0,
    inactive: 0,
    expired: 0,
    exhausted: 0,
  });
  const [selectedType, setSelectedType] = useState<string>("All");
  const [selectedStatus, setSelectedStatus] = useState<string>("Active");
  const [applicableModal, setApplicableModal] = useState(false);

  useEffect(() => {
    fetchMembershipItem();
  }, []);

  useEffect(() => {
    filterMembershipList();
    calculateStatusCounts();
  }, [membershipList, selectedType, selectedStatus]);

  const fetchMembershipItem = async () => {
    const outletId = localStorage.getItem("outlet_id");
    try {
      const url = `/membership/bycustomer/${id}/${outletId}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        // console.log("response....", data.data);
        setMembershipList(data.data);
      }
    } catch (error) {
      console.error("Error in fetching membership list", error);
    }
  };

  const filterMembershipList = () => {
    let filteredList = membershipList;

    // Filter by membership type
    if (selectedType !== "All") {
      filteredList = filteredList.filter(
        (item) => item.membership_type === selectedType
      );
    }

    // Filter by valid status
    if (selectedStatus) {
      filteredList = filteredList.filter(
        (item) => item.valid_status === selectedStatus.toLowerCase()
      );
    }

    setTempMembershipList(filteredList);
  };

  const calculateStatusCounts = () => {
    const counts = {
      active: 0,
      inactive: 0,
      expired: 0,
      exhausted: 0,
    };

    // Filter vouchers based on the selected type
    let filteredList = membershipList;
    if (selectedType !== "All") {
      filteredList = filteredList.filter((item) => item.membership_type === selectedType);
    }

    // Count statuses in the filtered list
    filteredList.forEach((voucher) => {
      const status = voucher.valid_status.toLowerCase();
      if (status === "active") counts.active++;
      else if (status === "inactive") counts.inactive++;
      else if (status === "expired") counts.expired++;
      else if (status === "exhausted") counts.exhausted++;
    });

    setStatusCount(counts);
  };

  const handleDetailModal = (item: any) => {
    setSelectedMembershipItem(item);
    setMembershipDetailModalVisible(true);
  };

  const handleApplicableModel = (item: any) => {
    setApplicableModal(true);
    setSelectedMembershipItem(item);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#f5f7fa",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Select
            defaultValue="All"
            style={{ width: 120, marginBottom: "20px" }}
            onChange={(value) => setSelectedType(value)}
          >
            <Select.Option value="All">All</Select.Option>
            <Select.Option value="Prepaid">Prepaid</Select.Option>
            <Select.Option value="Discount">Discount</Select.Option>
            <Select.Option value="Visit-based">Visit based</Select.Option>
          </Select>

          {/* Tabs for Status */}
          <Tabs
            defaultActiveKey="Active"
            type="card"
            style={{ marginBottom: "20px" }}
            onChange={(key) => setSelectedStatus(key)}
          >
            <TabPane tab={`Active (${statusCount.active})`} key="Active" />
            <TabPane
              tab={`Inactive (${statusCount.inactive})`}
              key="Inactive"
            />
            <TabPane
              tab={`Exhausted (${statusCount.exhausted})`}
              key="Exhausted"
            />
            <TabPane tab={`Expired (${statusCount.expired})`} key="Expired" />
          </Tabs>
        </div>
        <div>
          {tempMembershipList &&
            tempMembershipList.length > 0 &&
            tempMembershipList.map((item: any, index: any) => (
              <>
                <div
                  key={index}
                  style={{
                    color: `${
                      item?.membership_type == "Prepaid"
                        ? "rgba(93, 74, 140, 0.8)"
                        : item?.membership_type == "Discount"
                        ? "rgba(45,73,127,0.8)"
                        : item?.membership_type == "Visit-based"
                        ? "rgba(139, 88, 59, .8)"
                        : null
                    } `,
                    position: "relative",
                    width: " 100%",
                    // height: "110px",
                    marginBottom: "16px",
                    backgroundImage: `url(${
                      item?.membership_type === "Prepaid"
                        ? membershipbgdPrepaid
                        : item?.membership_type === "Discount"
                        ? membershipbgdDiscount
                        : item?.membership_type === "Visit-based"
                        ? membershipbgdVisitBased
                        : null
                    })`,
                    backgroundSize: "100% 100%",
                    transition: "all .2s",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  onClick={() => handleDetailModal(item)}
                >
                  <div className="gft_image-icon">
                    <div className="gft_van-image">
                      <img
                        className="gft_van-image__img"
                        src={
                          item?.membership_type === "Prepaid"
                            ? membershipimgPrepaid
                            : item?.membership_type === "Discount"
                            ? membershipimgDiscount
                            : item?.membership_type === "Visit-based"
                            ? membershipimgVisitBased
                            : undefined
                        }
                        alt="membership"
                      ></img>
                    </div>
                  </div>
                  <div className="ms_view-bc">
                    <div className="ms_view-info">
                      <div className="ms_overflowText">
                        <span className="ms_title">{item?.name}</span>
                        <span className="ms_saleCardItem_text_code">
                          Code:{item?.code}
                        </span>
                      </div>
                      <div className="ms_view-apply">
                        <span
                          className="ms_view-cardApply"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent parent div's click event
                            handleApplicableModel(item);
                          }}
                        >
                          <div className="ms_bk-cardApply">
                            <span className="ms_cardApply_text_applicable">
                              Applicable to:
                            </span>
                            {item?.applicable_to}
                          </div>
                        </span>
                        <span className="ms_icon-gengduo">
                          <RightOutlined />
                        </span>
                      </div>
                    </div>
                    {item?.membership_type == "Prepaid" && (
                      <div className="ms_display-def_view-item">
                        <div className="ms_view-tip">Stored value</div>
                        <div className="ms_view-value">{currencySymbol.CURRENCY_SYMBOL}{item?.remaining_store_balance}</div>
                      </div>
                    )}
                    {item?.membership_type == "Prepaid" && (
                      <div className="ms_display-def_view-item">
                        <div className="ms_view-tip">Bonus value</div>
                        <div className="ms_view-value">{item?.remaining_bounce}</div>
                      </div>
                    )}

                    {item?.membership_type == "Discount" && (
                      <div className="ms_display-def_view-item_session">
                        <div className="ms_view-tip">Remaining</div>
                        <div className="ms_view-value">{item?.max_usage}</div>
                      </div>
                    )}

                    {item?.membership_type == "Visit-based" && (
                      <div className="ms_display-def_view-item_session">
                        <div className="ms_view-tip">Giveaway remaining</div>
                        <div className="ms_view-value">{item?.giveaway}</div>
                      </div>
                    )}

                    {(item?.membership_type == "Discount" ||
                      item?.membership_type == "Prepaid") && (
                      <div className="ms_display-def_view-item_disc">
                        <div className="ms_view-tip">
                          <span className="ms_view-tip">Discount</span>
                          <span style={{ marginLeft: "3px" }}>
                            <Tooltip
                              placement="topRight"
                              title="Service discount / Product discount / Privilege discount"
                            >
                              <InfoCircleOutlined
                                style={{
                                  // fontSize: "16px",
                                  cursor: "pointer",
                                  fontFamily: "iconfont !important",
                                  fontStyle: "normal",
                                }}
                              />
                            </Tooltip>
                          </span>
                        </div>
                        {item?.membership_type == "Discount" && (
                          <div className="ms_view-value">
                            {item?.service_discount}%/
                            {item?.product_discount}%/
                            {item?.privilege_discount}%
                          </div>
                        )}
                        {item?.membership_type == "Prepaid" && (
                          <div className="ms_view-value">
                            {item?.service_discount}%/
                            {item?.product_discount}%/
                            {item?.privilege_discount}%
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="ms_view-bottom">
                    <div className="ms_view-left">
                      <div className="ms_bk-validDateText">
                        <span className="ms_saleCardItem_text_valid">
                          Valid for:{" "}
                        </span>
                        <span className="ms_bk-date">
                          {item?.typeof_validity === "Custom" ? (
                            <>
                              {item?.valid_startdate}~{item?.valid_enddate}
                            </>
                          ) : (
                            <></>
                          )}

                          {item?.typeof_validity === "Immediately" ? (
                            <>
                              {item?.valid_count}
                              {item?.valid_countperiod}
                            </>
                          ) : (
                            <></>
                          )}

                          {item?.typeof_validity ===
                          "Effective on consumption day" ? (
                            <>
                              {item?.valid_count} {item?.valid_countperiod}
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                        {/* <span>~</span>
                                <span className="gtf_bk-date">17/11/2024</span> */}
                      </div>
                      <div className=""></div>
                    </div>
                    {item?.membership_type == "Prepaid" && (
                      <div className="ms_view-right">
                        <div className="ms_view-price">
                          <span className="ms_saleCardItem_text_price">
                            Balance
                          </span>
                          <span className="ms_color-def">
                            {currencySymbol.CURRENCY_SYMBOL} {parseFloat(item?.remaining_store_balance) + parseFloat(item?.remaining_bounce)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          {tempMembershipList && tempMembershipList.length == 0 && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={<span>No data available</span>}
              />
            </>
          )}
        </div>
      </div>
      {membershipDetailModalVisible && (
        <MembershipDetailModal
          visible={membershipDetailModalVisible}
          selectedItem={selectedMembershipItem}
          onClose={() => {
            setMembershipDetailModalVisible(false);
          }}
        />
      )}
      {applicableModal && (
        <ApplicableMembershipModel
          visible={applicableModal}
          selectedItem={selectedMembershipItem}
          onClose={() => {
            setApplicableModal(false);
          }}
        />
      )}
    </>
  );
};

export default MembershipSection;
