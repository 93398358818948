import React, { useState } from "react";
import { Dropdown, Layout, Menu, Space, Avatar } from "antd";
import {
  Outlet,
  Link,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom"; // Added useNavigate
import {
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShopOutlined,
  UserOutlined,
  SettingOutlined,
  InboxOutlined,
  ShoppingOutlined,
  HomeFilled,
  ShopFilled,
  SettingFilled,
  BellOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_White.png`;

const { Header, Content, Sider } = Layout;

const menuItems = [
  { key: "/", icon: <HomeFilled />, label: "Home" },
  { key: "/outlet", icon: <ShopFilled />, label: "Outlet" },
  { key: "/customer", icon: <UserOutlined />, label: "Customers" },
  { key: "/settings", icon: <SettingFilled />, label: "Settings" },
  { key: "/merchant/list", icon: <UserOutlined />, label: "Merchant" },
  { key: "/inventory", icon: <InboxOutlined />, label: "Inventory" },
  { key: "/sales", icon: <ShoppingOutlined />, label: "Sales" },
  { key: "/noticeboard", icon: <BellOutlined />, label: "Noticeboard" },
  {
    key: "/onlinesales",
    icon: <ShoppingCartOutlined />,
    label: "Online Sales",
  },
];

const MainLayout: React.FC = () => {
  let userRole: any = null;
  try {
    const token: any = localStorage.getItem("token");
    if (token) {
      const decodedToken: any = jwtDecode(token);
      userRole = decodedToken?.tokendata?.role;
    } else {
      localStorage.setItem("token", "");
      <Navigate to="/login" replace />;
    }
  } catch (error) {
    console.error("Token decoding failed:", error);
    localStorage.setItem("token", "");
  }
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate for redirect

  const isProductListRoute = location.pathname === "/setting/product/list";
  const isServiceListRoute = location.pathname === "/setting/service/list";

  // Handle logout: clear token from localStorage and redirect to login
  const handleLogout = () => {
    let merchantid = localStorage.getItem("merchantid");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("store_name");
    localStorage.removeItem("token");
    localStorage.removeItem("outlet_id");
    localStorage.removeItem("timeout");
    localStorage.removeItem("enableonlineshop");
    localStorage.removeItem("enableretailshop");
    localStorage.removeItem("merchantid");
    localStorage.removeItem("userRole");
    let outletlogin = false;
    if (localStorage.getItem("outletlogin") == "true") {
      outletlogin = true;
    }
    if (outletlogin) {
      navigate(`/login?id=${merchantid}`); // Redirect to the login page
      localStorage.removeItem("outletlogin");
    } else {
      navigate("/login"); // Redirect to the login page
      localStorage.removeItem("outletlogin");
    }
  };

  const userMenu = (
    <Menu>
      {/* <Menu.Item key="1">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/settings">Settings</Link>
      </Menu.Item> */}
      <Menu.Item key="3" onClick={handleLogout}>
        {" "}
        {/* Handle Logout */}
        Logout
      </Menu.Item>
    </Menu>
  );

  const enableretailshop = localStorage.getItem("enableretailshop") === "true";

  const filteredMenuItems =
    userRole === "Superadmin"
      ? menuItems.filter((item) => item.key === "/merchant/list") // Only show "Merchant" for Superadmin
      : menuItems.filter(
          (item) =>
            item.key !== "/merchant/list" &&
            (item.key !== "/sales" || enableretailshop)
        );

  if (isProductListRoute || isServiceListRoute) {
    return <Outlet />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        style={{
          backgroundColor: "#2e56f2",
          display: "flex",
          flexDirection: "column",
          width: "240px",
        }}
        className="main-sider"
        trigger={
          collapsed ? (
            <MenuUnfoldOutlined />
          ) : (
            <MenuFoldOutlined style={{ alignSelf: "end" }} />
          )
        }
      >
        <img
          src={logoIcon}
          alt="DACO"
          className="logo"
          style={{
            width: "calc(100% - 30px)",
            height: 50,
            margin: 16,
            background: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <Menu
          theme="dark"
          defaultSelectedKeys={[location.pathname]}
          mode="inline"
          style={{
            backgroundColor: "#2e56f2",
            flex: 1,
          }}
        >
          {filteredMenuItems.map((item) => (
            <Menu.Item
              key={item.key}
              icon={React.cloneElement(item.icon, {
                style: {
                  color: "#FFF",
                },
              })}
              style={
                location.pathname === item.key
                  ? { backgroundColor: "#2749ce", color: "#FFF" }
                  : { color: "#FFF" }
              }
            >
              <Link
                to={item.key}
                style={{
                  color: "#FFF",
                }}
              >
                {item.label}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            position: "fixed",
            width: `calc(100% - ${collapsed ? "80px" : "240px"})`,
            padding: "0 20px",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <div>
            <span
              style={{
                fontSize: "14px",
                lineHeight: "32px",
                fontWeight: "700",
                color: "#333333",
              }}
            >
              {localStorage.getItem("store_name")}
            </span>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Dropdown
              overlay={userMenu}
              placement="bottomCenter"
              trigger={["click"]}
            >
              <Space
                style={{
                  cursor: "pointer",
                }}
              >
                <Avatar icon={<UserOutlined />} />
                <span>{userRole}</span>
              </Space>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            marginTop: 64,
            padding: 16,
            overflowY: "auto",
            height: "calc(100vh - 64px)",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
