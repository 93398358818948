import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Dropdown,
  message,
  Modal,
  Pagination,
  Switch,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { deleteData, get, post, put } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/global.css";
import { Content } from "antd/es/layout/layout";
import { useCurrency } from "../../contexts/CurrencyContext";
// import { currencySymbol } from "../../constants/currencySymbol";

const { Title, Text } = Typography;

interface PackageDetails {
  key: React.Key;
  id: string | undefined;
  package_code: string;
  package_name: string;
  usage_status: boolean;
  description: string;
  applicable_type: string;
  typeof_validity: string;
  valid_startdate: string;
  valid_enddate: string;
  selling_startdate: string;
  selling_enddate: string;
  valid_count: number;
  valid_countperiod: string;
  sellingperiod_startdate: string;
  sellingperiod_enddate: string;
  loyalty_pointtype: string;
  loyalty_pointvalue: number;
  note: string;
  total_amount: string;
  tax: null;
  packageProduct: packageProduct[];
  packageService: packageService[];
}

interface packageProduct {
  product_id?: string;
  product_name?: string;
  product_price?: string;
  qty?: string;
  selling_price: string;
  rule: string;
  key: string;
  max_available?: string;
  combo?: combo[];
  type: string;
}

interface combo {
  combo_id: string;
  product_id: string;
  product_name: string;
  product_price: string;
  max_available?: string;
  type: string;
}

interface packageService {
  service_id?: string;
  service_name?: string;
  service_price?: string;
  qty?: string;
  selling_price: string;
  rule: string;
  key: string;
  max_available?: string;
  combo?: combo[];
  type: string;
}

interface combo {
  combo_id: string;
  service_id: string;
  service_name: string;
  service_price: string;
  max_available?: string;
  type: string;
}

export default function PackagesView() {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const { id } = useParams();
  const [packageDetails, setpackageDetails] = useState<PackageDetails | null>(
    null
  );
  const [packageProduct, setpackageProduct] = useState<packageProduct[]>([]);
  const [packageService, setpackageService] = useState<packageService[]>([]);
  const fetchPackageDetails = async (id: any) => {
    try {
      const response = await get(`/package/${id}`);
      if (response?.data?.success) {
        setpackageDetails(response.data.data);
        setpackageProduct(response.data.data.products);
        setpackageService(response.data.data.services);
      }
    } catch (error) {
      console.error("Error fetching stock details:", error);
    }
  };

  const handleSwitchChange = async (id: any, checked: boolean) => {
    try {
      let url = `/package/${id}`;

      let body = {
        usage_status: checked,
      };
      const response = await put(url, body);

      if (response && response.data) {
        console.log(response.data);
        if (response.data.success) {
          fetchPackageDetails(id);
        } else {
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.error("Error updating usage status:", error);
    }
  };

  useEffect(() => {
    fetchPackageDetails(id);
  }, [id]);

  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Packages</h2>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/* <Button
              icon={<SortAscendingOutlined />}
              style={{
                height: "32px !important",
                background: "#fff",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                color: "#2e56f2",
                marginRight: "8px",
              }}
            >
              Sync to purchased customers
            </Button> */}
            {/* <Button className="quick-settings">Quick Settings</Button> */}
            {/* <Button
              type="primary"
              icon={<EditOutlined />}
              // onClick={showModal}
              style={{
                height: "32px !important",
                background: "#fff",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                color: "#2e56f2",
              }}
              onClick={() => () => {}}
            >
              Edit
            </Button> */}
          </div>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          margin: "auto",
          left: 0,
          right: 0,
          marginTop: "10px",
          borderRadius: "12px",
          boxSizing: "border-box",
          maxWidth: "1280px",
          minWidth: "920px",
          height: "calc(100vh - 100px)",
          overflow: "hidden",
          padding: "24px",
          overflowY: "auto",
          border: ".5px solid #e0e7fe",
          backgroundColor: "hsla(0, 0%, 100%, .5)",
        }}
      >
        <div
          // bordered={false}
          style={{
            // width: "100%",
            // height: "96px",
            // display: "flex",
            padding: "12px 24px",
            // justifyContent: "space-between",
            borderRadius: "12px 12px 12px 12px",
            background: "#fff linear-gradient(161deg, #a3f4e6, #26c0c9)",
          }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{ width: "100%", padding: "0px !important" }}
          >
            <Col style={{ display: "flex", gap: "10px" }}>
              <Title
                level={5}
                style={{
                  margin: 0,
                  fontWeight: 700,
                  height: "26px",
                  fontSize: "18px",
                  lineHeight: "26px",
                  color: "rgba(51, 129, 132, .8)",
                }}
              >
                {packageDetails?.package_name}
              </Title>
              <Text
                style={{
                  marginTop: "5px",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginLeft: "12px",
                  color: "rgba(51, 129, 132, .6)",
                }}
              >
                No:{packageDetails?.package_code}
              </Text>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Text
                style={{
                  marginLeft: "10px",
                  verticalAlign: "top",
                  color: "rgba(51, 129, 132, .6)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Usage Status
              </Text>

              <Switch
                checked={packageDetails?.usage_status}
                onChange={(checked) => handleSwitchChange(id, checked)}
              />
            </Col>
          </Row>

          {packageDetails?.description !== "" ? (
            <Text
              style={{
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "8px",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                color: "rgba(51, 129, 132, .4)",
              }}
            >
              {packageDetails?.description}
            </Text>
          ) : (
            <></>
          )}

          <Text
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              color: "rgba(51, 129, 132, .6)",
            }}
          >
            Valid for:{" "}
            {packageDetails?.typeof_validity === "Custom" ? (
              `${packageDetails?.valid_startdate}-${packageDetails?.valid_enddate}`
            ) : (
              <></>
            )}
            {packageDetails?.typeof_validity === "Immediately" ? (
              `${packageDetails?.valid_count} ${packageDetails?.valid_countperiod}`
            ) : (
              <></>
            )}
            {packageDetails?.typeof_validity === "EffectiveOnConsumption" ? (
              `${packageDetails?.valid_count} ${packageDetails?.valid_countperiod}`
            ) : (
              <></>
            )}
          </Text>
          {packageDetails?.selling_startdate === "" ||
          packageDetails?.selling_enddate === "" ? (
            <></>
          ) : (
            <Text
              style={{
                display: "flex",
                marginTop: "px",
                justifyContent: "flex-end",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                color: "rgba(51, 129, 132, .6)",
              }}
            >
              Selling period:{" "}
              {packageDetails?.typeof_validity === "Custom" ? (
                `${packageDetails?.selling_startdate}-${packageDetails?.selling_enddate}`
              ) : (
                <></>
              )}
              {packageDetails?.typeof_validity === "Immediately" ? (
                `${packageDetails?.selling_startdate}-${packageDetails?.selling_enddate}`
              ) : (
                <></>
              )}
              {packageDetails?.typeof_validity === "EffectiveOnConsumption" ? (
                `${packageDetails?.selling_startdate}-${packageDetails?.selling_enddate}`
              ) : (
                <></>
              )}
            </Text>
          )}
        </div>

        <Row
          justify="end"
          style={{
            height: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0 16px",
          }}
        >
          <span style={{ fontSize: "13px", marginLeft: "24px", color: "#666" }}>
            Selling price:
          </span>
          <span style={{ color: "#f43b00", fontWeight: 700 }}>
            {currencySymbol.CURRENCY_SYMBOL}
            {packageDetails?.total_amount}
          </span>
        </Row>

        {/* Service */}

        {packageService.length > 0 ? (
          <div
            style={{
              marginTop: "12px",
              padding: "16px 24px",
              border: ".5px solid #e0e7fe",
              borderRadius: "12px 12px 12px 12px",
              background: "hsla(0, 0%, 100%, .5)",
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                color: "#333",
                fontSize: "14px",
                lineHeight: "16px",
              }}
            >
              Service
            </Text>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "2px 10px",
                background: "hsla(0, 0%, 100%, .9)",
                borderRadius: "6px 6px 6px 6px",
                fontWeight: 700,
                border: ".5px solid #e0e7fe",
                color: "rgba(0, 0, 0, .3)",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "6px",
                  padding: "8px 16px",
                }}
              >
                <Text style={{ width: "80%", color: "rgba(0, 0, 0, .3)" }}>
                  Service name
                </Text>
                <Text style={{ width: "20%", color: "rgba(0, 0, 0, .3)" }}>
                  Price
                </Text>
                <Text style={{ width: "20%", color: "rgba(0, 0, 0, .3)" }}>
                  Max available
                </Text>
              </div>
              <div style={{ width: "10%" }} />
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, .3)",
                  }}
                >
                  Qty.
                </Text>
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, .3)",
                  }}
                >
                  Selling price
                </Text>
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, .3)",
                  }}
                >
                  Rule
                </Text>
              </div>
            </div>
            {packageService.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    margin: "4px 10px",
                    background: "hsla(0, 0%, 100%, .9)",
                    borderRadius: "6px",
                    fontWeight: 400,
                    border: ".5px solid #e0e7fe",
                    color: "rgba(0, 0, 0, .3)",
                    fontSize: "12px",
                    position: "relative",
                  }}
                >
                  {item.type == "Single" && (
                    <div style={{ display: "flex", width: "100%" }}>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "6px",
                          padding: "8px 16px",
                        }}
                      >
                        <Text style={{ width: "80%" }}>
                          {item.service_name}
                        </Text>
                        <Text style={{ width: "20%" }}>
                          {currencySymbol.CURRENCY_SYMBOL}
                          {item.service_price}
                        </Text>
                        <Text style={{ width: "20%", textAlign: "center" }}>
                          {item.max_available}
                        </Text>
                      </div>
                      <div style={{ width: "10%" }} />
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ width: "25%", textAlign: "center" }}>
                          {item.qty}
                        </Text>
                        <Text style={{ width: "25%", textAlign: "center" }}>
                          {currencySymbol.CURRENCY_SYMBOL}
                          {item.selling_price}
                        </Text>
                        <Text style={{ width: "25%", textAlign: "center" }}>
                          {item.rule}
                        </Text>
                      </div>
                    </div>
                  )}

                  {item.type !== "Single" && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      {item?.combo.length > 1 && (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              left: "55%",
                              top: "18px",
                              bottom: "10px",
                              width: "2px",
                              backgroundColor: "#000",
                            }}
                          />
                        </>
                      )}
                      {item?.combo.length > 1 && (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              left: "55%",
                              top: "50%",
                              height: "2px",
                              width: "15px",
                              backgroundColor: "#000",
                            }}
                          />
                        </>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "59%" }}>
                          {item?.combo.map((combo: any, idx: any) => (
                            <div
                              key={combo.combo_id || idx}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom:
                                  idx === item.combo.length - 1 ? "0" : "10px",
                                position: "relative",
                              }}
                            >
                              {idx === 0 &&
                                idx !== item.combo.length - 1 &&
                                item.combo.length > 1 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "91%",
                                      top: "18px",
                                      height: "2px",
                                      width: "15px",
                                      backgroundColor: "#000",
                                    }}
                                  />
                                )}
                              {idx === item.combo.length - 1 &&
                                item.combo.length > 1 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "91%",
                                      bottom: "10px",
                                      height: "2px",
                                      width: "15px",
                                      backgroundColor: "#000",
                                    }}
                                  />
                                )}
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "6px",
                                  padding: "8px 16px",
                                }}
                              >
                                <Text style={{ width: "57%" }}>
                                  {combo.service_name}
                                </Text>
                                <Text style={{ width: "20%" }}>
                                  {currencySymbol.CURRENCY_SYMBOL}
                                  {combo.service_price}
                                </Text>
                                <Text style={{ width: "20%" }}>
                                  {combo.max_available}
                                </Text>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40%",
                          }}
                        >
                          <Text
                            style={{
                              width: "35%",
                              textAlign: "center",
                              marginRight: "2px",
                            }}
                          >
                            {item.qty}
                          </Text>
                          <Text style={{ width: "15%", textAlign: "center" }}>
                            {currencySymbol.CURRENCY_SYMBOL}
                            {item.selling_price}
                          </Text>
                          <Text style={{ width: "25%", textAlign: "center" }}>
                            {item.rule}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        {/* product */}

        {packageProduct.length > 0 ? (
          <div
            style={{
              marginTop: "12px",
              padding: "16px 24px",
              border: ".5px solid #e0e7fe",
              borderRadius: "12px 12px 12px 12px",
              background: "hsla(0, 0%, 100%, .5)",
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                color: "#333",
                fontSize: "14px",
                lineHeight: "16px",
              }}
            >
              Product
            </Text>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "2px 10px",
                background: "hsla(0, 0%, 100%, .9)",
                borderRadius: "6px 6px 6px 6px",
                fontWeight: 700,
                border: ".5px solid #e0e7fe",
                color: "rgba(0, 0, 0, .3)",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "6px",
                  padding: "8px 16px",
                }}
              >
                <Text style={{ width: "80%", color: "rgba(0, 0, 0, .3)" }}>
                  Product name
                </Text>
                <Text style={{ width: "20%", color: "rgba(0, 0, 0, .3)" }}>
                  Price
                </Text>
                <Text style={{ width: "20%", color: "rgba(0, 0, 0, .3)" }}>
                  Max available
                </Text>
              </div>
              <div style={{ width: "10%" }} />
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, .3)",
                  }}
                >
                  Qty.
                </Text>
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, .3)",
                  }}
                >
                  Selling price
                </Text>
                <Text
                  style={{
                    width: "25%",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, .3)",
                  }}
                >
                  Rule
                </Text>
              </div>
            </div>
            {packageProduct.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "2px 10px",
                    background: "hsla(0, 0%, 100%, .9)",
                    borderRadius: "6px",
                    fontWeight: 400,
                    border: ".5px solid #e0e7fe",
                    color: "rgba(0, 0, 0, .3)",
                    fontSize: "12px",
                    position: "relative",
                  }}
                >
                  {item.type == "Single" && (
                    <div style={{ display: "flex", width: "100%" }}>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "6px",
                          padding: "8px 16px",
                        }}
                      >
                        <Text style={{ width: "80%" }}>
                          {item.product_name}
                        </Text>
                        <Text style={{ width: "20%" }}>
                          {currencySymbol.CURRENCY_SYMBOL}
                          {item.product_price}
                        </Text>
                        <Text style={{ width: "20%", textAlign: "center" }}>
                          {item.max_available}
                        </Text>
                      </div>
                      <div style={{ width: "10%" }} />
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ width: "25%", textAlign: "center" }}>
                          {item.qty}
                        </Text>
                        <Text style={{ width: "25%", textAlign: "center" }}>
                          {currencySymbol.CURRENCY_SYMBOL}
                          {item.selling_price}
                        </Text>
                        <Text style={{ width: "25%", textAlign: "center" }}>
                          {item.rule}
                        </Text>
                      </div>
                    </div>
                  )}

                  {item.type !== "Single" && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      {item?.combo.length > 1 && (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              left: "55%",
                              top: "18px",
                              bottom: "10px",
                              width: "2px",
                              backgroundColor: "#000",
                            }}
                          />
                        </>
                      )}
                      {item?.combo.length > 1 && (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              left: "55%",
                              top: "50%",
                              height: "2px",
                              width: "15px",
                              backgroundColor: "#000",
                            }}
                          />
                        </>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "59%" }}>
                          {item?.combo.map((combo: any, idx: any) => (
                            <div
                              key={combo.combo_id || idx}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom:
                                  idx === item.combo.length - 1 ? "0" : "10px",
                                position: "relative",
                              }}
                            >
                              {idx === 0 &&
                                idx !== item.combo.length - 1 &&
                                item.combo.length > 1 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "91%",
                                      top: "18px",
                                      height: "2px",
                                      width: "15px",
                                      backgroundColor: "#000",
                                    }}
                                  />
                                )}
                              {idx === item.combo.length - 1 &&
                                item.combo.length > 1 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "91%",
                                      bottom: "10px",
                                      height: "2px",
                                      width: "15px",
                                      backgroundColor: "#000",
                                    }}
                                  />
                                )}
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "6px",
                                  padding: "8px 16px",
                                }}
                              >
                                <Text style={{ width: "57%" }}>
                                  {combo.product_name}
                                </Text>
                                <Text style={{ width: "20%" }}>
                                  {currencySymbol.CURRENCY_SYMBOL}
                                  {combo.product_price}
                                </Text>
                                <Text style={{ width: "20%" }}>
                                  {combo.max_available}
                                </Text>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40%",
                          }}
                        >
                          <Text
                            style={{
                              width: "35%",
                              textAlign: "center",
                              marginRight: "2px",
                            }}
                          >
                            {item.qty}
                          </Text>
                          <Text style={{ width: "15%", textAlign: "center" }}>
                            {currencySymbol.CURRENCY_SYMBOL}
                            {item.selling_price}
                          </Text>
                          <Text style={{ width: "25%", textAlign: "center" }}>
                            {item.rule}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        <div
          style={{
            marginTop: "12px",
            padding: "16px 24px",
            border: ".5px solid #e0e7fe",
            borderRadius: "12px 12px 12px 12px",
            background: "hsla(0, 0%, 100%, .5)",
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              color: "#333",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Applicable to
          </Text>
          <div style={{ marginTop: "10px" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Text style={{ color: "#666" }}>Store:</Text>
              <Text style={{ color: "#333" }}>
                {packageDetails?.applicable_type == "All stores" ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    All Store
                  </span>
                ) : (
                  <></>
                )}

                {packageDetails?.applicable_type == "Current store only" ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    Current store only
                  </span>
                ) : (
                  <></>
                )}

                {packageDetails?.applicable_type == "By store" ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    By Item
                  </span>
                ) : (
                  <></>
                )}
              </Text>
            </div>
          </div>
        </div>
      </Content>
    </Content>
  );
}
