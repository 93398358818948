import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Segmented,
  Typography,
} from "antd";
import type { TabsProps } from "antd";
import { RightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import "./ModalStyles.css";
import Datatable from "../../Datatable";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { currencySymbol } from "../../../constants/currencySymbol";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const packagecardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_PackageCard.png`;
const packageCardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Package.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

interface CustomerPackageDetailsModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
}

interface GiftCardsData {
  key: any;
  id: string;
  name: string;
  code: string;
  giftType: string;
  ref: string;
  store: string;
  time: string;
  item: string;
  account: string;
  qutUsed: string;
  accountedAmount: string;
}

type Align = "Change History" | "Applicable Stores" | "Package Items";

const CustomerPackageDetailsModal: React.FC<
  CustomerPackageDetailsModalProps
> = ({ visible, selectedItem = null, onClose }) => {
  const { currencySymbol } = useCurrency();

  const columns = [
    {
      title: "Type",
      dataIndex: "giftType",
      key: "giftType",
      width: 150,
    },
    {
      title: "Ref#",
      dataIndex: "ref",
      key: "ref",
      width: 100,
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      width: 100,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 150,
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      width: 150,
    },
    {
      title: "Account",
      dataIndex: "account",
      key: "account",
      width: 150,
    },
    {
      title: "Qty. used",
      dataIndex: "qutUsed",
      key: "qutUsed",
      width: 150,
    },
    {
      title: "Accounted amount",
      dataIndex: "accountedAmount",
      key: "accountedAmount",
      width: 150,
    },
  ];

  const jsonVoucherData = {
    id: "1TzSTCpC",
    key: "1TzSTCpC",
    name: "giftcard new4",
    code: "GC-20241211039",
    giftType: "Card purchase",
    ref: "241212003",
    store: "Suilady",
    time: "12/12/2024 05:38pm",
    item: "PKG-2x1",
    account: "Purchase",
    qutUsed: "+1",
    accountedAmount: `${currencySymbol.CURRENCY_SYMBOL}1`,
  };

  const storeColumns = [
    {
      title: "Store code",
      dataIndex: "storeCode",
      key: "storeCode",
      width: 100,
    },
    {
      title: "Store",
      dataIndex: "storeName",
      key: "storeName",
      width: 100,
    },
  ];

  const packageItemColumn = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      width: 100,
    },
    {
      title: "Max available",
      dataIndex: "maxAvailable",
      key: "maxAvailable",
      width: 100,
    },
    {
      title: "Used",
      dataIndex: "used",
      key: "used",
      width: 100,
    },
    {
      title: "Purchase Sessions",
      dataIndex: "purchaseSession",
      key: "purchaseSession",
      width: 100,
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
      width: 100,
    },
    {
      title: "Remaining",
      dataIndex: "remaining",
      key: "remaining",
      width: 100,
    },
  ];

  interface StoreDataProps {
    key: any;
    id: string;
  }

  const jsonStoreData = {
    id: "1TzSTCpC",
    key: "1TzSTCpC",
    storeCode: "001",
    storeName: "Suilady",
  };

  const [toggleButtonValue, setToggleButtonValue] =
    useState<Align>("Package Items");
  const [dataSource, setDataSource] = useState<GiftCardsData[]>([
    jsonVoucherData,
  ]);
  const [storeDataSource, setStoreDataSource] = useState<StoreDataProps[]>([
    jsonStoreData,
  ]);

  return (
    <>
      <Modal
        visible={visible}
        title={"Package Details"}
        onCancel={onClose}
        footer={null}
        width="1000px"
        style={{
          top: "60px",
        }}
        bodyStyle={{
          height: "calc(100vh - 180px)",
          scrollbarWidth: "thin",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            // height: "calc(100vh - 300px)",
            overflowY: "auto",
          }}
        >
          {selectedItem && (
            <>
              <div
                style={{
                  color: "rgba(176, 87, 77, .8)",
                  position: "relative",
                  width: " 100%",
                  marginBottom: "16px",
                  backgroundImage: `url(${packagecardImage})`,
                  backgroundSize: "100% 100%",
                  transition: "all .2s",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
                // onClick={() => {
                //   handlePackageDetailModal(item);
                // }}
              >
                <div className="package_image-icon">
                  <div className="package_van-image">
                    <img
                      className="package_van-image__img"
                      src={packageCardImg}
                      alt="package img"
                    ></img>
                  </div>
                </div>
                <div className="package_view-bc">
                  <div className="package_view-info">
                    <div className="package_overflowText">
                      <span className="package_title">
                        {selectedItem?.name}
                      </span>
                      <span className="package_saleCardItem_text_code">
                        Code:{selectedItem?.code}
                      </span>
                    </div>
                    <div className="package_view-apply">
                      {selectedItem?.applicable_type == "All store" && (
                        <>
                          <span
                            className="package_view-cardApply"
                            // onClick={(e) => {
                            //   e.stopPropagation(); // Prevent parent div's click event
                            //   handleApplicableProductModel(item.id, "package");
                            // }}
                          >
                            <div className="package_bk-cardApply">
                              <span className="package_cardApply_text_applicable">
                                Applicable to:
                              </span>
                              {selectedItem?.applicable_to}
                            </div>
                          </span>
                        </>
                      )}
                      <span className="package_icon-gengduo">
                        <RightOutlined />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="package_view-bottom">
                  <div className="package_view-left">
                    <div className="package_bk-validDateText">
                      <span className="package_saleCardItem_text_valid">
                        Valid for:{" "}
                      </span>
                      <span className="package_bk-date">
                        {selectedItem?.validfor}
                      </span>
                    </div>
                    <div className=""></div>
                  </div>
                  <div className="package_view-right">
                    <div className="package_view-price">
                      <span className="package_saleCardItem_text_price">
                        Price
                      </span>
                      <span className="package_color-def">
                        {currencySymbol.CURRENCY_SYMBOL}
                        {selectedItem?.sellprice}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {!selectedItem && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={<span>No data available</span>}
              />
            </>
          )}
        </div>
        <div>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Purchase date: 12/12/2024</Col>
            <Col span={12}>Paid: {currencySymbol.CURRENCY_SYMBOL}1</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Purchase store: Suilady</Col>
            <Col span={12}>Purchase ref#: 241212003</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Debt: -</Col>
            <Col span={12}>Frozen: -</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Notes:</Col>
          </Row>
        </div>
        <div
          style={{
            borderBottom: "1px solid #e0e7fe",
            marginTop: "10px",
          }}
        />
        <div
          style={{
            marginTop: "15px",
          }}
        >
          <Segmented
            value={toggleButtonValue}
            style={{ marginBottom: 8 }}
            onChange={setToggleButtonValue}
            options={["Package Items", "Change History", "Applicable Stores"]}
          />
        </div>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {toggleButtonValue == "Package Items" ? (
            <>
              {/* -------- Header -------- */}
              <Row
                style={{
                  border: "1px solid #f0f0f0",
                  lineHeight: "20px",
                  padding: "8px 8px 8px 12px",
                  background: "#fff",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#666",
                }}
              >
                <Col span={4}>Items</Col>
                <Col span={4}>Max available</Col>
                <Col span={4}>Used</Col>
                <Col span={4}>Purchase Session</Col>
                <Col span={4}>Paid</Col>
                <Col span={4}>Remaining</Col>
              </Row>

              {/* -------- single item Body -------- */}
              <Row
                style={{
                  border: "1px solid #f0f0f0",
                  padding: "18px 8px 18px 12px",
                  marginTop: "10px",
                  lineHeight: "20px",
                  background: "#fff",
                  fontSize: "12px",
                  position: "relative",
                }}
              >
                <Col span={4}>
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: "-18px",
                      bottom: "-18px",
                      left: "-15px",
                      width: "25px",
                      background: "rgba(46, 86, 242, .2)",
                      borderRadius: "4px 0 0 4px",
                      zIndex: 100,
                      fontFamily: "PingFang SC",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#2e56f2",
                    }}
                  >
                    <span
                      style={{
                        transform: "rotate(-90deg)",
                        verticalAlign: "top",
                      }}
                    >
                      Product
                    </span>
                  </div>
                  <div
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    FaceWash
                  </div>
                </Col>
                <Col span={4}>1</Col>
                <Col span={4}>0</Col>
                <Col span={4}>1</Col>
                <Col span={4}>{currencySymbol.CURRENCY_SYMBOL}12</Col>
                <Col span={4}>1</Col>
              </Row>

              {/* -------- combo item Body -------- */}
              <Row
                style={{
                  border: "1px solid #f0f0f0",
                  padding: "18px 8px 18px 12px",
                  marginTop: "10px",
                  lineHeight: "20px",
                  background: "#fff",
                  fontSize: "12px",
                  position: "relative",
                  display: "flex",
                  alignItems: "stretch",
                }}
              >
                <Col span={4}>
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: "-18px",
                      bottom: "-18px",
                      left: "-15px",
                      width: "25px",
                      background: "rgba(46, 86, 242, .2)",
                      borderRadius: "4px 0 0 4px",
                      zIndex: 100,
                      fontFamily: "PingFang SC",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#2e56f2",
                    }}
                  >
                    <span
                      style={{
                        transform: "rotate(-90deg)",
                        verticalAlign: "top",
                      }}
                    >
                      Service
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "9px",
                      marginLeft: "20px",
                    }}
                  >
                    <Text>Service 001</Text>
                    <Text>Service 002</Text>
                  </div>
                </Col>
                <Col span={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "9px",
                    }}
                  >
                    <Text>1</Text>
                    <Text>1</Text>
                  </div>
                </Col>
                <Col span={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "9px",
                    }}
                  >
                    <Text>1</Text>
                    <Text>1</Text>
                  </div>
                </Col>
                <Col span={4}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0",
                    }}
                  >
                    <Text>1</Text>
                  </div>
                </Col>
                <Col span={4}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Text>{currencySymbol.CURRENCY_SYMBOL}12</Text>
                  </div>
                </Col>
                <Col span={4}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Text>1</Text>
                  </div>
                </Col>
              </Row>
            </>
          ) : toggleButtonValue == "Change History" ? (
            <>
              {/* Table  */}
              <Datatable<GiftCardsData>
                columns={columns}
                dataSource={dataSource}
                scroll={{ y: "calc(100vh - 280px)" }}
                showCheckbox={false}
                //   rowSelection={rowSelection}
                showPagination={false}
                dynamicTableHeight="auto"
              />
            </>
          ) : (
            <>
              <Datatable<StoreDataProps>
                columns={storeColumns}
                dataSource={storeDataSource}
                scroll={{ y: "calc(100vh - 280px)" }}
                showCheckbox={false}
                //   rowSelection={rowSelection}
                showPagination={false}
                dynamicTableHeight="auto"
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CustomerPackageDetailsModal;
