import { Button, Form, Input, Modal, Radio, Typography } from "antd";
import React, { useState } from "react";

const { Text, Title } = Typography;
const { TextArea } = Input;

interface AdjustmentModalProps {
  visible: boolean;
  onClose: () => void;
}

const AdjustmentModal: React.FC<AdjustmentModalProps> = ({
  visible,
  onClose,
}) => {
  const [adjustmentType, setAdjustmentType] = useState<string>("increase");
  const [quantity, setQuantity] = useState<string>("");
  const [reason, setReason] = useState<string>("");

  const handleSave = () => {
    console.log("Adjustment Type:", adjustmentType);
    console.log("Quantity:", quantity);
    console.log("Reason:", reason);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title={
        <Title level={4} style={{ margin: 0 }}>
          Adjustment
        </Title>
      }
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ paddingBottom: "0" }}
    >
      {/* Header with Gradient Background */}
      <div
        style={{
          background: "linear-gradient(90deg, #FFB74D, #FF5722)",
          color: "#fff",
          textAlign: "center",
          padding: "10px",
          borderRadius: "8px",
          marginBottom: "16px",
        }}
      >
        <Title level={5} style={{ color: "#fff", margin: 0 }}>
          Total points
        </Title>
        <Title level={2} style={{ color: "#fff", margin: 0 }}>
          0
        </Title>
      </div>

      <Form layout="vertical">
        {/* Adjustment Type (Increase or Decrease) */}
        <Form.Item
          label={<Text strong>Adjust</Text>}
          style={{ marginBottom: "10px" }}
        >
          <Radio.Group
            value={adjustmentType}
            onChange={(e) => setAdjustmentType(e.target.value)}
          >
            <Radio value="increase">Increase</Radio>
            <Radio value="decrease">Decrease</Radio>
          </Radio.Group>
        </Form.Item>

        {/* Quantity Input */}
        <Form.Item
          label={<Text>Qty.</Text>}
          required
          style={{ marginBottom: "10px" }}
        >
          <Input
            type="number"
            min={0}
            placeholder="Please enter"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </Form.Item>

        {/* Reason Text Area */}
        <Form.Item label={<Text>Reason</Text>} style={{ marginBottom: "10px" }}>
          <TextArea
            placeholder="Please enter"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            autoSize={{ minRows: 3 }}
          />
        </Form.Item>
      </Form>

      {/* Save Button */}
      <div style={{ textAlign: "right" }}>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default AdjustmentModal;
