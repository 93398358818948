import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Row, Col, message } from "antd";
import Header from "../headerFooter/Header";
import { get, post } from "../../services/Apiclient";
import CryptoJS from "crypto-js"; // Importing CryptoJS for encryption
import { useNavigate, useParams } from "react-router-dom";

const { Text } = Typography;

const layoutStyles: React.CSSProperties = {
  minHeight: "100vh",
  backgroundColor: "#fff",
  padding: "80px 16px 16px 16px",
};

const logoStyle: React.CSSProperties = {
  display: "block",
  margin: "0 auto 16px",
  width: "80px",
  height: "80px",
};

const formItemStyle: React.CSSProperties = {
  marginBottom: "16px",
};

const signInButtonStyle: React.CSSProperties = {
  backgroundColor: "#FFA500",
  color: "#fff",
  border: "none",
  height: "48px",
  fontSize: "16px",
  width: "100%",
  borderRadius: "8px",
};

const linkStyle: React.CSSProperties = {
  color: "#FFA500",
  textDecoration: "underline",
};

const AppSignIn: React.FC = () => {
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const [logoUrl, setLogoUrl] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });

  useEffect(() => {
    // Scroll to the top of the page with smooth behavior on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
    getLogo();
  }, []);

  // ----------- fetch merchant logo -------------
  const getLogo = async () => {
    try {
      const response = await get(
        `/customerprofilemob/mobile/merchantlogo/${merchantId}`
      );
      const data = response?.data;
      if (data?.success) {
        setLogoUrl(data?.data?.storeimageurl);
      }
    } catch (error) {
      console.error("Error while fetching logo:", error);
    }
  };

  const validateEmail = (email: any) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePassword = (password: any) => password.length >= 6;

  const encryptPassword = (password: any) => {
    const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  const signIn = async () => {
    try {
      const encryptedPassword = encryptPassword(password);
      // console.log("encryptedPassword..", encryptedPassword);

      const response = await post("/customerprofilemob/login", {
        email,
        password: encryptedPassword,
      });
      // console.log("response...", response);
      const data = response?.data;
      if (data?.success) {
        localStorage.setItem("customer_id", data.data?.customer_id);
        localStorage.setItem("outlet_id", data.data?.outlet_id);
        navigate(`/app/profile/${merchantId}`);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      console.error("Error while sign in:", error);
      message.error("An error occurred during sign-in.");
    }
  };
  const handleSignIn = async () => {
    // console.log("Form Submitted: ", values);
    const emailError = validateEmail(email)
      ? ""
      : "Please enter a valid email.";
    const passwordError = validatePassword(password)
      ? ""
      : "Password must be at least 6 characters long.";

    setErrors({ email: emailError, password: passwordError });

    if (!emailError && !passwordError) {
      signIn();
    }
  };

  const handleForgotpass = async () => {
    const emailError = validateEmail(email)
      ? ""
      : "Please enter a valid email.";
    setErrors((pre) => ({
      ...pre,
      email: emailError,
    }));
    if (emailError) {
      return;
    }
    try {
      const url = `/customerprofilemob/mobile/customer/check`;
      const customerCheck = await post(url, {
        email: email,
      });
      if (customerCheck?.data.success) {
        const otp = {
          email: customerCheck?.data?.data?.email,
        };
        const otpResponse = await post("/customerprofilemob/sendotp", otp);
        // console.log("otpResponse...", otpResponse);
        if (otpResponse?.data.success) {
          navigate(
            `/app/otp-screen/${merchantId}/${otp.email}/Email/forgot-password`
          );
        } else {
          message.error(otpResponse?.data?.message);
        }
      } else {
        message.error(customerCheck?.data?.message);
      }
    } catch (error) {
      console.error("Error while check customer email:", error);
    }
  };
  return (
    <>
      <Header
        title="Sign in to continue"
        onBack={() => navigate(`/app/merchant/details/${merchantId}`)}
        backgroundColor={"#fff"}
      />
      <div style={layoutStyles}>
        {/* Logo */}
        <img src={logoUrl} alt="Logo" style={logoStyle} />
        {/* Form */}
        <Form
          layout="vertical"
          onFinish={handleSignIn}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          {/* Email or Phone */}
          <Form.Item
            name="emailOrPhone"
            validateStatus={errors.email ? "error" : ""}
            help={errors.email}
            required
            style={formItemStyle}
          >
            <Input
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoComplete="off"
            />
          </Form.Item>
          {/* Password */}
          <Form.Item
            name="password"
            validateStatus={errors.password ? "error" : ""}
            help={errors.password}
            required
            style={formItemStyle}
          >
            <Input.Password
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />
          </Form.Item>
          {/* Forgot Password */}
          <div style={{ textAlign: "right", marginBottom: "16px" }}>
            {/* <a href="/app/forgot-password" style={linkStyle}>
              Forgot password?
            </a> */}
            <span
              style={{ ...linkStyle, cursor: "pointer" }}
              onClick={handleForgotpass}
            >
              Forgot password?
            </span>
          </div>
          {/* Sign In Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit" style={signInButtonStyle}>
              Sign In
            </Button>
          </Form.Item>
          {/* Sign Up Link */}
          <div style={{ textAlign: "center", marginTop: "16px" }}>
            <Text>Don't have an account? </Text>
            <a href={`/app/signUp/${merchantId}`} style={linkStyle}>
              Sign up now
            </a>
          </div>
        </Form>
      </div>
    </>
  );
};
export default AppSignIn;
