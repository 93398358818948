import { Col, Modal, Row, Empty } from "antd";
import React from "react";
import "../billing/ModalStyles.css";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { currencySymbol } from "../../../constants/currencySymbol";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

interface CustomerDebtModalProps {
  visible: boolean;
  // selectedItem: any;
  onClose: () => void;
  // onSelect?: (selectedObject: any) => void;
  customerDebtData?: any;
}

const CustomerDebtModal: React.FC<CustomerDebtModalProps> = ({
  visible,
  // selectedItem = null,
  onClose,
  // onSelect,
  customerDebtData,
}) => {
  const { currencySymbol } = useCurrency();
  return (
    <>
      <Modal
        visible={visible}
        title={"Debt Details"}
        onCancel={onClose}
        footer={null}
        width="900px"
        style={{
          top: "60px",
        }}
        bodyStyle={{
          height: "calc(100vh - 280px)",
          scrollbarWidth: "thin",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            height: "calc(100vh - 300px)",
            overflowY: "auto",
          }}
        >
          {customerDebtData && customerDebtData.length > 0 && (
            <div
              style={{
                color: "#000",
                position: "relative",
                cursor: "pointer",
                // height: "110px",
                marginBottom: "16px",
                transition: "all .2s",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Row gutter={24} style={{ padding: "0px 24px 0 16px" }}>
                <Col span={8}>
                  <span>Date</span>
                </Col>
                <Col span={8}>
                  <span>Ref#</span>
                </Col>
                <Col span={8}>
                  <span>Due Amount</span>
                </Col>
              </Row>
            </div>
          )}
          {customerDebtData &&
            customerDebtData.length > 0 &&
            customerDebtData.map((item: any, index: any) => (
              <>
                <div
                  key={index}
                  style={{
                    color: "#588c66",
                    border: "2px solid #588c66",
                    position: "relative",
                    cursor: "pointer",
                    // height: "110px",
                    marginBottom: "16px",
                    background: "rgba(204,222,200,255)",
                    transition: "all .2s",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <Row gutter={24} style={{ padding: "0px 24px 0 16px" }}>
                    <Col span={8}>
                      <span>{item?.order_date}</span>
                    </Col>
                    <Col span={8}>
                      <span>{item?.ref_no}</span>
                    </Col>
                    <Col span={8}>
                      <span>
                        {currencySymbol.CURRENCY_SYMBOL} {item?.item_price}
                      </span>
                    </Col>
                  </Row>
                </div>
              </>
            ))}

          {customerDebtData && customerDebtData.length == 0 && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={<span>No debt data</span>}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CustomerDebtModal;
