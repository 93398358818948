import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import {
  ArrowLeftOutlined,
  PrinterOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import "./SalesReports.css";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { get } from "../../../services/Apiclient";
// import { currencySymbol } from "../../../constants/currencySymbol";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

export default function AccountingReport() {
  const { currencySymbol } = useCurrency();
  const navigate = useNavigate();
  const today: [Dayjs, Dayjs] = [dayjs().startOf("day"), dayjs().endOf("day")];
  const [outletData, setOutletData] = useState<any[]>([]);
  const [outletCode, setoutletCode] = useState<any>(
    localStorage.getItem("outlet_id") ? localStorage.getItem("outlet_id") : null
  );
  const [allDepartmentData, setAllDepartmentData] = useState([]);
  const [reportDetails, setReportDetails] = useState<any>("");
  const [turnoverDetails, setTurnoverDetails] = useState<any[]>([]);
  const [sheetType, setSheetType] = useState("receipt");
  const [foldExpand, setFoldExpand] = useState("expand");
  const [checkBoxSelect, setCheckBoxSelect] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(today);
  const [startDate, setStartDate] = useState<string | null>(
    today[0].format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string | null>(
    today[1].format("YYYY-MM-DD")
  );

  useEffect(() => {
    getReportDetails();
  }, [outletCode, checkBoxSelect, startDate, endDate]);

  useEffect(() => {
    getOutletDropdown();
  }, []);

  useEffect(() => {
    getDepartmentDropdown();
  }, [outletCode]);

  const getReportDetails = async () => {
    // console.log("Start Date:", startDate);
    // console.log("End Date:", endDate);
    try {
      const reportUrl = `/report/accounting/records?outletfield=9n9MSFUr&startDate=${startDate}&endDate=${endDate}&allstore=false`;
      const response = await get(reportUrl);
      if (response.data.success) {
        const report = response.data.data;
        setReportDetails(report);
        setTurnoverDetails(report.turnover);
      }
    } catch (error) {
      console.log("Error while getting report data", error);
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getDepartmentDropdown = async () => {
    try {
      let url = `/department/dropdown/${outletCode})}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setAllDepartmentData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handleOutletChange = (value: string) => {
    setoutletCode(value);
  };

  const handleSheetTypeChange = (value: any) => {
    setSheetType(value);
  };
  const toggleFoldExpand = () => {
    setFoldExpand((prevState) => (prevState === "expand" ? "fold" : "expand"));
  };
  const handleCheckboxChange = (e: any) => {
    setCheckBoxSelect(e.target.checked);
  };

  const handleDateChange = (dates: any) => {
    if (dates) {
      const formattedStartDate = dates[0].format("YYYY-MM-DD");
      const formattedEndDate = dates[1].format("YYYY-MM-DD");

      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      setDateRange(dates);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleDepartmentChange = (value: any) => {
    // console.log("value...", value);
  };

  return (
    <>
      <Content className="mainContainer">
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => navigate("/inventory")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
                />
              </div>
              <p
                style={{
                  margin: 0,
                  fontFamily:
                    "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC",
                  fontSize: "18px",
                  fontWeight: 700,
                }}
                className="Accounting_header_title"
              >
                Accounting Record Sheet
              </p>
            </div>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <Select placeholder="Export as" style={{ width: "100%" }}>
                <Option value="null">Excel</Option>
                <Option value="Walk-in">PDF</Option>
              </Select>
              <Button
                icon={<PrinterOutlined />}
                className="Accounting_print_button"
              >
                Print
              </Button>
            </div>
          </Col>
        </Row>

        {/* Content Section */}
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          {/* Filters */}
          <div
            style={{
              position: "relative",
            }}
          >
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              <Col>
                <Select
                  value={outletCode}
                  onChange={(value) => handleOutletChange(value)}
                  placeholder="Store List"
                  style={{ width: "198px", height: "40px !important" }}
                >
                  {outletData.length > 0 &&
                    outletData.map((outdata: any) => (
                      <Option value={outdata.id} key={outdata.id}>
                        {outdata.name}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col
                span={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Checkbox
                  checked={checkBoxSelect}
                  onChange={handleCheckboxChange}
                >
                  Including sub stores
                </Checkbox>
              </Col>
              <Col>
                {/* Date Picker Row */}
                <RangePicker
                  style={{ width: "248px", height: "42px" }}
                  value={dateRange}
                  onChange={handleDateChange}
                  ranges={{
                    Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                    Yesterday: [
                      dayjs().subtract(1, "day").startOf("day"),
                      dayjs().subtract(1, "day").endOf("day"),
                    ],
                    "This Week": [
                      dayjs().startOf("week"),
                      dayjs().endOf("week"),
                    ],
                    "This Month": [
                      dayjs().startOf("month"),
                      dayjs().endOf("month"),
                    ],
                    "Last Month": [
                      dayjs().subtract(1, "month").startOf("month"),
                      dayjs().subtract(1, "month").endOf("month"),
                    ],
                  }}
                />
              </Col>
              <Col>
                <Select
                  placeholder="Select"
                  style={{ width: "198px", height: "40px !important" }}
                  value={sheetType}
                  onChange={handleSheetTypeChange}
                  disabled
                >
                  <Option value="receipt">Receipt</Option>
                  <Option value="summarySheet">Summary sheet</Option>
                </Select>
              </Col>
            </Row>
            {foldExpand == "expand" && (
              <Row>
                <Col span={10}>
                  <Select
                    placeholder="All department"
                    style={{ width: "400px", height: "40px !important" }}
                    disabled={sheetType == "receipt"}
                    // value={all}
                    onChange={(value) => handleDepartmentChange(value)}
                  >
                    {allDepartmentData.length > 0 &&
                      allDepartmentData.map((unit: any) => (
                        <Option key={unit.id} value={unit.id}>
                          {unit.departmenttype}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            )}
            <div
              style={{
                top: foldExpand == "fold" ? "10px" : "45px",
                display: "flex",
                gap: "10px",
                position: "absolute",
                right: "0px",
              }}
            >
              <Button
                color="primary"
                variant="solid"
                className="Accounting_run_button"
              >
                Run
              </Button>
              <Button
                icon={
                  foldExpand === "expand" ? (
                    <DoubleRightOutlined
                      style={{
                        transform: "rotate(85deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  ) : (
                    <DoubleRightOutlined
                      style={{
                        transform: "rotate(270deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  )
                }
                className="Accounting_fold-expand_button"
                onClick={toggleFoldExpand}
              >
                {foldExpand == "fold" ? "Expand" : "Fold"}
              </Button>
            </div>
          </div>
          <Row justify="center">
            <Col span={6}>
              <div className="Accounting_receipt_card">
                <p className="Accounting_title">Account Slip</p>
                <p
                  style={{
                    fontWeight: 700,
                    fontFamily:
                      "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC",
                  }}
                >
                  (Suilady)
                </p>
                {/* <Row> */}
                <Col
                  style={{
                    fontSize: "14px",
                  }}
                >
                  <span className="Accounting_sub_title">Date range:</span>{" "}
                  {reportDetails?.date_range}
                </Col>
                {/* </Row> */}
                <Row>
                  <Col
                    flex={2}
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    <span className="Accounting_sub_title">Printer:</span>{" "}
                    {reportDetails?.printer}
                  </Col>
                  <Col flex={2}>
                    <span className="Accounting_sub_title">Time printed:</span>{" "}
                    07:36:34
                  </Col>
                </Row>
                <div className="Accounting_divider"></div>
                <Row>
                  <Col>
                    <span className="Accounting_sub_title">Department:</span>{" "}
                    {reportDetails?.deparment}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="Accounting_sub_title">Business days:</span>{" "}
                    1
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="Accounting_sub_title">
                      Number of customers:
                    </span>{" "}
                    {reportDetails?.customer_count}
                  </Col>
                </Row>
                <div className="Accounting_divider"></div>
                {/* <Row>
                  <Col
                    span={12}
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Collected turnover:
                  </Col>
                  <Col span={12}>${reportDetails?.collected_turnover}</Col>
                </Row> */}
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px 0",
                  }}
                >
                  Collected turnover:{" "}
                  <span>{currencySymbol.CURRENCY_SYMBOL}{reportDetails?.collected_turnover || "0"}</span>
                </div>
                <hr className="Accounting_hr" />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px 0",
                  }}
                >
                  Turnover:{" "}
                  <span>{currencySymbol.CURRENCY_SYMBOL}{reportDetails?.collected_turnover || "0"}</span>
                </div>

                {/* Products */}
                {turnoverDetails?.length > 0 &&
                  turnoverDetails.map((item) => (
                    <>
                      <div
                        style={{
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px 0",
                            color: "#999",
                          }}
                        >
                          <span>Products</span>
                          <span>{currencySymbol.CURRENCY_SYMBOL}{item?.products || "0"}</span>
                        </div>
                        {/* Services */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px 0",
                            color: "#999",
                          }}
                        >
                          <span>Services</span>
                          <span>{currencySymbol.CURRENCY_SYMBOL}{item?.service || "0"}</span>
                        </div>
                        {/* Privileges */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px 0",
                            color: "#999",
                          }}
                        >
                          <span>Privileges</span>
                          <span>{currencySymbol.CURRENCY_SYMBOL}1</span>
                        </div>
                        {/* Privileges Details */}
                        {item.privileges?.length > 0 &&
                          item.privileges.map((data: any) => (
                            <>
                              <div
                                style={{
                                  marginLeft: "20px",
                                  color: "#999",
                                  marginTop: "10px",
                                  backgroundColor: "#f6f7fa",
                                  padding: "6px 10px",
                                  borderRadius: "6px",
                                }}
                              >
                                {/* Prepaid Membership */}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "5px 0",
                                    color: "#666",
                                  }}
                                >
                                  <span>Prepaid membership</span>
                                  <span>
                                    {currencySymbol.CURRENCY_SYMBOL}{data?.prepaid_membership || "0"}
                                  </span>
                                </div>

                                {/* Visit-Based Membership */}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "5px 0",
                                    color: "#666",
                                  }}
                                >
                                  <span>Visit-based membership</span>
                                  <span>
                                    {currencySymbol.CURRENCY_SYMBOL}{data?.visit_based_membership || "0"}
                                  </span>
                                </div>

                                {/* Discount Membership */}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "5px 0",
                                    color: "#666",
                                  }}
                                >
                                  <span>Discount membership</span>
                                  <span>
                                    {currencySymbol.CURRENCY_SYMBOL}{data?.discount_membership || "0"}
                                  </span>
                                </div>

                                {/* Gift Card */}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "5px 0",
                                    color: "#666",
                                  }}
                                >
                                  <span>Gift card</span>
                                  <span>{currencySymbol.CURRENCY_SYMBOL}{data?.gift_card || "0"}</span>
                                </div>

                                {/* Packages */}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "5px 0",
                                    color: "#666",
                                  }}
                                >
                                  <span>Packages</span>
                                  <span>{currencySymbol.CURRENCY_SYMBOL}{data?.packages || "0"}</span>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </>
                  ))}
                <hr className="Accounting_hr" />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 0",
                    borderBottom: "1px solid #e0e7fe",
                  }}
                >
                  Other income{" "}
                  <span>{currencySymbol.CURRENCY_SYMBOL}{reportDetails?.collected_turnover || "0"}</span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 0",
                    borderBottom: "1px solid #e0e7fe",
                  }}
                >
                  Expenses{" "}
                  <span>{currencySymbol.CURRENCY_SYMBOL}{reportDetails?.collected_turnover || "0"}</span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 0",
                    borderBottom: "1px solid #e0e7fe",
                  }}
                >
                  Taxes <span>{currencySymbol.CURRENCY_SYMBOL}{reportDetails?.collected_turnover || "0"}</span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 0",
                    borderBottom: "1px solid #e0e7fe",
                  }}
                >
                  Service Charge{" "}
                  <span>{currencySymbol.CURRENCY_SYMBOL}{reportDetails?.collected_turnover || "0"}</span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 0",
                    borderBottom: "1px solid #e0e7fe",
                  }}
                >
                  Total Revenue{" "}
                  <span>{currencySymbol.CURRENCY_SYMBOL}{reportDetails?.collected_turnover || "0"}</span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 0",
                    borderBottom: "1px solid #e0e7fe",
                  }}
                >
                  Collected revenue{" "}
                  <span>{currencySymbol.CURRENCY_SYMBOL}{reportDetails?.collected_turnover || "0"}</span>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
      </Content>
    </>
  );
}
