import {
  Card,
  Col,
  Collapse,
  Divider,
  Empty,
  Row,
  Tabs,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
import Header from "../headerFooter/Header";
const { Panel } = Collapse;
const { Title, Text } = Typography;

const cardStyle: React.CSSProperties = {
  borderRadius: "12px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  //   padding: "16px",
};
const sectionStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 0",
};
const labelStyle: React.CSSProperties = {
  color: "#888",
};
const usageRowStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#FFA500",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
  padding: "8px 16px",
  color: "#fff",
  marginBottom: "8px",
};
const usageDetailStyle: React.CSSProperties = {
  padding: "8px 16px",
};

// interface DetailItem {
//   id: string;
//   name: string;
// }

// interface GiftCardDetail {
//   item_name: string;
//   item_validfor: string;
//   item_price: string;
//   item_qty: string;
//   item_singleqty: string;
//   item_value: string;
//   giftcard_code: string;
//   service_category_details: DetailItem[];
//   product_category_details: DetailItem[];
//   product_item_details: DetailItem[];
//   service_item_details: DetailItem[];
//   privilege_item_details: DetailItem[];
//   applicable_to: string;
// }

// interface GiftCardHistoryDetail {
//   item_name: string;
//   item_id: string;
//   item_price: string;
//   bill_id: string;
//   ref_no: string;
//   created_at: string;
// }

const GiftCardDetails: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("details");
  const [giftCardDetails, setGiftCardDetails] = useState<any | null>(null);
  const [giftCardHistoryDetails, setGiftCardHistoryDetails] = useState<
    any[] | null
  >(null);
  const { id, merchantId } = useParams();
  const customerId = localStorage.getItem("customer_id");
  const outletId = localStorage.getItem("outlet_id");
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top of the page with smooth behavior on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
    getGiftCardDetails();
    getGiftCardHistoryDetails();
  }, []);

  const getGiftCardDetails = async () => {
    try {
      const url = `/merchantandoutletmob/mobile/giftcarddetails/${id}/${customerId}/${outletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setGiftCardDetails(data.data);
      }
    } catch (error) {
      console.error("Error while getting giftcard details:", error);
    }
  };

  const getGiftCardHistoryDetails = async () => {
    try {
      const url = `/merchantandoutletmob/mobile/giftcardhistory/${id}/${customerId}/${outletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setGiftCardHistoryDetails(data.data);
      }
    } catch (error) {
      console.error("Error while getting giftcard history details:", error);
    }
  };

  const renderDetails = (title: string, data: any[]) => (
    <Collapse.Panel header={title} key={title}>
      {data.length > 0 ? (
        data.map((item) => (
          <div key={item.id} style={sectionStyle}>
            <Text>{item.name}</Text>
            <Text>Available</Text>
          </div>
        ))
      ) : (
        <Text type="secondary">No data available</Text>
      )}
    </Collapse.Panel>
  );

  const renderHistoryItem = (history: any) => (
    <div key={history.ref_no} style={cardStyle}>
      <div style={usageRowStyle}>
        <Text>Ref#: {history.ref_no}</Text>
        <Text>{history.created_at}</Text>
      </div>
      <div style={usageDetailStyle}>
        <Text>{history.item_name}</Text>
        <Text style={{ float: "right", fontWeight: "bold" }}>
          -RM{history.item_price}
        </Text>
      </div>
    </div>
  );

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
      {/* Header */}
      <Header
        title="Gift Cards"
        onBack={() => navigate(`/app/giftcard/list/${merchantId}`)}
        backgroundColor={"#fff"}
      />
      {/* Tabs */}
      <div style={{ padding: "16px", paddingTop: "50px" }}>
        <Tabs
          defaultActiveKey="details"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
        >
          {/* Details Tab */}
          {giftCardDetails && (
            <Tabs.TabPane tab="Details" key="details">
              <Card style={cardStyle}>
                <Row style={{ marginBottom: "16px" }}>
                  <Col>
                    <Title level={5} style={{ margin: 0 }}>
                      {giftCardDetails.item_name}
                    </Title>
                    <Text type="secondary">
                      {giftCardDetails.giftcard_code}
                    </Text>
                  </Col>
                </Row>
                <Divider style={{ margin: "8px 0" }} />
                <div style={sectionStyle}>
                  <Text style={labelStyle}>Price</Text>
                  <Text>RM{giftCardDetails.item_price}</Text>
                </div>
                <div style={sectionStyle}>
                  <Text style={labelStyle}>Value</Text>
                  <Text>{giftCardDetails.item_value}</Text>
                </div>
                <div style={sectionStyle}>
                  <Text style={labelStyle}>Single use qty.</Text>
                  <Text>{giftCardDetails.item_singleqty}</Text>
                </div>
                <div style={sectionStyle}>
                  <Text style={labelStyle}>Validity</Text>
                  <Text>{giftCardDetails.item_validfor}</Text>
                </div>
                <div style={{ marginTop: "16px" }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginBottom: 10,
                      display: "block",
                    }}
                  >
                    Applicable to
                  </Text>
                  {/* <Collapse expandIconPosition="right" style={{ border: 0 }}>
                    {renderDetails(
                      "Products",
                      giftCardDetails.product_item_details
                    )}
                    {renderDetails(
                      "Services",
                      giftCardDetails.service_item_details
                    )}
                    {renderDetails(
                      "Privileges",
                      giftCardDetails.privilege_item_details
                    )}
                    {renderDetails(
                      "Product Categories",
                      giftCardDetails.product_category_details
                    )}
                    {renderDetails(
                      "Service Categories",
                      giftCardDetails.service_category_details
                    )}
                  </Collapse> */}

                  {/* product */}
                  {giftCardDetails?.allproduct_applicable ? (
                    <Row
                      justify="space-between"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Text>Products</Text>
                      <Text>All products</Text>
                    </Row>
                  ) : giftCardDetails?.product_item_details &&
                    giftCardDetails?.product_item_details.length > 0 ? (
                    <Row
                      justify="space-between"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Col>
                        <Text>Product</Text>
                      </Col>
                      <Col>
                        <Text>By items</Text>
                        {giftCardDetails.product_item_details.map(
                          (item: any) => (
                            <Row key={item.name}>
                              <Col>{item.name}</Col>
                            </Row>
                          )
                        )}
                      </Col>
                    </Row>
                  ) : giftCardDetails?.product_category_details &&
                    giftCardDetails.product_category_details.length > 0 ? (
                    <Row
                      justify="space-between"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Col>
                        <Text>Product</Text>
                      </Col>
                      <Col>
                        <Text>By category</Text>
                        {giftCardDetails.product_category_details.map(
                          (item: any) => (
                            <Row key={item.name}>
                              <Col>{item.name}</Col>
                            </Row>
                          )
                        )}
                      </Col>
                    </Row>
                  ) : giftCardDetails?.productnot_applicable ? (
                    <Row
                      justify="space-between"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Text>Products</Text>
                      <Text>Not applicable</Text>
                    </Row>
                  ) : null}

                  {/* service  */}
                  {giftCardDetails?.allservice_applicable ? (
                    <Row
                      justify="space-between"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Text>Service</Text>
                      <Text>All services</Text>
                    </Row>
                  ) : giftCardDetails?.service_item_details &&
                    giftCardDetails?.service_item_details?.length > 0 ? (
                    <>
                      <Row
                        justify="space-between"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "lightgray",
                          marginTop: "5px",
                          borderRadius: "6px",
                        }}
                      >
                        <Col>
                          <Text>Service</Text>
                        </Col>
                        <Col>
                          <Text>By items</Text>
                          {giftCardDetails?.service_item_details.map(
                            (item: any) => (
                              <>
                                <Row>
                                  <Col>{item.name}</Col>
                                </Row>
                              </>
                            )
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : giftCardDetails?.service_category_details &&
                    giftCardDetails?.service_category_details?.length > 0 ? (
                    <>
                      <Row
                        justify="space-between"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "lightgray",
                          marginTop: "5px",
                          borderRadius: "6px",
                        }}
                      >
                        <Col>
                          <Text>Service</Text>
                        </Col>
                        <Col>
                          <Text>By category</Text>
                          {giftCardDetails?.service_category_details.map(
                            (item: any) => (
                              <>
                                <Row>
                                  <Col>{item.name}</Col>
                                </Row>
                              </>
                            )
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : giftCardDetails?.servicenot_applicable ? (
                    <Row
                      justify="space-between"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Text>Service</Text>
                      <Text>Not applicable</Text>
                    </Row>
                  ) : null}

                  {/* privilege */}
                  {giftCardDetails?.allprivilege_applicable ? (
                    <Row
                      justify="space-between"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Text>Privilege</Text>
                      <Text>All privileges</Text>
                    </Row>
                  ) : giftCardDetails?.privilegeitem_applicable ? (
                    <>
                      <Row
                        justify="space-between"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "lightgray",
                          marginTop: "5px",
                          borderRadius: "6px",
                        }}
                      >
                        <Col>
                          <Text>Privilege</Text>
                        </Col>
                        <Col>
                          <Text>By items</Text>
                        </Col>
                      </Row>
                    </>
                  ) : giftCardDetails?.privilegenot_applicable ? (
                    <>
                      <Row
                        justify="space-between"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "lightgray",
                          marginTop: "5px",
                          borderRadius: "6px",
                        }}
                      >
                        <Col>
                          <Text>Privilege</Text>
                        </Col>
                        <Col>
                          <Text>Not applicable</Text>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  {/* store */}
                  {giftCardDetails?.allstore_applicable ? (
                    <Row
                      justify="space-between"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Text>Store</Text>
                      <Text>All stores</Text>
                    </Row>
                  ) : giftCardDetails?.currentstore_applicable ? (
                    <>
                      <Row
                        justify="space-between"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "lightgray",
                          marginTop: "5px",
                          borderRadius: "6px",
                        }}
                      >
                        <Col>
                          <Text>Store</Text>
                        </Col>
                        <Col>
                          <Text>Current store only</Text>
                        </Col>
                      </Row>
                    </>
                  ) : giftCardDetails?.bystore_applicable ? (
                    <>
                      <Row
                        justify="space-between"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "lightgray",
                          marginTop: "5px",
                          borderRadius: "6px",
                        }}
                      >
                        <Col>
                          <Text>Store</Text>
                        </Col>
                        <Col>
                          <Text>By store</Text>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </div>
              </Card>
            </Tabs.TabPane>
          )}
          {/* Usage History Tab */}
          {giftCardHistoryDetails && (
            <Tabs.TabPane tab="Usage History" key="usage-history">
              {giftCardHistoryDetails.length > 0 ? (
                giftCardHistoryDetails.map((history) =>
                  renderHistoryItem(history)
                )
              ) : (
                <Empty description="No usage history available." />
              )}
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};
export default GiftCardDetails;
