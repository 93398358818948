// import {
//   InfoCircleOutlined,
//   PlusOutlined,
//   CloseOutlined,
// } from "@ant-design/icons";
// import {
//   Button,
//   Col,
//   Form,
//   Input,
//   message,
//   Modal,
//   Radio,
//   Row,
//   Select,
//   Switch,
//   Tag,
//   Tooltip,
// } from "antd";
// import { useState } from "react";
// import * as Yup from "yup";
// import { post } from "../../services/Apiclient";

// interface AddCustomerTagProps {
//   visible: boolean;
//   onClose: () => void;
//   onSelect: (selectedObject: any) => void;
// }

// interface FormValues {
//   customer_tag_name: string;
//   color_code: string;
//   note: string;
//   auto_usage_status: boolean;
//   show_profile: boolean;
//   subtags: {
//     tag_name: string;
//     subtagConditions: {
//       customer_condtion_type: string;
//       customer_age_type: string;
//       customer_condition_tag_count: string;
//       customer_condition_value_count: string;
//     }[];
//   }[]; // Sub-tags array
//   status?: boolean; // Added status to FormValues
// }

// const initialFormValues: FormValues = {
//   customer_tag_name: "",
//   color_code: "#26C0E2",
//   note: "",
//   auto_usage_status: true,
//   show_profile: true,
//   subtags: [],
// };

// const colors = [
//   "#26C0E2",
//   "#605BFF",
//   "#FF6A77",
//   "#1BAC7D",
//   "#FFCC00",
//   "#118098",
//   "#AD5BFF",
//   "#FF6D1B",
//   "#65D268",
//   "#EC85D5",
// ];

// const initialAddFormValues = {
//   tag_name: "",
// };

// const initialAddFromConditionValues = {
//   customer_condtion_type: "",
//   customer_age_type: "",
//   customer_condition_tag_count: "",
//   customer_condition_value_count: "",
// };

// const AddCustomerTag: React.FC<AddCustomerTagProps> = ({
//   visible,
//   onClose,
//   onSelect,
// }) => {
//   const [formValues, setFormValues] = useState(initialFormValues);
//   const [selectedColor, setSelectedColor] = useState("#26C0E2");
//   const [addTagModalVisible, setAddTagModalVisible] = useState(false);
//   const [addTagConditonModalVisible, setAddTagConditionModalVisible] = useState(
//     initialAddFromConditionValues
//   );
//   const [tagGenerationMethod, setTagGenerationMethod] =
//     useState<string>("manual");
//   const [editingRecord, setEditingRecord] = useState<any>(null);
//   const [addFormValues, setAddFormValues] = useState(initialAddFormValues);
//   const [tags, setTags] = useState<string[]>([]);

//   const handleSaveData = async () => {
//     const dataToSubmit = {
//       ...formValues,
//       tag_generation_method: tagGenerationMethod,
//     };

//     console.log("Submitting data:", dataToSubmit); // Debug payload
//     onSelect(dataToSubmit); // Submit to parent or API
//   };

//   const handleAddSubtagWithConditions = (newSubtag: string) => {
//     const newSubtagData = {
//       tag_name: newSubtag,
//       subcondition: [] as any[], // Initialize with an empty array of conditions
//     };
//     setFormValues((prev: any) => ({
//       ...prev,
//       subtags: [...prev.subtags, newSubtagData],
//     }));
//   };

//   const handleInputChange = (name: any, value: any) => {
//     setFormValues((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handelShowTagAddModal = () => {
//     setAddTagModalVisible(true);
//   };

//   const validationAddSchema = Yup.object().shape({
//     tag_name: Yup.string().required("Please enter Tag name"),
//   });

//   const handleCancel = () => {
//     setAddTagModalVisible(false);
//     setAddFormValues(initialAddFormValues);
//   };

//   const [conditions, setConditions] = useState<
//     {
//       customer_condtion_type: string | null;
//       customer_age_type: string | null;
//       customer_condition_tag_count: string;
//       customer_condition_value_count: string;
//     }[]
//   >([]);

//   const addConditionRow = () => {
//     if (conditions.length < 6) {
//       setConditions([
//         ...conditions,
//         {
//           customer_condtion_type: null,
//           customer_age_type: null,
//           customer_condition_tag_count: "",
//           customer_condition_value_count: "",
//         },
//       ]);
//     }
//   };

//   const removeConditionRow = (index: any) => {
//     setConditions(conditions.filter((_, i) => i !== index));
//   };

//   const handleConditionChange = (index: any, field: any, value: any) => {
//     const updatedConditions = conditions.map((condition, i) =>
//       i === index ? { ...condition, [field]: value } : condition
//     );
//     setConditions(updatedConditions);
//   };

//   // const removeCondition = (index: number) => {
//   //   setFormValues((prev) => ({
//   //     ...prev,
//   //     conditions: prev.subtagConditions.filter((_, i) => i !== index),
//   //   }));
//   // };

//   const handleOk = async () => {
//     try {
//       await validationAddSchema.validate(addFormValues, { abortEarly: false });
//       const newTag = { tag_name: addFormValues.tag_name };
//       const subtagCondition = {
//         customer_condtion_type:
//           addTagConditonModalVisible.customer_condtion_type,
//         customer_age_type: addTagConditonModalVisible.customer_age_type,
//         customer_condition_tag_count:
//           addTagConditonModalVisible.customer_condition_tag_count,
//         customer_condition_value_count:
//           addTagConditonModalVisible.customer_condition_value_count,
//       };

//       setFormValues((prev) => ({
//         ...prev,
//         subtags: [...prev.subtags, newTag], // Append the new tag
//         subtagConditions: [...prev.subtagConditions, subtagCondition],
//       }));

//       setAddTagModalVisible(false);
//       setAddTagConditionModalVisible(initialAddFromConditionValues);
//       setAddFormValues(initialAddFormValues); // Reset the modal form
//     } catch (errors: any) {
//       if (errors instanceof Yup.ValidationError) {
//         const firstError = errors.inner[0];
//         if (firstError) {
//           message.error(firstError.message);
//         }
//       }
//     }
//   };

//   const addCondition = () => {
//     if (conditions.length < 6) {
//       // Define a new condition object
//       const newCondition = {
//         customer_condtion_type: null, // Initially set to null or any default value
//         customer_age_type: "between", // Same here
//         customer_condition_tag_count: "0", // Default empty string
//         customer_condition_value_count: "0", // Default empty string
//       };

//       // Add the new condition to the conditions array
//       setConditions([...conditions, newCondition]);
//     }
//   };

//   const removeCondition = (index: number) => {
//     // Filter out the condition at the given index
//     setConditions(conditions.filter((_, i) => i !== index));
//   };

//   return (
//     <>
//       <Modal
//         title="Add"
//         open={visible}
//         footer={[
//           <Button key="cancel" onClick={onClose}>
//             Cancel
//           </Button>,
//           <Button key="save" type="primary" onClick={handleSaveData}>
//             Save
//           </Button>,
//         ]}
//         onCancel={onClose}
//         width={"900px"}
//         style={{
//           top: "10px",
//         }}
//         styles={{
//           body: {
//             height: "calc(100vh - 180px)",
//             scrollbarWidth: "thin",
//             overflowX: "hidden",
//             overflowY: "auto",
//           },
//         }}
//       >
//         <Form layout="vertical">
//           <Form.Item label="Tag generation method" required>
//             <Radio.Group
//               value={tagGenerationMethod}
//               onChange={(e) => setTagGenerationMethod(e.target.value)}
//             >
//               <Radio value="manual">Manually added</Radio>
//               <Radio value="auto">Automatically generated</Radio>
//             </Radio.Group>
//           </Form.Item>
//           <Row gutter={16}>
//             <Col span={12}>
//               <Form.Item
//                 label="Tag name"
//                 name="customer_tag_name"
//                 rules={[
//                   { required: true, message: "Please input the tag name!" },
//                 ]}
//               >
//                 <Input
//                   placeholder="Please enter"
//                   value={formValues.customer_tag_name}
//                   onChange={(e) =>
//                     handleInputChange("customer_tag_name", e.target.value)
//                   }
//                   allowClear
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={12}>
//               <Form.Item label="Tag type">
//                 <Input
//                   placeholder="Please enter"
//                   value="Custom Tag"
//                   disabled
//                   allowClear
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={16}>
//             <Col span={24}>
//               <Form.Item>
//                 <div>
//                   <div style={{ display: "flex" }}>
//                     <label>
//                       Update method:{" "}
//                       {tagGenerationMethod === "auto" && (
//                         <span>Automatically updated at 3am every day</span>
//                       )}
//                       {tagGenerationMethod === "manual" && (
//                         <span>Manually added to 'Customer Profile'</span>
//                       )}
//                     </label>
//                   </div>
//                 </div>
//               </Form.Item>
//             </Col>
//           </Row>
//           {tagGenerationMethod === "auto" && (
//             <Row gutter={16}>
//               <Col span={12}>
//                 <Form.Item style={{ marginBottom: "16px" }}>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                     }}
//                   >
//                     <label>Usage status</label>
//                     <Switch
//                       style={{ width: "30px" }}
//                       checked={formValues.auto_usage_status}
//                       onChange={(value) =>
//                         handleInputChange("auto_usage_status", value)
//                       }
//                     />
//                   </div>
//                 </Form.Item>
//               </Col>
//               <Col span={12}>
//                 <Form.Item style={{ marginBottom: "16px" }}>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                     }}
//                   >
//                     <label>Show in customer profile</label>
//                     <Switch
//                       style={{ width: "30px" }}
//                       checked={formValues.show_profile}
//                       onChange={(value) =>
//                         handleInputChange("show_profile", value)
//                       }
//                     />
//                   </div>
//                 </Form.Item>
//               </Col>
//             </Row>
//           )}
//           <Row gutter={16}>
//             <Col span={24}>
//               <Form.Item label="Color">
//                 <Radio.Group
//                   className="worktag-radiogroup"
//                   style={{ display: "flex" }}
//                   value={formValues.color_code}
//                   onChange={(e) =>
//                     handleInputChange("color_code", e.target.value)
//                   }
//                 >
//                   {colors.map((color) => (
//                     <Radio.Button
//                       key={color}
//                       value={color}
//                       style={{
//                         position: "relative",
//                         backgroundColor: color,
//                         width: 30,
//                         height: 30,
//                         borderRadius: "50%",
//                         margin: "5px",
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         border:
//                           selectedColor === color
//                             ? "2px solid #000"
//                             : "1px solid #d9d9d9",
//                         boxSizing: "border-box",
//                         padding: "5px",
//                       }}
//                       onClick={() => setSelectedColor(color)}
//                     >
//                       {selectedColor === color && (
//                         <span
//                           style={{
//                             color: "#fff",
//                             fontSize: 18,
//                             fontWeight: "bold",
//                           }}
//                         >
//                           ✔
//                         </span>
//                       )}
//                     </Radio.Button>
//                   ))}
//                 </Radio.Group>
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={16}>
//             <Col span={24}>
//               <Form.Item label="Note">
//                 <Input.TextArea
//                   placeholder="Please enter"
//                   value={formValues.note}
//                   onChange={(e) => handleInputChange("note", e.target.value)}
//                   allowClear
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={16}>
//             <Col span={24}>
//               <Form.Item>
//                 <div>
//                   <div style={{ display: "flex" }}>
//                     <label>
//                       <span className="vertical-line"></span>Tags{" "}
//                       <Tooltip title="The tags will be matched in order">
//                         <InfoCircleOutlined />
//                       </Tooltip>
//                     </label>
//                     <Button
//                       type="default"
//                       icon={<PlusOutlined />}
//                       style={{
//                         marginLeft: "40px",
//                         border: "none",
//                         color: "#ff971e",
//                       }}
//                       onClick={handelShowTagAddModal}
//                     >
//                       Add
//                     </Button>
//                   </div>
//                   <div>
//                     {formValues.subtags.map((tag, index) => (
//                       <Tag
//                         key={index}
//                         closable
//                         onClose={() =>
//                           setFormValues((prev) => ({
//                             ...prev,
//                             subtags: prev.subtags.filter((_, i) => i !== index),
//                           }))
//                         }
//                       >
//                         <span style={{ textDecoration: "underline" }}>
//                           {tag.tag_name}
//                         </span>
//                       </Tag>
//                     ))}
//                   </div>
//                 </div>
//               </Form.Item>
//             </Col>
//           </Row>
//         </Form>
//       </Modal>
//       <Modal
//         title="Add Tag"
//         visible={addTagModalVisible}
//         onCancel={handleCancel}
//         onOk={handleOk}
//         okText="Save"
//         cancelText="Cancel"
//         width={"900px"}
//       >
//         <Form layout="vertical">
//           <Form.Item label="Tag name">
//             <Input
//               placeholder="Please enter"
//               value={addFormValues.tag_name}
//               onChange={(e) => setAddFormValues({ tag_name: e.target.value })}
//               allowClear
//             />
//           </Form.Item>
//           {tagGenerationMethod === "auto" && (
//             <>
//               <Row gutter={16}>
//                 <Col span={24}>
//                   <span>
//                     Conditions (Up to 6 conditions, can not be repeat)
//                   </span>
//                   <Button
//                     type="default"
//                     icon={<PlusOutlined />}
//                     style={{
//                       marginLeft: "40px",
//                       border: "none",
//                       color: "#ff971e",
//                     }}
//                     onClick={addCondition}
//                   >
//                     Add
//                   </Button>
//                   {conditions.map((condition, index) => (
//                     <Row key={index} gutter={8} style={{ marginBottom: "8px" }}>
//                       <Col span={6}>
//                         <Select
//                           value={condition.customer_condtion_type}
//                           onChange={(value) =>
//                             handleConditionChange(
//                               index,
//                               "customer_condtion_type",
//                               value
//                             )
//                           }
//                           style={{ width: "100%" }}
//                         >
//                           <Select.Option value="Age stage">
//                             Age stage
//                           </Select.Option>
//                           <Select.Option value="Total spending">
//                             Total spending
//                           </Select.Option>
//                           <Select.Option value="Total visits">
//                             Total visits
//                           </Select.Option>
//                           <Select.Option value="Joining time">
//                             Joining time
//                           </Select.Option>
//                           <Select.Option value="Last consumption date">
//                             Last consumption date
//                           </Select.Option>
//                         </Select>
//                       </Col>
//                       <Col span={6}>
//                         <Select
//                           value={condition.customer_age_type}
//                           onChange={(value) =>
//                             handleConditionChange(
//                               index,
//                               "customer_age_type",
//                               value
//                             )
//                           }
//                           style={{ width: "100%" }}
//                         >
//                           <Select.Option value="between">Between</Select.Option>
//                           <Select.Option value="above">Above</Select.Option>
//                           <Select.Option value="below">Below</Select.Option>
//                         </Select>
//                       </Col>
//                       <Col span={4}>
//                         <Input
//                           type="number"
//                           value={condition.customer_condition_tag_count}
//                           onChange={(e) =>
//                             handleConditionChange(
//                               index,
//                               "customer_condition_tag_count",
//                               e.target.value
//                             )
//                           }
//                         />
//                       </Col>
//                       <Col span={4}>
//                         <Input
//                           type="number"
//                           value={condition.customer_condition_value_count}
//                           onChange={(e) =>
//                             handleConditionChange(
//                               index,
//                               "customer_condition_value_count",
//                               e.target.value
//                             )
//                           }
//                         />
//                       </Col>
//                       <Col span={4}>
//                         <Button
//                           type="text"
//                           danger
//                           onClick={() => removeCondition(index)}
//                           icon={<CloseOutlined />}
//                         ></Button>
//                       </Col>
//                     </Row>
//                   ))}
//                 </Col>
//               </Row>
//             </>
//           )}
//         </Form>
//       </Modal>
//     </>
//   );
// };

// export default AddCustomerTag;
import {
  CloseOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Tag,
  Tooltip
} from "antd";
import { useState } from "react";
import * as Yup from "yup";

interface AddCustomerTagProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

interface FormValues {
  customer_tag_name: string;
  color_code: string;
  note: string;
  auto_usage_status: boolean;
  show_profile: boolean;
  subtags: {
    tag_name: string;
    subtagConditions: {
      customer_condtion_type: string;
      customer_age_type: string;
      customer_condition_tag_count: string;
      customer_condition_value_count: string;
    }[];
  }[];
  status?: boolean;
}

interface addFormValues {
  tag_name: string;
  subtagConditions: {
    customer_condtion_type: string;
    customer_age_type: string;
    customer_condition_tag_count: string;
    customer_condition_value_count: string;
  }[];
}

const initialFormValues: FormValues = {
  customer_tag_name: "",
  color_code: "#26C0E2",
  note: "",
  auto_usage_status: true,
  show_profile: true,
  subtags: [],
};

const colors = [
  "#26C0E2",
  "#605BFF",
  "#FF6A77",
  "#1BAC7D",
  "#FFCC00",
  "#118098",
  "#AD5BFF",
  "#FF6D1B",
  "#65D268",
  "#EC85D5",
];

const initialAddFormValues: addFormValues = {
  tag_name: "",
  subtagConditions: [],
};

const AddCustomerTag: React.FC<AddCustomerTagProps> = ({
  visible,
  onClose,
  onSelect,
}) => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [selectedColor, setSelectedColor] = useState("#26C0E2");
  const [addTagModalVisible, setAddTagModalVisible] = useState(false);
  const [addFormValues, setAddFormValues] = useState(initialAddFormValues);
  const [tagGenerationMethod, setTagGenerationMethod] =
    useState<string>("manual");

  // Save form data to parent or API
  const handleSaveData = async () => {
    const dataToSubmit = {
      ...formValues,
      tag_generation_method: tagGenerationMethod,
    };
    console.log("Submitting data:", dataToSubmit); // Debug payload
    onSelect(dataToSubmit); // Submit to parent or API
  };

  const handleTagGenerationMethod = (e: any) => {
    setTagGenerationMethod(e.target.value);
    setFormValues((prev) => ({
      ...prev,
      subtags: [],
    }));
  };

  // Add a subtag and its conditions
  const handleAddSubtagWithConditions = (newSubtag: string) => {
    const newSubtagData = {
      tag_name: newSubtag,
      subtagConditions: [] as any[], // Initialize with an empty array of conditions
    };
    setFormValues((prev) => ({
      ...prev,
      subtags: [...prev.subtags, newSubtagData],
    }));
  };

  // Add a condition to a specific subtag
  const addSubcondition = () => {
    setAddFormValues((prev) => ({
      ...prev,
      subtagConditions: [
        ...prev.subtagConditions,
        {
          customer_condtion_type: "",
          customer_age_type: "",
          customer_condition_tag_count: "0",
          customer_condition_value_count: "0",
        },
      ],
    }));
  };

  // Remove a condition from a specific subtag
  const removeSubcondition = (conditionIndex: number) => {
    setAddFormValues((prev) => {
      const updatedConditions = [...prev.subtagConditions];
      updatedConditions.splice(conditionIndex, 1);
      return { ...prev, subtagConditions: updatedConditions };
    });
  };

  // Update a condition for a specific subtag
  const handleConditionChange = (
    conditionIndex: number,
    field: keyof addFormValues["subtagConditions"][0],
    value: string
  ) => {
    setAddFormValues((prev) => {
      const updatedConditions = [...prev.subtagConditions];
      updatedConditions[conditionIndex][field] = value;
      return { ...prev, subtagConditions: updatedConditions };
    });
  };

  const handelShowTagAddModal = () => {
    setAddTagModalVisible(true);
  };

  const validationAddSchema = Yup.object().shape({
    tag_name: Yup.string().required("Please enter Tag name"),
  });

  const handleCancel = () => {
    setAddTagModalVisible(false);
    setAddFormValues(initialAddFormValues);
  };

  return (
    <>
      <Modal
        title="Add Customer Tag"
        visible={visible}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveData}>
            Save
          </Button>,
        ]}
        onCancel={onClose}
        width={"900px"}
      >
        <Form layout="vertical">
          <Form.Item label="Tag generation method" required>
            <Radio.Group
              value={tagGenerationMethod}
              onChange={(e) => handleTagGenerationMethod(e)}
            >
              <Radio value="manual">Manually added</Radio>
              <Radio value="auto">Automatically generated</Radio>
            </Radio.Group>
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Tag name"
                name="customer_tag_name"
                rules={[
                  { required: true, message: "Please input the tag name!" },
                ]}
              >
                <Input
                  placeholder="Please enter"
                  value={formValues.customer_tag_name}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      customer_tag_name: e.target.value,
                    })
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tag type">
                <Input
                  placeholder="Please enter"
                  value="Custom Tag"
                  disabled
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <div>
                  <div style={{ display: "flex" }}>
                    <label>
                      Update method:{" "}
                      {tagGenerationMethod === "auto" && (
                        <span>Automatically updated at 3am every day</span>
                      )}
                      {tagGenerationMethod === "manual" && (
                        <span>Manually added to 'Customer Profile'</span>
                      )}
                    </label>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
          {tagGenerationMethod === "auto" && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item style={{ marginBottom: "16px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>Usage status</label>
                    <Switch
                      style={{ width: "30px" }}
                      checked={formValues.auto_usage_status}
                      onChange={(value) =>
                        setFormValues({
                          ...formValues,
                          auto_usage_status: value,
                        })
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item style={{ marginBottom: "16px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>Show in customer profile</label>
                    <Switch
                      style={{ width: "30px" }}
                      checked={formValues.show_profile}
                      onChange={(value) =>
                        setFormValues({ ...formValues, show_profile: value })
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Color">
                <Radio.Group
                  className="worktag-radiogroup"
                  style={{ display: "flex" }}
                  value={formValues.color_code}
                  onChange={(e) =>
                    setFormValues({ ...formValues, color_code: e.target.value })
                  }
                >
                  {colors.map((color) => (
                    <Radio.Button
                      key={color}
                      value={color}
                      style={{
                        position: "relative",
                        backgroundColor: color,
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        margin: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border:
                          selectedColor === color
                            ? "2px solid #000"
                            : "1px solid #d9d9d9",
                        boxSizing: "border-box",
                        padding: "5px",
                      }}
                      onClick={() => setSelectedColor(color)}
                    >
                      {selectedColor === color && (
                        <span
                          style={{
                            color: "#fff",
                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          ✔
                        </span>
                      )}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Note">
                <Input.TextArea
                  placeholder="Please enter"
                  value={formValues.note}
                  onChange={(e) =>
                    setFormValues({ ...formValues, note: e.target.value })
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <div>
                  <div style={{ display: "flex" }}>
                    <label>
                      <span className="vertical-line"></span>Tags{" "}
                      <Tooltip title="The tags will be matched in order">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </label>
                    <Button
                      type="default"
                      icon={<PlusOutlined />}
                      style={{
                        marginLeft: "40px",
                        border: "none",
                        color: "#ff971e",
                      }}
                      onClick={handelShowTagAddModal}
                    >
                      Add
                    </Button>
                  </div>
                  <div>
                    {formValues.subtags.map((tag, index) => (
                      <Tag
                        key={index}
                        closable
                        onClose={() =>
                          setFormValues((prev) => ({
                            ...prev,
                            subtags: prev.subtags.filter((_, i) => i !== index),
                          }))
                        }
                      >
                        <span style={{ textDecoration: "underline" }}>
                          {tag.tag_name}
                        </span>
                      </Tag>
                    ))}
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Add Tag"
        visible={addTagModalVisible}
        onCancel={handleCancel}
        onOk={() => {
          handleAddSubtagWithConditions(addFormValues.tag_name);
          setAddTagModalVisible(false);
        }}
        okText="Save"
        cancelText="Cancel"
        width={"900px"}
      >
        <Form layout="vertical">
          <Form.Item label="Tag name">
            <Input
              placeholder="Please enter"
              value={addFormValues.tag_name}
              onChange={(e) =>
                setAddFormValues((prev) => ({
                  ...prev,
                  tag_name: e.target.value,
                }))
              }
              allowClear
            />
          </Form.Item>
          {tagGenerationMethod === "auto" && (
            <div>
              <span>Conditions (Up to 6 conditions, can not be repeat)</span>
              <Button
                type="default"
                icon={<PlusOutlined />}
                style={{
                  marginLeft: "40px",
                  border: "none",
                  color: "#ff971e",
                }}
                onClick={addSubcondition}
              >
                Add
              </Button>

              {/* Display the subtag conditions */}
              {addFormValues.subtagConditions.map(
                (condition, conditionIndex) => (
                  <Row
                    key={conditionIndex}
                    gutter={8}
                    style={{ marginBottom: "8px" }}
                  >
                    <Col span={4}>
                      <Select
                        value={condition.customer_condtion_type}
                        onChange={(value) =>
                          handleConditionChange(
                            conditionIndex,
                            "customer_condtion_type",
                            value
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <Select.Option value="Age stage">
                          Age stage
                        </Select.Option>
                        <Select.Option value="Total spending">
                          Total spending
                        </Select.Option>
                        <Select.Option value="Total visits">
                          Total visits
                        </Select.Option>
                        <Select.Option value="Joining time">
                          Joining time
                        </Select.Option>
                        <Select.Option value="Total clicks">
                          Total clicks
                        </Select.Option>
                      </Select>
                    </Col>

                    <Col span={6}>
                      <Select
                        value={condition.customer_age_type}
                        onChange={(value) =>
                          handleConditionChange(
                            conditionIndex,
                            "customer_age_type",
                            value
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <Select.Option value="between">Between</Select.Option>
                        <Select.Option value="over">Over</Select.Option>
                        <Select.Option value="under">Under</Select.Option>
                      </Select>
                    </Col>

                    <Col span={6}>
                      <Input
                        type="number"
                        min={0}
                        value={condition.customer_condition_tag_count}
                        onChange={(e) =>
                          handleConditionChange(
                            conditionIndex,
                            "customer_condition_tag_count",
                            e.target.value
                          )
                        }
                        placeholder="Tag count"
                      />
                    </Col>

                    <Col span={6}>
                      <Input
                        type="number"
                        min={0}
                        value={condition.customer_condition_value_count}
                        onChange={(e) =>
                          handleConditionChange(
                            conditionIndex,
                            "customer_condition_value_count",
                            e.target.value
                          )
                        }
                        placeholder="Value count"
                      />
                    </Col>

                    <Col span={2}>
                      <CloseOutlined
                        onClick={() => removeSubcondition(conditionIndex)}
                        style={{ cursor: "pointer", color: "#ff4d4f" }}
                      />
                    </Col>
                  </Row>
                )
              )}
            </div>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddCustomerTag;
