import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Card,
  Table,
  Form,
  message,
  InputNumber,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../services/Apiclient"; // Assuming this is where the get and post functions are located
import ProductModel from "./ProductModel";
import dayjs from "dayjs";

const { Option } = Select;

interface Product {
  number: number;
  productcode: string;
  productname: string;
  unit: string;
  supplier: string;
  expected_qty: number;
  counted_qty: number;
  difference: number;
  key: string;
}

export default function AddStockTakes() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [storageOptions, setStorageOptions] = useState<any[]>([]);
  const [operatorOptions, setOperatorOptions] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState<string>("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCountedChange = (value: number, productKey: string) => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.key === productKey) {
        const difference = value - product.expected_qty;
        return {
          ...product,
          counted_qty: value,
          difference,
        };
      }
      return product;
    });
    setSelectedProducts(updatedProducts);
  };

  const handleDeleteProduct = (productKey: string) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.key !== productKey
    );
    setSelectedProducts(updatedProducts);
  };

  // Columns for the table
  const columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Product code",
      dataIndex: "productcode",
      key: "productcode",
    },
    {
      title: "Product name",
      dataIndex: "productname",
      key: "productname",
    },
    {
      title: "Unit",
      dataIndex: "unit_type",
      key: "unit_type",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: "Expected",
      dataIndex: "expected_qty",
      key: "expected_qty",
    },
    {
      title: "Counted",
      dataIndex: "counted_qty",
      key: "counted_qty",
      render: (text: number, record: Product) => (
        <InputNumber
          type="number"
          min={0}
          value={text}
          onChange={(value: any) => handleCountedChange(value, record.key)}
        />
      ),
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: Product) => (
        <a
          style={{
            color: "rgb(245, 63, 63)",
            fontWeight: 700,
            textDecoration: "underline",
            textUnderlineOffset: "4px",
          }}
          onClick={() => handleDeleteProduct(record.key)}
        >
          Delete
        </a>
      ),
    },
  ];

  const getStorageDropdown = async () => {
    try {
      const response = await get(
        `/storage/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response && response.data && response.data.success) {
        setStorageOptions(response.data.data);
      } else {
        console.log("Error in API call: ", response.data.message);
      }
    } catch (errors: any) {
      message.error("Error fetching storage options");
    }
  };

  const getOperatorDropdown = async () => {
    try {
      const response = await get(
        `/staff/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response && response.data && response.data.success) {
        setOperatorOptions(response.data.data);
      } else {
        console.log("Error in API call: ", response.data.message);
      }
    } catch (errors: any) {
      message.error("Error fetching operator options");
    }
  };

  const getReferenceNumber = async () => {
    try {
      const response = await get("/user/randomgenerated");
      if (response && response.data && response.data.success) {
        setReferenceNumber(response.data.result);
        form.setFieldsValue({ ref: response.data.result });
      } else {
        console.log("Error in API call: ", response.data.message);
      }
    } catch (errors: any) {
      message.error("Error fetching operator options");
    }
  };

  const fetchStockTakeById = async () => {
    try {
      const response = await get(`/stock/takes/${id}`);
      if (response && response.data && response.data.success) {
        const stockTakeData = response.data.data;
        form.setFieldsValue({
          ref: stockTakeData.ref_no,
          date: dayjs(stockTakeData.stock_adj_date),
          storage: stockTakeData.storage_id,
          operator: stockTakeData.staff_id,
        });
        setSelectedProducts(
          stockTakeData.products.map((product: any, index: any) => ({
            ...product,
            number: index + 1,
            key: product.product_id,
            id: product.product_id,
          }))
        );
      } else {
        console.log("Error in fetching stock take: ", response.data.message);
      }
    } catch (error) {
      message.error("Error fetching stock take details");
    }
  };

  // Function to handle form submission
  const handleSubmit = async (values: any) => {
    try {
      const products = selectedProducts.map((product: any) => ({
        product_id: product.id,
        expected_qty: product.expected_qty,
        counted_qty: product.counted_qty,
        difference: product.counted_qty - product.expected_qty,
        status: "To be adjusted",
      }));

      if (products && products.length == 0) {
        message.error("Please enter one product");
        return;
      }

      const payload = {
        ref_no: values.ref,
        stock_adj_date: dayjs(values.date).format("YYYY-MM-DD"),
        status: "To be adjusted",
        storage_id: values.storage,
        staff_id: values.operator,
        outlet_id: localStorage.getItem("outlet_id"),
        products,
      };

      const response = id
        ? await put(`/stock/takesupdate/${id}`, payload) // Update if ID is present
        : await post("/stock/stocktakescreate", payload); // Create if no ID

      if (response && response.data.success) {
        message.success(`Stock take ${id ? "updated" : "saved"} successfully!`);
        navigate("/inventory/stocktakes/list");
      } else {
        message.error("Failed to save stock take: " + response.data.message);
      }
    } catch (error) {
      const errorMessage = (error as Error).message || "Unknown error occurred";
      message.error("Error saving stock take: " + errorMessage);
    }
  };

  const handleSaveProducts = (selectedData: any[]) => {
    const updatedProducts = selectedData.map((product, index) => ({
      ...product,
      number: index + 1,
      key: product.id || index + 1,
      expected_qty: product.stockcount || 1,
      counted_qty: product.stockcount || 1,
      difference: (product.counted_qty || 1) - (product.expected_qty || 1),
    }));

    setSelectedProducts(updatedProducts);
    setIsModalVisible(false); // Close the modal after saving products
  };

  useEffect(() => {
    getStorageDropdown();
    getOperatorDropdown();
    getReferenceNumber();
    if (id) fetchStockTakeById(); // Fetch data if updating an existing stock take
  }, [id]);

  return (
    <div style={{ padding: "20px", background: "#F0F3F8" }}>
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate("/inventory/stocktakes/list")}
          >
            <ArrowLeftOutlined
              style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
            />
            <h2
              style={{
                margin: 0,
                fontFamily: "SourceHanSansCN-Bold",
                fontSize: "18px",
              }}
            >
              {id ? "Edit Stock Takes" : "Add Stock Takes"}
            </h2>
          </div>
        </Col>
      </Row>
      <Card
        style={{
          marginTop: "20px",
          padding: "16px 24px",
          borderRadius: "12px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "1140px",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#FAFAFA",
        }}
        bodyStyle={{ padding: 0 }}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Ref#"
                name="ref"
                rules={[{ required: true, message: "Ref# is required" }]}
              >
                <Input
                  placeholder="202410250016"
                  value={referenceNumber}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: "Date is required" }]}
              >
                <DatePicker style={{ width: "100%", height: "40px" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Storage"
                name="storage"
                rules={[{ required: true, message: "Storage is required" }]}
              >
                <Select placeholder="Select storage" style={{ height: "40px" }}>
                  {storageOptions.map((storage) => (
                    <Option key={storage.id} value={storage.id}>
                      {storage.type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Operator"
                name="operator"
                rules={[{ required: true, message: "Operator is required" }]}
              >
                <Select
                  placeholder="Select operator"
                  style={{ height: "40px" }}
                >
                  {operatorOptions.map((operator) => (
                    <Option key={operator.id} value={operator.id}>
                      <div style={{ textAlign: "center" }}>{operator.name}</div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
            }}
          >
            <span
              style={{
                borderLeft: "3px solid #325DF2",
                paddingLeft: "10px",
                fontFamily: "SourceHanSansCN-Bold",
              }}
            >
              Product
            </span>
            <Button
              type="link"
              style={{
                color: "orange",
                fontSize: "12px",
                marginRight: 1011,
                marginTop: 5,
              }}
              onClick={() => showModal()}
            >
              Add
            </Button>
          </div>
          <Row style={{ marginTop: "10px" }}>
            <Col span={6}>
              <Input placeholder="Enter or scan barcode" />
            </Col>
          </Row>
          <Table
            style={{ marginTop: "20px" }}
            columns={columns}
            dataSource={selectedProducts} // Display selected products in the table
            locale={{
              emptyText: (
                <div style={{ textAlign: "center", height: "auto" }}>
                  <img
                    src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                    alt="No Data"
                    style={{ width: "80px", marginBottom: "10px" }}
                  />
                  <p>No data available</p>
                </div>
              ),
            }}
            pagination={false}
          />
          <div
            style={{ marginTop: "20px", borderTop: "1px solid black" }}
          ></div>
          <div style={{ marginTop: "20px", textAlign: "right" }}>
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => navigate("/inventory/stocktakes/list")}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Card>
      <ProductModel
        title="Product"
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSaveProducts}
        selectedProducts={selectedProducts}
      />
    </div>
  );
}
