import { ShoppingCartOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Footer from "../headerFooter/footer";
import Paragraph from "antd/es/typography/Paragraph";
import { get } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../headerFooter/Header";
const productImage = `${process.env.PUBLIC_URL}/images/logo/product.png`;
const categoryImage = `${process.env.PUBLIC_URL}/images/logo/category.jpg`;

const { Title, Text } = Typography;

const categoryStyle: React.CSSProperties = {
  padding: "16px",
  paddingTop: "72px",
};

const cardStyle: React.CSSProperties = {
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
};

const promotionCardStyle: React.CSSProperties = {
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
};

const sectionHeaderStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
};

const productCardStyle: React.CSSProperties = {
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
};

const Products: React.FC = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [promotion, setPromotion] = useState<any[]>([]);
  const [product, setProduct] = useState<any[]>([]);
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const [cartCount, setCartCount] = useState<number>(
    Number(localStorage.getItem("cartCount")) || 0
  );

  const fetchCategories = async () => {
    try {
      const response = await get(`/productmobile/mobile/${merchantId}`);
      if (response.data.success) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const fetchPromotion = async () => {
    try {
      const response = await get(
        `/productmobile/mobile/productpromolistrandom/${merchantId}`
      );
      if (response.data.success) {
        setPromotion(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching promotion", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await get(
        `/productmobile/mobile/productlistrandom/${merchantId}`
      );
      if (response.data.success) {
        setProduct(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page with smooth behavior on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
    fetchCategories();
    fetchPromotion();
    fetchProducts();
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "50px",
        }}
      >
        {/* Header */}
        <Header
          title=""
          backgroundColor="#fff"
          extraContent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              <Badge
                count={cartCount}
                onClick={() => navigate(`/app/cart/${merchantId}`)}
                style={{
                  backgroundColor: "#f5222d",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                <ShoppingCartOutlined
                  style={{ fontSize: "24px", color: "#000" }}
                />
              </Badge>
            </div>
          }
        />

        {/* Categories */}
        <div style={categoryStyle}>
          <Title style={{ marginTop: 0 }} level={5}>
            Category
          </Title>
          <Row gutter={[16, 16]}>
            {categories.map((category: any) => (
              <Col
                key={category.id}
                xs={6}
                sm={6}
                md={6}
                onClick={() =>
                  navigate(`/app/productCategory/${merchantId}/${category?.id}`)
                }
              >
                <div style={{ textAlign: "center", cursor: "pointer" }}>
                  <Avatar
                    size={64}
                    src={categoryImage}
                    style={{ marginBottom: "8px" }}
                  />
                  <br />
                  <Text
                    style={{
                      // fontSize: "1rem",
                      wordWrap: "break-word",
                      maxWidth: "100%",
                    }}
                  >
                    {category.name}
                  </Text>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        {/* Promotion Section */}
        {/* {promotion.length > 0 && ( */}
        <div style={{ padding: "16px" }}>
          <div style={sectionHeaderStyle}>
            <Title level={5} style={{ margin: 0 }}>
              Promotion
            </Title>
            <Button
              onClick={() => navigate(`/app/allpromotion/${merchantId}`)}
              style={{ margin: 0, color: "#FFA500" }}
              type="link"
            >
              Show All
            </Button>
          </div>

          <Row gutter={[16, 16]}>
            {promotion.map((promotion, index) => (
              <Col key={index} xs={12}>
                <Card
                  cover={<img alt={promotion.productname} src={productImage} />}
                  onClick={() =>
                    navigate(
                      `/app/productDetails/${merchantId}/${promotion?.id}`
                    )
                  }
                  style={cardStyle}
                  bodyStyle={{ padding: "8px" }}
                >
                  <Paragraph
                    ellipsis={{ rows: 1 }}
                    style={{ marginBottom: 4, fontWeight: "bold" }}
                  >
                    {promotion.productname}
                  </Paragraph>
                  <Text
                    delete
                    style={{
                      display: "block",
                      color: "#999",
                      fontSize: "13px",
                      visibility: promotion.originalprice
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    RM {promotion.originalprice}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        display: "block",
                        color: "#FFA500",
                        fontWeight: "bold",
                      }}
                    >
                      RM {promotion.sellprice}
                    </Text>
                    <Text>⭐ {promotion.product_review}</Text>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        {/* )} */}

        {/* All Products Section */}
        <div style={{ padding: "16px" }}>
          <div style={sectionHeaderStyle}>
            <Title level={5} style={{ margin: 0, lineHeight: "1.5" }}>
              All Product
            </Title>
            <Button
              onClick={() => navigate(`/app/allproducts/${merchantId}`)}
              style={{ margin: 0, color: "#FFA500" }}
              type="link"
            >
              Show All
            </Button>
          </div>
          {/* Placeholder for All Products */}
          <Row gutter={[16, 16]}>
            {product.map((product, index) => (
              <Col key={index} xs={12}>
                <Card
                  cover={<img alt={product.productname} src={productImage} />}
                  onClick={() =>
                    navigate(`/app/productDetails/${merchantId}/${product?.id}`)
                  }
                  style={cardStyle}
                  bodyStyle={{ padding: "8px" }}
                >
                  <Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{ marginBottom: 4, fontWeight: "bold" }}
                  >
                    {product.productname}
                  </Paragraph>
                  <Text
                    delete
                    style={{
                      display: "block",
                      color: "#999",
                      fontSize: "13px",
                      visibility: product.originalprice ? "visible" : "hidden",
                    }}
                  >
                    RM {product.originalprice}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        display: "block",
                        color: "#FFA500",
                        fontWeight: "bold",
                      }}
                    >
                      RM {product.sellprice}
                    </Text>
                    <Text>⭐ {product.product_review}</Text>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Products;
