import { CameraOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Typography,
  Upload,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../services/Apiclient";
import Header from "../headerFooter/Header";
import PhoneInput from "react-phone-input-2";
const { Text } = Typography;
const { Option } = Select;

const formItemStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 0",
  borderBottom: "1px solid #ddd",
};
const labelStyle: React.CSSProperties = {
  fontWeight: 500,
  // flex: 1, // Make label flexible to occupy available space
};
const valueStyle: React.CSSProperties = {
  color: "#888",
};
const saveButtonStyle: React.CSSProperties = {
  position: "fixed",
  bottom: "16px",
  left: "16px",
  right: "16px",
  backgroundColor: "#FFA500",
  color: "#fff",
  border: "none",
  height: "48px",
  fontSize: "16px",
  borderRadius: "8px",
};

const genders = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

interface Profile {
  id: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  email: string;
  gender: string;
  dob: string;
  country: string;
  customerimage: string;
  countrycode: string;
  imagename: string;
}
interface ImageProfile {
  baseuri: string;
}

const PersonalDetails: React.FC = () => {
  const { merchantId } = useParams();
  const [profileDetails, setProfileDetails] = useState<Profile | null>(null);
  const [userDetails, setUserDetails] = useState<Profile | null>(null);
  const [profileImage, setProfileImage] = useState<ImageProfile | null>(null);
  const customerId = localStorage.getItem("customer_id");
  const outletId = localStorage.getItem("outlet_id");
  const validDob = userDetails?.dob ? dayjs(userDetails.dob) : null;
  const navigate = useNavigate();

  const fetchProfile = async () => {
    if (!merchantId || !outletId) {
      console.error("Merchant ID or Outlet ID not found in localStorage");
      return;
    }
    try {
      const response = await get(
        `/customerprofilemob/mobile/customer/${customerId}/${outletId}/${merchantId}`
      );
      if (response?.data?.success && response.data.data) {
        console.log(response.data.data);
        const fetchedData = response.data.data;
        // Extract phone number without the country code
        let formattedPhoneNumber = fetchedData.phonenumber;
        if (fetchedData.countrycode) {
          formattedPhoneNumber = fetchedData.phonenumber.replace(
            new RegExp(`^\\+?${fetchedData.countrycode}`),
            ""
          );
        }
        // Update profile details
        setProfileDetails(fetchedData);
        // Update user details with formatted phone number
        setUserDetails({
          ...fetchedData,
          phonenumber:
            `${fetchedData.countrycode}${fetchedData.phonenumber}` || "", // Set formatted phone number
        });
      } else {
        console.log("No profile data found");
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
    }
  };

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);

  useEffect(() => {
    fetchProfile();
  }, [merchantId, outletId, customerId]);

  const handleSave = async () => {
    if (!userDetails) {
      console.error("No user details available to save");
      return;
    }

    const updatedProfile = {
      firstname: userDetails.firstname,
      email: userDetails.email,
      gender: userDetails.gender,
      countrycode: userDetails.countrycode, // Include the country code
      dob: userDetails.dob,
      customerimage: userDetails.imagename || userDetails.customerimage,
    };

    try {
      const response = await put(
        `/customerprofilemob/mobile/address/${merchantId}/${customerId}`,
        updatedProfile
      );
      if (response?.data?.success) {
        message.success("Profile updated successfully!");
        navigate(`/app/profile/${merchantId}`);
      } else {
        console.error("Error updating profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleImageUpload = async (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    // Only allow jpg and png
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG or PNG files!");
      return;
    }

    if (info.file.status === "done") {
      try {
        const formData = new FormData();
        formData.append("file", info.file.originFileObj);

        const response = await post(
          `/noticeboardmob/mobile/uploadimage/customerimage`,
          formData,
          true
        );

        if (response?.data?.success && response.data.data[0]?.imagename) {
          const uploadedImageName = response.data.data[0].imagename;
          const uploadedImageUrl = response.data.data[0].baseuri;

          // Update profileImage to show the uploaded image
          setProfileImage({ baseuri: uploadedImageUrl });

          // Update userDetails with the imagename
          setUserDetails({
            ...userDetails!,
            customerimage: uploadedImageName,
            imagename: uploadedImageName,
          });

          message.success("Profile Image updated successfully!");
        } else {
          console.error(
            "Error uploading image:",
            response?.data?.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error during image upload:", error);
      }
    }
  };

  return (
    <>
      <Header
        title=" Personal Details"
        onBack={() => navigate(`/app/profile/${merchantId}`)}
        backgroundColor={"#fff"}
      />
      <div
        style={{
          minHeight: "100vh",
          paddingTop: "50px",
          backgroundColor: "#fff",
        }}
      >
        {/* Content */}
        <div style={{ padding: "16px" }}>
          <Form layout="vertical">
            <div style={formItemStyle}>
              <Text style={labelStyle}>Profile</Text>

              <Upload
                name="avatar"
                listType="picture"
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess!("ok");
                  }, 0);
                }}
                onChange={handleImageUpload}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {userDetails?.customerimage || profileImage?.baseuri ? (
                    <img
                      src={profileImage?.baseuri || userDetails?.customerimage}
                      alt="Customer Image"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <CameraOutlined
                      style={{ fontSize: "24px", marginRight: "8px" }}
                    />
                  )}

                  <RightOutlined />
                </div>
              </Upload>
            </div>
            {/* First Name */}
            <div style={formItemStyle}>
              <Text style={labelStyle}>First name</Text>
              <Input
                value={userDetails?.firstname}
                onChange={(e) =>
                  setUserDetails({ ...userDetails!, firstname: e.target.value })
                }
                bordered={false}
                style={{ float: "right", border: 0, flex: 1 }}
              />
            </div>
            {/* Last Name */}
            <div style={formItemStyle}>
              <Text style={labelStyle}>Last name</Text>
              <Input
                value={userDetails?.lastname}
                onChange={(e) =>
                  setUserDetails({ ...userDetails!, lastname: e.target.value })
                }
                bordered={false}
                style={{ float: "right", border: 0, flex: 1 }}
              />
            </div>
            {/* Email Address */}
            <div style={formItemStyle}>
              <Text style={labelStyle}>Email address</Text>
              <Input
                value={userDetails?.email}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails!,
                    email: e.target.value,
                  })
                }
                bordered={false}
                style={{ float: "right", border: 0, flex: 1 }}
              />
            </div>
            {/* Mobile Number */}
            <div style={formItemStyle}>
              <Text style={labelStyle}>Mobile number</Text>
              <PhoneInput
                placeholder="Please enter"
                country={"my"}
                value={userDetails?.phonenumber}
                enableSearch
                countryCodeEditable={true}
                onChange={(value: any, country: any) => {
                  setUserDetails({
                    ...userDetails!,
                    countrycode: country.dialCode,
                    phonenumber: value,
                  });
                }}
                inputStyle={{
                  border: 0,
                  width: "100%",
                  height: "46px",
                  paddingLeft: "35px",
                  borderColor: "#E0E0E0",
                  borderRadius: "5px",
                  color: "#5C5C5C",
                  backgroundColor: "#F8F9FD",
                }}
                containerStyle={{
                  width: "100%",
                }}
                disabled
              />
            </div>
            {/* dob */}
            <div style={formItemStyle}>
              <Text style={labelStyle}>Birthday</Text>
              <DatePicker
                value={validDob}
                placeholder="Please Select"
                onChange={(date) => {
                  setUserDetails({
                    ...userDetails!,
                    dob: date ? date.format("YYYY-MM-DD") : "",
                  });
                }}
                style={{ float: "right", border: 0, flex: 1 }}
              />
            </div>
            {/* Gender */}
            <div style={formItemStyle}>
              <Text style={labelStyle}>Gender</Text>
              <Select
                value={userDetails?.gender}
                onChange={(value) =>
                  setUserDetails({ ...userDetails!, gender: value })
                }
                style={{
                  width: "100%",
                  border: 0,
                  float: "right",
                  flex: 1,
                  marginLeft: "10px",
                }}
              >
                {genders.map((gender) => (
                  <Option key={gender.value} value={gender.value}>
                    {gender.label}
                  </Option>
                ))}
              </Select>
            </div>
          </Form>
        </div>
        {/* Save Button */}
        <Button style={saveButtonStyle} onClick={handleSave}>
          Save
        </Button>
      </div>
    </>
  );
};

export default PersonalDetails;
