import React, { useEffect } from "react";
import { Typography, Button, Select, List } from "antd";
import {
  RightOutlined,
  ArrowLeftOutlined,
  LockFilled,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
const locationIcon = `${process.env.PUBLIC_URL}/images/logo/location.png`;

const { Title, Text } = Typography;

const headerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "16px",
  backgroundColor: "#ffffff",
  color: "#fff",
};

const backButtonStyle: React.CSSProperties = {
  background: "none",
  border: "none",
  color: "#FFA500",
  fontSize: "16px",
  cursor: "pointer",
};

const AddressSetting: React.FC = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const handleSave = (values: any) => {
    console.log("Updated Personal Details: ", values);
  };

  const handleBack = () => {
    navigate(`/app/profile/${merchantId}`);
  };

  const handleNavigation = (path: string) => {
    navigate(path); // Redirect to the specified path
  };

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
        paddingBottom: "64px",
      }}
    >
      {/* Header */}
      <div style={headerStyle}>
        <Button
          style={backButtonStyle}
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
        />
        <Title level={5} style={{ margin: 0 }}>
          Settings
        </Title>
      </div>

      {/* Form */}
      <List
        itemLayout="horizontal"
        style={{ padding: "0px 16px" }}
        dataSource={[
          {
            title: "Address",
            icon: (
              <img
                src={locationIcon}
                alt="Location Icon"
                style={{ width: "20px", height: "20px" }}
              />
            ),
            path: `/app/setting/myaddress/${merchantId}`,
          },
          {
            title: "Password",
            icon: <LockFilled style={{ fontSize: "20px", color: "#FFA500" }} />,
            path: `/app/setting/changepassword/${merchantId}`,
          },
        ]}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="text"
                icon={<RightOutlined />}
                onClick={() => handleNavigation(item.path)}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={item.icon}
              title={<Text style={{ fontWeight: 500 }}>{item.title}</Text>}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default AddressSetting;
