import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Typography,
  Slider,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  MailFilled,
  PhoneFilled,
  SwapOutlined,
  UserOutlined,
  UpOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import TipsModel from "../../components/model/billing/TipsModel";
import { get, post } from "../../services/Apiclient";
import TaxModel from "../../components/model/billing/TaxModel";
import { nanoid } from "nanoid";
import SplitAmountModal from "../../components/model/billing/SplitAmountModal";
import SelectPrivilegesProductModal from "../../components/model/billing/SelectPrivilegesProductModal";
import CustomerVoucherModal from "../../components/model/billing/CustomerVoucherModal";
import CustomerPackageModal from "../../components/model/billing/CustomerPackageModal";
import CustomerMembershipListModal from "../../components/model/billing/CustomerMembershipListModal";
import CustomerMembershipPrepaidModal from "../../components/model/billing/CustomerMembershipPrepaidModal";
import { useCurrency } from "../../contexts/CurrencyContext";
// import { currencySymbol } from "../../constants/currencySymbol";
const { Text } = Typography;

const FullPayIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_FullPay.png`;
const PaymentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Payment_XJ.png`;
const splitIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_OrderPay_Split_N.png`;
const aftersplitIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_OrderPay_Merge.png`;
const maleIcon = `${process.env.PUBLIC_URL}/images/logo/male-icon.png`;
const femaleIcon = `${process.env.PUBLIC_URL}/images/logo/female-icon.png`;
const selectedgiftcardIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_OrderPay_CardPay_S.png`;
const giftcardIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_OrderPay_CardPay_N.png`;

interface BillData {
  id: any;
  order_date: string;
  outlet_id: string;
  ref_no: string;
  customer_id: string | null;
  customer_name: string | null;
  customer_code: string | null;
  customer_email: string | null;
  customer_phone: string | null;
  customer_gender: string | null;
  customer_source_name: string | null;
  bill_status: string;
  discount: string;
  taxes: string;
  sub_total: string;
  final_total: string;
  note: string;
  note_images: any;
  items: any;
  male_count: number;
  female_count: number;
}

const Pay: React.FC = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const { id } = useParams();
  const [showTipModal, setShowTipModal] = useState(false);
  const [showTaxModal, setShowTaxModal] = useState(false);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [billData, setBillData] = useState<BillData | null>(null);
  const [billPayloadData, setBillpayloadData] = useState<any>(null);
  const [paymentOptions, setPaymentOptions] = useState<any>([]);
  const [taxesItems, setTaxesItems] = useState<any[]>([]);
  const [customerMembershipPrepaidData, setCustomerMembershipPrepaidData] =
    useState<any>([]);
  const [customerMembershipDiscountData, setCustomerMembershipDiscountData] =
    useState<any[]>([]);

  const [
    customerMembershipVisitBasedData,
    setCustomerMembershipVisitBasedData,
  ] = useState<any[]>([]);

  const [mergeMembershipListData, setMergeMembershipListData] = useState<any[]>(
    []
  );
  const [customerCountChange, setCustomerCountChanges] = useState(false);
  const [taxesUpdate, setTaxesUpdate] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllUnpaid, setSelectAllUnpaid] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
    useState<any>(null);
  const [selectedPaymentMethodName, setSelectedPaymentMethodName] =
    useState<any>(null);
  const [selectedPaymentChanges, setselectedPaymentChanges] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedSplitId, setSelectedSplitId] = useState<any>(null);
  const [selectedSplitType, setSelectedSplitType] = useState<any>("outer");
  const [selectedPrice, setSelectedPrice] = useState("0");
  const [isCustomerPrivilegeVisible, setIsCustomerPrivilegeVisible] =
    useState(false);
  const [showCustomerGiftCardModal, setShowCustomerGiftCardModal] =
    useState(false);
  const [showOnlyCustomerGiftCardModal, setShowOnlyCustomerGiftCardModal] =
    useState(false);
  const [showOnlyCustomerPackageModal, setShowOnlyCustomerPackageModal] =
    useState(false);
  const [showCustomerMembershipListModal, setShowCustomerMembershipListModal] =
    useState(false);
  const [showCustomerPrepaidModal, setShowCustomerPrepaidModal] =
    useState(false);
  const [customerGiftCardData, setCustomerGiftCardData] = useState<any[]>([]);
  const [customerPackageData, setCustomerPackageData] = useState<any[]>([]);
  const [customerTempGiftCardData, setCustomerTempGiftCardData] = useState<
    any[]
  >([]);
  const [customerTempPackageData, setCustomerTempPackageData] = useState<any[]>(
    []
  );
  const [
    customerTempMembershipDiscountData,
    setCustomerTempMembershipDiscountData,
  ] = useState<any[]>([]);

  const [
    customerTempMembershipVisitBasedData,
    setCustomerTempMembershipVisitBasedData,
  ] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPrivilegeChange, setIsPrivilegeChange] = useState<boolean>(false);
  const [customerCount, setCustomerCount] = useState<any[]>([]);

  useEffect(() => {
    const mergedData = mergeMembershipData(
      customerMembershipPrepaidData,
      customerMembershipDiscountData,
      customerMembershipVisitBasedData
    );
    setMergeMembershipListData(mergedData);
  }, [
    customerMembershipPrepaidData,
    customerMembershipDiscountData,
    customerMembershipVisitBasedData,
  ]);

  const mergeMembershipData = (
    prepaidData: any[],
    discountData: any[],
    visitbasedData: any[]
  ) => {
    return [...prepaidData, ...discountData, ...visitbasedData];
  };

  const fetchPaymentMethods = async () => {
    try {
      const response = await get(
        `/paymentmethod/list/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        const updatedData = response.data.data.map((payment: any) => ({
          ...payment,
          payment_paid_amount: 0,
        }));
        await setPaymentOptions(updatedData);
      } else {
        message.error("Failed to fetch payment methods");
      }
    } catch (error) {
      console.error("Error fetching payment methods: ", error);
      message.error("Error fetching payment methods");
    }
  };
  const fetchCartDetails = async (id: any) => {
    try {
      const response = await get(`/billing/${id}`);
      if (response.data.success) {
        await setBillData(response.data.data);
      } else {
        message.error("Failed to fetch payment methods");
      }
    } catch (error) {
      console.error("Error fetching payment methods: ", error);
      message.error("Error fetching payment methods");
    }
  };

  const handlePaymentMethodSelection = async (
    methodId: string,
    methodName: string
  ) => {
    await setSelectedPaymentMethodId(methodId);
    await setSelectedPaymentMethodName(methodName);
    setselectedPaymentChanges(true);
  };

  const handleCheckboxChange = (itemId: string, checked: boolean) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (checked) {
        newSelectedItems.add(itemId);
      } else {
        newSelectedItems.delete(itemId);
      }
      return newSelectedItems;
    });
  };

  const handleSelectAll = (e: any) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const allItemAndTaxIds = [
        ...billPayloadData.items.map((item: any) => item.item_unique_id),
        ...billPayloadData.taxes.map((tax: any) => tax.tax_unique_id),
        billPayloadData?.tip_id,
      ];

      const allSplitPayIds = billPayloadData.items.flatMap(
        (item: any) =>
          item.item_split?.map((split: any) => split.split_pay_id) || []
      );

      const allIds = [...allItemAndTaxIds, ...allSplitPayIds];

      setSelectedItems(new Set(allIds));
    } else {
      setSelectedItems(new Set());
    }
  };

  const handleSelectAllUnpaid = (e: any) => {
    const isChecked = e.target.checked;
    setSelectAllUnpaid(isChecked);
    if (isChecked) {
      const unpaidSplitPayIds = billPayloadData.items.flatMap(
        (item: any) =>
          item.item_split
            ?.filter(
              (split: any) => split.split_item_payment_method == "Unpaid"
            )
            .map((split: any) => split.split_pay_id) || []
      );
      setSelectedItems(new Set(unpaidSplitPayIds));
    } else {
      setSelectedItems(new Set());
    }
  };

  const updateSelectedItemsPaymentMethod = () => {
    if (billPayloadData && selectedItems && selectedItems.size > 0) {
      console.log(selectedItems, "selectedItems");
      const updatedItems = billPayloadData?.items.map((item: any) => {
        // Check if the main item is selected
        if (selectedItems.has(item.item_unique_id)) {
          // Update the main item
          console.log(selectedPaymentMethodName, "selectedPaymentMethodName");
          console.log(item, "item");
          if (
            item.item_type == "Debt" &&
            selectedPaymentMethodName == "Billing on account"
          ) {
            return item;
          } else {
            return {
              ...item,
              item_payment_method: selectedPaymentMethodName || "",
              item_payment_id: selectedPaymentMethodId || "",
              item_split: item.item_split?.map((split: any) => {
                // Update item_split entries based on split_pay_id
                if (selectedItems.has(split.split_pay_id)) {
                  return {
                    ...split,
                    split_item_payment_method: selectedPaymentMethodName || "",
                    split_item_payment_id: selectedPaymentMethodId || "",
                  };
                }
                return split;
              }),
            };
          }
        }

        // Check if item_split entries are selected
        const updatedSplits = item.item_split?.map((split: any) => {
          if (selectedItems.has(split.split_pay_id)) {
            return {
              ...split,
              split_item_payment_method: selectedPaymentMethodName || "",
              split_item_payment_id: selectedPaymentMethodId || "",
            };
          }
          return split;
        });

        return {
          ...item,
          item_split: updatedSplits,
        };
      });

      // Calculate payment sums for items
      const paymentSums = updatedItems.reduce((acc: any, item: any) => {
        const price = parseFloat(item.item_price);
        const qty = parseFloat(item.item_qty);

        if (!isNaN(price) && !isNaN(qty)) {
          const paymentId = item.item_payment_id;
          acc[paymentId] = (acc[paymentId] || 0) + price * qty;
        }

        // Include item_split entries in payment calculations
        item.item_split?.forEach((split: any) => {
          const splitPrice = parseFloat(split.split_item_price);
          const splitPaymentId = split.split_item_payment_id;

          if (!isNaN(splitPrice)) {
            acc[splitPaymentId] = (acc[splitPaymentId] || 0) + splitPrice;
          }
        });

        return acc;
      }, {});

      const updatedTaxesItems = billPayloadData?.taxes.map(
        (item: any, index: any) => {
          if (selectedItems.has(item.tax_unique_id)) {
            return {
              ...item,
              tax_payment_method: selectedPaymentMethodName,
              tax_payment_id: selectedPaymentMethodId,
            };
          }
          return item;
        }
      );

      const paymentTaxesSums = updatedTaxesItems.reduce(
        (acc: any, item: any) => {
          const taxamount = item.tax_amount;

          if (!isNaN(taxamount)) {
            const paymentId = item.tax_payment_id;
            acc[paymentId] = (acc[paymentId] || 0) + taxamount;
          }

          return acc;
        },
        {}
      );

      let updatedTip = {};

      if (billPayloadData.tip_id && selectedItems.has(billPayloadData.tip_id)) {
        const tipPaymentMethod = selectedPaymentMethodName;
        const tipPaymentId = selectedPaymentMethodId;

        const tipValue = parseFloat(billPayloadData.tips);

        if (!isNaN(tipValue)) {
          updatedTip = {
            tip_payment_method: tipPaymentMethod,
            tip_payment_id: tipPaymentId,
            tip_value: tipValue,
            tips: tipValue,
          };
        }
      }

      if (paymentOptions && paymentOptions.length > 0) {
        const updatedPaymentOptions = paymentOptions.map((payment: any) => {
          const itemPayment = paymentSums[payment.id] || 0;
          const taxPayment = paymentTaxesSums[payment.id] || 0;
          let totalTipAmount = 0;
          if (billPayloadData.tip_payment_id === payment.id) {
            totalTipAmount = billPayloadData.tip_value || 0;
          }
          return {
            ...payment,
            payment_paid_amount: itemPayment + taxPayment + totalTipAmount,
          };
        });

        setPaymentOptions(updatedPaymentOptions);
      }
      setBillpayloadData((prevData: any) => ({
        ...prevData,
        items: updatedItems,
        taxes: updatedTaxesItems,
        ...updatedTip,
      }));
    }
    setSelectedItems(new Set());
    setselectedPaymentChanges(false);
  };

  const giftcardItem = (
    id: any,
    price: any,
    item: any,
    type: any,
    innerid: any = "null",
    showData: any
  ) => {
    setSelectedItem(item);
    setSelectedSplitId(id);
    setSelectedSplitType(type);

    if (type == "outer") {
      if (
        item.item_payment_method == "Giftcard" ||
        item.item_payment_method == "Visit-based"
      ) {
        const itemIndex = customerGiftCardData.findIndex(
          (giftcarditem) => giftcarditem.item_id == item.item_payment_id
        );
        if (itemIndex !== -1 && item.item_payment_method == "Giftcard") {
          setSelectedIndex(itemIndex);
        }
        const membershipitemIndex =
          customerTempMembershipVisitBasedData.findIndex(
            (giftcarditem) => giftcarditem.item_id == item.item_payment_id
          );
        if (
          membershipitemIndex !== -1 &&
          item.item_payment_method == "Visit-based"
        ) {
          setSelectedIndex(membershipitemIndex);
        }
      }
      let itemdata = billPayloadData?.items.filter(
        (x: any) => x.item_unique_id == item.item_unique_id
      )[0];
      if (itemdata) {
        if (itemdata.item_split && itemdata.item_split.length > 0) {
          const totalSplitSum = itemdata.item_split
            .filter((split: any) => !split.is_main_split)
            .reduce(
              (sum: number, split: any) => sum + Number(split.split_item_price),
              0
            );

          const remainingprice = Number(price) - totalSplitSum;

          setSelectedPrice(remainingprice.toString());
        } else {
          setSelectedPrice(price);
        }

        console.log(
          item.item_mebership_privilege_id,
          "membership_privilege_id"
        );
        if (
          item.item_mebership_privilege_id &&
          item.item_mebership_privilege_id != null
        ) {
          console.log("this is calling");
          setSelectedGiftcard(item.item_mebership_privilege_id);
        } else {
          setSelectedGiftcard(item.item_payment_id);
        }
      }

      //Filter membership discount
      const filteredMembershipDiscount = customerMembershipDiscountData.filter(
        (discount: any) => {
          if (item.item_type === "Product") {
            return (
              !discount.productnot_applicable &&
              (discount.allproduct_applicable ||
                discount.product_category_details.some(
                  (category: any) => category.id === item.item_categorycode
                ) ||
                discount.product_item_details.some(
                  (product: any) => product.id === item.item_id
                ))
            );
          } else if (item.item_type === "Service") {
            return (
              !discount.servicenot_applicable &&
              (discount.allservice_applicable ||
                discount.service_category_details.some(
                  (category: any) => category.id === item.item_categorycode
                ) ||
                discount.service_item_details.some(
                  (service: any) => service.id === item.item_id
                ))
            );
          }
          return false;
        }
      );

      setCustomerTempMembershipDiscountData(filteredMembershipDiscount);

      //Filter membership visit based
      const filteredMembershipVisitBased =
        customerMembershipVisitBasedData.filter((discount: any) => {
          if (item.item_type === "Service") {
            return discount.serviceid == item.item_id;
          }
          return false;
        });
      setCustomerTempMembershipVisitBasedData(filteredMembershipVisitBased);

      if (showData) {
        //Filter membership visit based
        const filteredMembershipVisitBased =
          customerMembershipVisitBasedData.filter((discount: any) => {
            if (item.item_type === "Service") {
              return discount.serviceid == item.item_id;
            }
            return false;
          });
        setCustomerTempMembershipVisitBasedData(filteredMembershipVisitBased);
      } else {
        setCustomerTempMembershipVisitBasedData([]);
      }
    } else {
      const itemIndex = customerGiftCardData.findIndex(
        (giftcarditem) => giftcarditem.item_id === innerid.split_item_payment_id
      );
      if (itemIndex !== -1 && innerid.split_item_payment_method == "Giftcard") {
        setSelectedIndex(itemIndex);
      }
      setSelectedGiftcard(innerid.split_item_payment_id);
      setSelectedPrice(price);

      setCustomerTempMembershipDiscountData([]);

      if (showData) {
        //Filter membership visit based
        const filteredMembershipVisitBased =
          customerMembershipVisitBasedData.filter((discount: any) => {
            if (item.item_type === "Service") {
              return discount.serviceid == item.item_id;
            }
            return false;
          });
        setCustomerTempMembershipVisitBasedData(filteredMembershipVisitBased);
      } else {
        setCustomerTempMembershipVisitBasedData([]);
      }
    }

    const filteredGiftCards = customerTempGiftCardData.filter(
      (giftcard: any) => {
        if (item.item_type === "Product") {
          return (
            !giftcard.productnot_applicable &&
            (giftcard.allproduct_applicable ||
              giftcard.product_category_details.some(
                (category: any) => category.id === item.item_categorycode
              ) ||
              giftcard.product_item_details.some(
                (product: any) => product.id === item.item_id
              ))
          );
        } else if (item.item_type === "Service") {
          return (
            !giftcard.servicenot_applicable &&
            (giftcard.allservice_applicable ||
              giftcard.service_category_details.some(
                (category: any) => category.id === item.item_categorycode
              ) ||
              giftcard.service_item_details.some(
                (service: any) => service.id === item.item_id
              ))
          );
        }
        return false;
      }
    );

    // console.log("customer package....", customerPackageData);
    // Filter packages based on item type
    const filteredPackages = customerPackageData.filter((pkg: any) => {
      if (
        item.item_type === "Service" &&
        (!pkg.services || pkg.services.length === 0)
      ) {
        return false;
      }
      if (
        item.item_type === "Product" &&
        (!pkg.products || pkg.products.length === 0)
      ) {
        return false;
      }
      // Ensure item_id matches with service_id or product_id in the package
      if (
        item.item_type === "Service" &&
        !pkg.services.some(
          (service: any) => service.service_id === item.item_id
        )
      ) {
        return false;
      }
      if (
        item.item_type === "Product" &&
        !pkg.products.some(
          (product: any) => product.product_id === item.item_id
        )
      ) {
        return false;
      }
      return true;
    });

    // console.log("Filtered customer packages: ", filteredPackages);

    // Set filtered gift cards
    setCustomerTempPackageData(filteredPackages);
    setShowCustomerGiftCardModal(true);
  };

  const handlePackageItem = (id: any, price: any, item: any, type: any) => {
    setSelectedItem(item);
    setSelectedSplitId(id);
    setSelectedSplitType(type);

    if (type == "outer") {
      let itemdata = billPayloadData?.items.filter(
        (x: any) => x.item_unique_id == item.item_unique_id
      )[0];
      if (itemdata) {
        if (itemdata.item_split && itemdata.item_split.length > 0) {
          const totalSplitSum = itemdata.item_split
            .filter((split: any) => !split.is_main_split)
            .reduce(
              (sum: number, split: any) => sum + Number(split.split_item_price),
              0
            );

          const remainingprice = Number(price) - totalSplitSum;

          setSelectedPrice(remainingprice.toString());
        } else {
          setSelectedPrice(price);
        }
        setSelectedGiftcard(item.item_payment_id);
      }
    } else {
      setSelectedGiftcard(item.split_item_payment_id);
      setSelectedPrice(price);
    }

    const filteredPackageData = customerTempGiftCardData.filter(
      (packageItem: any) => {
        if (item.item_type === "Product") {
          return (
            !packageItem.productnot_applicable &&
            (packageItem.allproduct_applicable ||
              packageItem.product_category_details.some(
                (category: any) => category.id === item.item_categorycode
              ) ||
              packageItem.product_item_details.some(
                (product: any) => product.id === item.item_id
              ))
          );
        } else if (item.item_type === "Service") {
          return (
            !packageItem.servicenot_applicable &&
            (packageItem.allservice_applicable ||
              packageItem.service_category_details.some(
                (category: any) => category.id === item.item_categorycode
              ) ||
              packageItem.service_item_details.some(
                (service: any) => service.id === item.item_id
              ))
          );
        }
        return false;
      }
    );

    // Set filtered gift cards
    // setCustomerTempGiftCardData(filteredPackageData);
    setShowCustomerGiftCardModal(true);
  };

  const splitItem = (id: any, price: any, item: any, type: any) => {
    setSelectedItem(item);
    setSelectedSplitId(id);
    setSelectedSplitType(type);
    if (type == "outer") {
      let itemdata = billPayloadData?.items.filter(
        (x: any) => x.item_unique_id == item.item_unique_id
      )[0];
      if (itemdata) {
        if (itemdata.item_split && itemdata.item_split.length > 0) {
          const totalSplitSum = itemdata.item_split
            .filter((split: any) => !split.is_main_split) // Exclude `is_main_split` entries
            .reduce(
              (sum: number, split: any) => sum + Number(split.split_item_price),
              0
            );

          const remainingprice = Number(price) - totalSplitSum;

          setSelectedPrice(remainingprice.toString());
        } else {
          setSelectedPrice(price);
        }
      }
    } else {
      setSelectedPrice(price);
    }
    setShowSplitModal(true);
  };

  // const removeSplitItem = (id: any, item: any) => {
  //   let itemdata = billPayloadData?.items.filter(
  //     (x: any) => x.item_id == item.item_id
  //   )[0];
  //   if (itemdata) {
  //     if (itemdata.item_split && itemdata.item_split.length > 2) {
  //       itemdata.item_split = itemdata.item_split.filter(
  //         (split: any) => split.split_pay_id !== id
  //       );
  //       const totalSplitSum = itemdata.item_split
  //         .filter((split: any) => !split.is_main_split)
  //         .reduce(
  //           (sum: number, split: any) => sum + Number(split.split_item_price),
  //           0
  //         );

  //       const mainSplit = itemdata.item_split.find(
  //         (split: any) => split.is_main_split
  //       );
  //       if (mainSplit) {
  //         mainSplit.split_item_price = (
  //           Number(itemdata.item_price) - totalSplitSum
  //         ).toString();
  //       }
  //     } else if (itemdata.item_split && itemdata.item_split.length == 2) {
  //       itemdata.item_split = [];
  //       itemdata.is_split = false;
  //     }

  //     setBillpayloadData((prevData: any) => ({
  //       ...prevData,
  //       item: itemdata,
  //     }));
  //   }
  // };

  const removeSplitItem = (id: any, item: any) => {
    let itemdata = billPayloadData?.items.find(
      (x: any) => x.item_unique_id == item.item_unique_id
    );

    if (itemdata) {
      if (itemdata.item_split && itemdata.item_split.length > 2) {
        // Filter out the split item with the given ID
        itemdata.item_split = itemdata.item_split.filter(
          (split: any) => split.split_pay_id !== id
        );

        // Recalculate total split sum (excluding main split)
        const totalSplitSum = itemdata.item_split
          .filter((split: any) => !split.is_main_split)
          .reduce(
            (sum: number, split: any) => sum + Number(split.split_item_price),
            0
          );

        // Update main split price
        const mainSplit = itemdata.item_split.find(
          (split: any) => split.is_main_split
        );
        if (mainSplit) {
          mainSplit.split_item_price = (
            Number(itemdata.item_price) - totalSplitSum
          ).toString();
        }

        // Clear item payment fields if item has split payments
        itemdata.item_payment_id = "";
        itemdata.item_payment_method = "";
      } else if (itemdata.item_split && itemdata.item_split.length == 2) {
        const firstSplit = item.item_split[0];
        if (firstSplit) {
          itemdata.item_payment_id = firstSplit.split_item_payment_id || "";
          itemdata.item_payment_method =
            firstSplit.split_item_payment_method || "";
        }
        // Handle case when only two splits remain
        itemdata.item_split = [];

        // Set `is_split` to false
        itemdata.is_split = false;

        // Update payment method and ID from the first split
      } else if (itemdata.item_split && itemdata.item_split.length == 1) {
        if (itemdata.item_payment_method == "Visit-based") {
          itemdata.deduction_item = false;
          let totalunit =
            parseFloat(itemdata.item_qty) +
            parseFloat(itemdata.item_remain_qty);
          itemdata.item_remain_qty = "0";
          itemdata.item_qty = totalunit.toString();
          itemdata.item_price = (
            parseFloat(itemdata.item_oneunit_price) * totalunit
          ).toString();
          itemdata.item_payment_id =
            item.item_split[0].split_item_payment_id || "";
          itemdata.item_payment_method =
            item.item_split[0].split_item_payment_method || "";
          itemdata.item_membership_type = "";
          itemdata.item_mebership_privilege_id = "";
        }
        itemdata.item_split = [];

        // Set `is_split` to false
        itemdata.is_split = false;
      }

      // Update state with the modified item
      setBillpayloadData((prevData: any) => ({
        ...prevData,
        items: prevData.items.map((x: any) =>
          x.item_unique_id === item.item_unique_id ? itemdata : x
        ),
      }));
    }
  };

  const handleTipSubmit = (body: any) => {
    setBillpayloadData((prevData: any) => ({
      ...prevData,
      tip_id: body.tip_id,
      tip_rate: body.tip_rate,
      tip_value: body.tip_value,
      tips: body.tip_value,
      tip_payment_method:
        paymentOptions && paymentOptions.length > 0
          ? paymentOptions[0]?.paymentmethodtype
          : "",
      tip_payment_id:
        paymentOptions && paymentOptions.length > 0
          ? paymentOptions[0]?.id
          : "",
    }));
    setShowTipModal(false);
  };

  const handleInputChange = (item: any, name: string, value: any) => {
    let itemdata = item;

    if (name == "pvg_taken_qty") {
      if (parseFloat(value) <= parseFloat(itemdata.item_qty)) {
        itemdata.pvg_taken_qty = parseFloat(value);
        itemdata.pvg_remain_qty =
          parseFloat(item.item_qty) - parseFloat(value || 0);
      }
    } else if (name == "pvg_remain_qty") {
      if (parseFloat(value) <= parseFloat(itemdata.item_qty)) {
        itemdata.pvg_remain_qty = parseFloat(value);
        itemdata.pvg_taken_qty =
          parseFloat(item.item_qty) - parseFloat(value || 0);
      }
    }

    setBillpayloadData((prevData: any) => ({
      ...prevData,
      items: prevData.items.map((x: any) =>
        x.item_unique_id === item.item_unique_id ? itemdata : x
      ),
    }));
  };

  const handleGiftcardSplit = (data: any) => {
    let itemdata = billPayloadData?.items.filter(
      (x: any) => x.item_unique_id == selectedItem.item_unique_id
    )[0];

    if (itemdata) {
      if (selectedSplitType == "outer") {
        if (data == null) {
          if (selectedItem.item_price == 0) {
            itemdata.item_payment_id = "";
            itemdata.item_payment_method = "Unpaid";
            itemdata.item_membership_discount = 0;
            itemdata.item_membership_type = "";
            itemdata.item_mebership_privilege_id = "";
            itemdata.item_price =
              itemdata.item_unit_price ||
              itemdata.item_membership_discount_amount;
            itemdata.deduction_item = false;
          } else {
            if (itemdata.item_membership_type == "Discount") {
              itemdata.is_split = false;
              itemdata.item_split = [];
            }
            itemdata.item_payment_id = "";
            itemdata.item_payment_method = "Unpaid";
            itemdata.item_membership_discount = 0;
            itemdata.item_membership_type = "";
            itemdata.item_mebership_privilege_id = "";
            itemdata.item_price = itemdata.item_membership_discount_amount;
            itemdata.deduction_item = false;
          }
          setSelectedIndex(0);
          setSelectedGiftcard(null);
          setIsPrivilegeChange(!isPrivilegeChange);
          return;
        }
        setSelectedIndex(data.index);
        if (data.type == undefined || data.type == null) {
          return;
        }
        if (data.type == "GiftCard") {
          if (itemdata.item_payment_id == data.item_id) {
            setShowSplitModal(false);
            return;
          } else {
            if (data.valid_type == "Value") {
              if (itemdata.item_price > data.sessionvalue) {
                itemdata.is_split = true;

                if (itemdata.item_split && itemdata.item_split.length > 0) {
                  const mainSplit = itemdata.item_split.find(
                    (split: any) => split.is_main_split
                  );

                  if (mainSplit) {
                    const currentRemainingPrice = Number(
                      mainSplit.split_item_price
                    );

                    if (selectedSplitType === "outer") {
                      const newRemainingPrice =
                        currentRemainingPrice - Number(data.sessionvalue);

                      mainSplit.split_item_price = newRemainingPrice.toString();

                      const newSplitData = {
                        split_pay_id: generateUniqueId(),
                        split_item_id: selectedItem.item_id,
                        split_item_price: Number(data.sessionvalue),
                        split_item_payment_id: data.item_id,
                        split_item_payment_method: "Giftcard",
                        is_main_split: false,
                        deduction_item: false,
                        split_item_membership_type: "",
                        split_item_membership_discount: "",
                      };

                      itemdata.item_split.push(newSplitData);
                    } else if (selectedSplitType === "inner") {
                      const newRemainingPrice =
                        currentRemainingPrice - Number(data.sessionvalue);

                      if (newRemainingPrice < 0) {
                        console.error("Split amount exceeds remaining price!");
                        return; // Prevent invalid operations
                      }

                      mainSplit.split_item_price = newRemainingPrice.toString();

                      const newSplitData = {
                        split_pay_id: generateUniqueId(),
                        split_item_id: selectedItem.item_id,
                        split_item_price: Number(data.sessionvalue),
                        split_item_payment_id: data.item_id,
                        split_item_payment_method: "Giftcard",
                        is_main_split: false,
                        deduction_item: false,
                        split_item_membership_type: "",
                        split_item_membership_discount: "",
                      };

                      itemdata.item_split.push(newSplitData);
                    }
                  }
                } else {
                  const remainingprice =
                    Number(selectedPrice) - Number(data.sessionvalue);
                  itemdata.item_payment_id = "";
                  itemdata.item_payment_method = "";
                  itemdata.item_split = [
                    {
                      split_pay_id: generateUniqueId(),
                      split_item_id: selectedItem.item_id,
                      split_item_price: remainingprice.toString(),
                      split_item_payment_id:
                        paymentOptions && paymentOptions.length > 0
                          ? paymentOptions[0].id
                          : "",
                      split_item_payment_method:
                        paymentOptions && paymentOptions.length > 0
                          ? paymentOptions[0].paymentmethodtype
                          : "",
                      is_main_split: true,
                      deduction_item: false,
                      split_item_membership_type: "",
                      split_item_membership_discount: "",
                    },
                    {
                      split_pay_id: generateUniqueId(),
                      split_item_id: selectedItem.item_id,
                      split_item_price: Number(data.sessionvalue),
                      split_item_payment_id: data.item_id,
                      split_item_payment_method: "Giftcard",
                      is_main_split: false,
                      deduction_item: false,
                      split_item_membership_type: "",
                      split_item_membership_discount: "",
                    },
                  ];
                }

                // Update the state
                setBillpayloadData((prevData: any) => ({
                  ...prevData,
                  items: billPayloadData.items.map((x: any) =>
                    x.item_unique_id === itemdata.item_unique_id ? itemdata : x
                  ),
                }));
              } else {
                itemdata.item_payment_id = data.item_id;
                itemdata.item_payment_method = "Giftcard";
                itemdata.deduction_item = false;
                setShowSplitModal(false);
                return;
              }
            } else {
              if (data.sessionvalue > 0) {
                itemdata.item_payment_id = data.item_id;
                itemdata.item_payment_method = "Giftcard";
                itemdata.deduction_item = true;
                itemdata.item_membership_type = "";
                itemdata.item_mebership_privilege_id = "";

                // Update the state
                setBillpayloadData((prevData: any) => ({
                  ...prevData,
                  items: billPayloadData.items.map((x: any) =>
                    x.item_unique_id === itemdata.item_unique_id ? itemdata : x
                  ),
                }));
              }
            }
          }
        } else if (data.type == "Package") {
          if (itemdata.item_payment_id == data.id) {
            setShowSplitModal(false);
            return;
          } else {
            if (itemdata.item_price > data.total_amount) {
              itemdata.is_split = true;

              if (itemdata.item_split && itemdata.item_split.length > 0) {
                const mainSplit = itemdata.item_split.find(
                  (split: any) => split.is_main_split
                );

                if (mainSplit) {
                  const currentRemainingPrice = Number(
                    mainSplit.split_item_price
                  );

                  if (selectedSplitType === "outer") {
                    const newRemainingPrice =
                      currentRemainingPrice - Number(data.total_amount);

                    mainSplit.split_item_price = newRemainingPrice.toString();

                    const newSplitData = {
                      split_pay_id: generateUniqueId(),
                      split_item_id: selectedItem.item_id,
                      split_item_price: Number(data.total_amount),
                      split_item_payment_id: data.id,
                      split_item_payment_method: "Package",
                      is_main_split: false,
                      deduction_item: false,
                      split_item_membership_type: "",
                      split_item_membership_discount: "",
                    };

                    itemdata.item_split.push(newSplitData);
                  } else if (selectedSplitType === "inner") {
                    const newRemainingPrice =
                      currentRemainingPrice - Number(data.total_amount);

                    if (newRemainingPrice < 0) {
                      console.error("Split amount exceeds remaining price!");
                      return; // Prevent invalid operations
                    }

                    mainSplit.split_item_price = newRemainingPrice.toString();

                    const newSplitData = {
                      split_pay_id: generateUniqueId(),
                      split_item_id: selectedItem.item_id,
                      split_item_price: Number(data.total_amount),
                      split_item_payment_id: data.id,
                      split_item_payment_method: "Package",
                      is_main_split: false,
                      deduction_item: false,
                      split_item_membership_type: "",
                      split_item_membership_discount: "",
                    };

                    itemdata.item_split.push(newSplitData);
                  }
                }
              } else {
                const remainingprice =
                  Number(selectedPrice) - Number(data.total_amount);
                itemdata.item_payment_id = "";
                itemdata.item_payment_method = "";
                itemdata.item_split = [
                  {
                    split_pay_id: generateUniqueId(),
                    split_item_id: selectedItem.item_id,
                    split_item_price: remainingprice.toString(),
                    split_item_payment_id:
                      paymentOptions && paymentOptions.length > 0
                        ? paymentOptions[0].id
                        : "",
                    split_item_payment_method:
                      paymentOptions && paymentOptions.length > 0
                        ? paymentOptions[0].paymentmethodtype
                        : "",
                    is_main_split: true,
                    deduction_item: false,
                    split_item_membership_type: "",
                    split_item_membership_discount: "",
                  },
                  {
                    split_pay_id: generateUniqueId(),
                    split_item_id: selectedItem.item_id,
                    split_item_price: Number(data.total_amount),
                    split_item_payment_id: data.id,
                    split_item_payment_method: "Package",
                    is_main_split: false,
                    deduction_item: false,
                    split_item_membership_type: "",
                    split_item_membership_discount: "",
                  },
                ];
              }

              // Update the state
              setBillpayloadData((prevData: any) => ({
                ...prevData,
                items: billPayloadData.items.map((x: any) =>
                  x.item_unique_id === itemdata.item_unique_id ? itemdata : x
                ),
              }));
            } else {
              itemdata.item_payment_id = data.id;
              itemdata.item_payment_method = "Package";
              setShowSplitModal(false);
              return;
            }
          }
        } else if (data.type == "Discount") {
          if (itemdata.item_payment_id == data.item_id) {
            setShowSplitModal(false);
            return;
          } else {
            if (data.remaining_session > 0) {
              const isApplicable =
                itemdata.item_type === "Product"
                  ? !data.productnot_applicable &&
                    (data.allproduct_applicable ||
                      data.product_category_details.some(
                        (category: any) =>
                          category.id === itemdata.item_categorycode
                      ) ||
                      data.product_item_details.some(
                        (product: any) => product.id === itemdata.item_id
                      ))
                  : itemdata.item_type === "Service"
                  ? !data.servicenot_applicable &&
                    (data.allservice_applicable ||
                      data.service_category_details.some(
                        (category: any) =>
                          category.id === itemdata.item_categorycode
                      ) ||
                      data.service_item_details.some(
                        (service: any) => service.id === itemdata.item_id
                      ))
                  : null;

              if (isApplicable) {
                const membershipDiscountAmount =
                  itemdata.item_type === "Product"
                    ? parseFloat(data.product_discount)
                    : itemdata.item_type === "Service"
                    ? parseFloat(data.service_discount)
                    : 0;

                const item_price = parseFloat(itemdata.item_price);

                const item_membership_discount_amount =
                  (item_price * membershipDiscountAmount) / 100;

                const discounted_price =
                  item_price - item_membership_discount_amount;

                itemdata.item_payment_id =
                  paymentOptions && paymentOptions.length > 0
                    ? paymentOptions[0].id
                    : "";
                itemdata.item_payment_method =
                  paymentOptions && paymentOptions.length > 0
                    ? paymentOptions[0].paymentmethodtype
                    : "";
                itemdata.item_membership_type = "Discount";
                itemdata.item_mebership_privilege_id = data.item_id;
                itemdata.deduction_item = false;
                itemdata.item_membership_discount = membershipDiscountAmount;
                itemdata.item_price = parseFloat(discounted_price.toFixed(2));

                // Update the state
                setBillpayloadData((prevData: any) => ({
                  ...prevData,
                  items: billPayloadData.items.map((x: any) =>
                    x.item_unique_id === itemdata.item_unique_id ? itemdata : x
                  ),
                }));
                return;
              }
            }
          }
        } else if (data.type == "Visit-based") {
          if (itemdata.item_payment_id == data.item_id) {
            setShowSplitModal(false);
            return;
          } else {
            if (data.buyprice > 0 || data.giveaway > 0) {
              let isApplicable = false;

              if (itemdata.item_id == data.serviceid) {
                isApplicable = true;
              }

              let counttotal =
                parseFloat(data.buyprice) + parseFloat(data.giveaway);

              if (isApplicable) {
                itemdata.item_payment_id = data.item_id;
                itemdata.item_payment_method = "Visit-based";
                itemdata.item_membership_type = "Visit-based";
                itemdata.item_mebership_privilege_id = data.item_id;
                itemdata.deduction_item = true;

                if (itemdata.item_qty > counttotal) {
                  // Calculate item_remain_qty
                  const item_qty = parseFloat(itemdata.item_qty); // Assuming item_qty is a numeric value
                  let unit_price = parseFloat(itemdata.item_price) / item_qty;
                  const item_remain_qty = item_qty - counttotal;

                  itemdata.item_oneunit_price = unit_price.toString();

                  // Set item_remain_qty if item_qty and counttotal are valid numbers
                  itemdata.item_remain_qty =
                    item_remain_qty >= 0 ? item_remain_qty : 0; // Avoid negative quantity
                  itemdata.item_qty = itemdata.item_qty - item_remain_qty;

                  if (item_remain_qty > 0) {
                    let setremainprice = item_remain_qty * unit_price;
                    itemdata.is_split = true;
                    itemdata.item_split = [
                      {
                        split_pay_id: generateUniqueId(),
                        split_item_id: itemdata.item_id,
                        split_item_price: setremainprice.toString(),
                        split_item_payment_id:
                          paymentOptions && paymentOptions.length > 0
                            ? paymentOptions[0].id
                            : "",
                        split_item_payment_method:
                          paymentOptions && paymentOptions.length > 0
                            ? paymentOptions[0].paymentmethodtype
                            : "",
                        is_main_split: false,
                        deduction_item: false,
                        split_item_membership_type: "",
                        split_item_membership_discount: "",
                      },
                    ];
                  }
                }

                // Update the state
                setBillpayloadData((prevData: any) => ({
                  ...prevData,
                  items: billPayloadData.items.map((x: any) =>
                    x.item_unique_id === itemdata.item_unique_id ? itemdata : x
                  ),
                }));
                return;
              }
            }
          }
        }
      } else {
        if (data == null) {
          setSelectedIndex(0);
          setSelectedGiftcard(null);
          if (itemdata.item_payment_method == "Visit-based") {
            itemdata.deduction_item = false;
            let totalunit =
              parseFloat(itemdata.item_qty) +
              parseFloat(itemdata.item_remain_qty);
            itemdata.item_remain_qty = "0";
            itemdata.item_qty = totalunit.toString();
            itemdata.item_price = (
              parseFloat(itemdata.item_oneunit_price) * totalunit
            ).toString();
            itemdata.item_payment_id = "";
            itemdata.item_payment_method = "";
            itemdata.item_membership_type = "";
            itemdata.item_mebership_privilege_id = "";
          }
          itemdata.item_split = itemdata.item_split.map((split: any) => {
            if (selectedSplitId == split.split_pay_id) {
              return {
                ...split,
                split_item_payment_id: "",
                split_item_payment_method: "Unpaid",
              };
            }
            return split;
          });
          setBillpayloadData((prevData: any) => ({
            ...prevData,
            items: billPayloadData.items.map((x: any) =>
              x.item_unique_id === itemdata.item_unique_id ? itemdata : x
            ),
          }));
          return;
        }
        setSelectedIndex(data.index);
        if (data.type == "GiftCard") {
          if (data.valid_type == "Value") {
            itemdata.item_split = itemdata.item_split.map((split: any) => {
              if (
                split.split_item_payment_id !== data.item_id &&
                selectedSplitId == split.split_pay_id
              ) {
                return {
                  ...split,
                  split_item_payment_id: data.item_id,
                  split_item_payment_method: "Giftcard",
                };
              }
              return split;
            });
          } else {
            itemdata.item_split = itemdata.item_split.map((split: any) => {
              if (
                split.split_item_payment_id !== data.item_id &&
                selectedSplitId == split.split_pay_id
              ) {
                return {
                  ...split,
                  split_item_payment_id: data.item_id,
                  split_item_payment_method: "Giftcard",
                  deduction_item: true,
                };
              }
              return split;
            });
          }
          setBillpayloadData((prevData: any) => ({
            ...prevData,
            items: billPayloadData.items.map((x: any) =>
              x.item_unique_id === itemdata.item_unique_id ? itemdata : x
            ),
          }));
          return;
        } else if (data.type == "Package") {
          itemdata.item_split = itemdata.item_split.map((split: any) => {
            if (
              split.split_item_payment_id !== data.id &&
              selectedSplitId == split.split_pay_id
            ) {
              return {
                ...split,
                split_item_payment_id: data.id,
                split_item_payment_method: "Package",
              };
            }
            return split;
          });
          setBillpayloadData((prevData: any) => ({
            ...prevData,
            items: billPayloadData.items.map((x: any) =>
              x.item_unique_id === itemdata.item_unique_id ? itemdata : x
            ),
          }));
          return;
        }
      }
    }
    setSelectedGiftcard(null);
    setShowSplitModal(false);
  };

  const handleSplitEdit = (data: any) => {
    let itemdata = billPayloadData?.items.filter(
      (x: any) => x.item_unique_id == selectedItem.item_unique_id
    )[0];

    if (itemdata) {
      if (!itemdata.deduction_item) {
        itemdata.is_split = true;

        if (itemdata.item_split && itemdata.item_split.length > 0) {
          const mainSplit = itemdata.item_split.find(
            (split: any) => split.is_main_split
          );

          if (mainSplit) {
            const currentRemainingPrice = Number(mainSplit.split_item_price);

            if (selectedSplitType === "outer") {
              const newRemainingPrice = currentRemainingPrice - Number(data);

              mainSplit.split_item_price = newRemainingPrice.toString();

              const newSplitData = {
                split_pay_id: generateUniqueId(),
                split_item_id: selectedItem.item_id,
                split_item_price: data,
                split_item_payment_id: "",
                split_item_payment_method: "Unpaid",
                is_main_split: false,
                deduction_item: false,
              };

              itemdata.item_split.push(newSplitData);
            } else if (selectedSplitType === "inner") {
              const newRemainingPrice = currentRemainingPrice - Number(data);

              if (newRemainingPrice < 0) {
                console.error("Split amount exceeds remaining price!");
                return; // Prevent invalid operations
              }

              mainSplit.split_item_price = newRemainingPrice.toString();

              const newSplitData = {
                split_pay_id: generateUniqueId(),
                split_item_id: selectedItem.item_id,
                split_item_price: data,
                split_item_payment_id: "",
                split_item_payment_method: "Unpaid",
                is_main_split: false,
                deduction_item: false,
              };

              itemdata.item_split.push(newSplitData);
            }
          }
        } else {
          const remainingprice = Number(selectedPrice) - Number(data);
          itemdata.item_split = [
            {
              split_pay_id: generateUniqueId(),
              split_item_id: selectedItem.item_id,
              split_item_price: remainingprice.toString(),
              split_item_payment_id: itemdata.item_payment_id
                ? itemdata.item_payment_id
                : paymentOptions && paymentOptions.length > 0
                ? paymentOptions[0].id
                : "",
              split_item_payment_method: itemdata.item_payment_method
                ? itemdata.item_payment_method
                : paymentOptions && paymentOptions.length > 0
                ? paymentOptions[0].paymentmethodtype
                : "",
              is_main_split: true,
              deduction_item: false,
              split_item_membership_type: "",
              split_item_membership_discount: "",
            },
            {
              split_pay_id: generateUniqueId(),
              split_item_id: selectedItem.item_id,
              split_item_price: data,
              split_item_payment_id: "",
              split_item_payment_method: "Unpaid",
              is_main_split: false,
              deduction_item: false,
              split_item_membership_type: "",
              split_item_membership_discount: "",
            },
          ];
          itemdata.item_payment_id = "";
          itemdata.item_payment_method = "";
        }

        // Update the state
        setBillpayloadData((prevData: any) => ({
          ...prevData,
          items: billPayloadData.items.map((x: any) =>
            x.item_unique_id === itemdata.item_unique_id ? itemdata : x
          ),
        }));
      }
    }

    setShowSplitModal(false);
  };

  const handleTaxesEdit = (body: any) => {
    const totalTaxAmount = body.reduce(
      (sum: any, tax: any) => sum + Number(tax.tax_amount),
      0
    );
    setBillpayloadData((prevData: any) => ({
      ...prevData,
      taxes: body,
      total_tax: totalTaxAmount,
    }));
    setShowTaxModal(false);
  };

  useEffect(() => {
    if (billPayloadData) {
      let total_amount =
        Number(billPayloadData?.sub_total) + Number(billPayloadData?.total_tax);
      setBillpayloadData((prevData: any) => ({
        ...prevData,
        final_total: total_amount,
        paid_amount: total_amount + Number(billPayloadData?.tips),
      }));
    }
  }, [
    billPayloadData?.items,
    billPayloadData?.taxes,
    billPayloadData?.tip_value,
  ]);

  const handlePayNow = async () => {
    try {
      if (getUnpaidItemsSum() > 0) {
        message.error(
          `Please check the allocated payment method!（Await pay ${
            currencySymbol.CURRENCY_SYMBOL
          }${getUnpaidItemsSum()}）`
        );
        return;
      }
      if (paymentOptions && paymentOptions.length == 0) {
        message.error("Please select payment method");
        return;
      }
      const url = "/billing/checkout";

      let billPayload = billPayloadData;

      let giftcardPriceSum = 0;
      let giftcardUsageCount = 0;
      let giftcardDetails: { id: string; price: number }[] = []; // Array to store Giftcard details (ID and price)

      // Iterate over items
      billPayloadData.items.forEach((item: any) => {
        // Check if `item_payment_method` is "Giftcard"
        if (item.item_payment_method === "Giftcard") {
          giftcardPriceSum += item.item_price;
          giftcardUsageCount++;
          if (item.item_payment_id) {
            // Add Giftcard details
            giftcardDetails.push({
              id: item.item_payment_id,
              price: item.item_price,
            });
          }
        } else {
          // Check splits only if `item_payment_method` is not "Giftcard"
          const giftcardSplit = item.item_split.find(
            (split: any) => split.split_item_payment_method === "Giftcard"
          );
          if (giftcardSplit) {
            const splitPrice = parseFloat(giftcardSplit.split_item_price);
            giftcardPriceSum += splitPrice;
            giftcardUsageCount++;
            if (giftcardSplit.split_item_payment_id) {
              // Add Giftcard details
              giftcardDetails.push({
                id: giftcardSplit.split_item_payment_id,
                price: splitPrice,
              });
            }
          }
        }
      });

      // Ensure unique Giftcard details by aggregating prices
      const aggregatedGiftcardDetails = giftcardDetails.reduce(
        (acc, { id, price }) => {
          const existing = acc.find((detail) => detail.id === id);
          if (existing) {
            existing.price += price; // Aggregate prices for the same Giftcard
          } else {
            acc.push({ id, price });
          }
          return acc;
        },
        [] as { id: string; price: number }[]
      );

      let packagePriceSum = 0;
      let packageUsageCount = 0;
      let packageDetails: { id: string; price: number }[] = []; // Array to store Giftcard details (ID and price)

      billPayloadData.items.forEach((item: any) => {
        // Check if `item_payment_method` is "Giftcard"
        if (item.item_payment_method === "Package") {
          packagePriceSum += item.item_price;
          packageUsageCount++;
          if (item.item_payment_id) {
            // Add Giftcard details
            packageDetails.push({
              id: item.item_payment_id,
              price: item.item_price,
            });
          }
        } else {
          // Check splits only if `item_payment_method` is not "Giftcard"
          const packageSplit = item.item_split.find(
            (split: any) => split.split_item_payment_method === "Package"
          );
          if (packageSplit) {
            const splitPrice = parseFloat(packageSplit.split_item_price);
            packagePriceSum += splitPrice;
            packageUsageCount++;
            if (packageSplit.split_item_payment_id) {
              // Add Giftcard details
              packageDetails.push({
                id: packageSplit.split_item_payment_id,
                price: splitPrice,
              });
            }
          }
        }
      });

      // Ensure unique Giftcard details by aggregating prices
      const aggregatedPackageDetails = packageDetails.reduce(
        (acc, { id, price }) => {
          const existing = acc.find((detail) => detail.id === id);
          if (existing) {
            existing.price += price; // Aggregate prices for the same Giftcard
          } else {
            acc.push({ id, price });
          }
          return acc;
        },
        [] as { id: string; price: number }[]
      );

      let visitbasedPriceSum = 0;
      let visitbasedUsageCount = 0;
      let visitbasedDetails: { id: string; price: number }[] = []; // Array to store Giftcard details (ID and price)

      // Iterate over items
      billPayloadData.items.forEach((item: any) => {
        // Check if `item_payment_method` is "Giftcard"
        if (item.item_payment_method === "Visit-based") {
          visitbasedPriceSum += item.item_price;
          visitbasedUsageCount++;
          if (item.item_payment_id) {
            // Add Giftcard details
            visitbasedDetails.push({
              id: item.item_payment_id,
              price: item.item_price,
            });
          }
        } else {
          // Check splits only if `item_payment_method` is not "Giftcard"
          const visitbasedSplit = item.item_split.find(
            (split: any) => split.split_item_payment_method === "Visit-based"
          );
          if (visitbasedSplit) {
            const splitPrice = parseFloat(visitbasedSplit.split_item_price);
            visitbasedPriceSum += splitPrice;
            visitbasedUsageCount++;
            if (visitbasedSplit.split_item_payment_id) {
              // Add Giftcard details
              visitbasedDetails.push({
                id: visitbasedSplit.split_item_payment_id,
                price: splitPrice,
              });
            }
          }
        }
      });

      // Ensure unique Giftcard details by aggregating prices
      const aggregatedVisitBasedDetails = visitbasedDetails.reduce(
        (acc, { id, price }) => {
          const existing = acc.find((detail) => detail.id === id);
          if (existing) {
            existing.price += price; // Aggregate prices for the same Giftcard
          } else {
            acc.push({ id, price });
          }
          return acc;
        },
        [] as { id: string; price: number }[]
      );

      billPayload.payment = paymentOptions
        .filter((item: any) => item.payment_paid_amount > 0)
        .map((item: any) => ({
          payment_id: item.id,
          payment_name: item.paymentmethodtype,
          payment_amount: item.payment_paid_amount.toString(),
        }));

      const additionalPayments = aggregatedGiftcardDetails.map((item) => ({
        payment_id: item.id,
        payment_name: "Giftcard",
        payment_amount: item.price.toString(),
      }));

      const additionalpackagePayments = aggregatedPackageDetails.map(
        (item) => ({
          payment_id: item.id,
          payment_name: "Package",
          payment_amount: item.price.toString(),
        })
      );

      const additionalvisitbasedPayments = aggregatedVisitBasedDetails.map(
        (item) => ({
          payment_id: item.id,
          payment_name: "Visit-based",
          payment_amount: item.price.toString(),
        })
      );

      billPayload.payment = [...billPayload.payment, ...additionalPayments];

      billPayload.payment = [
        ...billPayload.payment,
        ...additionalpackagePayments,
      ];

      billPayload.payment = [
        ...billPayload.payment,
        ...additionalvisitbasedPayments,
      ];

      billPayload.taxes = billPayload.taxes.map((item: any) => ({
        ...item,
        tax_amount: item.tax_amount.toString(), // Convert tax_amount to string
      }));
      billPayload.items = billPayload.items.map((item: any) => ({
        ...item,
        ...(item.item_payment_method &&
          item.item_payment_method.includes("Prepaid-") && {
            item_payment_method: item.item_payment_method.split("-")[0],
          }),
        ...(item.item_membership_type &&
          item.item_membership_type == "Discount" && {
            item_payment_id: item.item_mebership_privilege_id,
            item_payment_method: "Discount",
          }),
        item_price: item.item_price.toString(), // Convert item_price to string
      }));
      billPayload.payment = billPayload.payment.map((item: any) => ({
        ...item,
        payment_amount: parseFloat(item.payment_amount).toFixed(2), // Convert payment_amount to fixed with 2 point
      }));
      billPayload.payment = billPayload.payment.map((item: any) => ({
        ...item,
        payment_amount: item.payment_amount.toString(), // Convert payment_amount to string
      }));
      billPayload.final_total = Number(billPayloadData.final_total || 0)
        .toFixed(2)
        .toString();
      billPayload.paid_amount = Number(billPayloadData.paid_amount)
        .toFixed(2)
        .toString();
      billPayload.sub_total = billPayloadData.sub_total?.toString();
      billPayload.tips = Number(billPayloadData.tips || 0)
        .toFixed(2)
        .toString();
      billPayload.total_tax = Number(billPayloadData.total_tax || 0)
        .toFixed(2)
        .toString();
      billPayload.cart_discount = billPayloadData.cart_discount?.toString();
      billPayload.bill_paid_status = true;
      billPayload.bill_status = "paid";
      billPayload.tip_value = billPayloadData.tip_value
        ? billPayloadData.tip_value
        : 0;
      billPayload.tip_value = billPayload.tip_value.toString();
      billPayload.used_giftcard_count = Number(giftcardUsageCount).toString();
      billPayload.used_pkg_count = Number(packageUsageCount).toString();
      billPayload.used_membership_count = Number(0).toString();

      const response = await post(url, billPayload);

      if (response && response.data && response.data.success) {
        message.success("Successfully added!");
        navigate(`/sales/billdetails/${id}`);
      } else {
        message.error(response.data.message || "Submission failed");
      }
    } catch (error: any) {
      message.error(error || "Something went wrong");
    }
  };

  const getUnpaidItemsSum = () => {
    let totalUnpaidAmount = 0;

    billPayloadData?.items.forEach((item: any) => {
      if (item.item_split && item.item_split.length > 0) {
        item.item_split.forEach((split: any) => {
          if (split.split_item_payment_method === "Unpaid") {
            totalUnpaidAmount += Number(split.split_item_price);
          }
        });
      }
    });

    return totalUnpaidAmount;
  };

  const updatePaymentSums = () => {
    if (billPayloadData) {
      const paymentSums = billPayloadData?.items.reduce(
        (acc: any, item: any) => {
          const price = parseFloat(item.item_price);
          const qty = parseFloat(item.item_qty);
          const paymentId = item.item_payment_id;

          if (!isNaN(price) && !isNaN(qty) && paymentId) {
            acc[paymentId] = (acc[paymentId] || 0) + price;
          }

          item.item_split?.forEach((split: any) => {
            const splitPrice = parseFloat(split.split_item_price);
            const splitPaymentId = split.split_item_payment_id;

            if (!isNaN(splitPrice) && splitPaymentId) {
              acc[splitPaymentId] = (acc[splitPaymentId] || 0) + splitPrice;
            }
          });

          return acc;
        },
        {}
      );

      const taxPaymentSums = billPayloadData?.taxes.reduce(
        (acc: any, item: any) => {
          const taxamount = parseFloat(item.tax_amount);

          if (!isNaN(taxamount)) {
            const paymentId = item.tax_payment_id;
            acc[paymentId] = (acc[paymentId] || 0) + taxamount;
          }

          return acc;
        },
        {}
      );

      let tipPaymentSum = 0; // Initialize tip sum
      if (billPayloadData.tip_id) {
        const tipPaymentId =
          billPayloadData.tip_payment_id ||
          (paymentOptions && paymentOptions.length > 0)
            ? paymentOptions[0]?.id
            : ""; // Use first payment option if no tip_payment_id
        const tipValue = parseFloat(billPayloadData.tips);

        if (!isNaN(tipValue)) {
          tipPaymentSum = tipValue; // Add tip to tip sum
        }
      }

      if (paymentOptions && paymentOptions.length > 0) {
        const updatedPaymentOptions = paymentOptions.map((payment: any) => {
          const itemPayment = paymentSums[payment.id] || 0;
          const taxPayment = taxPaymentSums[payment.id] || 0;
          const totalPayment =
            itemPayment +
            taxPayment +
            (payment.id === billPayloadData.tip_payment_id ? tipPaymentSum : 0);
          return {
            ...payment,
            payment_paid_amount: totalPayment,
          };
        });

        setPaymentOptions(updatedPaymentOptions);
      }
    }
  };

  useEffect(() => {
    const allItemsSelected =
      billPayloadData?.items.every((item: any) =>
        selectedItems.has(item.item_unique_id)
      ) &&
      billPayloadData?.taxes.every((tax: any) =>
        selectedItems.has(tax.tax_unique_id)
      ) &&
      selectedItems.has(billPayloadData?.tip_id);

    setSelectAll(allItemsSelected);

    if (!billPayloadData || !billPayloadData.items) {
      setSelectAllUnpaid(false); // Reset to false if data is missing
      return;
    }

    if (selectedItems.size == 0) {
      setSelectAllUnpaid(false);
      return;
    }

    // Gather all unpaid split IDs
    const unpaidSplitIds = billPayloadData.items.flatMap(
      (item: any) =>
        item.item_split
          ?.filter((split: any) => split.split_item_payment_method == "Unpaid")
          .map((split: any) => split.split_pay_id) || []
    );

    // Check if all unpaid splits are selected
    const allUnpaidSplitsSelected = unpaidSplitIds.every((id: any) =>
      selectedItems.has(id)
    );

    // Set SelectAllUnpaid based on conditions
    setSelectAllUnpaid(allUnpaidSplitsSelected);
  }, [selectedItems, billPayloadData?.items, billPayloadData?.taxes]);

  useEffect(() => {
    if (selectedPaymentChanges) {
      updateSelectedItemsPaymentMethod();
    }
  }, [selectedPaymentChanges]);

  useEffect(() => {
    updatePaymentSums();
    // fetchTaxes();
  }, [billPayloadData]);

  useEffect(() => {
    if (billData !== null && paymentOptions && paymentOptions.length > 0) {
      let paymentmethoddata = paymentOptions;
      if (
        billData?.customer_id == null ||
        billData.customer_id == "" ||
        billData.customer_id == undefined
      ) {
        let updatedData = paymentmethoddata.filter(
          (item: any) => item.paymentmethodtype !== "Billing on account"
        );
        setPaymentOptions(updatedData);
        setSelectedPaymentMethodId(updatedData[0].id);
        setSelectedPaymentMethodName(updatedData[0].paymentmethodtype);

        // Calculate sub_total by summing up item prices after applying discount
        const updatedItems = billData?.items.map((item: any) => {
          const item_price = parseFloat(item.item_pay_amount);
          const item_membership_discount = parseFloat(
            item.item_membership_discount
          );

          const item_membership_discount_amount =
            item.item_privilege_name === "Discount"
              ? (item_price * item_membership_discount) / 100
              : 0;

          const discounted_price = item_price - item_membership_discount_amount;

          return {
            item_unique_id: generateUniqueId(),
            item_id: item.item_id,
            item_categorycode: item.item_categorycode,
            item_name: item.item_name,
            item_qty: item.item_qty,
            item_remain_qty: "0",
            item_oneunit_price: "0",
            pvg_taken_qty: parseFloat(item.item_qty),
            pvg_remain_qty: "0",
            item_price: parseFloat(discounted_price.toFixed(2)),
            used_item_price: parseFloat(item.item_price).toFixed(2),
            used_item_price_discount:
              Number(item?.item_membership_discount) || 0,
            item_type: item.item_type,
            item_payment_method:
              item.item_privilege_name &&
              item.item_privilege_name !== "Discount"
                ? item.item_privilege_name
                : item.item_type == "kiv"
                ? "kiv"
                : item.item_type == "course"
                ? "course"
                : updatedData && updatedData.length > 0
                ? updatedData[0].paymentmethodtype
                : "",
            item_payment_id:
              item.item_privilege_name !== "Discount" && item.item_privilege_id
                ? item.item_privilege_id
                : item.item_type == "kiv"
                ? item.item_id
                : item.item_type == "course"
                ? item.item_id
                : updatedData && updatedData.length > 0
                ? updatedData[0].id
                : "",
            item_unit_price: item.item_price,
            deduction_item: item.item_deduction,
            is_split: false,
            item_split: [],
            item_membership_type: item.item_privilege_name,
            item_mebership_privilege_id: item.item_privilege_id,
            item_membership_discount: item.item_membership_discount,
            item_membership_discount_amount: parseFloat(item.item_pay_amount),
          };
        });

        // Calculate sub_total by summing up all item prices
        const subTotal = updatedItems.reduce(
          (total: number, item: any) => total + item.item_price,
          0
        );
        setBillpayloadData({
          outlet_id: localStorage.getItem("outlet_id"),
          bill_id: id,
          ref_no: billData?.ref_no,
          customer_id: billData?.customer_id,
          tip_id: null,
          tip_rate: "",
          tip_value: "",
          tip_payment_method: "",
          tip_payment_id: "",
          taxes: [],
          // items: billData?.items.map((item: any) => ({
          //   item_unique_id: generateUniqueId(),
          //   item_id: item.item_id,
          //   item_categorycode: item.item_categorycode,
          //   item_name: item.item_name,
          //   item_qty: item.item_qty,
          //   item_price: parseFloat(item.item_pay_amount),
          //   item_type: item.item_type,
          //   item_payment_method:
          //     item.item_privilege_name !== "Discount"
          //       ? item.item_privilege_name
          //       : updatedData && updatedData.length > 0
          //       ? updatedData[0].paymentmethodtype
          //       : "",
          //   item_payment_id:
          //     item.item_privilege_name !== "Discount" && item.item_privilege_id
          //       ? item.item_privilege_id
          //       : updatedData && updatedData.length > 0
          //       ? updatedData[0].id
          //       : "",
          //   item_unit_price: item.item_price,
          //   is_split: false,
          //   item_split: [],
          //   item_membership_type: item.item_privilege_name,
          //   item_membership_discount: item.item_membership_discount,
          // })),
          items: updatedItems,
          payment: [],
          cart_discount: 0,
          sub_total: parseFloat(subTotal),
          total_tax: parseFloat(billData?.taxes),
          tips: 0,
          final_total: parseFloat(billData?.final_total),
          paid_amount: parseFloat(billData?.final_total),
        });
      } else {
        if (paymentOptions && paymentOptions.length > 0) {
          setSelectedPaymentMethodId(paymentOptions[0].id);
          setSelectedPaymentMethodName(paymentOptions[0].paymentmethodtype);
        }
        const updatedItems = billData?.items.map((item: any) => {
          const item_price = parseFloat(item.item_pay_amount);
          const item_membership_discount = parseFloat(
            item.item_membership_discount
          );

          const item_membership_discount_amount =
            item.item_privilege_name === "Discount"
              ? (item_price * item_membership_discount) / 100
              : 0;

          const discounted_price = item_price - item_membership_discount_amount;

          return {
            item_unique_id: generateUniqueId(),
            item_id: item.item_id,
            item_categorycode: item.item_categorycode,
            item_name: item.item_name,
            item_qty: item.item_qty,
            item_remain_qty: "0",
            item_oneunit_price: "0",
            pvg_taken_qty: parseFloat(item.item_qty),
            pvg_remain_qty: "0",
            item_price: parseFloat(discounted_price.toFixed(2)),
            item_type: item.item_type,
            item_payment_method:
              item.item_privilege_name &&
              item.item_privilege_name !== "Discount"
                ? item.item_privilege_name
                : item.item_type == "kiv"
                ? "kiv"
                : item.item_type == "course"
                ? "course"
                : paymentOptions && paymentOptions.length > 0
                ? paymentOptions[0].paymentmethodtype
                : "",
            item_payment_id:
              item.item_privilege_name !== "Discount" && item.item_privilege_id
                ? item.item_privilege_id
                : item.item_type == "kiv"
                ? item.item_id
                : item.item_type == "course"
                ? item.item_id
                : paymentOptions && paymentOptions.length > 0
                ? paymentOptions[0].id
                : "",
            item_unit_price: item.item_price,
            deduction_item: item.item_deduction,
            is_split: false,
            item_split: [],
            item_membership_type: item.item_privilege_name,
            item_mebership_privilege_id: item.item_privilege_id,
            used_item_price: parseFloat(item.item_price).toFixed(2),
            used_item_price_discount:
              Number(item?.item_membership_discount) || 0,
            item_membership_discount: item.item_membership_discount,
            item_membership_discount_amount: parseFloat(item.item_pay_amount),
          };
        });

        const subTotal = updatedItems.reduce(
          (total: number, item: any) => total + item.item_price,
          0
        );

        setBillpayloadData({
          outlet_id: localStorage.getItem("outlet_id"),
          bill_id: id,
          ref_no: billData?.ref_no,
          customer_id: billData?.customer_id,
          tip_id: null,
          tip_rate: "",
          tip_value: "",
          tip_payment_method: "",
          tip_payment_id: "",
          taxes: [],
          // items: billData?.items.map((item: any) => ({

          //   item_unique_id: generateUniqueId(),
          //   item_id: item.item_id,
          //   item_categorycode: item.item_categorycode,
          //   item_name: item.item_name,
          //   item_qty: item.item_qty,
          //   item_price: parseFloat(item.item_pay_amount),
          //   item_type: item.item_type,
          //   item_payment_method:
          //     item.item_privilege_name !== "Discount"
          //       ? item.item_privilege_name
          //       : paymentOptions && paymentOptions.length > 0
          //       ? paymentOptions[0].paymentmethodtype
          //       : "",
          //   item_payment_id:
          //     item.item_privilege_name !== "Discount" && item.item_privilege_id
          //       ? item.item_privilege_id
          //       : paymentOptions && paymentOptions.length > 0
          //       ? paymentOptions[0].id
          //       : "",
          //   is_split: false,
          //   item_split: [],
          //   item_membership_type: item.item_privilege_name,
          //   item_membership_discount: item.item_membership_discount,
          // })),
          items: updatedItems,
          payment: [],
          cart_discount: 0,
          sub_total: parseFloat(subTotal),
          total_tax: parseFloat(billData?.taxes),
          tips: 0,
          final_total: parseFloat(billData?.final_total),
          paid_amount: parseFloat(billData?.final_total),
        });
        // fetchCustomerGiftCard();
        // fetchCustomerPackage();
        // getCustomerMembershipPrepaid();
        // getCustomerMembershipDiscount();
        fetchCoustomerCountData();
      }
    }
  }, [billData]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        customerCount?.find((item) => item.type === "Prepaid")
          ?.remaining_balance > 0 ||
        customerCount?.find((item) => item.type === "Prepaid")
          ?.remaining_bouns > 0
      ) {
        await getCustomerMembershipPrepaid();
      }

      if (
        customerCount?.find((item) => item.type === "Discount")?.total_count > 0
      ) {
        await getCustomerMembershipDiscount();
      }

      if (
        customerCount?.find((item) => item.type === "Visit-based")
          ?.total_count > 0
      ) {
        await getCustomerMembershipVisitBased();
      }

      if (
        customerCount?.find((item) => item.type === "Giftcard")?.total_count > 0
      ) {
        await fetchCustomerGiftCard();
      }

      if (
        customerCount?.find((item) => item.type === "Package")?.total_count > 0
      ) {
        await fetchCustomerPackage();
      }
    };
    fetchData();
  }, [customerCountChange]);

  useEffect(() => {
    if (billPayloadData?.items) {
      const subTotal = billPayloadData.items?.reduce(
        (total: number, item: any) => total + item.item_price,
        0
      );
      setBillpayloadData((prevData: any) => ({
        ...prevData,
        sub_total: subTotal,
      }));
    }
  }, [isPrivilegeChange]);

  const generateUniqueId = () => {
    const uniqueId = nanoid(8);
    return uniqueId;
  };

  const fetchTaxes = async () => {
    try {
      const response = await get(
        `/tax/addmanuallylist/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        await setTaxesItems(response.data.data);
      } else {
        message.error("Failed to fetch taxess");
      }
    } catch (error) {
      console.error("Error fetching taxes: ", error);
      message.error("Error fetching taxes");
    }
  };

  useEffect(() => {
    if (billPayloadData && billData) {
      if (loadData) {
        let taxes: any = [];

        billData?.items.forEach((item: any) => {
          if (item.item_tax_rate) {
            const taxAmount =
              (parseFloat(item.item_tax_rate) / 100) *
              parseFloat(item.item_price);
            taxes.push({
              tax_unique_id: generateUniqueId(),
              tax_id: item.item_tax,
              tax_name: item.item_tax_name,
              tax_rate: item.item_tax_rate,
              tax_amount: taxAmount,
              tax_payment_method:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0]?.paymentmethodtype
                  : "",
              tax_payment_id:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0]?.id
                  : "",
            });
          }
        });

        if (taxesItems && taxesItems.length > 0) {
          // const hasPrivilegeName = billPayloadData?.items.some(
          //   (item: any) =>
          //     "item_privilege_name" in item &&
          //     item.item_privilege_name == "Giftcard"
          // );

          const hasPrivilegeName = billPayloadData?.items.some(
            (item: any) =>
              "item_privilege_name" in item &&
              (item.item_privilege_name === "Giftcard" ||
                (item.item_privilege_name &&
                  item.item_privilege_name.includes("Prepaid-")))
          );

          if (!hasPrivilegeName && billPayloadData !== null) {
            taxesItems.forEach((taxItem) => {
              const taxAmount =
                (Number(billPayloadData.sub_total) * Number(taxItem.taxrate)) /
                100;
              taxes.push({
                tax_unique_id: generateUniqueId(),
                tax_id: taxItem.id,
                tax_name: taxItem.taxname,
                tax_rate: taxItem.taxrate,
                tax_amount: taxAmount,
                tax_payment_method:
                  paymentOptions && paymentOptions.length > 0
                    ? paymentOptions[0]?.paymentmethodtype
                    : "",
                tax_payment_id:
                  paymentOptions && paymentOptions.length > 0
                    ? paymentOptions[0]?.id
                    : "",
              });
            });
          }
        }

        const totalTax = taxes.reduce(
          (sum: any, tax: any) => sum + parseFloat(tax.tax_amount),
          0
        );

        setBillpayloadData((prevData: any) => ({
          ...prevData,
          taxes: taxes,
          total_tax: totalTax,
        }));
        setLoadData(false);
      }
    }
  }, [billPayloadData?.items, taxesItems]);

  const fetchCustomerGiftCard = async () => {
    if (billData?.customer_id) {
      try {
        const url = `/billing/customer/giftcarddetails/${
          billData?.customer_id
        }/${localStorage.getItem("outlet_id")}`;
        const response = await get(url);

        if (response?.data?.success) {
          const customerGiftCard = response?.data?.data;
          setCustomerGiftCardData(customerGiftCard);
          setCustomerTempGiftCardData(customerGiftCard);
        } else {
          console.log("Error in API call: ", response);
        }
      } catch (error) {
        console.error("Fetch error: ", error);
      }
    }
  };

  const fetchCustomerPackage = async () => {
    if (billData?.customer_id) {
      try {
        const url = `/billing/customer/packagedetails/${
          billData?.customer_id
        }/${localStorage.getItem("outlet_id")}`;
        const response = await get(url);

        if (response?.data?.success) {
          const customerPackage = response?.data?.data;
          setCustomerPackageData(customerPackage);
          setCustomerTempPackageData(customerPackage);
        } else {
          console.log("Error in API call: ", response);
        }
      } catch (error) {
        console.error("Fetch error: ", error);
      }
    }
  };

  const getCustomerMembershipDiscount = async () => {
    const outlateid = localStorage.getItem("outlet_id");
    if (billData?.customer_id && outlateid) {
      try {
        const url = `/billing/customer/discountdetails/${billData?.customer_id}/${outlateid}`;
        const response = await get(url);
        if (response.data.success) {
          const discountData = response.data.data;
          // console.log("discountData...", discountData);
          setCustomerMembershipDiscountData(discountData);
          setCustomerTempMembershipDiscountData(discountData);
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  const getCustomerMembershipVisitBased = async () => {
    const outlateid = localStorage.getItem("outlet_id");
    if (billData?.customer_id && outlateid) {
      try {
        const url = `/billing/customer/visitdetails/${billData?.customer_id}/${outlateid}`;
        const response = await get(url);
        if (response.data.success) {
          const discountData = response.data.data;
          // console.log("discountData...", discountData);
          setCustomerMembershipVisitBasedData(discountData);
          setCustomerTempMembershipVisitBasedData(discountData);
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  const fetchCoustomerCountData = async () => {
    const outlateid = localStorage.getItem("outlet_id");
    const id = selectedCustomer?.id;
    if (outlateid && billData?.customer_id) {
      try {
        const url = `/customer/privilege/${outlateid}/${billData?.customer_id}`;
        const response = await get(url);
        if (response.data.success) {
          const countData = response.data.data;
          await setCustomerCount(countData);
          await setCustomerCountChanges(!customerCountChange);
        } else {
          console.error("Failed to fetch customer count.");
        }
      } catch (error) {
        console.error("Error fetching customer count: ", error);
      }
    }
  };

  const getCustomerMembershipPrepaid = async () => {
    const outlateid = localStorage.getItem("outlet_id");
    if (billData?.customer_id && outlateid) {
      try {
        const url = `/billing/customer/storedetails/${billData?.customer_id}/${outlateid}`;
        const response = await get(url);
        if (response.data.success) {
          const discountData = response.data.data;
          setCustomerMembershipPrepaidData(discountData);
        }
      } catch (error) {
        console.error("Error while getting package details", error);
      }
    }
  };

  // useEffect(() => {
  //   fetchPaymentMethods();
  //   fetchCartDetails(id);
  //   setShowTipModal(true);
  //   // fetchTaxes();
  //   setLoadData(true);
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      await fetchPaymentMethods();
      await fetchTaxes();
      await fetchCartDetails(id);
      setTaxesUpdate(!taxesUpdate);
      setShowTipModal(true);
      setLoadData(true);
    };

    fetchData();
  }, [id]);

  const handleCustomerToggle = () => {
    setIsCustomerPrivilegeVisible(!isCustomerPrivilegeVisible);
  };
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [selectedGiftcard, setSelectedGiftcard] = useState<any>(null);
  const handleOnlyCustomerGiftCardModal = () => {
    setShowOnlyCustomerGiftCardModal(true);
    setSelectedCustomer({
      id: billData?.customer_id,
    });
  };
  const handleOnlyCustomerPackageModal = () => {
    setShowOnlyCustomerPackageModal(true);
    setSelectedCustomer({
      id: billData?.customer_id,
    });
  };
  const handleCustomerMembershipListModal = () => {
    setShowCustomerMembershipListModal(true);
  };
  const handleCustomerMembershipPrepaidModal = () => {
    setShowCustomerPrepaidModal(true);
  };

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate(`/sales/billing/${id}`)}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Checkout</h2>
          </div>
        </Col>
      </Row>

      {/* Main Layout */}
      <Row
        gutter={24}
        style={{
          padding: "24px 24px 24px 24px",
          overflowY: "auto",
        }}
      >
        {/* Left Section */}
        <Col
          span={18}
          style={{
            border: "1px solid #E6E6E6",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
            height: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              borderBottom: ".5px solid #e0e7fe",
              padding: "16px 16px 12px 16px",
            }}
          >
            <Row gutter={24}>
              <Col span={4}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginRight: "6px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  Date:
                </Text>{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {billData?.order_date}
                </Text>
              </Col>
              <Col span={4}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginRight: "6px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  Ref#:
                </Text>{" "}
                <Text style={{ fontWeight: "bold" }}>{billData?.ref_no}</Text>
              </Col>
              <Col span={5}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginRight: "6px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  Customer:
                </Text>
                {"    "}
                <Text style={{ marginRight: "12px", fontWeight: "bold" }}>
                  {billData?.male_count}
                </Text>
                {"    "}
                <span
                  style={{
                    color: "#2e56f2",
                    marginRight: "12px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  <img
                    src={maleIcon}
                    style={{ width: "12px", height: "12px" }}
                  />
                </span>
                {"    "}
                <Text style={{ marginRight: "12px", fontWeight: "bold" }}>
                  {billData?.female_count}
                </Text>
                {"    "}
                <span
                  style={{
                    color: "#2e56f2",
                    marginRight: "12px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  <img
                    src={femaleIcon}
                    style={{ width: "12px", height: "12px" }}
                  />
                </span>
              </Col>
              <Col span={5}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginRight: "6px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  Customer source:
                </Text>{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {billData?.customer_source_name}
                </Text>
              </Col>
            </Row>
          </div>

          <div
            style={{
              height: "calc(100% - 180px)",
              overflowY: "auto",
              padding: "12px 18px 72px 18px",
              maxHeight: "650px",
            }}
          >
            {billData?.customer_id !== "" && (
              <Card
                style={{
                  marginTop: "10px",
                  backgroundColor: "#6e7687",
                  borderRadius: "20px",
                  padding: "10px",
                  color: "#fff",
                  display: "flex",
                  position: "relative",
                  // height: "80px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundImage:
                    "radial-gradient(circle, rgba(126,130,153,1) 0%, rgba(85,90,110,1) 100%)",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                }}
                bodyStyle={{ padding: "0px", position: "relative" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    <Avatar
                      size={48}
                      icon={<UserOutlined />}
                      style={{ backgroundColor: "#8a92a5" }}
                    />
                  </div>

                  <div
                    style={{
                      marginLeft: "15px",
                      textAlign: "justify",
                      width: "100%",
                    }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Text
                          strong
                          style={{ color: "#fff", fontSize: "16px" }}
                        >
                          {billData?.customer_name}
                        </Text>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                            }}
                          >
                            Phone number: {billData?.customer_phone}
                          </Text>
                          <EyeInvisibleOutlined
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <Text style={{ color: "#c0c5cf", fontSize: "14px" }}>
                          customer code : {billData?.customer_code}
                        </Text>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                            }}
                          >
                            Email:{" "}
                            {billData?.customer_email
                              ? billData?.customer_email
                              : "-"}
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #e0e7fe",
                    marginTop: "10px",
                  }}
                />

                <Row
                  gutter={24}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "#fff",
                      }}
                    >
                      Available balance:
                    </Text>
                    <Text
                      style={{
                        fontSize: "14px",
                        color: "#fff",
                        borderBottom: "1px solid #fff",
                        cursor: "pointer",
                      }}
                      onClick={handleCustomerMembershipPrepaidModal}
                    >
                      {currencySymbol.CURRENCY_SYMBOL}
                      {customerCount?.find((item) => item.type === "Prepaid")
                        ?.remaining_balance || 0}
                      /{currencySymbol.CURRENCY_SYMBOL}
                      {customerCount?.find((item) => item.type === "Prepaid")
                        ?.remaining_bouns || 0}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "#fff",
                      }}
                    >
                      Membership
                    </Text>
                    <Text
                      style={{
                        fontSize: "14px",
                        color: "#fff",
                        borderBottom: "1px solid #fff",
                        cursor: "pointer",
                      }}
                      onClick={handleCustomerMembershipListModal}
                    >
                      {mergeMembershipListData &&
                      mergeMembershipListData.length > 0
                        ? mergeMembershipListData.length
                        : 0}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "#fff",
                      }}
                    >
                      Gift Card
                    </Text>
                    <Text
                      style={{
                        fontSize: "14px",
                        color: "#fff",
                        borderBottom: "1px solid #fff",
                        cursor: "pointer",
                      }}
                      onClick={handleOnlyCustomerGiftCardModal}
                    >
                      {customerGiftCardData && customerGiftCardData.length > 0
                        ? customerGiftCardData.length
                        : 0}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "#fff",
                      }}
                    >
                      Package
                    </Text>
                    <Text
                      style={{
                        fontSize: "14px",
                        color: "#fff",
                        borderBottom: "1px solid #fff",
                        cursor: "pointer",
                      }}
                      onClick={handleOnlyCustomerPackageModal}
                    >
                      {customerPackageData && customerPackageData.length > 0
                        ? customerPackageData.length
                        : 0}
                    </Text>
                  </Col>
                </Row>
              </Card>
            )}
            {billPayloadData?.items.map((item: any, index: any) => (
              <>
                <Row
                  key={index}
                  gutter={[12, 12]}
                  style={{
                    backgroundColor: "#fff",
                    minHeight: "60px",
                    alignItems: "center",
                  }}
                >
                  <Col span={1}>
                    <Checkbox
                      checked={selectedItems.has(item.item_unique_id)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          item.item_unique_id,
                          e.target.checked
                        )
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <Text
                      style={{ flex: 2, marginLeft: "10px", fontWeight: 700 }}
                    >
                      {item.item_name}
                    </Text>
                  </Col>
                  <Col span={2}>
                    {item.item_payment_method == "Visit-based" ? (
                      <Text style={{ flex: 1, textAlign: "center" }}>
                        x
                        {parseFloat(item.item_remain_qty) +
                          parseFloat(item.item_qty)}
                      </Text>
                    ) : (
                      <Text style={{ flex: 1, textAlign: "center" }}>
                        x{Number(item.item_qty)}
                      </Text>
                    )}
                  </Col>
                  <Col span={3}>
                    {item.item_name !== "onlinesales" &&
                      item.item_name !== "Debt" &&
                      item.item_type !== "course" &&
                      item.item_type !== "kiv" && (
                        <>
                          {item.item_payment_method == "Visit-based" ? (
                            <img
                              src={splitIcon}
                              style={{
                                flex: 1,
                                textAlign: "left",
                                width: "20px",
                                height: "20px",
                                cursor:
                                  !item.deduction_item &&
                                  item.item_remain_qty !== "0"
                                    ? "pointer"
                                    : "not-allowed",
                                opacity:
                                  !item.deduction_item &&
                                  item.item_remain_qty !== "0"
                                    ? "100%"
                                    : "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={splitIcon}
                              style={{
                                flex: 1,
                                textAlign: "left",
                                width: "20px",
                                height: "20px",
                                cursor: !item.deduction_item
                                  ? "pointer"
                                  : "not-allowed",
                                opacity: !item.deduction_item ? "100%" : "50%",
                              }}
                              onClick={() =>
                                !item.deduction_item
                                  ? splitItem(
                                      item.id,
                                      item.item_price,
                                      item,
                                      "outer"
                                    )
                                  : null
                              }
                            />
                          )}
                        </>
                      )}
                    {/* {item.item_payment_method == "Giftcard" ? (
                      <>
                        <img
                          src={selectedgiftcardIcon}
                          style={{
                            flex: 1,
                            textAlign: "left",
                            marginLeft: "15px",
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            giftcardItem(
                              item.id,
                              item.item_price,
                              item,
                              "outer"
                            );
                          }}
                        />
                      </>
                    ) : item.item_payment_method == "Package" ? (
                      <>
                        <img
                          src={selectedgiftcardIcon}
                          style={{
                            flex: 1,
                            textAlign: "left",
                            marginLeft: "15px",
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handlePackageItem(
                              item.id,
                              item.item_price,
                              item,
                              "outer"
                            );
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={giftcardIcon}
                          style={{
                            flex: 1,
                            textAlign: "left",
                            marginLeft: "15px",
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            giftcardItem(
                              item.id,
                              item.item_price,
                              item,
                              "outer"
                            );
                          }}
                        />
                      </>
                    )} */}
                    {/* {item.item_payment_method !== "Giftcard" &&
                      item.item_payment_method !== "Package" &&
                      item.item_payment_method &&
                      !item.item_payment_method.includes("Prepaid-") &&
                      item.item_membership_type &&
                      item.item_membership_type !== "Discount" && (
                        <img
                          src={giftcardIcon}
                          style={{
                            flex: 1,
                            textAlign: "left",
                            marginLeft: "15px",
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            giftcardItem(
                              item.id,
                              item.item_price,
                              item,
                              "outer"
                            );
                          }}
                        />
                      )} */}
                    {item.item_name !== "onlinesales" &&
                      item.item_name !== "Debt" &&
                      item.item_type !== "course" &&
                      item.item_type !== "kiv" && (
                        <>
                          {item.item_payment_method == "Giftcard" ||
                          item.item_payment_method == "Package" ||
                          item.item_payment_method == "Visit-based" ||
                          (item.item_payment_method &&
                            item.item_payment_method?.includes("Prepaid-")) ||
                          (item.item_membership_type &&
                            item.item_membership_type == "Discount") ? (
                            <img
                              src={selectedgiftcardIcon}
                              style={{
                                flex: 1,
                                textAlign: "left",
                                marginLeft: "15px",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                giftcardItem(
                                  item.id,
                                  item.item_price,
                                  item,
                                  "outer",
                                  null,
                                  true
                                );
                              }}
                            />
                          ) : (
                            <>
                              <img
                                src={giftcardIcon}
                                style={{
                                  flex: 1,
                                  textAlign: "left",
                                  marginLeft: "15px",
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  giftcardItem(
                                    item.id,
                                    item.item_price,
                                    item,
                                    "outer",
                                    null,
                                    true
                                  );
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                  </Col>
                  <Col span={4}>
                    {item.item_type == "kiv" ? (
                      <>
                        <div
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          {item.item_qty} Deduction
                        </div>
                      </>
                    ) : item.item_type == "course" ? (
                      <>
                        <div
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          {item.item_qty} Deduction
                        </div>
                      </>
                    ) : item.item_payment_method == "Package" ? (
                      <>
                        <div
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          1 Deduction
                        </div>
                        <Text
                          style={{
                            flex: 1,
                            textAlign: "right",
                            fontWeight: 700,
                            fontSize: "12px",
                            textDecoration: "line-through",
                            color: "rgba(0,0,0,.45)",
                          }}
                        >
                          {currencySymbol.CURRENCY_SYMBOL}
                          {Number(item.item_price).toFixed(2)}
                        </Text>
                      </>
                    ) : item.item_payment_method == "Visit-based" ? (
                      <>
                        <div
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          {item.item_qty} Deduction
                        </div>
                        {item.item_remain_qty > 0 && (
                          <>
                            <Text
                              style={{
                                flex: 1,
                                textAlign: "right",
                                fontWeight: 700,
                                fontSize: "12px",
                                color: "rgba(0,0,0,.45)",
                              }}
                            >
                              {currencySymbol.CURRENCY_SYMBOL}
                              {(
                                parseFloat(item.item_qty) *
                                parseFloat(item.item_oneunit_price)
                              ).toFixed(2)}
                            </Text>
                            <Text
                              style={{
                                flex: 1,
                                textAlign: "right",
                                fontWeight: 700,
                                fontSize: "12px",
                                textDecoration: "line-through",
                                color: "rgba(0,0,0,.45)",
                              }}
                            >
                              {currencySymbol.CURRENCY_SYMBOL}
                              {(
                                (parseFloat(item.item_remain_qty) +
                                  parseFloat(item.item_qty)) *
                                parseFloat(item.item_oneunit_price)
                              ).toFixed(2)}
                            </Text>
                          </>
                        )}
                        {item.item_remain_qty == 0 && (
                          <Text
                            style={{
                              flex: 1,
                              textAlign: "right",
                              fontWeight: 700,
                              fontSize: "12px",
                              textDecoration: "line-through",
                              color: "rgba(0,0,0,.45)",
                            }}
                          >
                            {currencySymbol.CURRENCY_SYMBOL}
                            {parseFloat(item.item_price).toFixed(2)}
                          </Text>
                        )}
                      </>
                    ) : item.item_membership_type == "Discount" ? (
                      <>
                        <div>
                          <Text
                            style={{
                              flex: 1,
                              textAlign: "center",
                              fontWeight: 700,
                              fontSize: "14px",
                            }}
                          >
                            {currencySymbol.CURRENCY_SYMBOL}
                            {Number(item.item_price).toFixed(2)}
                          </Text>
                          <div>
                            <span
                              style={{
                                backgroundColor: "#ffb800",
                                borderRadius: "3px",
                                padding: "0px 4px",
                                fontSize: "10px",
                                fontWeight: 700,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {item.item_membership_discount}%OFF
                            </span>
                            <Text
                              style={{
                                flex: 1,
                                textAlign: "center",
                                fontWeight: 700,
                                fontSize: "12px",
                                textDecoration: "line-through",
                                color: "rgba(0,0,0,.45)",
                              }}
                            >
                              {currencySymbol.CURRENCY_SYMBOL}
                              {Number(
                                item.item_membership_discount_amount
                              ).toFixed(2)}
                            </Text>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {item.item_payment_method &&
                        item.item_payment_method?.includes("Prepaid-") ? (
                          <>
                            <Text
                              style={{
                                flex: 1,
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                marginLeft:
                                  item.used_item_price_discount !== 0
                                    ? "0px"
                                    : "0px",
                              }}
                            >
                              {currencySymbol.CURRENCY_SYMBOL}
                              {Number(item.item_price).toFixed(2)}
                            </Text>
                            <br />
                            {item.used_item_price_discount !== 0 && (
                              <Text
                                style={{
                                  flex: 1,
                                  textAlign: "center",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  background: "#ffb800",
                                  color: "#fff",
                                  padding: "0 4px",
                                  marginRight: "12px",
                                }}
                              >
                                {item.used_item_price_discount}% off
                              </Text>
                            )}
                            {Number(item.item_price) !==
                              Number(item.used_item_price) && (
                              <Text
                                style={{
                                  flex: 1,
                                  textAlign: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  textDecoration: "line-through",
                                }}
                              >
                                {currencySymbol.CURRENCY_SYMBOL}
                                {Number(item.used_item_price).toFixed(2)}
                              </Text>
                            )}
                          </>
                        ) : (
                          <Text
                            style={{
                              flex: 1,
                              textAlign: "center",
                              fontWeight: 700,
                              fontSize: "14px",
                            }}
                          >
                            {currencySymbol.CURRENCY_SYMBOL}
                            {Number(item.item_price).toFixed(2)}
                          </Text>
                        )}
                      </>
                    )}
                  </Col>
                  <Col span={4}>
                    {item.item_split &&
                      item.item_split.length > 0 &&
                      item.item_payment_method === "Visit-based" && (
                        <Text
                          style={{
                            flex: 1,
                            textAlign: "right",
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#333333",
                            whiteSpace: "nowrap", // Prevent text wrapping
                            overflow: "hidden", // Hide overflow text
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.item_payment_method}
                        </Text>
                      )}
                    {item.item_split && item.item_split.length === 0 && (
                      <>
                        {item.item_payment_method === "Giftcard" ? (
                          <Text
                            style={{
                              flex: 1,
                              textAlign: "right",
                              fontWeight: 700,
                              fontSize: "13px",
                              color: "#833fd9",
                              borderColor: "#833fd9",
                              background: "rgba(131,63,217,.1)",
                              padding: "8px 12px",
                            }}
                          >
                            {item.deduction_item == true
                              ? "1 deduction"
                              : "Gift Card"}
                          </Text>
                        ) : item.item_payment_method &&
                          item.item_payment_method.includes("Prepaid-") ? (
                          <Text
                            style={{
                              flex: 1,
                              textAlign: "right",
                              fontWeight: 700,
                              fontSize: "13px",
                              color: "#20b8fa",
                              padding: "0 4px",
                              background: "rgba(32,184,250,.1)",
                              border: "1px solid #20b8fa",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.item_payment_method.split("-")[1]}
                          </Text>
                        ) : (
                          <Text
                            style={{
                              flex: 1,
                              textAlign: "right",
                              fontWeight: 700,
                              fontSize: "13px",
                              color:
                                item.item_payment_method === "Unpaid"
                                  ? "#f43b00"
                                  : "#333333",
                              whiteSpace: "nowrap", // Prevent text wrapping
                              overflow: "hidden", // Hide overflow text
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.item_payment_method}
                          </Text>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
                {item.item_split &&
                  item.item_split.length > 0 &&
                  item?.item_split.map((inneritem: any, index: any) => (
                    <Row
                      key={index}
                      gutter={[12, 12]}
                      style={{
                        backgroundColor: "#fff",
                        minHeight: "60px",
                        alignItems: "center",
                      }}
                    >
                      <Col span={1}></Col>
                      <Col span={10}>
                        <Checkbox
                          checked={selectedItems.has(inneritem.split_pay_id)}
                          onChange={(e) =>
                            handleCheckboxChange(
                              inneritem.split_pay_id,
                              e.target.checked
                            )
                          }
                        />
                      </Col>
                      <Col span={2}></Col>
                      <Col span={4}>
                        {inneritem && inneritem.is_main_split && (
                          <>
                            <img
                              src={splitIcon}
                              style={{
                                flex: 1,
                                textAlign: "left",
                                width: "20px",
                                height: "20px",
                                cursor:
                                  inneritem.split_item_payment_method !==
                                  "Visit-based"
                                    ? "pointer"
                                    : "not-allowed",
                                opacity:
                                  inneritem.split_item_payment_method !==
                                  "Visit-based"
                                    ? "100%"
                                    : "50%",
                              }}
                              onClick={() =>
                                splitItem(
                                  inneritem.split_pay_id,
                                  inneritem.split_item_price,
                                  item,
                                  "inner"
                                )
                              }
                            />
                          </>
                        )}
                        {inneritem && !inneritem.is_main_split && (
                          <>
                            <img
                              src={aftersplitIcon}
                              style={{
                                flex: 1,
                                textAlign: "left",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                removeSplitItem(inneritem.split_pay_id, item)
                              }
                            />
                          </>
                        )}
                        {inneritem.split_item_payment_method !== "Giftcard" &&
                          inneritem.split_item_payment_method !== "Package" &&
                          inneritem.split_item_payment_method !==
                            "Visit-based" &&
                          !inneritem.item_payment_method?.includes(
                            "Prepaid-"
                          ) && (
                            <img
                              src={giftcardIcon}
                              style={{
                                flex: 1,
                                textAlign: "left",
                                marginLeft: "15px",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                giftcardItem(
                                  inneritem.split_pay_id,
                                  inneritem.split_item_price,
                                  item,
                                  "inner",
                                  inneritem,
                                  false
                                );
                              }}
                            />
                          )}
                        {(inneritem.split_item_payment_method == "Giftcard" ||
                          inneritem.split_item_payment_method == "Package" ||
                          inneritem.split_item_payment_method ==
                            "Visit-based" ||
                          inneritem.item_payment_method?.includes(
                            "Prepaid-"
                          )) && (
                          <img
                            src={selectedgiftcardIcon}
                            style={{
                              flex: 1,
                              textAlign: "left",
                              marginLeft: "15px",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              giftcardItem(
                                inneritem.split_pay_id,
                                inneritem.split_item_price,
                                item,
                                "inner",
                                inneritem,
                                true
                              );
                            }}
                          />
                        )}
                      </Col>
                      <Col span={3}>
                        <Text
                          style={{
                            flex: 1,
                            textAlign: "center",
                            fontWeight: 700,
                            fontSize: "14px",
                          }}
                        >
                          {currencySymbol.CURRENCY_SYMBOL}
                          {Number(inneritem.split_item_price).toFixed(2)}
                        </Text>
                      </Col>
                      <Col span={4}>
                        <>
                          {inneritem.split_item_payment_method == "Giftcard" ? (
                            <Text
                              style={{
                                flex: 1,
                                textAlign: "right",
                                fontWeight: 700,
                                fontSize: "13px",
                                color: "#833fd9",
                                borderColor: "#833fd9",
                                background: "rgba(131,63,217,.1)",
                                padding: "8px 12px",
                              }}
                            >
                              {inneritem.deduction_item == true
                                ? "1 deduction"
                                : "Gift Card"}
                            </Text>
                          ) : inneritem.split_item_payment_method &&
                            inneritem.split_item_payment_method.includes(
                              "Prepaid-"
                            ) ? (
                            <Text
                              style={{
                                flex: 1,
                                textAlign: "right",
                                fontWeight: 700,
                                fontSize: "13px",
                                color: "#20b8fa",
                                padding: "0 4px",
                                background: "rgba(32,184,250,.1)",
                                border: "1px solid #20b8fa",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {
                                inneritem.split_item_payment_method.split(
                                  "-"
                                )[1]
                              }
                            </Text>
                          ) : (
                            <Text
                              style={{
                                flex: 1,
                                textAlign: "right",
                                fontWeight: 700,
                                fontSize: "13px",
                                color:
                                  inneritem.split_item_payment_method ==
                                  "Unpaid"
                                    ? "#f43b00"
                                    : "#333333",
                              }}
                            >
                              {inneritem.split_item_payment_method}
                            </Text>
                          )}
                        </>
                      </Col>
                    </Row>
                  ))}
                {/* {item.item_type == "Service" &&
                  billData?.customer_id !== "" && (
                    <Row
                      gutter={[12, 12]}
                      style={{
                        backgroundColor: "#fff",
                        alignItems: "center",
                      }}
                    >
                      <Col span={1}></Col>
                      <Col span={10}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>Treatment</span>
                            <Input
                              style={{ width: "30%", marginLeft: "30px" }}
                              placeholder=""
                              type="number"
                              min="0"
                              max={item.item_qty}
                              value={item.pvg_taken_qty}
                              onChange={(e) =>
                                handleInputChange(
                                  item,
                                  "pvg_taken_qty",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>Course</span>
                            <Input
                              style={{ width: "30%", marginLeft: "30px" }}
                              placeholder=""
                              value={item.pvg_remain_qty}
                              type="number"
                              min="0"
                              max={item.item_qty}
                              onChange={(e) =>
                                handleInputChange(
                                  item,
                                  "pvg_remain_qty",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </Col>
                      <Col span={13}></Col>
                    </Row>
                  )}
                {item.item_type == "Product" &&
                  billData?.customer_id !== "" && (
                    <Row
                      gutter={[12, 12]}
                      style={{
                        backgroundColor: "#fff",
                        alignItems: "center",
                      }}
                    >
                      <Col span={1}></Col>
                      <Col span={10}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>Taken</span>
                            <Input
                              style={{ width: "30%", marginLeft: "30px" }}
                              placeholder=""
                              type="number"
                              min="0"
                              max={item.item_qty}
                              value={item.pvg_taken_qty}
                              onChange={(e) =>
                                handleInputChange(
                                  item,
                                  "pvg_taken_qty",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>KIV</span>
                            <Input
                              style={{ width: "30%", marginLeft: "30px" }}
                              placeholder=""
                              value={item.pvg_remain_qty}
                              type="number"
                              min="0"
                              max={item.item_qty}
                              onChange={(e) =>
                                handleInputChange(
                                  item,
                                  "pvg_remain_qty",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </Col>
                      <Col span={13}></Col>
                    </Row>
                  )} */}
                {item.item_type == "Service" &&
                  billData?.customer_id !== "" && (
                    <Row
                      gutter={[12, 12]}
                      style={{
                        backgroundColor: "#fff",
                        alignItems: "center",
                      }}
                    >
                      <Col span={1}></Col>
                      <Col span={10}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                Course
                              </span>
                              <span
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                {item.pvg_remain_qty}
                              </span>
                            </div>
                            <Slider
                              style={{ width: "30%", marginLeft: "30px" }}
                              min={0}
                              max={item.item_qty}
                              value={item.pvg_taken_qty}
                              onChange={(value) =>
                                handleInputChange(item, "pvg_taken_qty", value)
                              }
                              className="slider-kiv-taken"
                              tipFormatter={null}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                alignItems: "center",
                                marginLeft: "30px",
                              }}
                            >
                              <span
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                Treatment
                              </span>
                              <span
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                {item.pvg_taken_qty}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col span={13}></Col>
                    </Row>
                  )}

                {item.item_type == "Product" &&
                  billData?.customer_id !== "" && (
                    <Row
                      gutter={[12, 12]}
                      style={{
                        backgroundColor: "#fff",
                        alignItems: "center",
                      }}
                    >
                      <Col span={1}></Col>
                      <Col span={10}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                KIV
                              </span>
                              <span
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                {item.pvg_remain_qty}
                              </span>
                            </div>
                            <Slider
                              style={{ width: "30%", marginLeft: "30px" }}
                              min={0}
                              max={item.item_qty}
                              value={item.pvg_taken_qty}
                              onChange={(value) =>
                                handleInputChange(item, "pvg_taken_qty", value)
                              }
                              className="slider-kiv-taken"
                              tipFormatter={null}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                alignItems: "center",
                                marginLeft: "30px",
                              }}
                            >
                              <span
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                Taken
                              </span>
                              <span
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                {item.pvg_taken_qty}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col span={13}></Col>
                    </Row>
                  )}
              </>
            ))}
            {billPayloadData?.taxes.map((item: any, index: any) => (
              <>
                <Row
                  key={index}
                  gutter={[12, 12]}
                  style={{
                    backgroundColor: "#fff",
                    minHeight: "60px",
                    alignItems: "center",
                  }}
                >
                  <Col span={1}>
                    <Checkbox
                      checked={selectedItems.has(item.tax_unique_id)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          item.tax_unique_id,
                          e.target.checked
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Text
                      style={{ flex: 2, marginLeft: "10px", fontWeight: 700 }}
                    >
                      {item.tax_name} ({Number(item.tax_rate).toFixed(2)}%)
                    </Text>
                  </Col>
                  <Col span={3}></Col>
                  <Col span={4}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      {currencySymbol.CURRENCY_SYMBOL}
                      {Number(item.tax_amount).toFixed(2)}
                    </Text>
                  </Col>
                  <Col span={4}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "right",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      {item.tax_payment_method}
                    </Text>
                  </Col>
                </Row>
              </>
            ))}
            {billPayloadData?.tip_id && billPayloadData?.tip_value !== "" && (
              <>
                <Row
                  key={billPayloadData.tip_id}
                  gutter={[12, 12]}
                  style={{
                    backgroundColor: "#fff",
                    minHeight: "60px",
                    alignItems: "center",
                  }}
                >
                  <Col span={1}>
                    <Checkbox
                      checked={selectedItems.has(billPayloadData.tip_id)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          billPayloadData.tip_id,
                          e.target.checked
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Text
                      style={{ flex: 2, marginLeft: "10px", fontWeight: 700 }}
                    >
                      Tips {Number(billPayloadData.tip_rate).toFixed(2)}%
                    </Text>
                  </Col>
                  <Col span={3}></Col>
                  <Col span={4}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      {currencySymbol.CURRENCY_SYMBOL}
                      {Number(billPayloadData.tip_value).toFixed(2)}
                    </Text>
                  </Col>
                  <Col span={4}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "right",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      {billPayloadData.tip_payment_method}
                    </Text>
                  </Col>
                </Row>
              </>
            )}
          </div>

          <Row
            justify="start"
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgb(250, 250, 250)",
              borderRadius: "0 0 12px 12px",
              padding: "12px 24px",
              zIndex: 1,
              gap: "50px",
            }}
          >
            <Col>
              <Checkbox checked={selectAll} onChange={handleSelectAll}>
                Select All
              </Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={selectAllUnpaid}
                onChange={handleSelectAllUnpaid}
              >
                {" "}
                Select All Unpaid{" "}
              </Checkbox>
            </Col>
          </Row>
        </Col>

        {/* Right Section */}
        <Col
          span={6}
          style={{
            border: "1px solid #E6E6E6",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
            height: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          <div style={{ padding: "16px 18px 12px 18px" }}>
            <span
              style={{
                display: "inline-block",
                fontFamily:
                  "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC",
                fontSize: "16px",
                lineHeight: "32px",
                fontWeight: 700,
              }}
            >
              Payment Methods
            </span>
          </div>
          <div
            style={{
              height: "calc(100% - 180px)",
              overflowY: "auto",
              padding: "0 18px 18px 18px",
              maxHeight: "800px",
            }}
          >
            {paymentOptions.map((option: any, index: any) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                  background: "#fff",
                  border: "0.5px solid #e0e7fe",
                  borderRadius: "8px",
                  padding: "12px",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  {
                    await handlePaymentMethodSelection(
                      option.id,
                      option.paymentmethodtype
                    );
                  }
                }}
              >
                <Row align="middle">
                  <Col>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#fff",
                        background: "#2e56f2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        marginRight: "10px",
                      }}
                    >
                      {option.paymentmethodtype.charAt(0).toUpperCase()}
                    </div>
                  </Col>
                  <Col>
                    <Text strong style={{ fontSize: "16px" }}>
                      {option.paymentmethodtype}
                    </Text>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col>
                    <Text strong style={{ fontSize: "16px" }}>
                      {option.payment_paid_amount == 0
                        ? ""
                        : `${Number(option.payment_paid_amount).toFixed(2)}`}
                    </Text>
                  </Col>
                  <Col style={{ marginLeft: "8px" }}>
                    {/* <EditOutlined
                      style={{ color: "#6B8EFA", fontSize: "18px" }}
                    /> */}
                    <div
                      style={{
                        content: "",
                        // left: "auto",
                        bottom: 0,
                        top: 0,
                        width: "20px",
                        height: "20px",
                        background: `rgba(194, 202, 221, 0.1) url(${FullPayIcon}) 50% / 20px no-repeat`,
                        textAlign: "center",
                      }}
                    ></div>
                  </Col>
                </Row>
              </div>
            ))}

            <div
              style={{
                background: "#fff",
                padding: "8px 16px",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{ color: "#999", fontSize: "13px", fontWeight: 700 }}
                >
                  Subtotal
                </Text>
                <Text
                  style={{
                    color: "#f43b00",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}
                  {Number(billPayloadData?.sub_total).toFixed(2)}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{
                    color: "#2e56f2",
                    textDecoration: "underline",
                    fontSize: "13px",
                    fontWeight: 700,
                    textUnderlineOffset: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowTaxModal(true);
                  }}
                >
                  Taxes
                </Text>
                <Text
                  style={{
                    color: "#f43b00",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}
                  {Number(billPayloadData?.total_tax).toFixed(2)}
                </Text>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{
                    color: "#2e56f2",
                    textDecoration: "underline",
                    fontSize: "13px",
                    fontWeight: 700,
                  }}
                >
                  <a href="#">Service charge</a>
                </Text>
                <Text
                  style={{
                    color: "#ff4d4f",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  $6
                </Text>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{ color: "#999", fontSize: "13px", fontWeight: 700 }}
                >
                  Total
                </Text>
                <Text
                  style={{
                    color: "#f43b00",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}
                  {Number(billPayloadData?.final_total).toFixed(2)}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    color: "#2e56f2",
                    textDecoration: "underline",
                    fontSize: "13px",
                    fontWeight: 700,
                    textUnderlineOffset: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowTipModal(true);
                  }}
                >
                  Tips
                </Text>
                <Text
                  style={{
                    color: "#f43b00",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {billPayloadData?.tips
                    ? `${currencySymbol.CURRENCY_SYMBOL}${Number(
                        billPayloadData?.tips
                      ).toFixed(2)}`
                    : "-"}
                </Text>
              </div>
            </div>
          </div>

          <Row
            justify="end"
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgb(250, 250, 250)",
              borderRadius: "0 0 12px 12px",
              padding: "12px 24px",
              borderTop: "1px solid #e0e7fe",
              zIndex: 1,
            }}
          >
            <Col span={24}>
              <Row
                justify="space-between"
                align="middle"
                style={{ marginBottom: "8px" }}
              >
                <Col>
                  <span style={{ fontWeight: 500, fontSize: "16px" }}>
                    To Pay
                  </span>
                </Col>
                <Col>
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    {currencySymbol.CURRENCY_SYMBOL}
                    {Number(getUnpaidItemsSum()).toFixed(2)}
                  </span>
                </Col>
              </Row>
              <Row justify="end">
                <Button
                  type="primary"
                  style={{
                    width: "99px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    padding: "8px 16px",
                    height: "40px",
                    backgroundColor: "#2e56f2",
                    borderRadius: "6px",
                    border: "none",
                  }}
                  onClick={handlePayNow}
                >
                  Pay now
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <TipsModel
        visible={showTipModal}
        onCancel={() => {
          setShowTipModal(false);
        }}
        onSubmit={handleTipSubmit}
        totalvalue={
          billPayloadData !== null
            ? Number(billPayloadData?.final_total).toFixed(2)
            : 0
        }
      />
      <TaxModel
        visible={showTaxModal}
        taxArray={billPayloadData?.taxes}
        totalValue={Number(billPayloadData?.sub_total)}
        paymentMethod={
          paymentOptions && paymentOptions.length > 0
            ? paymentOptions[0]?.paymentmethodtype
            : ""
        }
        paymentMethodId={
          paymentOptions && paymentOptions.length > 0
            ? paymentOptions[0]?.id
            : ""
        }
        onCancel={() => {
          setShowTaxModal(false);
        }}
        onSubmit={handleTaxesEdit}
      />
      <SplitAmountModal
        visible={showSplitModal}
        onClose={() => {
          setShowSplitModal(false);
        }}
        onConfirm={handleSplitEdit}
        maxValue={selectedPrice}
        type={selectedSplitType}
      />
      {showCustomerGiftCardModal && (
        <SelectPrivilegesProductModal
          visible={showCustomerGiftCardModal}
          selectedItem={selectedGiftcard}
          customerGiftCardData={customerTempGiftCardData}
          customerPackageData={customerTempPackageData}
          customerMembershipDiscountData={customerTempMembershipDiscountData}
          customerMembershipVisitBasedData={
            customerTempMembershipVisitBasedData
          }
          onClose={() => {
            setShowCustomerGiftCardModal(false);
          }}
          selectedIndex={selectedIndex}
          onSelect={handleGiftcardSplit}
        />
      )}
      <CustomerVoucherModal
        visible={showOnlyCustomerGiftCardModal}
        selectedItem={selectedCustomer}
        customerGiftCardData={customerGiftCardData}
        onClose={() => {
          setShowOnlyCustomerGiftCardModal(false);
        }}
        onSelect={() => {}}
      />
      {showOnlyCustomerPackageModal && (
        <CustomerPackageModal
          visible={showOnlyCustomerPackageModal}
          selectedItem={selectedCustomer}
          onClose={() => {
            setShowOnlyCustomerPackageModal(false);
          }}
          onSelect={() => {}}
        />
      )}
      {showCustomerMembershipListModal && (
        <CustomerMembershipListModal
          visible={showCustomerMembershipListModal}
          selectedItem={mergeMembershipListData}
          onClose={() => {
            setShowCustomerMembershipListModal(false);
          }}
          onSelect={() => {}}
        />
      )}
      {showCustomerPrepaidModal && (
        <CustomerMembershipPrepaidModal
          visible={showCustomerPrepaidModal}
          customerPrepaidData={customerMembershipPrepaidData}
          onClose={() => {
            setShowCustomerPrepaidModal(false);
          }}
        />
      )}
    </Content>
  );
};

export default Pay;
