import React, { useEffect, useState } from "react";
import { Card, Typography, Row, Col, Divider } from "antd";
import Header from "../headerFooter/Header";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
const { Title, Text } = Typography;
const cardStyle: React.CSSProperties = {
  borderRadius: "12px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  // padding: "16px",
  backgroundColor: "#fff",
  maxWidth: "360px",
  margin: "16px",
};
const receiptRowStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  // alignItems: "center",
  marginBottom: "8px",
};

const verticalLineStyle: React.CSSProperties = {
  display: "inline-block",
  content: "",
  width: "3px",
  height: "12px",
  background: "rgb(255, 165, 0)",
  marginRight: "10px",
};

const headertopStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: "16px",
};

const containerStyle: React.CSSProperties = {
  minHeight: "100vh",
  padding: "80px 16px 16px 16px",
  backgroundColor: "#fff",
};

const AppPurchaseHistory: React.FC = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const customerId = localStorage.getItem("customer_id");
  const outletId = localStorage.getItem("outlet_id");
  const [purchaseHistory, setPurchaseHistory] = useState<any[]>([]);

  const fetchPurchaseHistoryList = async () => {
    if (!merchantId || !outletId) {
      console.error("Merchant ID or Outlet ID not found in localStorage");
      return;
    }

    try {
      const response = await get(
        `/customerprofilemob/mobile/purchasehistory/${customerId}/${outletId}/${merchantId}`
      );

      if (response?.data?.success && response.data.data) {
        setPurchaseHistory(response.data.data); // Store the response data
      } else {
        console.log("No details found in the response");
      }
    } catch (error) {
      console.error("Error fetching purchase history list:", error);
    }
  };

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);

  useEffect(() => {
    fetchPurchaseHistoryList(); // Fetch data when the component is mounted
  }, [merchantId, outletId, customerId]);

  return (
    <>
      <Header
        title="Purchase History"
        onBack={() => navigate(`/app/profile/${merchantId}`)}
        backgroundColor={"#fff"}
      />

      {/* <div style={headertopStyle}>
        <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
          <ArrowLeftOutlined
            style={{ marginRight: 12, color: "rgb(255, 165, 0)", fontSize: 15 }}
          />
        </div>
        <h5
          style={{
            margin: 0,
            color: "rgba(0, 0, 0, 0.88)",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: 1.5,
          }}
        >
          Purchase History
        </h5>
      </div> */}

      {/* <h2 style={{ marginBottom: "10px" }}>Purchase History</h2> */}

      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "16px",
          fontSize: "14px",
        }}
      >
        <span style={verticalLineStyle}></span>15/10/2024
      </div> */}
      <div style={containerStyle}>
        {purchaseHistory?.length > 0 ? (
          purchaseHistory.map((purchase) => (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // cursor: "pointer",
                  padding: "16px",
                  fontSize: "14px",
                  maxWidth: "360px",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  <span style={verticalLineStyle}></span>
                  {new Date(purchase.created_at).toLocaleDateString()}
                </span>
                {purchase.iscart &&
                  purchase.bill_status == "pending" &&
                  purchase.isshipping &&
                  !purchase.selfcollect && (
                    <span style={{ color: "rgb(255, 165, 0)" }}>Shipping</span>
                  )}
                {purchase.iscart &&
                  purchase.bill_status == "pending" &&
                  !purchase.isshipping &&
                  purchase.selfcollect && (
                    <span style={{ color: "rgb(255, 165, 0)" }}>
                      Self collect
                    </span>
                  )}
                {purchase.iscart && purchase.bill_status == "cancel" && (
                  <span style={{ color: "rgb(255, 165, 0)" }}>Cancel</span>
                )}
                {purchase.iscart && purchase.bill_status == "confirm" && (
                  <span style={{ color: "rgb(255, 165, 0)" }}>Accepted</span>
                )}
                {purchase.iscart && purchase.bill_status == "completed" && (
                  <span style={{ color: "rgb(255, 165, 0)" }}>Completed</span>
                )}
              </div>
              <Card
                key={purchase.id}
                // onClick={() =>
                //   navigate(
                //     `/app/purchasehistory/details/${merchantId}/${purchase.id}`
                //   )
                // }
                onClick={() =>
                  navigate(`/app/purchasehistory/details/${merchantId}`, {
                    state: { purchaseId: purchase.id },
                  })
                }
                style={cardStyle}
              >
                {/* Receipt Number */}
                <Row style={receiptRowStyle}>
                  <Col>
                    <Text style={{ fontSize: "13px" }} type="secondary">
                      No. {purchase.ref_no}
                    </Text>
                  </Col>
                  <Col>
                    <Text style={{ fontSize: "13px" }} type="secondary">
                      {new Date(purchase.created_at).toLocaleString()}
                    </Text>
                    <Text style={{ cursor: "pointer", marginLeft: "10px" }}>
                      {">"}
                    </Text>
                  </Col>
                </Row>
                <Divider style={{ marginTop: "7px", marginBottom: "7px" }} />
                {/* Item Details */}
                {purchase.items.map((item: any) => (
                  <Row style={receiptRowStyle}>
                    <Col>
                      <Text>{item.item_name}</Text>
                    </Col>
                    <Col>
                      <Text>{item.item_qty}</Text>
                    </Col>
                    <Col>
                      <Text>{item.item_price}</Text>
                    </Col>
                  </Row>
                ))}
                <Divider style={{ marginTop: "7px", marginBottom: "7px" }} />
                {/* Subtotal, Taxes, Total, Tips */}
                {/* <Row style={receiptRowStyle}>
                <Col>
                  <Title level={5} style={{ margin: 0 }}>
                    Taxes
                  </Title>
                </Col>
                <Col>
                  <Title level={5} style={{ margin: 0 }}>
                    {purchase.taxes}
                  </Title>
                </Col>
              </Row> */}
                <Row style={receiptRowStyle}>
                  <Col>
                    <Title level={5} style={{ margin: 0 }}>
                      Total
                    </Title>
                  </Col>
                  <Col>
                    <Title level={5} style={{ margin: 0 }}>
                      {purchase.final_total}
                    </Title>
                  </Col>
                </Row>
              </Card>
            </>
          ))
        ) : (
          <Text>No purchase history available.</Text>
        )}
      </div>
    </>
  );
};
export default AppPurchaseHistory;
