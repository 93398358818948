import {
  DeleteOutlined,
  DownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Tabs,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import "../../../pages/membership/Membership.css";
import { get, post } from "../../../services/Apiclient";
import ServiceCategoryModel from "../ServiceCategoryModel";
import PackageProductModel from "./PackageProductModel";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { currencySymbol } from "../../../constants/currencySymbol";

const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const { TextArea } = Input;
const { Text } = Typography;

interface PackagesModelProps {
  visible: boolean;
  onClose: () => void;
  onSelect: () => void;
}
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

interface formValues {
  package_code: string;
  package_name: string;
  usage_status: boolean;
  description: string;
  typeof_validity: string;
  valid_startdate: string;
  valid_enddate: string;
  selling_startdate: string;
  selling_enddate: string;
  valid_count: string;
  valid_countperiod: string;
  loyalty_pointtype: string | null;
  loyalty_pointvalue: number;
  note: string;
  tax: null;
  applicable_type: string;
  products: products[];
  services: any[];
}

interface products {
  product_id?: string;
  product_name?: string;
  product_price?: string;
  qty?: string;
  selling_price: string;
  rule: string;
  type?: string;
  key: string;
  combo?: ComboProduct[];
}

interface ComboProduct {
  combo_id: string;
  product_id: string;
  product_name: string;
  product_price: string;
  type: string;
  max_available?: string;
}

const PackagesModel: React.FC<PackagesModelProps> = ({
  visible,
  onClose,
  onSelect,
}) => {
  const { currencySymbol } = useCurrency();
  const validationSchema = Yup.object().shape({
    package_code: Yup.string().required("Please enter Package Code"),
    package_name: Yup.string().required("Please enter Package Name"),
    valid_startdate: Yup.lazy((value, options) => {
      return formValues.typeof_validity === "Custom"
        ? Yup.string().required("Please fill in valid start date")
        : Yup.string();
    }),
    valid_enddate: Yup.lazy((value, options) => {
      return formValues.typeof_validity === "Custom"
        ? Yup.string().required("Please fill in valid end date")
        : Yup.string();
    }),
  });
  const generateUniqueId = () => {
    const uniqueId = nanoid(8);
    return uniqueId;
  };

  const initialFormValues: formValues = {
    package_code: "",
    package_name: "",
    usage_status: true,
    description: "",
    typeof_validity: "Custom",
    applicable_type: "All stores",
    valid_startdate: "",
    valid_enddate: "",
    selling_startdate: "",
    selling_enddate: "",
    valid_count: "0",
    valid_countperiod: "Year",
    loyalty_pointtype: null,
    loyalty_pointvalue: 0.0,
    note: "",
    tax: null,
    services: [],
    products: [],
  };
  const [formValues, setFormValues] = useState<formValues>(initialFormValues);
  const [taxData, setTaxData] = useState([]);
  const [selectionType, setSelectionType] = useState("Single");
  const [membershipType, setMembershipType] = useState<string>("value");
  const [validityType, setValidityType] = useState<string>("custom");
  const [validityUnit, setValidityUnit] = useState<string>("year");
  const [selectedTab, setSelectedTab] = useState("service");
  const [activeKey, setActiveKey] = useState("service");
  const [modalVisible, setModalVisible] = useState(false);
  const [isProductExcPriceVisible, setProductExcPriceVisible] = useState(false);
  const [isPrivilegeExcPriceVisible, setPrivilegeExcPriceVisible] =
    useState(false);
  const [showServiceItemModal, setShowServiceItemModal] = useState(false);
  const [showProductsItemModel, setshowProductsItemModel] = useState(false);
  const [isLimitedUsage, setIsLimitedUsage] = useState(true);
  const [isLimitedUsageVisitbased, isSetIsLimitedUsage] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const [grandTotal, setGrandTotal] = useState(0);

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeleteProduct = (productKey: string) => {
    // Filter out the product with the given key from the products array
    const updatedProducts = formValues.products.filter(
      (product) => product.key !== productKey
    );

    setFormValues({
      ...formValues,
      products: updatedProducts,
    });
  };

  const handleDeleteService = (productKey: string) => {
    // Filter out the product with the given key from the products array
    const updatedServices = formValues.services.filter(
      (product) => product.key !== productKey
    );

    setFormValues({
      ...formValues,
      services: updatedServices,
    });
  };

  const handleDeleteServiceCombo = (comboKey: string) => {
    // Filter out the product with the matching comboKey from formValues.products
    const updatedServices = formValues.services.filter(
      (product: any) => product.key !== comboKey
    );

    // Update form values
    setFormValues({
      ...formValues,
      services: updatedServices,
    });
  };

  const handleDeleteCombo = (comboKey: string) => {
    // Filter out the product with the matching comboKey from formValues.products
    const updatedProducts = formValues.products.filter(
      (product: any) => product.key !== comboKey
    );

    // Update form values
    setFormValues({
      ...formValues,
      products: updatedProducts,
    });
  };

  const handleDeleteComboItem = (productKey: string, comboId: string) => {
    // Update the products state to remove the combo item
    const updatedProducts = formValues.products.map((product: any) => {
      if (product.key === productKey && product.pkg_item_type === "Combo") {
        // Filter out the combo item by its combo_id
        product.combo = product.combo.filter(
          (combo: any) => combo.combo_id !== comboId
        );
      }
      return product;
    });

    // Update the formValues state with the new products array
    setFormValues({
      ...formValues,
      products: updatedProducts,
    });
  };

  const handleCancel = () => {
    setShowServiceItemModal(false);
  };

  const handleSaveService = (selectedData: any[]) => {
    console.log(selectedData, "selectdata");
    let updatedServices: any = [];

    if (selectionType === "Combo") {
      let combo_id = generateUniqueId();
      const updatedCombo = selectedData.map((comboService: any) => ({
        combo_id: combo_id,
        service_id: comboService.id || "",
        service_name: comboService.servicename || "",
        service_price: comboService.costprice || 0,
        selling_price: comboService.costprice || 0,
        type: "Combo",
        qty: "0",
        max_available: "1",
        key: generateUniqueId(),
      }));
      let rule = "all";
      if (selectedData.length > 1) {
        rule = `1outof${selectedData.length}`;
      }
      updatedServices = [
        {
          key: generateUniqueId(),
          pkg_item_type: "Combo",
          combo: updatedCombo,
          qty: "1",
          selling_price: selectedData[0]?.costprice || 0,
          rule: rule,
        },
      ];
    } else if (selectionType === "Single") {
      updatedServices = selectedData.map((service: any) => ({
        service_id: service.id || "",
        service_name: service.servicename || "",
        service_price: service.costprice || 0,
        qty: "1",
        selling_price: service.costprice || 0,
        rule: "-",
        type: "Single",
        pkg_item_type: "Single",
        key: generateUniqueId(),
      }));
    }

    const mergedServices = [...formValues.services, ...updatedServices];
    setFormValues((prev) => ({ ...prev, services: mergedServices }));
  };

  const handleSaveProducts = (selectedData: any[]) => {
    let updatedProducts: any = [];

    if (selectionType === "Combo") {
      let combo_id = generateUniqueId();
      const updatedCombo = selectedData.map((comboProduct: any) => ({
        combo_id: combo_id,
        product_id: comboProduct.id || "",
        product_name: comboProduct.productname || "",
        product_price: comboProduct.sellprice || 0,
        selling_price: comboProduct.sellprice || 0,
        qty: "0",
        type: "Combo",
        max_available: "1",
        key: generateUniqueId(),
      }));
      let rule = "all";
      if (selectedData.length > 1) {
        rule = `1outof${selectedData.length}`;
      }
      updatedProducts = [
        {
          key: generateUniqueId(),
          pkg_item_type: "Combo",
          combo: updatedCombo,
          qty: "1",
          selling_price: selectedData[0]?.sellprice || 0,
          rule: rule,
        },
      ];
    } else if (selectionType === "Single") {
      updatedProducts = selectedData.map((product: any) => ({
        product_id: product.id || "",
        product_name: product.productname || "",
        product_price: product.sellprice || 0,
        qty: "1",
        selling_price: product.sellprice || 0,
        rule: "-",
        type: "Single",
        pkg_item_type: "Single",
        key: generateUniqueId(),
      }));
    }

    const mergedProducts = [...formValues.products, ...updatedProducts];
    setFormValues((prev) => ({ ...prev, products: mergedProducts }));
  };

  const handleSingleServiceSelection = () => {
    setSelectionType("Single");
    setShowServiceItemModal(true);
  };

  const handleComboServiceSelection = () => {
    setSelectionType("Combo");
    setShowServiceItemModal(true);
  };

  const handleSingleProductSelection = () => {
    setSelectionType("Single");
    setshowProductsItemModel(true);
  };

  const handleComboProductSelection = () => {
    setSelectionType("Combo");
    setshowProductsItemModel(true);
  };

  const handleSaveServices = (selectedData: any) => {
    const transformedArray = selectedData.map((item: any) => ({
      key: item.key,
      serviceid: item.id,
      servicecode: item.servicecode,
      servicename: item.servicename,
      price: item.costprice,
    }));
    setFormValues((prev) => ({
      ...prev,
      services: transformedArray,
    }));
  };

  const menuService = (
    <Menu>
      <Menu.Item key="1" onClick={handleSingleServiceSelection}>
        Single
      </Menu.Item>
      <Menu.Item key="2" onClick={handleComboServiceSelection}>
        Combo
      </Menu.Item>
    </Menu>
  );

  const menuProduct = (
    <Menu>
      <Menu.Item key="1" onClick={handleSingleProductSelection}>
        Single
      </Menu.Item>
      <Menu.Item key="2" onClick={handleComboProductSelection}>
        Combo
      </Menu.Item>
    </Menu>
  );

  const handleSwitchChange = (checked: any) => {
    setIsDisabled(checked);
  };
  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      if (formValues) {
        let body = {
          package_code: formValues.package_code,
          package_name: formValues.package_name,
          description: formValues.description,
          usage_status: formValues.usage_status,
          typeof_validity: formValues.typeof_validity,
          applicable_type: formValues.applicable_type,
          valid_startdate: formValues.valid_startdate,
          valid_enddate: formValues.valid_enddate,
          selling_startdate: formValues.selling_startdate,
          selling_enddate: formValues.selling_enddate,
          valid_count: formValues.valid_count,
          valid_countperiod: formValues.valid_countperiod,
          loyalty_pointvalue: formValues.loyalty_pointvalue,
          loyalty_pointtype: formValues.loyalty_pointtype,
          note: formValues.note,
          tax: formValues.tax,
          products: formValues.products,
          services: formValues.services,
          total_amount: Number(grandTotal).toFixed(2).toString(),
          outlet_id: localStorage.getItem("outlet_id"),
          stores: [],
        };

        let url = "/package";
        const response = await post(url, body);

        if (response && response.data) {
          if (response.data.success) {
            message.success("Saved successfully");
            setFormValues(initialFormValues);
            onClose();
            onSelect();
          } else {
            console.log("Error in api call: ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
      }
    } catch (errors: any) {
      // Handle validation errors
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const generatePackageCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("package_code", `PKG-${data}`);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to load room details");
    }
  };

  const getTaxDropdown = async () => {
    try {
      let url = `/tax/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setTaxData(response.data.data);
        }
        if (response.data.data.length > 0) {
          setFormValues((prev) => ({ ...prev, tax: response.data.data[0].id }));
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const requiredLabel = (label: any) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleModalSave = () => {
    // Logic for saving the selected services can go here
    console.log("Services saved");
    setModalVisible(false); // Close modal after saving
  };

  const calculateTotal = (items: any) => {
    return items.reduce((total: any, item: any) => {
      if (item.pkg_item_type === "Combo" || item.combo) {
        return total + parseFloat(item.selling_price || 0);
      }
      return total + parseFloat(item.selling_price || 0);
    }, 0);
  };

  useEffect(() => {
    const productsTotal = calculateTotal(formValues.products);
    const servicesTotal = calculateTotal(formValues.services);
    const grandTotal = productsTotal + servicesTotal;
    setGrandTotal(grandTotal);
  }, [formValues.products, formValues.services]);

  useEffect(() => {
    if (visible) {
      getTaxDropdown();
    }
  }, [visible]);

  return (
    <>
      <Modal
        title="Add New Package"
        visible={visible}
        onCancel={onClose}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} />
            <Button key="save" type="primary" onClick={handleSubmit}>
              Save
            </Button>
          </>,
        ]}
        style={{ top: "80px" }}
        bodyStyle={{
          height: "calc(100vh - 340px)",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
          paddingRight: "50px !important",
        }}
        width={1024}
      >
        <div style={{ paddingRight: "15px" }}>
          <Form onFinish={handleSubmit} layout="vertical">
            <Row gutter={16}>
              <Col span={9}>
                <Form.Item label={null} required>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <label>{requiredLabel("Package Code")}</label>
                    <a
                      onClick={generatePackageCode}
                      style={{ color: "#325df2" }}
                    >
                      Auto Generated
                    </a>
                  </div>
                  <Input
                    placeholder="Please enter"
                    value={formValues.package_code}
                    onChange={(e) =>
                      handleInputChange("package_code", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={requiredLabel("Package name")}>
                  <Input
                    placeholder="Please enter"
                    value={formValues.package_name}
                    onChange={(e) =>
                      handleInputChange("package_name", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Status"
                  valuePropName="checked"
                  tooltip={{
                    title:
                      "Once the switch is turned off, the gift card will be disabled and cannot be sold.",
                    icon: (
                      <InfoCircleOutlined
                        style={{ color: "rgba(46, 86, 242, .5)" }}
                      />
                    ),
                  }}
                >
                  <Switch
                    checked={formValues.usage_status}
                    onChange={(value) =>
                      handleInputChange("usage_status", value)
                    }
                    defaultChecked
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Description">
              <TextArea
                rows={3}
                autoSize={{ minRows: 3 }}
                placeholder="Please enter"
                value={formValues.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
            </Form.Item>

            <div>
              <h3 style={{ display: "flex", alignItems: "center" }}>
                <span className="vertical-line"></span>Assign
              </h3>
            </div>
            <Row
              justify="space-between"
              style={{
                marginTop: "10px",
                display: "flex",
                background: "#f6f7fa",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 24px",
                borderBottom: "1px solid #e0e7fe",
              }}
            >
              <Col>
                <span
                  style={{ fontWeight: "700", color: "#333", fontSize: "14px" }}
                >
                  Service
                </span>
              </Col>
              <Col>
                <Dropdown overlay={menuService} trigger={["click"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "rgb(255, 151, 30)",
                    }}
                  >
                    +Add
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Col>
            </Row>
            <div style={{ padding: "4px 8px 8px 8px;" }}>
              <div
                style={{
                  paddingTop: "8px",
                  width: "100%",
                  position: "relative",
                  background: "rgb(246, 247, 250)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "hsla(0, 0%, 100%, .9)",
                    margin: "1px 10px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                      padding: "8px 16px",
                    }}
                  >
                    <Text style={{ width: "60%" }}>Service name</Text>
                    <Text style={{ width: "20%" }}>Price</Text>
                    <Text style={{ width: "20%" }}>Max available</Text>
                  </div>
                  <div style={{ width: "10%" }} />
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 16px",
                    }}
                  >
                    <Text style={{ width: "25%", textAlign: "center" }}>
                      Qty.
                    </Text>
                    <Text style={{ width: "25%", textAlign: "center" }}>
                      Selling price
                    </Text>
                    <Text style={{ width: "25%", textAlign: "center" }}>
                      Rule
                    </Text>
                    <Text>Action</Text>
                  </div>
                </div>
                {formValues.services.map((service: any, index: any) => {
                  console.log(formValues.services);
                  return (
                    <div
                      key={service.key}
                      style={{
                        position: "relative",
                        marginBottom: "20px",
                        padding: "0 10px",
                        marginTop: "5px",
                      }}
                    >
                      {/* Render single service */}
                      {service.pkg_item_type == "Single" && (
                        <div
                          key={service.key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "50%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Text style={{ width: "60%" }}>
                              {service.service_name}
                            </Text>
                            <Text style={{ width: "20%" }}>
                              {currencySymbol.CURRENCY_SYMBOL}
                              {service.service_price}
                            </Text>
                            <Text style={{ width: "20%" }}>-</Text>
                          </div>
                          <div style={{ width: "10%" }} />
                          <div
                            style={{
                              width: "40%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <InputNumber
                              type="number"
                              min={0}
                              value={service.qty}
                              onChange={(value) => {
                                const updatedServices = [
                                  ...formValues.services,
                                ];
                                updatedServices[index].qty = value;
                                setFormValues({
                                  ...formValues,
                                  services: updatedServices,
                                });
                              }}
                              style={{ width: "25%", marginRight: "2px" }}
                              className="editgiftcardvalue"
                            />
                            <InputNumber
                              type="number"
                              min={1}
                              value={service.selling_price}
                              onChange={(value) => {
                                const updatedServices = [
                                  ...formValues.services,
                                ];
                                updatedServices[index].selling_price = value;
                                setFormValues({
                                  ...formValues,
                                  services: updatedServices,
                                });
                              }}
                              style={{ width: "25%", height: "40px" }}
                            />
                            <Text style={{ width: "25%", textAlign: "center" }}>
                              {service.rule}
                            </Text>
                            <Button
                              type="link"
                              danger
                              style={{
                                textDecoration: "underline",
                                textUnderlineOffset: "4px",
                              }}
                              onClick={() => handleDeleteService(service.key)}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      )}

                      {/* Render combo service */}
                      {service.pkg_item_type == "Combo" && (
                        <>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            {service.combo.length > 1 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "55%",
                                  top: "18px",
                                  bottom: "10px",
                                  width: "2px",
                                  backgroundColor: "#000",
                                }}
                              />
                            )}
                            {service.combo.length > 1 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "55%",
                                  top: "50%",
                                  height: "2px",
                                  width: "15px",
                                  backgroundColor: "#000",
                                }}
                              />
                            )}
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div style={{ width: "59%" }}>
                                {service.combo.map((combo: any, idx: any) => (
                                  <div
                                    key={combo.key || idx}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom:
                                        idx === service.combo.length - 1
                                          ? "0"
                                          : "10px",
                                      position: "relative",
                                    }}
                                  >
                                    {idx === 0 &&
                                      idx !== service.combo.length - 1 &&
                                      service.combo.length > 1 && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            left: "90.8%",
                                            top: "18px",
                                            height: "2px",
                                            width: "15px",
                                            backgroundColor: "#000",
                                          }}
                                        />
                                      )}
                                    {idx === service.combo.length - 1 &&
                                      service.combo.length > 1 && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            left: "90.8%",
                                            bottom: "10px",
                                            height: "2px",
                                            width: "15px",
                                            backgroundColor: "#000",
                                          }}
                                        />
                                      )}
                                    <div
                                      style={{
                                        width: "80%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Button
                                        type="link"
                                        icon={<DeleteOutlined />}
                                        style={{ color: "#666" }}
                                        onClick={() => {
                                          const updatedProducts = [
                                            ...formValues.services,
                                          ];
                                          const productIndex =
                                            updatedProducts.findIndex(
                                              (p: any) => p.key === service.key
                                            );

                                          if (productIndex !== -1) {
                                            const product =
                                              updatedProducts[productIndex];
                                            if (Array.isArray(product.combo)) {
                                              const updatedCombo =
                                                product.combo.filter(
                                                  (comboItem: any) =>
                                                    comboItem.key !== combo.key
                                                );

                                              if (updatedCombo) {
                                                updatedProducts[
                                                  productIndex
                                                ].combo = updatedCombo;

                                                if (updatedCombo.length === 0) {
                                                  updatedProducts.splice(
                                                    productIndex,
                                                    1
                                                  );
                                                }

                                                setFormValues({
                                                  ...formValues,
                                                  services: updatedProducts,
                                                });
                                              }
                                            }
                                          }
                                        }}
                                      ></Button>
                                      <Text style={{ width: "60%" }}>
                                        {combo.service_name}
                                      </Text>
                                      <Text style={{ width: "20%" }}>
                                        {currencySymbol.CURRENCY_SYMBOL}
                                        {combo.service_price}
                                      </Text>
                                      <InputNumber
                                        type="number"
                                        value={combo.max_available}
                                        style={{ width: "20%" }}
                                        onChange={(value) => {
                                          const updatedServices = [
                                            ...formValues.services,
                                          ];
                                          const productIndex =
                                            updatedServices.findIndex(
                                              (p) => p.key === service.key
                                            );

                                          if (productIndex !== -1) {
                                            const updatedCombo = [
                                              ...updatedServices[productIndex]
                                                .combo,
                                            ];
                                            const comboIndex =
                                              updatedCombo.findIndex(
                                                (c) => c.key === combo.key
                                              );

                                            if (comboIndex !== -1) {
                                              updatedCombo[
                                                comboIndex
                                              ].max_available = value;
                                              updatedServices[
                                                productIndex
                                              ].combo = updatedCombo;
                                              setFormValues({
                                                ...formValues,
                                                services: updatedServices,
                                              });
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "41%",
                                }}
                              >
                                <InputNumber
                                  type="number"
                                  min={0}
                                  value={service.qty}
                                  onChange={(value) => {
                                    const updatedServices = [
                                      ...formValues.services,
                                    ];
                                    updatedServices[index].qty = value;
                                    setFormValues({
                                      ...formValues,
                                      services: updatedServices,
                                    });
                                  }}
                                  style={{ width: "25%", marginRight: "2px" }}
                                />
                                <InputNumber
                                  type="number"
                                  min={1}
                                  value={service.selling_price}
                                  onChange={(value) => {
                                    const updatedServices = [
                                      ...formValues.services,
                                    ];
                                    updatedServices[index].selling_price =
                                      value;
                                    setFormValues({
                                      ...formValues,
                                      services: updatedServices,
                                    });
                                  }}
                                  style={{ width: "25%", marginRight: "5px" }}
                                />
                                <Select
                                  value={service.rule}
                                  onChange={(value) => {
                                    const updatedServices = [
                                      ...formValues.services,
                                    ];
                                    updatedServices[index].rule = value;
                                    setFormValues({
                                      ...formValues,
                                      services: updatedServices,
                                    });
                                  }}
                                  style={{ width: "25%" }}
                                >
                                  <>
                                    <Select.Option value="all">
                                      All
                                    </Select.Option>
                                    {Array.from(
                                      { length: service.combo.length - 1 },
                                      (_, i) => (
                                        <Select.Option
                                          key={`rule-${i + 1}`}
                                          value={`${i + 1}outof${
                                            service.combo.length
                                          }`}
                                        >
                                          {`${i + 1} out of ${
                                            service.combo.length
                                          }`}
                                        </Select.Option>
                                      )
                                    )}
                                  </>
                                </Select>

                                <Button
                                  type="link"
                                  danger
                                  style={{
                                    textDecoration: "underline",
                                    textUnderlineOffset: "4px",
                                  }}
                                  onClick={() => {
                                    handleDeleteServiceCombo(service.key);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                          {/* <div
                            style={{
                              marginLeft: "10px",
                              color: "orange",
                              fontSize: "16px",
                            }}
                          >
                            <span> + Add</span>
                          </div> */}
                        </>
                      )}
                    </div>
                  );
                })}

                {formValues.services && formValues.services.length == 0 && (
                  <>
                    <Empty
                      image={emptyImageUrl}
                      imageStyle={{
                        height: 120,
                      }}
                      description={<span>No data available</span>}
                    />
                  </>
                )}
              </div>
            </div>
            <Row
              justify="space-between"
              style={{
                marginTop: "10px",
                display: "flex",
                background: "#f6f7fa",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 24px",
                borderBottom: "1px solid #e0e7fe",
              }}
            >
              <Col>
                {" "}
                <span
                  style={{ fontWeight: "700", color: "#333", fontSize: "14px" }}
                >
                  Product
                </span>
              </Col>
              <Col>
                <Dropdown overlay={menuProduct} trigger={["click"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "rgb(255, 151, 30)",
                    }}
                  >
                    +Add
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Col>
            </Row>
            <div style={{ padding: "4px 8px 8px 8px;" }}>
              <div
                style={{
                  paddingTop: "8px",
                  width: "100%",
                  position: "relative",
                  background: "rgb(246, 247, 250)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "hsla(0, 0%, 100%, .9)",
                    margin: "1px 10px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                      padding: "8px 16px",
                    }}
                  >
                    <Text style={{ width: "60%" }}>Product name</Text>
                    <Text style={{ width: "20%" }}>Price</Text>
                    <Text style={{ width: "20%" }}>Max available</Text>
                  </div>
                  <div style={{ width: "10%" }} />
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ width: "25%", textAlign: "center" }}>
                      Qty
                    </Text>
                    <Text style={{ width: "25%", textAlign: "center" }}>
                      Selling price
                    </Text>
                    <Text style={{ width: "25%", textAlign: "center" }}>
                      Rule
                    </Text>
                    <Text>Action</Text>
                  </div>
                </div>

                {formValues.products.map((product: any, index: any) => {
                  return (
                    <div
                      key={product.key}
                      style={{
                        position: "relative",
                        marginBottom: "20px",
                        padding: "0 10px",
                        marginTop: "5px",
                      }}
                    >
                      {/* Render single product */}
                      {product.pkg_item_type == "Single" && (
                        <div
                          key={product.key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "50%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Text style={{ width: "60%" }}>
                              {product.product_name}
                            </Text>
                            <Text style={{ width: "20%" }}>
                              {currencySymbol.CURRENCY_SYMBOL}
                              {product.product_price}
                            </Text>
                            <Text style={{ width: "20%" }}>-</Text>
                          </div>
                          <div style={{ width: "10%" }} />
                          <div
                            style={{
                              width: "40%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <InputNumber
                              type="number"
                              min={0}
                              value={product.qty}
                              onChange={(value) => {
                                const updatedProducts = [
                                  ...formValues.products,
                                ];
                                updatedProducts[index].qty = value;
                                setFormValues({
                                  ...formValues,
                                  products: updatedProducts,
                                });
                              }}
                              style={{ width: "25%", marginRight: "2px" }}
                              className="editgiftcardvalue"
                            />
                            <InputNumber
                              min={1}
                              type="number"
                              value={product.selling_price}
                              onChange={(value) => {
                                const updatedProducts = [
                                  ...formValues.products,
                                ];
                                updatedProducts[index].selling_price = value;
                                setFormValues({
                                  ...formValues,
                                  products: updatedProducts,
                                });
                              }}
                              style={{ width: "25%", height: "40px" }}
                            />
                            <Text style={{ width: "25%", textAlign: "center" }}>
                              {product.rule}
                            </Text>
                            <Button
                              type="link"
                              danger
                              style={{
                                textDecoration: "underline",
                                textUnderlineOffset: "4px",
                              }}
                              onClick={() => handleDeleteProduct(product.key)}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      )}

                      {/* Render combo products */}
                      {product.pkg_item_type == "Combo" && (
                        <>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            {product.combo.length > 1 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "55%",
                                  top: "18px",
                                  bottom: "10px",
                                  width: "2px",
                                  backgroundColor: "#000",
                                }}
                              />
                            )}
                            {product.combo.length > 1 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "55%",
                                  top: "50%",
                                  height: "2px",
                                  width: "15px",
                                  backgroundColor: "#000",
                                }}
                              />
                            )}
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div style={{ width: "59%" }}>
                                {product.combo.map((combo: any, idx: any) => (
                                  <div
                                    key={combo.key || idx}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom:
                                        idx === product.combo.length - 1
                                          ? "0"
                                          : "10px",
                                      position: "relative",
                                    }}
                                  >
                                    {idx === 0 &&
                                      idx !== product.combo.length - 1 &&
                                      product.combo.length > 1 && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            left: "90.8%",
                                            top: "18px",
                                            height: "2px",
                                            width: "15px",
                                            backgroundColor: "#000",
                                          }}
                                        />
                                      )}
                                    {idx === product.combo.length - 1 &&
                                      product.combo.length > 1 && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            left: "90.8%",
                                            bottom: "10px",
                                            height: "2px",
                                            width: "15px",
                                            backgroundColor: "#000",
                                          }}
                                        />
                                      )}
                                    <div
                                      style={{
                                        width: "80%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Button
                                        type="link"
                                        icon={<DeleteOutlined />}
                                        style={{ color: "#666" }}
                                        onClick={() => {
                                          const updatedProducts = [
                                            ...formValues.products,
                                          ];
                                          const productIndex =
                                            updatedProducts.findIndex(
                                              (p: any) => p.key === product.key
                                            );

                                          if (productIndex !== -1) {
                                            const product =
                                              updatedProducts[productIndex];
                                            if (Array.isArray(product.combo)) {
                                              const updatedCombo =
                                                product.combo.filter(
                                                  (comboItem: any) =>
                                                    comboItem.key !== combo.key
                                                );

                                              if (updatedCombo) {
                                                updatedProducts[
                                                  productIndex
                                                ].combo = updatedCombo;

                                                if (updatedCombo.length === 0) {
                                                  updatedProducts.splice(
                                                    productIndex,
                                                    1
                                                  );
                                                }

                                                setFormValues({
                                                  ...formValues,
                                                  products: updatedProducts,
                                                });
                                              }
                                            }
                                          }
                                        }}
                                      ></Button>
                                      <Text style={{ width: "60%" }}>
                                        {combo.product_name}
                                      </Text>
                                      <Text style={{ width: "20%" }}>
                                        {currencySymbol.CURRENCY_SYMBOL}
                                        {combo.product_price}
                                      </Text>
                                      <InputNumber
                                        type="number"
                                        value={combo.max_available}
                                        onChange={(value) => {
                                          const updatedProducts = [
                                            ...formValues.products,
                                          ];
                                          const productIndex =
                                            updatedProducts.findIndex(
                                              (p: any) => p.key === product.key
                                            );

                                          if (productIndex !== -1) {
                                            const updatedCombo = [
                                              ...(updatedProducts[productIndex]
                                                .combo || []),
                                            ]; // Ensure combo is an array
                                            const comboIndex =
                                              updatedCombo.findIndex(
                                                (c: any) => c.key === combo.key
                                              );

                                            if (comboIndex !== -1) {
                                              updatedCombo[
                                                comboIndex
                                              ].max_available = value; // Update the max_available value
                                              updatedProducts[
                                                productIndex
                                              ].combo = updatedCombo;
                                              setFormValues({
                                                ...formValues,
                                                products: updatedProducts,
                                              });
                                            }
                                          }
                                        }}
                                        style={{ width: "20%" }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "41%",
                                }}
                              >
                                <InputNumber
                                  type="number"
                                  min={0}
                                  value={product.qty}
                                  onChange={(value) => {
                                    const updatedProducts = [
                                      ...formValues.products,
                                    ];
                                    updatedProducts[index].qty = value;
                                    setFormValues({
                                      ...formValues,
                                      products: updatedProducts,
                                    });
                                  }}
                                  style={{ width: "25%", marginRight: "2px" }}
                                />
                                <InputNumber
                                  type="number"
                                  min={1}
                                  value={product.selling_price}
                                  onChange={(value) => {
                                    const updatedProducts = [
                                      ...formValues.products,
                                    ];
                                    updatedProducts[index].selling_price =
                                      value;
                                    setFormValues({
                                      ...formValues,
                                      products: updatedProducts,
                                    });
                                  }}
                                  style={{ width: "25%", marginRight: "5px" }}
                                />
                                <Select
                                  value={product.rule} // Bind rule to formValues
                                  onChange={(value) => {
                                    const updatedProducts = [
                                      ...formValues.products,
                                    ];
                                    updatedProducts[index].rule = value;
                                    setFormValues({
                                      ...formValues,
                                      products: updatedProducts,
                                    });
                                  }}
                                  style={{ width: "25%" }}
                                >
                                  <>
                                    <Select.Option value="all">
                                      All
                                    </Select.Option>
                                    {Array.from(
                                      { length: product.combo.length - 1 },
                                      (_, i) => (
                                        <Select.Option
                                          key={`rule-${i + 1}`}
                                          value={`${i + 1}outof${
                                            product.combo.length
                                          }`}
                                        >
                                          {`${i + 1} out of ${
                                            product.combo.length
                                          }`}
                                        </Select.Option>
                                      )
                                    )}
                                  </>
                                </Select>

                                <Button
                                  type="link"
                                  danger
                                  style={{
                                    textDecoration: "underline",
                                    textUnderlineOffset: "4px",
                                  }}
                                  onClick={() => {
                                    handleDeleteCombo(product.key);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                          {/* <div
                            style={{
                              marginLeft: "10px",
                              color: "orange",
                              fontSize: "16px",
                            }}
                          >
                            <span> + Add</span>
                          </div> */}
                        </>
                      )}
                    </div>
                  );
                })}

                {formValues.products && formValues.products.length == 0 && (
                  <>
                    <Empty
                      image={emptyImageUrl}
                      imageStyle={{
                        height: 120,
                      }}
                      description={<span>No data available</span>}
                    />
                  </>
                )}
              </div>
            </div>

            <div>
              <h3 style={{ display: "flex", alignItems: "center" }}>
                <span className="vertical-line"></span>Type of Validity
              </h3>
            </div>

            <Radio.Group
              value={formValues.typeof_validity}
              onChange={(e) =>
                handleInputChange("typeof_validity", e.target.value)
              }
            >
              <Radio value="Custom">Custom</Radio>
              <Radio value="Immediately">Immediately</Radio>
              <Radio value="Effective on consumption day">
                Effective on consumption day
              </Radio>
            </Radio.Group>
            {/* </Form.Item> */}

            {formValues.typeof_validity === "Custom" && (
              <div className="validity-section">
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Valid for" required>
                      <RangePicker
                        style={{ width: "100%" }}
                        ranges={{
                          Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                          Yesterday: [
                            dayjs().subtract(1, "day").startOf("day"),
                            dayjs().subtract(1, "day").endOf("day"),
                          ],
                          "This Week": [
                            dayjs().startOf("week"),
                            dayjs().endOf("week"),
                          ],
                          "This Month": [
                            dayjs().startOf("month"),
                            dayjs().endOf("month"),
                          ],
                          "Last Month": [
                            dayjs().subtract(1, "month").startOf("month"),
                            dayjs().subtract(1, "month").endOf("month"),
                          ],
                        }}
                        value={[
                          formValues.valid_startdate
                            ? dayjs(formValues.valid_startdate)
                            : null,
                          formValues.valid_enddate
                            ? dayjs(formValues.valid_enddate)
                            : null,
                        ]}
                        onChange={(dates, dateStrings: [string, string]) => {
                          const [start, end] = dates || [null, null];
                          handleInputChange(
                            "valid_startdate",
                            start ? start.format("YYYY-MM-DD") : null
                          );
                          handleInputChange(
                            "valid_enddate",
                            end ? end.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Selling Period">
                      <RangePicker
                        style={{ width: "100%" }}
                        ranges={{
                          Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                          Yesterday: [
                            dayjs().subtract(1, "day").startOf("day"),
                            dayjs().subtract(1, "day").endOf("day"),
                          ],
                          "This Week": [
                            dayjs().startOf("week"),
                            dayjs().endOf("week"),
                          ],
                          "This Month": [
                            dayjs().startOf("month"),
                            dayjs().endOf("month"),
                          ],
                          "Last Month": [
                            dayjs().subtract(1, "month").startOf("month"),
                            dayjs().subtract(1, "month").endOf("month"),
                          ],
                        }}
                        value={[
                          formValues.selling_startdate
                            ? dayjs(formValues.selling_startdate)
                            : null,
                          formValues.selling_enddate
                            ? dayjs(formValues.selling_enddate)
                            : null,
                        ]}
                        onChange={(dates, dateStrings: [string, string]) => {
                          const [start, end] = dates || [null, null];
                          handleInputChange(
                            "selling_startdate",
                            start ? start.format("YYYY-MM-DD") : null
                          );
                          handleInputChange(
                            "selling_enddate",
                            end ? end.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {formValues.typeof_validity === "Immediately" && (
              <div className="validity-section">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Valid for" required>
                      <Input
                        type="number"
                        placeholder="Please enter"
                        style={{ width: "90%", height: "46px" }}
                        value={formValues.valid_count}
                        onChange={(e) =>
                          handleInputChange("valid_count", e.target.value)
                        }
                        addonAfter={
                          <Select
                            value={formValues.valid_countperiod}
                            onChange={(value) =>
                              handleInputChange("valid_countperiod", value)
                            }
                            style={{
                              width: 90,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Option value="day">day</Option>
                            <Option value="month">month</Option>
                            <Option value="year">year</Option>
                          </Select>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item label="Selling Period">
                      <RangePicker
                        style={{ width: "100%", height: "40px" }}
                        ranges={{
                          Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                          Yesterday: [
                            dayjs().subtract(1, "day").startOf("day"),
                            dayjs().subtract(1, "day").endOf("day"),
                          ],
                          "This Week": [
                            dayjs().startOf("week"),
                            dayjs().endOf("week"),
                          ],
                          "This Month": [
                            dayjs().startOf("month"),
                            dayjs().endOf("month"),
                          ],
                          "Last Month": [
                            dayjs().subtract(1, "month").startOf("month"),
                            dayjs().subtract(1, "month").endOf("month"),
                          ],
                        }}
                        value={[
                          formValues.selling_startdate
                            ? dayjs(formValues.selling_startdate)
                            : null,
                          formValues.selling_enddate
                            ? dayjs(formValues.selling_enddate)
                            : null,
                        ]}
                        onChange={(dates, dateStrings: [string, string]) => {
                          const [start, end] = dates || [null, null];
                          handleInputChange(
                            "selling_startdate",
                            start ? start.format("YYYY-MM-DD") : null
                          );
                          handleInputChange(
                            "selling_enddate",
                            end ? end.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {formValues.typeof_validity === "Effective on consumption day" && (
              <div className="validity-section">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={requiredLabel("Valid for")}>
                      <Input
                        type="number"
                        placeholder="Please enter"
                        style={{ width: "90%", height: "46px" }}
                        value={formValues.valid_count}
                        onChange={(e) =>
                          handleInputChange("valid_count", e.target.value)
                        }
                        addonAfter={
                          <Select
                            value={formValues.valid_countperiod}
                            onChange={(value) =>
                              handleInputChange("valid_countperiod", value)
                            }
                            style={{
                              width: 90,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Option value="day">day</Option>
                            <Option value="month">month</Option>
                            <Option value="year">year</Option>
                          </Select>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item label="Selling Period">
                      <RangePicker
                        style={{ width: "100%", height: "40px" }}
                        ranges={{
                          Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                          Yesterday: [
                            dayjs().subtract(1, "day").startOf("day"),
                            dayjs().subtract(1, "day").endOf("day"),
                          ],
                          "This Week": [
                            dayjs().startOf("week"),
                            dayjs().endOf("week"),
                          ],
                          "This Month": [
                            dayjs().startOf("month"),
                            dayjs().endOf("month"),
                          ],
                          "Last Month": [
                            dayjs().subtract(1, "month").startOf("month"),
                            dayjs().subtract(1, "month").endOf("month"),
                          ],
                        }}
                        value={[
                          formValues.selling_startdate
                            ? dayjs(formValues.selling_startdate)
                            : null,
                          formValues.selling_enddate
                            ? dayjs(formValues.selling_enddate)
                            : null,
                        ]}
                        onChange={(dates, dateStrings: [string, string]) => {
                          const [start, end] = dates || [null, null];
                          handleInputChange(
                            "selling_startdate",
                            start ? start.format("YYYY-MM-DD") : null
                          );
                          handleInputChange(
                            "selling_enddate",
                            end ? end.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            <div>
              <h3 style={{ display: "flex", alignItems: "center" }}>
                <span className="vertical-line"></span>Applicable to
              </h3>
            </div>
            {/* Tabs for Service, Product, Privilege, Store */}
            <Tabs defaultActiveKey={activeKey} onChange={setActiveKey}>
              <TabPane
                tab="Store"
                key="store"
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Row>
                  <span
                    style={{
                      color: "orange",
                      marginBottom: "5px",
                      display: "inline-block",
                    }}
                  >
                    <InfoCircleOutlined style={{ marginRight: "8px" }} />
                    Switching the selection method will clear the set data
                  </span>
                </Row>

                <Radio.Group
                  value={formValues.applicable_type}
                  onChange={(e) =>
                    handleInputChange("applicable_type", e.target.value)
                  }
                >
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <Radio value="All stores">
                        <span className="radio-text">All stores</span>
                      </Radio>
                    </Col>
                    <Col span={10}>
                      <Radio value="Current store only">
                        <span className="radio-text">Current store only</span>
                      </Radio>
                    </Col>
                    <Col span={6}>
                      <Radio value="By store">
                        <span className="radio-text">By store</span>
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </TabPane>
            </Tabs>

            {/* Loyalty Points Section */}
            <div>
              <h3 style={{ display: "flex", alignItems: "center" }}>
                <span className="vertical-line"></span>Loyalty Points
              </h3>
            </div>
            <Row gutter={16}>
              {/* Dropdown for Loyalty Type */}
              <Col span={8}>
                <Form.Item>
                  <Select
                    placeholder="Please select"
                    value={formValues.loyalty_pointtype}
                    dropdownStyle={{ textAlign: "center" }}
                    style={{
                      // width: "206px",
                      height: "38px",
                      lineHeight: "40px",
                    }}
                    onChange={(value) =>
                      handleInputChange("loyalty_pointtype", value)
                    } // Make sure to update formValues
                    allowClear
                  >
                    <Option value="fixed">Fixed</Option>
                    <Option value="standardunitprice">
                      Standard Unit Price
                    </Option>
                    <Option value="turnover">Turnover</Option>
                    <Option value="actualturnover">Actual Turnover</Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* Input field for Percentage */}
              <Col span={8}>
                <Form.Item>
                  <Input
                    type="number"
                    placeholder="Please enter"
                    style={{
                      // width: "206px",
                      height: "40px",
                      lineHeight: "40px",
                    }}
                    suffix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                        }}
                      >
                        {formValues.loyalty_pointtype === "fixed"
                          ? "Points"
                          : "%"}
                      </span>
                    }
                    value={formValues.loyalty_pointvalue}
                    onChange={(e) =>
                      handleInputChange("loyalty_pointvalue", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Note Section */}
            <div>
              <h3 style={{ display: "flex", alignItems: "center" }}>
                <span className="vertical-line"></span>Note
              </h3>
            </div>
            <Form.Item>
              <TextArea
                rows={4}
                autoSize={{ minRows: 3 }}
                placeholder="Please enter"
                value={formValues.note}
                onChange={(e) => handleInputChange("note", e.target.value)}
              />
            </Form.Item>
          </Form>

          <Row justify="end" style={{ marginBottom: "0px !important" }}>
            <Col>
              <div style={{ display: "flex", gap: "5px" }}>
                <span
                  style={{ fontSize: "12px", fontWeight: 500, color: "#666" }}
                >
                  Total amount:
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#f43b00",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}
                  {grandTotal.toFixed(2)}
                </span>
              </div>
            </Col>
          </Row>
          <Row justify="end" align="middle" style={{ marginBottom: "48px" }}>
            {/* Total Amount */}
            {/* Tax Dropdown */}
            <Col span={6}>
              <Form.Item label={null}>
                <Select
                  // placeholder="No tax (0%)"
                  value={formValues.tax}
                  onChange={(value) => handleInputChange("tax", value)}
                  dropdownStyle={{ textAlign: "center" }}
                >
                  {taxData.length > 0 &&
                    taxData.map((tax: any) => (
                      <Option key={tax.id} value={tax.id}>
                        {tax.taxname}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* <ServiceByCategoryModel
        title="Services "
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSaveServices}
        selectedServices={selectedServices}
        footer={null}
      /> */}

      <PackageProductModel
        title="Product"
        visible={showProductsItemModel}
        onCancel={() => {
          setshowProductsItemModel(false);
        }}
        onSave={handleSaveProducts}
        // selectedProducts={selectedProducts}
        selectedProducts={formValues.products}
        footer={null}
      />

      <ServiceCategoryModel
        title="Services"
        visible={showServiceItemModal}
        onCancel={handleCancel}
        onSave={handleSaveService}
        selectedServices={formValues.services}
        footer={null}
      />

      {/* <SelectServiceItemModal
        title="Services"
        visible={showServiceItemModal}
        onCancel={() => {
          setShowServiceItemModal(false);
        }}
        onSave={handleSaveServices}
        selectedServices={services}
        footer={null}
      /> */}
    </>
  );
};

export default PackagesModel;
