import CryptoJS from "crypto-js";

// Function to encrypt password using AES
export const encryptPassword = (password: string) => {
  const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
  return CryptoJS.AES.encrypt(password, secretKey).toString();
};

export const encryptGoogleMap = (googleMapKey: string) => {
  const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
  return CryptoJS.AES.encrypt(googleMapKey, secretKey).toString();
};

export const decryptGoogleApiKey = (encryptedKey: string): string => {
  const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
  const bytes = CryptoJS.AES.decrypt(encryptedKey, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
