// src/CurrencyContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

// Define the type for the currency symbol
type Currency = {
  CURRENCY_SYMBOL: string;
  CURRENCY_CODE: string;
};

// Create a context with default values
const CurrencyContext = createContext<{
  currencySymbol: Currency;
  setCurrencySymbol: (newCurrency: Currency) => void;
}>({
  currencySymbol: { CURRENCY_SYMBOL: "RM", CURRENCY_CODE: "MYR" }, // Default values
  setCurrencySymbol: () => {},
});

interface CurrencyProviderProps {
  children: ReactNode;
}

// Currency provider component
export const CurrencyProvider: React.FC<CurrencyProviderProps> = ({ children }) => {
  // Load currency from localStorage if available, else use defaults
  const [currencySymbol, setCurrencySymbolState] = useState<Currency>(() => {
    const savedCurrency = localStorage.getItem("currencySymbol");
    return savedCurrency ? JSON.parse(savedCurrency) : { CURRENCY_SYMBOL: "RM", CURRENCY_CODE: "MYR" };
  });

  // Update the currency in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("currencySymbol", JSON.stringify(currencySymbol));
  }, [currencySymbol]);

  // Function to update the currency state
  const setCurrencySymbol = (newCurrency: Currency) => {
    setCurrencySymbolState(newCurrency);
  };

  return (
    <CurrencyContext.Provider value={{ currencySymbol, setCurrencySymbol }}>
      {children}
    </CurrencyContext.Provider>
  );
};

// Custom hook to use currency context
export const useCurrency = () => useContext(CurrencyContext);
