import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  message,
} from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { get } from "../../../services/Apiclient";
import ConfirmDeleteModal from "../DeleteModal";
// import { currencySymbol } from "../../../constants/currencySymbol";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Option } = Select;

interface TipsModelModelProps {
  visible: boolean;
  taxArray?: any;
  totalValue?: any;
  paymentMethod?: any;
  paymentMethodId?: any;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const TaxModel: React.FC<TipsModelModelProps> = ({
  visible,
  taxArray,
  totalValue,
  paymentMethod,
  paymentMethodId,
  onCancel,
  onSubmit,
}) => {
  const { currencySymbol } = useCurrency();
  const [taxItems, setTaxItems] = useState<any>([]);
  const validationSchema = Yup.object().shape({
    tax: Yup.string().required("Please choose a tax type."),
  });

  const initialFormValues = {
    tax: null,
  };

  const initialFormEditValues = {
    tax_rate: "",
    tax_amount: "",
  };
  const [isAddTaxModalVisible, setIsAddTaxModalVisible] = useState(false);
  const [isEditTaxModalVisible, setIsEditTaxModalVisible] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [formEditValues, setFormEditValues] = useState(initialFormEditValues);
  const [taxesData, setTaxesData] = useState<any[]>([]);
  const [editingTax, setEditingTax] = useState<any>(null);

  const [isDeleteTaxModalVisible, setIsDeleteTaxModalVisible] = useState(false);
  const columns = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: 60,
      render: (text: any, record: any, index: number) => (
        <span>
          <span>{index + 1}</span>
        </span>
      ),
    },
    {
      title: "Tax name",
      dataIndex: "tax_name",
      key: "tax_name",
      width: 100,
    },
    {
      title: "Tax rate",
      dataIndex: "tax_rate",
      key: "tax_rate",
      width: 100,
      render: (text: any) => <span>{text}%</span>,
    },
    {
      title: "Amount",
      dataIndex: "tax_amount",
      key: "tax_amount",
      width: 100,
      render: (text: any) => (
        <span>
          <b>
            {currencySymbol.CURRENCY_SYMBOL}
            {text}
          </b>
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text: any, record: any) => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleEditClick(record)}
          >
            Edit
          </a>
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleDeleteClick(record)}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const generateUniqueId = () => {
    const uniqueId = nanoid(8); // Generates a unique 8-character string
    return uniqueId;
  };

  const handleEditClick = (record: any) => {
    setEditingTax(record);
    console.log(record, "record-edit tax");
    setFormEditValues({
      tax_rate: record.tax_rate,
      tax_amount: record.tax_amount,
    });
    setIsEditTaxModalVisible(true);
  };

  const handleDeleteClick = (record: any) => {
    setEditingTax(record);
    setIsDeleteTaxModalVisible(true);
  };
  const handleEditSubmit = (values: any) => {
    const updatedTaxItem = {
      ...editingTax,
      tax_rate: formEditValues.tax_rate,
      tax_amount: formEditValues.tax_amount,
    };

    const updatedTaxItems = taxItems.map((taxItem: any) =>
      taxItem.tax_unique_id === updatedTaxItem.tax_unique_id
        ? updatedTaxItem
        : taxItem
    );
    setTaxItems(updatedTaxItems);
    setIsEditTaxModalVisible(false);
    message.success("Tax updated successfully!");
  };

  const handleEditTaxModalCancel = () => {
    setIsEditTaxModalVisible(false);
  };

  const handleConfirmTax = () => {
    const updatedTaxItems = taxItems.filter(
      (taxItem: any) => taxItem.tax_unique_id !== editingTax.tax_unique_id
    );
    setTaxItems(updatedTaxItems);
    setIsDeleteTaxModalVisible(false);
    setEditingTax(null);
  };

  const closeDeleteTaxModal = () => {
    setIsDeleteTaxModalVisible(false);
  };

  const calculateTaxAmount = (taxRate: string) => {
    const rate = parseFloat(taxRate);
    const amount = totalValue;
    return (rate / 100) * amount;
  };

  const handleSubmit = async () => {
    onSubmit(taxItems);
    onCancel();
  };

  const handleTaxAddSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      const selectedTax = taxesData.find(
        (tax: any) => tax.id === formValues.tax
      );

      if (selectedTax) {
        const taxExists = taxItems.some(
          (taxItem: any) => taxItem.tax_id === selectedTax.id
        );

        if (taxExists) {
          message.error("Please do not add taxes repeatedly.");
          return;
        }

        const newTaxItem = {
          tax_unique_id: generateUniqueId(),
          tax_id: selectedTax.id,
          tax_name: selectedTax.taxname,
          tax_rate: selectedTax.taxrate,
          tax_amount: calculateTaxAmount(selectedTax.taxrate).toFixed(2),
          tax_payment_method: paymentMethod,
          tax_payment_id: paymentMethodId,
        };

        setTaxItems((prevTaxItems: any[]) => [...prevTaxItems, newTaxItem]);

        setIsAddTaxModalVisible(false);
      } else {
        message.error("Selected tax not found!");
      }
    } catch (errors: any) {
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };
  const handleAddTax = () => {
    setIsAddTaxModalVisible(true);
  };

  const handleAddTaxModalCancel = () => {
    setIsAddTaxModalVisible(false);
  };

  const fetchTaxes = async () => {
    try {
      const response = await get(
        `/tax/addmanuallylist/${localStorage.getItem(
          "outlet_id"
        )}?addManually=true`
      );
      if (response.data.success) {
        setTaxesData(response.data.data);
      } else {
        message.error("Failed to fetch taxess");
      }
    } catch (error) {
      console.error("Error fetching taxes: ", error);
      message.error("Error fetching taxes");
    }
  };

  const handleInputEditChange = (name: any, value: any) => {
    if (value == "") {
      setFormEditValues({
        tax_rate: "0",
        tax_amount: "0",
      });
    } else {
      // setFormEditValues((prev) => ({
      //   ...prev,
      //   [name]: value,
      // }));
      if (name == "tax_rate") {
        const newTaxAmount = ((parseFloat(value) / 100) * totalValue).toFixed(
          2
        );
        setFormEditValues({
          tax_rate: value,
          tax_amount: newTaxAmount,
        });
      } else if (name == "tax_amount") {
        const newTaxRate = ((parseFloat(value) / totalValue) * 100).toFixed(2);
        setFormEditValues({
          tax_amount: value,
          tax_rate: newTaxRate,
        });
      }
    }
  };

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormValues(initialFormValues);
  }, [isAddTaxModalVisible]);

  useEffect(() => {
    setTaxItems(taxArray);
    fetchTaxes();
  }, [visible]);

  return (
    <>
      <Modal
        title="Taxes"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <>
            <Row justify={"end"}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button key="save" type="primary" onClick={handleSubmit}>
                  Confirm
                </Button>
              </div>
            </Row>
          </>,
        ]}
        style={{ top: "80px" }}
        bodyStyle={{
          height: "calc(100vh - 340px)",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
        width={800}
      >
        <Table
          columns={columns}
          dataSource={taxItems}
          pagination={false}
          scroll={{ x: 0, y: "calc(100vh - 400px)" }}
          rowClassName={() => "custom-row"}
        />
        <Row>
          <div
            style={{
              padding: "12px 18px",
              height: "32px",
              color: "#2e56f2",
              borderColor: "transparent",
            }}
          >
            <div
              style={{
                height: "100%",
                lineHeight: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handleAddTax}
            >
              +Add tax
            </div>
          </div>
        </Row>
      </Modal>

      {/* Add Tax Modal */}
      <Modal
        title="Add Tax"
        visible={isAddTaxModalVisible}
        onCancel={handleAddTaxModalCancel}
        style={{ top: "200px" }}
        footer={[
          <>
            <Row justify={"end"}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button key="save" type="primary" onClick={handleTaxAddSubmit}>
                  Confirm
                </Button>
              </div>
            </Row>
          </>,
        ]}
        width={500}
      >
        <Form
          layout="vertical"
          onFinish={handleTaxAddSubmit}
          style={{ paddingTop: "25px" }}
        >
          <Form.Item
            label="Select Tax"
            style={{ flex: 0.4, marginLeft: "7px" }}
          >
            <Select
              placeholder="Please select"
              value={formValues.tax}
              onChange={(value) => handleInputChange("tax", value)}
              dropdownStyle={{ textAlign: "center" }}
              style={{ height: "40px", width: "100%" }}
            >
              {taxesData.length > 0 &&
                taxesData.map((tax: any) => (
                  <Option value={tax.id} key={tax.id}>
                    {tax.taxname}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Tax"
        visible={isEditTaxModalVisible}
        onCancel={handleEditTaxModalCancel}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px 10px" }} />
            <Row justify={"end"}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button key="save" type="primary" onClick={handleEditSubmit}>
                  Confirm
                </Button>
              </div>
            </Row>
          </>,
        ]}
        width={500}
        style={{ top: "200px" }}
      >
        <Form layout="vertical" onFinish={handleEditSubmit}>
          <Form.Item label="Tax Rate">
            <Input
              type="number"
              min={0}
              placeholder="Please enter"
              value={Number(formEditValues.tax_rate)}
              onChange={(e) =>
                handleInputEditChange("tax_rate", e.target.value)
              }
              suffix={
                <span
                  style={{
                    color: "rgba(46, 86, 242, .5)",
                    fontWeight: 700,
                  }}
                >
                  %
                </span>
              }
              allowClear
            />
          </Form.Item>
          <Form.Item label="Amount">
            <Input
              type="number"
              min={0}
              placeholder="Please enter"
              value={Number(formEditValues.tax_amount)}
              onChange={(e) =>
                handleInputEditChange("tax_amount", e.target.value)
              }
              prefix={
                <span
                  style={{
                    color: "rgba(46, 86, 242, .5)",
                    fontWeight: 700,
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}
                </span>
              }
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
      <ConfirmDeleteModal
        visible={isDeleteTaxModalVisible}
        onCancel={closeDeleteTaxModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirmTax}
        message="Are you sure you want to delete?"
      />
    </>
  );
};

export default TaxModel;
