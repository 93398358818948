import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
  Table,
} from "antd";
import { Option } from "antd/es/mentions";
import update from "immutability-helper";
import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import { deleteData, get, post, put } from "../../services/Apiclient";

interface StockInProps {
  selectedOutlet: string;
}

const StockIn: React.FC<StockInProps> = ({ selectedOutlet }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [outletData, setOutletData] = useState<any[]>([]);
  const outletId = localStorage.getItem("outlet_id");
  const [selectedOutlets, setSelectedOutlets] = useState<string[]>([]);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditingRecord(null);
    setSelectedOutlets([]);
    setIsDuplicateModalVisible(false);
  };

  const handleOk = async () => {
    let messageStr = "Please complete the required fields.";
    try {
      const values = await form.validateFields();
      if (!values.stockintype) {
        if (!values.stockintype) {
          messageStr = "Please enter Types name.";
          throw new Error("Please enter Types name.");
        }
      }

      if (editingRecord) {
        values.outlet_id = selectedOutlet;
        await put(`/stockin/${editingRecord.key}`, values);
        message.success("Stock In updated successfully!");
        fetchStockIn();
      } else {
        values.outlet_id = selectedOutlet;
        await post("/stockin/addstockin", values);
        message.success("Stock In added successfully!");
        fetchStockIn();
      }
      setIsModalVisible(false);
      form.resetFields();
      setEditingRecord(null);
    } catch (error) {
      message.error(messageStr);
    }
  };

  const handleDeleteClick = (record: any) => {
    setSelectedData(record);
    setDeleteModalVisible(true);
  };

  const handleEdit = async (record: any) => {
    try {
      const response = await get(`/stockin/${record.key}`);
      if (response.data.success) {
        const data = response.data.data;
        const recordData = {
          key: data.id,
          code: data.code,
          stockintype: data.stockintype,
          status: data.status,
        };

        setEditingRecord(recordData);
        form.setFieldsValue(record);
        setIsModalVisible(true);
      }
    } catch (error) {
      message.error("Failed to load stockin details.");
      console.error(error);
    }
  };

  const handleConfirm = async () => {
    try {
      if (selectedData) {
        // Call delete API
        await deleteData(`/stockin/remove/${selectedData.key}`);
        message.success("Stock In deleted successfully!");
        fetchStockIn();
      }
      setDeleteModalVisible(false);
      setSelectedData(null);
    } catch (error) {
      message.error("Failed to delete stockin.");
      console.error(error);
    }
  };

  const handleToggle = async (key: React.Key, checked: boolean) => {
    try {
      let url = `/stockin/${key}`;

      let body = {
        status: checked,
      };

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          fetchStockIn();
        } else {
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (errors) {
      message.error("error occur in api call");
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const columns = [
    {
      title: "",
      dataIndex: "index",
      key: "order",
      width: 20,
      render: () => <span style={{ cursor: "move" }}>☰</span>,
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: 30,
      render: (text: any, record: any, index: number) => (
        <span>
          <span>{index + 1}</span>
        </span>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "stockintype",
      key: "stockintype",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 60,
      render: (status: any, record: any) => (
        <Switch
          checked={status}
          onChange={(checked) => handleToggle(record.key, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text: any, record: any) => (
        <>
          {" "}
          {record.allowdelete ? (
            <a
              style={{
                color: "#2e56f2",
                textDecoration: "underline",
                fontWeight: 700,
                marginRight: "16px",
                textUnderlineOffset: "4px",
              }}
              onClick={() => handleEdit(record)}
            >
              Edit
            </a>
          ) : (
            <></>
          )}
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleDeleteClick(record)}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const fetchStockIn = async () => {
    try {
      const response = await get(`/stockin/list/${selectedOutlet}`);
      if (response.data.success) {
        const data = response.data.data.map((item: any, index: number) => ({
          key: item.id,
          order: (index + 1).toString(),
          code: item.code,
          stockintype: item.stockintype,
          status: item.status,
          allowdelete: item.allowdelete,
        }));
        setDataSource(data);
      } else {
        message.error("Error while fetch data");
      }
    } catch (error) {
      message.error("Failed to load storage.");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchStockIn();
  }, [selectedOutlet]);

  useEffect(() => {
    fetchStockIn();
  }, []);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedRow = dataSource[dragIndex];
    const newDataSource = update(dataSource, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedRow],
      ],
    });
    setDataSource(newDataSource);
    updateSequence(newDataSource);
  };

  // Update sequence API call
  const updateSequence = async (newDataSource: any[]) => {
    const updatedSequence = newDataSource.map((item, index) => ({
      id: item.key,
      sequence: index + 1,
    }));
    try {
      await put("/stockin/sequenceupdate", {
        stockin: updatedSequence,
      });
    } catch {
      message.error("Failed to update stockin order.");
    }
  };

  const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }: any) => {
    const ref = React.useRef<HTMLTableRowElement>(null);
    const [, drop] = useDrop({
      accept: "row",
      hover(item: any, monitor) {
        if (!ref.current) return;
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) return;

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        moveRow(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "row",
      item: { index },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    drag(drop(ref));

    return (
      <tr
        ref={ref}
        style={{ ...style, opacity: isDragging ? 0.5 : 1 }}
        {...restProps}
      />
    );
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getOutletDropdown();
  }, []);

  const showDuplicateModal = () => {
    if (outletData.length === 0) {
      getOutletDropdown().then(() => {
        setIsDuplicateModalVisible(true);
      });
    } else {
      setIsDuplicateModalVisible(true);
    }
  };

  // Handle Duplicate action
  const handleDuplicate = async () => {
    if (selectedOutlets.length === 0) {
      message.error("Please select an outlet.");
      return;
    }

    try {
      const requestData = {
        target_outlet_id: selectedOutlets[0], // Use the single selected outlet ID
      };

      const response = await post(
        `/stockin/duplicate/${selectedOutlet}`,
        requestData
      );

      if (response.data.success) {
        message.success("Stock in duplicated successfully!");
        handleCancel();
      } else {
        message.error("Failed to duplicate stock in.");
      }
    } catch (error) {
      message.error("An error occurred while duplicating the stock in.");
      console.error(error);
    }
  };

  // Handle selecting the outlets
  const handleOutletChange = (value: string[]) => {
    setSelectedOutlets(value);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <h3 style={{ marginBottom: 0 }}>StockIn</h3>
          <div style={{ display: "flex", gap: "10px" }}>
            {localStorage.getItem("userRole") == "Merchant" && (
              <Button
                type="primary"
                icon={<CopyOutlined />}
                onClick={showDuplicateModal}
              >
                Duplicate
              </Button>
            )}
            <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
              Add
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          components={{
            body: {
              row: DraggableBodyRow,
            },
          }}
          scroll={{ y: "calc(100vh - 230px)" }}
          rowClassName={() => "custom-row"}
          onRow={(record, index) => ({
            index,
            className: "",
            style: {},
            moveRow,
          })}
        />
        <Modal
          title={editingRecord ? "Edit" : "Add"}
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleOk}
          okText="Save"
          cancelText="Cancel"
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Types name" name="stockintype" required>
              <Input placeholder="Please enter" allowClear />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Select Outlet to Duplicate"
          visible={isDuplicateModalVisible}
          onOk={handleDuplicate}
          onCancel={handleCancel}
          okText="Copy"
          cancelText="Cancel"
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Select Outlet" name="outlet" required>
              <Select
                value={selectedOutlets[0]}
                onChange={(value) => handleOutletChange([value])}
                placeholder="Select an outlet to duplicate"
                style={{ width: "100%" }}
              >
                {outletData.map((outlet: any) => (
                  <Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={handleCancelDelete}
          // onConfirm={() => handleDeleteApi(editingRecord.key)}
          onConfirm={handleConfirm}
          message="Are you sure to delete?"
        />
      </DndProvider>
    </>
  );
};

export default StockIn;
