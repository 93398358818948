import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Upload,
  Divider,
  Col,
  Select,
  Row,
} from "antd";
import { useParams } from "react-router-dom";
import { get } from "../../../services/Apiclient";
// import { currencySymbol } from "../../../constants/currencySymbol";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { useTranslation } from "react-i18next";import Item from "antd/es/list/Item";
const { Title, Text } = Typography;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;

interface PdfPreviewModelProps {
  visible: boolean;
  onClose: () => void;
  responseData?: any;
}
interface Payment {
  payment_id: string;
  payment_name: string;
  payment_amount: string;
}

interface Tax {
  tax_id: string;
  tax_name: string;
  tax_rate: string;
  tax_amount: string;
  tax_payment_id: string;
  tax_payment_method: string;
}

interface Staff {}

interface Service {
  item_id: string;
  item_name: string;
  item_qty: string;
  item_price: string;
  item_unit_price: string;
  item_payment_method: string;
  item_payment_id: string;
  staff: Staff[];
  splits: any[];
}

interface PdfData {
  id: string;
  ref_no: string;
  sign: string;
  outlet_logo: string | null;
  male_count: number;
  female_count: number;
  note: string;
  outlet_username: string;
  customer_source: string;
  order_date: string;
  customer_id: string;
  customercode: string | null;
  outlet_countrycode: string | null;
  customer_lastname: string | null;
  customer_name: string | null;
  customer_email: string | null;
  countrycode: string | null;
  phonenumber: string | null;
  tip_rate: string | null;
  tip_value: string | null;
  tip_payment_method: string | null;
  tip_payment_id: string | null;
  tip_code: string | null;
  item_created_at: string;
  created_at_myt: string;
  payment: Payment[];
  tax: Tax[];
  products: any[];
  giftcards: any[];
  membership: any[];
  packages: any[];
  debt: any[];
  kiv: any[];
  course: any[];
  services: Service[];
  sub_total: string;
  total_tax: string;
  tips: string;
  final_total: string;
  outlet_address: string;
  outlet_phone: string;
  print_time: string;
}

const PdfPreview: React.FC<PdfPreviewModelProps> = ({
  visible,
  onClose,
  responseData,
}) => {
  const { currencySymbol } = useCurrency();
  const { id } = useParams();
  const [pdfData, setPdfData] = useState<PdfData | null>(null);

  // const changeLanguage = (language: string) => {
  //   i18n.changeLanguage(language); // Change the current language
  // };

  // useEffect(() => {
  //   setRemark(value || "");
  // }, [value]);

  useEffect(() => {
    if (visible) {
      console.log(responseData, "responseData");
      setPdfData(responseData);
    }
  }, [visible]);

  const fetchBillingPdf = async () => {
    try {
      const response = await get(`/billing/pdf/${id}`);

      if (response?.data?.success) {
        window.open(response.data.url);
        // const iframe = document.createElement("iframe");

        // // Set iframe styles to hide it
        // iframe.style.position = "fixed";
        // iframe.style.width = "0";
        // iframe.style.height = "0";
        // iframe.style.border = "none";

        // // Set the PDF link as the iframe source
        // iframe.src = response.data.url;

        // // Append iframe to the body
        // document.body.appendChild(iframe);

        // // Wait for the iframe to load
        // iframe.onload = () => {
        //   // Ensure contentWindow is available before printing
        //   if (iframe.contentWindow) {
        //     iframe.contentWindow.print();

        //     // Optional: Remove iframe after printing
        //     setTimeout(() => {
        //       document.body.removeChild(iframe);
        //     }, 1000);
        //   } else {
        //     console.error("Iframe contentWindow is not available.");
        //   }
        // };
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };
  return (
    <Modal
      visible={visible}
      title="Print preview"
      onCancel={onClose}
      centered
      className="billdetail-modal"
      width={350}
      footer={[
        <>
          <Row justify="space-between" align="middle">
            <Col>
              <Select
                defaultValue="English"
                style={{
                  width: "180px",
                  textAlign: "left",
                  paddingLeft: "16px",
                  // paddingRight: "32px",
                  border: "none",
                  height: "38px !important",
                  lineHeight: "40px",
                  background: "transparent",
                }}
              >
                <Select.Option value="English">English</Select.Option>
                {/* <Select.Option value="Spanish">Spanish</Select.Option> */}
              </Select>
            </Col>
            <Col>
              <Button type="primary" onClick={fetchBillingPdf}>
                Print
              </Button>
            </Col>
          </Row>
        </>,
      ]}
    >
      <div style={{ textAlign: "center" }}>
        <img
          // className="image-preview"
          src={
            pdfData?.outlet_logo == null
              ? defaultStoreImage
              : pdfData?.outlet_logo
          }
          alt="Avatar"
          style={{
            margin: "5px 0",
            width: "auto",
            maxWidth: "200px",
            height: "56px",
            background: "transparent",
            objectFit: "contain",
          }}
        />
        <Title level={4} style={{ margin: "0px" }}>
          {pdfData?.outlet_username}
        </Title>
        <Text>Sales receipt</Text>
      </div>

      <Divider style={{ margin: "10px 0px 10px" }} />

      <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={12}>
          <Text>ref#: {pdfData?.ref_no}</Text>
        </Col>
      </Row>
      {/* <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={12}>
          <Text>Tax ID:-</Text>
        </Col>
      </Row> */}
      <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col>
          <Text>Accounting date: {pdfData?.item_created_at}</Text>
        </Col>
      </Row>
      <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={16}>
          <Text>Payment time: {pdfData?.created_at_myt}</Text>
        </Col>
      </Row>
      <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={18}>
          <Text>Print time: {pdfData?.print_time}</Text>
        </Col>
      </Row>
      <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={12}>
          <Text>
            Customer:{" "}
            {pdfData?.customer_name
              ? `${pdfData.customer_name}${
                  pdfData?.customer_lastname
                    ? ` ${pdfData.customer_lastname}`
                    : ""
                }`
              : "Walk-in customer"}
          </Text>
        </Col>
      </Row>
      <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={16}>
          <Text>Customer code: {pdfData?.customercode || "-"}</Text>
        </Col>
      </Row>
      <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={18}>
          <Text>
            Customer phone:{" "}
            {pdfData?.countrycode ? `+${pdfData?.countrycode} ` : ""}{" "}
            {pdfData?.phonenumber}
          </Text>
        </Col>
      </Row>
      <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={18}>
          <Text>Customer email: {pdfData?.customer_email}</Text>
        </Col>
      </Row>
      {/* <Row style={{ padding: "1px 0", lineHeight: "18px", fontSize: "10px" }}>
        <Col span={12}>
          <Text>
            Customer count:
            {(pdfData?.female_count ?? 0) + (pdfData?.male_count ?? 0)}
          </Text>
        </Col>
      </Row> */}

      <Divider style={{ margin: "10px 0px 10px" }} />

      <Title
        style={{
          margin: "0px",
          lineHeight: "24px",
          fontSize: "12px",
          fontWeight: 700,
        }}
      >
        Sales details
      </Title>
      <Row>
        <Col span={12}>
          <Text>Price & Qty.</Text>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Text>Paid</Text>
        </Col>
      </Row>

      {pdfData && pdfData.course && pdfData.course.length > 0 ? (
        pdfData.course.map((item: any, index: any) => (
          <Row key={index}>
            <Col span={16}>
              <Text
                style={{
                  lineHeight: "18px",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {item?.item_name}{" "}
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}></Col>
            <Text>
              {item.item_qty}{" "}
              {item.item_qty > 1 ? "deductions (course)" : "deduction (course)"}
            </Text>
          </Row>
        ))
      ) : (
        <></>
      )}

      {pdfData && pdfData.kiv && pdfData.kiv.length > 0 ? (
        pdfData.kiv.map((item: any, index: any) => (
          <Row key={index}>
            <Col span={16}>
              <Text
                style={{
                  lineHeight: "18px",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {item?.item_name}{" "}
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}></Col>
            <Text>
              {item.item_qty}{" "}
              {item.item_qty > 1 ? "deductions (kiv)" : "deduction (kiv)"}
            </Text>
          </Row>
        ))
      ) : (
        <></>
      )}

      {pdfData && pdfData.debt && pdfData.debt.length > 0 ? (
        pdfData.debt.map((item: any, index: any) => (
          <Row key={index}>
            <Col span={16}>
              <Text
                style={{
                  lineHeight: "18px",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {item?.item_name}{" "}
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Text>
                {currencySymbol.CURRENCY_SYMBOL}{" "}
                {parseFloat(item?.item_price).toFixed(2)}
              </Text>
            </Col>
            <Col span={24}>
              <Text>
                {currencySymbol.CURRENCY_SYMBOL}{" "}
                {parseFloat(item?.item_price).toFixed(2)} x {item.item_qty}
              </Text>
            </Col>
            {item?.debtdetails?.length > 0
              ? item?.debtdetails.map((staffMember: any) => (
                  <>
                    <Col span={24}>
                      <Text>
                        {staffMember?.debt_order_date}{" "}
                        {staffMember?.debt_ref_no}{" "}
                        {currencySymbol.CURRENCY_SYMBOL}
                        {staffMember.debt_item_price}
                      </Text>
                    </Col>
                  </>
                ))
              : null}
          </Row>
        ))
      ) : (
        <></>
      )}

      {pdfData && pdfData.services && pdfData.services.length > 0 ? (
        pdfData.services.map((item: any, index: any) => (
          <Row key={index}>
            <Col span={16}>
              <Text
                style={{
                  lineHeight: "18px",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {item?.item_name}{" "}
                {item?.staff?.length > 0
                  ? `(${item?.staff
                      .map((staffMember: any) => staffMember?.staff_name)
                      .join(", ")})`
                  : ""}
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Text>
                {currencySymbol.CURRENCY_SYMBOL}{" "}
                {parseFloat(item?.item_price).toFixed(2)}
              </Text>
            </Col>
            <Text>
              {currencySymbol.CURRENCY_SYMBOL}{" "}
              {parseFloat(item?.item_unit_price).toFixed(2)} x {item.item_qty}
            </Text>
          </Row>
        ))
      ) : (
        <></>
      )}

      {pdfData && pdfData.products && pdfData.products.length > 0 ? (
        pdfData.products.map((item: any, index: any) => (
          <Row key={index}>
            <Col span={16}>
              <Text
                style={{
                  lineHeight: "18px",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {item?.item_name}{" "}
                {item?.staff?.length > 0
                  ? `(${item?.staff
                      .map((staffMember: any) => staffMember?.staff_name)
                      .join(", ")})`
                  : ""}
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Text>
                {currencySymbol.CURRENCY_SYMBOL}{" "}
                {parseFloat(item?.item_price).toFixed(2)}
              </Text>
            </Col>
            <Text>
              {currencySymbol.CURRENCY_SYMBOL}{" "}
              {parseFloat(item?.item_unit_price).toFixed(2)} x {item.item_qty}
            </Text>
          </Row>
        ))
      ) : (
        <></>
      )}

      {pdfData && pdfData.giftcards && pdfData.giftcards.length > 0 ? (
        pdfData.giftcards.map((item: any, index: any) => (
          <Row key={index}>
            <Col span={16}>
              <Text
                style={{
                  lineHeight: "18px",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {item?.item_name}{" "}
                {item?.staff?.length > 0 &&
                item?.staff.some(
                  (staffMember: any) => staffMember?.staff_name !== null
                )
                  ? `(${item?.staff
                      .filter(
                        (staffMember: any) => staffMember?.staff_name !== null
                      )
                      .map((staffMember: any) => staffMember?.staff_name)
                      .join(", ")})`
                  : ""}
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Text>
                {currencySymbol.CURRENCY_SYMBOL}{" "}
                {parseFloat(item?.item_price).toFixed(2)}
              </Text>
            </Col>
            <Text>
              {currencySymbol.CURRENCY_SYMBOL}{" "}
              {parseFloat(item?.item_unit_price).toFixed(2)} x {item.item_qty}
            </Text>
          </Row>
        ))
      ) : (
        <></>
      )}

      {pdfData && pdfData.membership && pdfData.membership.length > 0 ? (
        pdfData.membership.map((item: any, index: any) => (
          <Row key={index}>
            <Col span={16}>
              <Text
                style={{
                  lineHeight: "18px",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {item?.item_name}{" "}
                {item?.staff?.length > 0 &&
                item?.staff.some(
                  (staffMember: any) => staffMember?.staff_name !== null
                )
                  ? `(${item?.staff
                      .filter(
                        (staffMember: any) => staffMember?.staff_name !== null
                      )
                      .map((staffMember: any) => staffMember?.staff_name)
                      .join(", ")})`
                  : ""}
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Text>
                {currencySymbol.CURRENCY_SYMBOL}{" "}
                {parseFloat(item?.item_price).toFixed(2)}
              </Text>
            </Col>
            <Text>
              {currencySymbol.CURRENCY_SYMBOL}{" "}
              {parseFloat(item?.item_unit_price).toFixed(2)} x {item.item_qty}
            </Text>
          </Row>
        ))
      ) : (
        <></>
      )}

      {pdfData && pdfData.packages && pdfData.packages.length > 0 ? (
        pdfData.packages.map((item: any, index: any) => (
          <Row key={index}>
            <Col span={16}>
              <Text
                style={{
                  lineHeight: "18px",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {item?.item_name}{" "}
                {item?.staff?.length > 0 &&
                item?.staff.some(
                  (staffMember: any) => staffMember?.staff_name !== null
                )
                  ? `(${item?.staff
                      .filter(
                        (staffMember: any) => staffMember?.staff_name !== null
                      )
                      .map((staffMember: any) => staffMember?.staff_name)
                      .join(", ")})`
                  : ""}
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Text>
                {currencySymbol.CURRENCY_SYMBOL}{" "}
                {parseFloat(item?.item_price).toFixed(2)}
              </Text>
            </Col>
            <Text>
              {currencySymbol.CURRENCY_SYMBOL}{" "}
              {parseFloat(item?.item_unit_price).toFixed(2)} x {item.item_qty}
            </Text>
          </Row>
        ))
      ) : (
        <></>
      )}

      <Divider style={{ margin: "10px 0px 10px" }} />

      <Title
        style={{
          margin: "0px",
          lineHeight: "24px",
          fontSize: "12px",
          fontWeight: 700,
        }}
      >
        Payment summary
      </Title>
      <Row>
        <Col span={12}>
          <Text>Payment</Text>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Text>Amount</Text>
        </Col>
      </Row>
      <Row>
        {pdfData &&
          pdfData.course &&
          pdfData.course.map((item: any, index: any) => (
            <>
              <Col span={12}>
                <Text>course</Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text>
                  {item.item_qty}{" "}
                  {item.item_qty > 1
                    ? "deductions (course)"
                    : "deduction (course)"}
                </Text>
              </Col>
            </>
          ))}
        {pdfData &&
          pdfData.kiv &&
          pdfData.kiv.map((item: any, index: any) => (
            <>
              <Col span={12}>
                <Text>kiv</Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text>
                  {item.item_qty}{" "}
                  {item.item_qty > 1 ? "deductions (kiv)" : "deduction (kiv)"}
                </Text>
              </Col>
            </>
          ))}
        {pdfData &&
          pdfData.payment &&
          pdfData.payment.map((item: any, index: any) => (
            <>
              <Col span={12}>
                <Text>{item.payment_name}</Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text>{Number(item.payment_amount).toFixed(2)}</Text>
              </Col>
            </>
          ))}
      </Row>

      <Divider style={{ margin: "10px 0px 10px" }} />

      <Row>
        <Col span={12}>
          <Text>Subtotal:</Text>
        </Col>
        {pdfData && pdfData.sub_total && (
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>
              {currencySymbol.CURRENCY_SYMBOL}{" "}
              {parseFloat(pdfData.sub_total).toFixed(2)}
            </Text>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={12}>
          <Text>Taxes:</Text>
        </Col>
      </Row>
      <Row>
        {pdfData &&
          pdfData.tax &&
          pdfData.tax.map((item: any, index: any) => (
            <>
              <Col span={12} offset={1}>
                <Text>{item.tax_name}:</Text>
              </Col>
              <Col span={11} style={{ textAlign: "right" }}>
                <Text>
                  {currencySymbol.CURRENCY_SYMBOL}{" "}
                  {parseFloat(item.tax_amount).toFixed(2)}
                </Text>
              </Col>
            </>
          ))}
      </Row>

      <Row>
        <Col span={12}>
          <Text>Tips:</Text>
        </Col>
        {pdfData && pdfData.tip_value && (
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>
              {currencySymbol.CURRENCY_SYMBOL}{" "}
              {parseFloat(pdfData.tip_value).toFixed(2)}
            </Text>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={12}>
          <Text>Total:</Text>
        </Col>
        {pdfData && pdfData.final_total && (
          <Col span={12} style={{ textAlign: "right" }}>
            <Text strong>
              {currencySymbol.CURRENCY_SYMBOL}{" "}
              {parseFloat(pdfData.final_total).toFixed(2)}
            </Text>
          </Col>
        )}
      </Row>

      {/* Remaining Privileges */}
      {/* <Divider style={{ margin: "10px 0px 10px" }} /> */}

      {/* <Title
        style={{
          margin: "0px",
          lineHeight: "24px",
          fontSize: "12px",
          fontWeight: 700,
        }}
      >
        Remaining Privileges
      </Title>
      <Row>
        <Col span={12}>
          <Text>Loyalty points:</Text>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Text>10</Text>
        </Col>
      </Row> */}
      {/* <Row>
        <Col span={24}>
          <Text>Mayur Qa Package:</Text>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Text>service 102</Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Text>1 Remaining</Text>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Text>Sampoo</Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Text>1 Remaining</Text>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Text>prd-1</Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Text>1 Remaining</Text>
        </Col>
      </Row> */}

      {/* Footer Details */}
      <Divider style={{ margin: "10px 0px 10px" }} />
      <Row>
        <Col span={12}>
          <Text>Note: {pdfData?.note}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Text>Cashier: admin</Text>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Text>
            Store phone:{" "}
            {pdfData?.outlet_countrycode
              ? `+${pdfData?.outlet_countrycode} `
              : ""}{" "}
            {pdfData?.outlet_phone}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Text>Store address: {pdfData?.outlet_address}</Text>
        </Col>
      </Row>
      {/* <Row>
        <Col span={12}>
          <Text>Signature: -</Text>
        </Col>
      </Row> */}
    </Modal>
  );
};

export default PdfPreview;
