import React from 'react';
import { Modal, Table, Select, Input, Button, Row, Col } from 'antd';
import { useCurrency } from '../../../contexts/CurrencyContext';
// import { currencySymbol } from '../../../constants/currencySymbol';

const { Option } = Select;

interface ServiceExcPriceProps {
    visible: boolean;
    onCancel: () => void;
    onSave: () => void;
}

const ServiceExcPrice: React.FC<ServiceExcPriceProps> = ({ visible, onCancel, onSave }) => {
    const { currencySymbol } = useCurrency();
    // Sample data for table
    const data = [
        {
            key: '1',
            serviceCode: '241019001',
            serviceName: 'Traditional Facial',
            price: `${currencySymbol.CURRENCY_SYMBOL}299`,
        },
        // Add more sample data to test scrolling
        {
            key: '2',
            serviceCode: '241019002',
            serviceName: 'Massage Therapy',
            price: `${currencySymbol.CURRENCY_SYMBOL}150`,
        },
        {
            key: '3',
            serviceCode: '241019003',
            serviceName: 'Hair Treatment',
            price: `${currencySymbol.CURRENCY_SYMBOL}120`,
        },
        // Add as many rows as you like to test scrolling behavior
    ];

    const columns = [
        {
            title: 'Service Code',
            dataIndex: 'serviceCode',
            key: 'serviceCode',
        },
        {
            title: 'Service Name',
            dataIndex: 'serviceName',
            key: 'serviceName',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
    ];

    return (
        <Modal
            title="Services"
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={onSave}>
                    Save
                </Button>,
            ]}
            width={800}  // Adjust the width to match the layout
            bodyStyle={{ minHeight: '70%', maxHeight: '70%', overflow: 'auto' }} // Set modal body height and make it scrollable
        >
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Select placeholder="All categories" style={{ width: '100%' }}>
                        <Option value="category1">Category 1</Option>
                        <Option value="category2">Category 2</Option>
                    </Select>
                </Col>
                <Col span={16}>
                    <Input placeholder="Select by service name / code" />
                </Col>
            </Row>

            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowSelection={{
                    type: 'checkbox',
                }}
                style={{ marginTop: '16px' }}
                scroll={{ y: 240 }}  // Set the height of the table to make it scrollable
            />

            <div style={{ marginTop: '16px' }}>
                <p>Selected: 0 items</p>
            </div>
        </Modal>
    );
};

export default ServiceExcPrice;
