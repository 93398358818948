import { Alert, Button, Form, Input, Modal, Typography } from "antd";
import React, { useState } from "react";
// import { currencySymbol } from "../../../constants/currencySymbol";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Text, Title } = Typography;

interface HistoricalCollectedPaymentModalProps {
  message: string;
  visible: boolean;
  onClose: () => void;
}

const HistoricalCollectedPaymentModal: React.FC<
  HistoricalCollectedPaymentModalProps
> = ({ message, visible, onClose }) => {
  const { currencySymbol } = useCurrency();
  const [inputValue, setInputValue] = useState<string>("");

  const handleSave = () => {
    console.log("Saved Value:", inputValue);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title={
        <Title level={4} style={{ margin: 0 }}>
          {message}
        </Title>
      }
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ paddingBottom: "0" }}
    >
      {/* Informational Alert */}
      <Alert
        message="To record the total historical visit of this customer before using Daco."
        type="info"
        showIcon
        style={{
          display: "flex",
          color: "rgba(0,0,0,.3)",
          padding: "7px 12px 7px 8px",
          marginBottom: "16px",
          background: "rgba(46, 86, 242, .1)",
          border: "none",
        }}
      />

      <Form layout="vertical">
        {/* Input Field */}
        <Form.Item
          label={
            <Text style={{ fontSize: "14px" }}>
              Total Historical Collected Payment
            </Text>
          }
          required
        >
          <Input
            prefix={
              <span
                className="textSuffix"
                style={{ color: "rgba(46, 86, 242, .5)", fontWeight: 700 }}
              >
                {currencySymbol.CURRENCY_SYMBOL}
              </span>
            }
            type="number"
            min={0}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Please enter"
          />
        </Form.Item>
      </Form>

      {/* Save Button */}
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default HistoricalCollectedPaymentModal;
