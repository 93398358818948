import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Button, Card, Tabs, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Header from "../headerFooter/Header";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const layoutStyles: React.CSSProperties = {
  minHeight: "100vh",
  padding: "50px 16px 16px 16px",
};

const cardStyle: React.CSSProperties = {
  background: "linear-gradient(90deg, #4F8EFF, #7A00FF)",
  color: "#fff",
  borderRadius: "12px",
  padding: "0px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "10px",
};

const tabStyle: React.CSSProperties = {
  margin: "16px 0",
};

const tabLabelStyle: React.CSSProperties = {
  fontWeight: "bold",
};
const activeTabStyle: React.CSSProperties = {
  //   color: "orange",
  fontWeight: "bold",
};

interface MembershipList {
  bill_id: string;
  membership_name: string;
  status: string;
  valid_startdate: string;
  valid_enddate: string;
  remaining_visit?: number;
  remaining_prepaid?: number;
  product_discount: string;
  service_discount: string;
  privilege_discount: string;
}

const AppMembershipList: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("Active");
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const customerId = localStorage.getItem("customer_id");
  const outletId = localStorage.getItem("outlet_id");
  const [membershipListData, setMembershipListData] = useState<any[]>([]);
  const [tempMembershipListData, setTempMembershipListData] = useState<any[]>(
    []
  );
  const [statusCount, setStatusCount] = useState({
    active: 0,
    expired: 0,
  });

  useEffect(() => {
    // Scroll to the top of the page with smooth behavior on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
    getPackageListData();
  }, []);

  useEffect(() => {
    filterMembershipList();
    calculateStatusCounts();
  }, [membershipListData, activeTab]);

  // ------------ get Membership listing data -------------
  const getPackageListData = async () => {
    try {
      const url = `/merchantandoutletmob/mobile/allcustomer/${customerId}/${outletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setMembershipListData(data?.data?.membershipDetails);
      }
    } catch (error) {
      console.error("Error while getting package list data:", error);
    }
  };

  // --------- filter data ------------
  const filterMembershipList = () => {
    let filteredList = membershipListData;

    // Filter by valid status
    if (activeTab) {
      filteredList = filteredList.filter(
        (item) => item.status?.toLowerCase() === activeTab.toLowerCase()
      );
    }
    setTempMembershipListData(filteredList);
  };

  const calculateStatusCounts = () => {
    const counts = {
      active: 0,
      expired: 0,
    };

    // Filter vouchers based on the selected type
    let filteredList = membershipListData;

    // Count statuses in the filtered list
    filteredList.forEach((membership) => {
      const status = membership.status.toLowerCase();
      if (status === "active") counts.active++;
      else if (status === "expired") counts.expired++;
    });

    setStatusCount(counts);
  };
  const renderMembershipCard = (membership: MembershipList) => (
    <Card hoverable style={cardStyle} key={membership.bill_id}>
      <Row justify="space-between" align="middle">
        <Col>
          <Space direction="vertical">
            <Text strong style={{ fontSize: "16px", color: "#fff" }}>
              {membership.membership_name}
            </Text>
            <Text style={{ color: "#fff" }}>
              Valid until {membership.valid_enddate}
            </Text>
          </Space>
        </Col>
        <Col>
          <RightOutlined style={{ fontSize: "16px", color: "#fff" }} />
        </Col>
      </Row>
      <Row justify="space-between" style={{ marginTop: "16px" }}>
        <Col>
          {membership.remaining_visit && (
            <>
              <Text style={{ color: "#fff", display: "block" }}>
                Remaining visits:
              </Text>
              <Text style={{ color: "#fff", display: "block" }}>
                <strong>{membership.remaining_visit}</strong>
              </Text>
            </>
          )}
          {membership.remaining_prepaid && (
            <>
              <Text style={{ color: "#fff", display: "block" }}>
                Remaining prepaid value:
              </Text>
              <Text style={{ color: "#fff", display: "block" }}>
                <strong>{membership.remaining_prepaid}</strong>
              </Text>
            </>
          )}
        </Col>
        <Col>
          <Text style={{ color: "#fff", display: "block" }}>Discount:</Text>
          <Text style={{ color: "#fff", display: "block" }}>
            <strong>
              {membership.product_discount}% / {membership.service_discount}% /{" "}
              {membership.privilege_discount}%
            </strong>
          </Text>
        </Col>
      </Row>
    </Card>
  );

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
      {/* Header */}
      <Header
        title="Memberships"
        onBack={() => navigate(`/app/profile/${merchantId}`)}
        backgroundColor={"#fff"}
      />

      {/* Tabs */}
      <div style={layoutStyles}>
        <Tabs
          defaultActiveKey="Active"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          style={tabStyle}
        >
          <TabPane
            tab={`Active (${statusCount.active})`}
            key="Active"
          ></TabPane>
          <TabPane
            tab={`Expired (${statusCount.expired})`}
            key="Expired"
          ></TabPane>
        </Tabs>
        {tempMembershipListData && tempMembershipListData?.length > 0 ? (
          tempMembershipListData?.map((membership) => (
            <>
              <Card
                hoverable
                style={cardStyle}
                key={membership.bill_id}
                onClick={() =>
                  navigate(
                    `/app/membership/details/${merchantId}/${membership?.item_id}`
                  )
                }
              >
                <Row justify="space-between" align="middle">
                  <Col>
                    <Space direction="vertical">
                      <Text strong style={{ fontSize: "16px", color: "#fff" }}>
                        {membership.membership_name}
                      </Text>
                      {membership?.valid_enddate ? (
                        <Text style={{ color: "#fff" }}>
                          Valid until {membership.valid_enddate}
                        </Text>
                      ) : (
                        <Text style={{ color: "#fff" }}>
                          Valid until {membership.valid_count}{" "}
                          {membership?.valid_countperiod}
                        </Text>
                      )}
                    </Space>
                  </Col>
                  <Col>
                    <RightOutlined
                      style={{ fontSize: "16px", color: "#fff" }}
                    />
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "16px" }}>
                  <Col>
                    {membership?.item_details_type == "Discount" ? (
                      <>
                        <Text style={{ color: "#fff", display: "block" }}>
                          Remaining
                        </Text>
                        <Text style={{ color: "#fff", display: "block" }}>
                          <strong>{membership.remaining_discount}</strong>
                        </Text>
                      </>
                    ) : membership?.item_details_type == "Prepaid" ? (
                      <>
                        <Text style={{ color: "#fff", display: "block" }}>
                          Remaining
                        </Text>
                        <Text style={{ color: "#fff", display: "block" }}>
                          <strong>{membership.remaining_prepaid}</strong>
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text style={{ color: "#fff", display: "block" }}>
                          Remaining
                        </Text>
                        <Text style={{ color: "#fff", display: "block" }}>
                          <strong>{membership.remaining_visit}</strong>
                        </Text>
                      </>
                    )}
                  </Col>
                  {membership?.item_details_type == "Visit-based" ? (
                    ""
                  ) : (
                    <>
                      <Col>
                        <Text style={{ color: "#fff", display: "block" }}>
                          Discount
                        </Text>
                        <Text style={{ color: "#fff", display: "block" }}>
                          <strong>
                            {membership.product_discount}% /{" "}
                            {membership.service_discount}% /{" "}
                            {membership.privilege_discount}%
                          </strong>
                        </Text>
                      </Col>
                    </>
                  )}
                </Row>
              </Card>
            </>
          ))
        ) : (
          <>
            <Row
              justify="center"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col
                style={{
                  color: "#333",
                }}
              >
                No membership found
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default AppMembershipList;
