// src/App.tsx
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import { CurrencyProvider } from "./contexts/CurrencyContext";

const App: React.FC = () => (
  <CurrencyProvider>
    <Router>
      <AppRoutes />
    </Router>
  </CurrencyProvider>
);

export default App;
