import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, message } from "antd";
import { InputOTP } from "antd-input-otp";
import Header from "../headerFooter/Header";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../../services/Apiclient";

const { Text } = Typography;

const layoutStyles: React.CSSProperties = {
  minHeight: "100vh",
  backgroundColor: "#fff",
  padding: "100px 16px 16px 16px",
};

const AppOtpScreen: React.FC = () => {
  const { merchantId, data, type, navigat } = useParams();
  const navigate = useNavigate();
  const [otpArray, setOtpArray] = useState(["", "", "", "", "", ""]);
  const [resendTimeout, setResendTimeout] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);

  useEffect(() => {
    // Start countdown when component mounts or resend is triggered
    if (isResendDisabled) {
      const timer = setInterval(() => {
        setResendTimeout((prev) => {
          if (prev > 0) return prev - 1;
          clearInterval(timer);
          setIsResendDisabled(false);
          return 0;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup timer on unmount
    }
  }, [isResendDisabled]);

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);

  const handleResendOtp = async () => {
    try {
      setResendOtpLoading(true);
      const url = `/customerprofilemob/sendotp`;
      const response = await post(url, {
        email: data,
      });
      if (response.data?.success) {
        message.success(response?.data?.message);
      } else {
        message.error(response?.data?.message);
      }
      setResendOtpLoading(false);
    } catch (error) {
      console.error("Error while resend otp:", error);
      setResendOtpLoading(false);
    }
    setResendTimeout(60);
    setIsResendDisabled(true);
  };

  const handleOtpVerification = async (otp: any) => {
    try {
      const otpData = {
        email: data,
        otp: otp,
      };
      const response = await post("/customerprofilemob/verify-otp", otpData);
      if (response?.data.success) {
        if (navigat == "login") {
          navigate(`/app/signIn/${merchantId}`);
        } else {
          navigate(`/app/forgot-password/${merchantId}/${data}`);
        }
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error while verify otp", error);
    }
  };

  const handleOtpChange = (value: (string | null)[]) => {
    setOtpArray((prevOtpArray) => {
      const updatedOtpArray = prevOtpArray.map((prevValue, index) =>
        value[index] !== null && value[index] !== undefined
          ? value[index]!
          : prevValue
      );

      // Check if all inputs are filled
      if (updatedOtpArray.every((otp) => otp !== "")) {
        handleOtpVerification(updatedOtpArray.join("")); // Join OTP and call verification
      }

      return updatedOtpArray;
    });
  };

  return (
    <>
      <Header
        title=""
        onBack={() => window.history.back()}
        backgroundColor={"#fff"}
      />
      <div style={layoutStyles}>
        <Row>
          <Col>
            <Text strong>{type} Authentication</Text>
          </Col>
        </Row>
        <Row justify="space-between" style={{ marginTop: "10px" }}>
          <Col>
            <Text>{data}</Text>
          </Col>
          <Col>
            <Button
              type="link"
              disabled={isResendDisabled || resendOtpLoading}
              onClick={handleResendOtp}
            >
              {isResendDisabled ? `Resend(${resendTimeout})` : "Resend"}
            </Button>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col>
            <InputOTP
              length={6}
              autoFocus
              inputStyle={{
                height: "50px",
                margin: "0 5px",
                fontSize: "18px",
                textAlign: "center",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
              }}
              onChange={handleOtpChange}
              inputMode="numeric"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AppOtpScreen;
