import { CameraOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Tag,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { uploadImg } from "../../../../services/Apiclient";

const CamaraIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Camera.png`;
const { Text } = Typography;

interface EditGalleryModelProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (data: any) => void;
  staffId?: string | undefined;
  workTagData?: any[];
  selectedItem?: any;
}
const getTagStyle = (isSelected: boolean) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "34px",
  borderRadius: "105px",
  padding: "0 24px",
  fontSize: "13px",
  fontFamily: "PingFang SC-Medium, PingFang SC",
  fontWeight: 500,
  marginRight: "17px",
  marginBottom: "8px",
  background: isSelected ? "#2e56f2" : "#e6e9f5",
  color: isSelected ? "#fff" : "#a1aaca",
});

const EditGalleryModel: React.FC<EditGalleryModelProps> = ({
  visible,
  onCancel,
  onSave,
  staffId,
  workTagData,
  selectedItem,
}) => {
  const [formData, setFormData] = useState<any>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);

  useEffect(() => {
    if (selectedItem) {
      setFormData((pre: any) => ({
        ...pre,
        title: selectedItem.title,
        price: selectedItem.price,
        worktags: selectedItem?.worktags?.map((tag: any) => tag.id),
        staff_id: staffId,
        showcase: selectedItem.showcase.map((img: any) => ({
          id: img.id,
          name: img.imageUrl,
          url: img.url,
        })),
      }));
      setSelectedImages(
        selectedItem.showcase.map((img: any) => ({
          name: img.imageUrl,
          url: img.url,
        }))
      );
      //   setSelectedImages(selectedItem.showcase);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (workTagData) {
      const initialSelectedTags = workTagData.reduce(
        (acc: any, category: any) => {
          const matchingTag = selectedItem.worktags.find((worktag: any) =>
            category.tags.some((tag: any) => tag.tag_id === worktag.id)
          );
          if (matchingTag) {
            acc[category.id] = matchingTag.id;
          }
          return acc;
        },
        {}
      );
      setSelectedTags(initialSelectedTags);
    }
  }, [workTagData]);

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleTagSelection = (categoryId: any, tagId: string) => {
    setSelectedTags((prevSelectedTags) => {
      const updatedTags = {
        ...prevSelectedTags,
        [categoryId]: prevSelectedTags[categoryId] === tagId ? "" : tagId,
      };

      // Update formData with the selected tag IDs
      const worktags = Object.values(updatedTags).filter(Boolean);
      setFormData((prevData: any) => ({
        ...prevData,
        worktags,
      }));

      return updatedTags;
    });
  };

  // ----------- Image upload functionality ----------
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      try {
        const newImages = Array.from(files).slice(0, 5 - selectedImages.length); // Limit to 5
        // setSelectedImages((prev) => [...prev, ...newImages].slice(0, 5));
        const updatedSelectedImages = [
          ...selectedImages,
          ...newImages.map((file) => ({
            name: file.name,
            file,
          })),
        ].slice(0, 5);

        setSelectedImages(updatedSelectedImages);

        // Prepare FormData
        const formData = new FormData();
        newImages.forEach((file) => {
          formData.append("file", file);
        });
        const uploadImageUrl = "/staffgallery/uploadimage/showcase";
        const response = await uploadImg(uploadImageUrl, formData);
        if (response.data.success) {
          // Extract image names from the API response
          //   const imageObjects = response.data.data.map(
          //     (item: { imagename: string }) => ({
          //       name: item.imagename,
          //     })
          //   );
          //   setFormData((prevData: any) => ({
          //     ...prevData,
          //     showcase: [...prevData.showcase, ...imageObjects],
          //   }));
          const uploadedImages = response.data.data.map(
            (item: { imagename: string; baseuri: string }) => ({
              name: item.imagename,
              url: item.baseuri,
            })
          );

          // Update formData.showcase
          setFormData((prevData: any) => ({
            ...prevData,
            showcase: [
              ...prevData.showcase.map((img: any) => ({ name: img.name })),
              ...uploadedImages.map((img: any) => ({ name: img.name })),
            ],
          }));

          // Update selectedImages with uploaded URLs
          setSelectedImages((prev) => [
            ...prev.slice(0, prev.length - newImages.length),
            ...uploadedImages,
          ]);
        }
      } catch (error) {
        console.error("Error while updating image", error);
      }
    }
  };

  const handleRemoveImage = async (index: number) => {
    // Get the file name of the image to remove
    const fileToRemove = selectedImages[index];

    // Remove the file from `selectedImages`
    setSelectedImages((prev) => prev.filter((_, i) => i !== index));

    // Remove the corresponding image object from `formData.showcase`
    setFormData((prevData: any) => ({
      ...prevData,
      showcase: prevData.showcase.filter(
        (item: { name: string }) => item.name !== fileToRemove.name
      ),
    }));
  };

  const handleSave = () => {
    if (!formData?.title) {
      message.error("Please enter Title");
      return;
    }
    if (!formData?.price) {
      message.error("Please enter Pricing");
      return;
    }
    if (!formData?.showcase || formData?.showcase.length === 0) {
      message.error("Please select Showcase");
      return;
    }
    onSave(formData);
    setFormData(null);
    setSelectedTags([]);
    setSelectedImages([]);
  };

  const handleCancel = () => {
    onCancel();
    setFormData(null);
    setSelectedTags([]);
    setSelectedImages([]);
  };

  return (
    <Modal
      title="Add Works"
      open={visible}
      onCancel={handleCancel}
      footer={null}
      centered
      width={578}
      className="add-model"
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <Form
        layout="vertical"
        style={{ height: 508, display: "flex", flexDirection: "column" }}
      >
        {/* Scrollable content */}
        <div style={{ flex: 1, overflowY: "auto", padding: "0 24px" }}>
          <div style={{ display: "flex", gap: "24px" }}>
            <Form.Item
              label={<Text strong>Title</Text>}
              style={{ flex: 1 }}
              required
            >
              <Input
                name="title"
                placeholder="Please enter"
                value={formData?.title}
                onChange={handleFieldChange}
              />
            </Form.Item>
            <Form.Item
              label={<Text strong>Pricing</Text>}
              style={{ flex: 1 }}
              required
            >
              <Input
                name="price"
                placeholder="Please enter"
                value={formData?.price}
                onChange={handleFieldChange}
              />
            </Form.Item>
          </div>

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Work Tags
            </h3>
          </div>

          {/* Color Tags */}
          {workTagData && workTagData?.length > 0 ? (
            workTagData?.map((category) => (
              <>
                <div key={category.id}>
                  <Text>{category.name}</Text>
                  {category?.tags.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "12px",
                        marginTop: "10px",
                      }}
                    >
                      {category?.tags?.map((tag: any) => (
                        <Tag
                          key={tag?.id}
                          style={getTagStyle(
                            selectedTags[category.id] === tag.tag_id
                          )}
                          onClick={() =>
                            toggleTagSelection(category.id, tag.tag_id)
                          }
                        >
                          {tag.name}
                        </Tag>
                      ))}
                    </div>
                  )}
                </div>
              </>
            ))
          ) : (
            <Text>No tags found</Text>
          )}

          {/* Image upload */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Showcase
            </h3>
            <Text style={{ color: "#999999" }}>
              Supported formats: jpg, png, mp4, avi, flv, mov <br />
              Max size: 30MB Max upload: 4
            </Text>
            <Form.Item style={{ marginTop: "10px" }}>
              <div className="image-upload-container">
                <div className="upload-grid">
                  {/* Display uploaded and prefilled images */}
                  {selectedImages &&
                    selectedImages.length > 0 &&
                    selectedImages.map((file, index) => (
                      <div key={index} className="image-preview">
                        {/* <img src={URL.createObjectURL(file)} alt={file.name} /> */}
                        <img
                          src={file.url || URL.createObjectURL(file.file)}
                          alt={file.name}
                        />
                        <CloseOutlined
                          className="remove-icon"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </div>
                    ))}
                  {/* Show upload box only if less than 5 images */}
                  {selectedImages.length < 4 && (
                    <div
                      className="upload-box"
                      onClick={() =>
                        document.getElementById("fileInput")?.click()
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <CameraOutlined className="upload-icon" />
                    </div>
                  )}
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    multiple
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </Form.Item>
          </div>
        </div>

        {/* Fixed Save button */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 24px",
            borderTop: "1px solid #D9D9D9",
          }}
        >
          <Button
            type="primary"
            onClick={handleSave}
            style={{
              background: "#2e56f2",
              color: "#fff",
              padding: "5px 12px",
              minWidth: "72px",
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditGalleryModel;
