import { Col, Image, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
import Header from "../headerFooter/Header";
import Footer from "../headerFooter/footer";
const { Title, Text, Paragraph } = Typography;

const layoutStyles: React.CSSProperties = {
  minHeight: "100vh",
  backgroundColor: "#fff",
  padding: "50px 0px",
};

const NewsDetails: React.FC = () => {
  const { id, merchantId } = useParams();
  const [noticeboardDetails, setNoticeboardDetails] = useState<any>();

  useEffect(() => {
    // Scroll to the top of the page with smooth behavior on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
    getNoticeboardDetails();
  }, []);

  // ----------- get noticeboard details ------------
  const getNoticeboardDetails = async () => {
    try {
      const url = `/noticeboardmob/mobile/${id}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setNoticeboardDetails(data?.data);
      }
    } catch (error) {
      console.error("Error while getting notice board details", error);
    }
  };

  return (
    <>
      {/* Header */}
      <Header
        title="Back"
        onBack={() => window.history.back()}
        backgroundColor={"#fff"}
      />
      <div style={layoutStyles}>
        {noticeboardDetails && (
          <>
            <Row justify="center" style={{ padding: "16px" }}>
              <Col span={24}>
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: "100%" }}
                >
                  {/* Title */}
                  <Title level={4}>
                    {noticeboardDetails?.noticeboardtitle}
                  </Title>
                  {/* Image */}
                  <Image
                    // src="https://via.placeholder.com/400x200"
                    src={noticeboardDetails?.noticeboardimage}
                    alt="Wise logo"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  />
                  {/* Text Content */}
                  {/* <Paragraph>{noticeboardDetails?.description}</Paragraph> */}
                  <Paragraph>
                    {noticeboardDetails?.description
                      .split("\n\n")
                      .map((para: string, index: number) => (
                        <p key={index} style={{ marginBottom: "16px" }}>
                          {para}
                        </p>
                      ))}
                  </Paragraph>

                  {/* <Paragraph>
                    So as of now, there’ll be a few things that look different
                    about Wise. Including:
                  </Paragraph>
                  <List
                    dataSource={[
                      "A refreshed Wise logo",
                      "A colour change, with the old Wise navy blue replaced with green",
                      "Some new graphics and fonts to reflect our new style",
                    ]}
                    renderItem={(item) => (
                      <List.Item style={{ padding: 0, border: "none" }}>
                        <Text>- {item}</Text>
                      </List.Item>
                    )}
                  />
                  <Paragraph>
                    Scammers are very tactical, and they might use our new look
                    to convince you to treat your account differently, or to
                    share information you wouldn’t normally share. We want to
                    help you stay safe.
                  </Paragraph> */}
                </Space>
              </Col>
            </Row>
          </>
        )}
        {/* Bottom Navigation */}
        <Footer />
      </div>
    </>
  );
};
export default NewsDetails;
