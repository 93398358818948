import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
  Table,
} from "antd";
import { Option } from "antd/es/mentions";
import update from "immutability-helper";
import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import { deleteData, get, post, put } from "../../services/Apiclient";

interface ShippingMethodProps {
  selectedOutlet: string;
}

const ShippingMethod: React.FC<ShippingMethodProps> = ({ selectedOutlet }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [outletData, setOutletData] = useState<any[]>([]);
  const outletId = localStorage.getItem("outlet_id");
  const [selectedOutlets, setSelectedOutlets] = useState<string[]>([]);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
    form.resetFields(); // Clear form fields
    setSelectedOutlets([]);
    setIsDuplicateModalVisible(false);
  };

  const handleOk = async () => {
    try {
      // Validate form fields and get values
      const values = await form.validateFields();

      if (editingRecord) {
        // Update existing record
        await put(`/shippingmethod/${editingRecord.key}`, {
          ...values,
          outlet_id: selectedOutlet,
        });
        message.success("Shipping method updated successfully!");
      } else {
        // Add new record
        await post("/shippingmethod/addshipping", {
          ...values,
          outlet_id: selectedOutlet,
        });
        message.success("Shipping method added successfully!");
      }

      // Refresh the data
      fetchShippingMethod();

      // Close modal and reset form
      setIsModalVisible(false);
      setEditingRecord(null);
      form.resetFields();
    } catch (error) {
      message.error("Please complete the required fields.");
      console.error(error);
    }
  };

  const handleDeleteClick = (record: any) => {
    setSelectedData(record);
    setDeleteModalVisible(true);
  };

  const handleEdit = async (record: any) => {
    try {
      // Fetch the record by ID
      const response = await get(`/shippingmethod/${record.key}`);
      if (response.data.success) {
        const data = response.data.data;

        // Set the form values
        form.setFieldsValue({
          shippingmethodtype: data.shippingmethodtype,
          shippingprice: data.shippingprice,
        });

        setEditingRecord({
          key: data.id,
        });

        setIsModalVisible(true);
      } else {
        message.error("Failed to load Shipping method details.");
      }
    } catch (error) {
      message.error("Failed to load Shipping method details.");
      console.error(error);
    }
  };

  const handleConfirm = async () => {
    try {
      if (selectedData) {
        // Call delete API
        await deleteData(`/shippingmethod/remove/${selectedData.key}`);
        message.success("Shipping method deleted successfully!");
        fetchShippingMethod();
      }
      setDeleteModalVisible(false);
      setSelectedData(null);
    } catch (error) {
      message.error("Failed to delete Shipping method.");
      console.error(error);
    }
  };

  const handleToggle = async (key: React.Key, checked: boolean) => {
    try {
      let url = `/shippingmethod/${key}`;

      let body = {
        status: checked,
      };
      console.log("update body");

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          fetchShippingMethod();
        } else {
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (errors) {
      message.error("error occur in api call");
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false); // Close the modal without deleting
  };

  const columns = [
    {
      title: "",
      dataIndex: "index",
      key: "order",
      width: 20,
      render: () => <span style={{ cursor: "move" }}>☰</span>, // Drag icon for order
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: 60,
      render: (text: any, record: any, index: number) => (
        <span>
          <span>{index + 1}</span>
        </span>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "shippingmethodtype",
      key: "shippingmethodtype",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 60,
      render: (status: any, record: any) => (
        <Switch
          checked={status}
          onChange={(checked) => handleToggle(record.key, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text: any, record: any) => (
        <>
          {" "}
          {record.allowedit ? (
            <a
              style={{
                color: "#2e56f2",
                textDecoration: "underline",
                fontWeight: 700,
                marginRight: "16px",
                textUnderlineOffset: "4px",
              }}
              onClick={() => handleEdit(record)}
            >
              Edit
            </a>
          ) : (
            <></>
          )}
          {record.allowdelete ? (
            <a
              style={{
                color: "rgb(245, 63, 63)",
                fontWeight: 700,
                textDecoration: "underline",
                textUnderlineOffset: "4px",
              }}
              onClick={() => handleDeleteClick(record)}
            >
              Delete
            </a>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const fetchShippingMethod = async () => {
    try {
      const response = await get(`/shippingmethod/list/${selectedOutlet}`);
      if (response.data.success) {
        const data = response.data.data.map((item: any, index: number) => ({
          key: item.id,
          order: (index + 1).toString(),
          code: item.code,
          shippingmethodtype: item.shippingmethodtype,
          status: item.status,
          allowdelete: item.allowdelete,
          allowedit: item.allowedit,
        }));
        setDataSource(data);
      } else {
        message.error("Error while fetch data");
      }
      console.log("list");
    } catch (error) {
      message.error("Failed to load Shipping method.");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchShippingMethod();
  }, [selectedOutlet]);

  useEffect(() => {
    fetchShippingMethod();
  }, []);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedRow = dataSource[dragIndex];
    const newDataSource = update(dataSource, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedRow],
      ],
    });
    setDataSource(newDataSource);
    updateSequence(newDataSource);
  };

  // Update sequence API call
  const updateSequence = async (newDataSource: any[]) => {
    const updatedSequence = newDataSource.map((item, index) => ({
      id: item.key,
      sequence: index + 1,
    }));
    try {
      await put("/shippingmethod/sequenceupdate", {
        shipping: updatedSequence,
      });
      console.log("update");
    } catch {
      message.error("Failed to update Shipping method order.");
    }
  };

  const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }: any) => {
    const ref = React.useRef<HTMLTableRowElement>(null);
    const [, drop] = useDrop({
      accept: "row",
      hover(item: any, monitor) {
        if (!ref.current) return;
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) return;

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        moveRow(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "row",
      item: { index },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    drag(drop(ref));

    return (
      <tr
        ref={ref}
        style={{ ...style, opacity: isDragging ? 0.5 : 1 }}
        {...restProps}
      />
    );
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getOutletDropdown();
  }, []);

  const showDuplicateModal = () => {
    if (outletData.length === 0) {
      getOutletDropdown().then(() => {
        setIsDuplicateModalVisible(true);
      });
    } else {
      setIsDuplicateModalVisible(true);
    }
  };

  // Handle Duplicate action
  const handleDuplicate = async () => {
    if (selectedOutlets.length === 0) {
      message.error("Please select an outlet.");
      return;
    }

    try {
      const requestData = {
        target_outlet_id: selectedOutlets[0],
      };

      const response = await post(
        `/shippingmethod/duplicate/${selectedOutlet}`,
        requestData
      );

      if (response.data.success) {
        message.success("Shipping method duplicated successfully!");
        handleCancel();
      } else {
        message.error("Failed to duplicate shipping method.");
      }
    } catch (error) {
      message.error("An error occurred while duplicating the shipping method.");
      console.error(error);
    }
  };

  // Handle selecting the outlets
  const handleOutletChange = (value: string[]) => {
    setSelectedOutlets(value);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <h3 style={{ marginBottom: 0 }}>Shipping Method</h3>
        <div style={{ display: "flex", gap: "10px" }}>
          {localStorage.getItem("userRole") == "Merchant" && (
            <Button
              type="primary"
              icon={<CopyOutlined />}
              onClick={showDuplicateModal}
            >
              Duplicate
            </Button>
          )}
          <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
            Add
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        components={{
          body: {
            row: DraggableBodyRow,
          },
        }}
        scroll={{ y: "calc(100vh - 230px)" }}
        rowClassName={() => "custom-row"}
        onRow={(record, index) => ({
          index,
          className: "",
          style: {},
          moveRow,
        })}
      />
      <Modal
        title={editingRecord ? "Edit Shipping Method" : "Add Shipping Method"}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Shipping method"
            name="shippingmethodtype"
            rules={[
              { required: true, message: "Please enter a shipping method" },
            ]}
          >
            <Input placeholder="Please enter" allowClear />
          </Form.Item>
          <Form.Item
            label="Price"
            name="shippingprice"
            rules={[{ required: true, message: "Please enter a price" }]}
          >
            <Input
              placeholder="0"
              prefix="RM"
              type="number"
              min={0}
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Select Outlet to Duplicate"
        visible={isDuplicateModalVisible}
        onOk={handleDuplicate}
        onCancel={handleCancel}
        okText="Copy"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Select Outlet" name="outlet" required>
            <Select
              value={selectedOutlets[0]}
              onChange={(value) => handleOutletChange([value])}
              placeholder="Select an outlet to duplicate"
              style={{ width: "100%" }}
            >
              {outletData.map((outlet: any) => (
                <Option key={outlet.id} value={outlet.id}>
                  {outlet.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <ConfirmDeleteModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirm}
        message="Are you sure to delete?"
      />
    </DndProvider>
  );
};

export default ShippingMethod;
