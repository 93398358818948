// import React, { useState } from "react";
// import { Typography, Row, Col, Tabs, Card, Table } from "antd";
// import Header from "../headerFooter/Header";

// const { Title, Text } = Typography;
// const { TabPane } = Tabs;

// const layoutStyles: React.CSSProperties = {
//   minHeight: "100vh",
//   backgroundColor: "#fff",
//   paddingTop: "50px",
// };

// const cardStyle: React.CSSProperties = {
//   borderRadius: "12px",
//   boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
//   marginBottom: "16px",
// };

// const usageRowStyle: React.CSSProperties = {
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   //   backgroundColor: "#FFA500",
//   borderRadius: "8px",
//   //   padding: "8px 16px",
//   color: "#fff",
//   marginBottom: "18px",
// };

// const usageDetailStyle: React.CSSProperties = {
//   //   padding: "8px 16px",
// };

// const noDataStyle: React.CSSProperties = {
//   textAlign: "center",
//   marginTop: "16px",
//   color: "#888",
// };

// const AppMembershipDetail: React.FC = () => {
//   const [activeTab, setActiveTab] = useState<string>("details");

//   const AppPackageDetail = [
//     {
//       ref: "241015001",
//       timestamp: "15/10/2024 05:06pm",
//       item: "Cleanser 300ml",
//     },
//   ];

//   return (
//     <>
//       <Header
//         title="Packages"
//         onBack={() => window.history.back()}
//         backgroundColor={"#fff"}
//       />
//       <div style={layoutStyles}>
//         {/* Tabs */}
//         <Tabs
//           centered
//           defaultActiveKey="details"
//           activeKey={activeTab}
//           onChange={(key) => setActiveTab(key)}
//           style={{ padding: "16px" }}
//         >
//           {/* Details Tab */}
//           <TabPane tab="Details" key="details">
//             <Card style={cardStyle}>
//               <Row justify="space-between" style={{ marginBottom: "16px" }}>
//                 <Col>
//                   <Title level={5} style={{ margin: 0 }}>
//                     Combo 1
//                   </Title>
//                   <Text type="secondary">PKG-202401020001</Text>
//                 </Col>
//               </Row>
//               <div style={{ marginBottom: "16px" }}>
//                 <Row justify="space-between">
//                   <Text>Price</Text>
//                   <Text>RM2449</Text>
//                 </Row>
//                 <Row justify="space-between">
//                   <Text>Validity</Text>
//                   <Text>12/10/2025</Text>
//                 </Row>
//                 <Row justify="space-between">
//                   <Text>Applicable to</Text>
//                 </Row>
//                 <Row
//                   justify="space-between"
//                   style={{
//                     padding: "10px",
//                     backgroundColor: "lightgray",
//                     marginTop: "5px",
//                     borderRadius: "6px",
//                   }}
//                 >
//                   <Text>Store</Text>
//                   <Text>All stores</Text>
//                 </Row>
//               </div>
//             </Card>

//             {/* Applicable Items Table */}
//             <Card style={cardStyle}>
//               <Row justify="space-between" style={{ marginBottom: "16px" }}>
//                 <Col>
//                   <Title level={5} style={{ margin: 0 }}>
//                     Applicable to
//                   </Title>
//                 </Col>
//               </Row>
//               <div style={{ marginBottom: "16px" }}>
//                 <Row
//                   gutter={24}
//                   justify="space-between"
//                   style={{
//                     borderBottom: "1px solid lightgray",
//                     padding: "6px",
//                   }}
//                 >
//                   <Col span={6}>Items</Col>
//                   <Col span={6}>Qty. included</Col>
//                   <Col span={6}>Qty. used</Col>
//                   <Col span={6}>Remaining</Col>
//                 </Row>
//                 <Row
//                   gutter={24}
//                   justify="space-between"
//                   style={{
//                     padding: "6px",
//                   }}
//                 >
//                   <Col span={6}>Cleanser 300ml</Col>
//                   <Col span={6}>1</Col>
//                   <Col span={6}>2</Col>
//                   <Col span={6}>0</Col>
//                 </Row>
//                 <Row
//                   gutter={24}
//                   justify="space-between"
//                   style={{
//                     padding: "6px",
//                   }}
//                 >
//                   <Col span={6}>Moisturiser 300ml</Col>
//                   <Col span={6}>1</Col>
//                   <Col span={6}>2</Col>
//                   <Col span={6}>0</Col>
//                 </Row>
//               </div>
//             </Card>
//           </TabPane>

//           {/* Usage History Tab */}
//           <TabPane tab="Usage history" key="usage-history">
//             {AppPackageDetail.length > 0 ? (
//               AppPackageDetail.map((usage, index) => (
//                 <Card key={index} style={cardStyle}>
//                   <div style={usageRowStyle}>
//                     <Text>Ref#: {usage.ref}</Text>
//                     <Text>{usage.timestamp}</Text>
//                   </div>
//                   <div style={usageDetailStyle}>
//                     <Text>{usage.item}</Text>
//                   </div>
//                 </Card>
//               ))
//             ) : (
//               <Text style={noDataStyle}>No more data</Text>
//             )}
//           </TabPane>
//         </Tabs>
//       </div>
//     </>
//   );
// };

// export default AppMembershipDetail;
import React, { useEffect, useState } from "react";
import { Typography, Card, Tabs, Row, Col, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
import Header from "../headerFooter/Header";

const { Title, Text } = Typography;

const headerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "16px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #f0f0f0",
};

const backButtonStyle: React.CSSProperties = {
  background: "none",
  border: "none",
  fontSize: "16px",
  cursor: "pointer",
  marginRight: "8px",
};

const cardStyle: React.CSSProperties = {
  borderRadius: "12px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
};

const noDataStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "16px",
  color: "#888",
};

const AppMembershipDetail: React.FC = () => {
  const { merchantId, id } = useParams();
  const navigate = useNavigate();
  const outletId = localStorage.getItem("outlet_id");
  const customerId = localStorage.getItem("customer_id");
  const [activeTab, setActiveTab] = useState<string>("details");
  const [membershipDetails, setMembershipDetails] = useState<any>();
  const [membershipHistory, setMembershipHistory] = useState<any[]>([]);

  useEffect(() => {
    if (activeTab === "details") {
      getMembershipDetails();
    } else if (activeTab === "usage-history") {
      // getMembershipHistory();
      setMembershipHistory([]);
    }
  }, [activeTab]);

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);

  // ---------- get package detail ----------
  const getMembershipDetails = async () => {
    try {
      const url = `/merchantandoutletmob/mobile/membershipdetails/${id}/${customerId}/${outletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setMembershipDetails(data.data);
      }
    } catch (error) {
      console.error("Error while getting package details:", error);
    }
  };

  const getMembershipHistory = async () => {
    try {
      const url = `/merchantandoutletmob/mobile/packagehistory/${id}/${customerId}/${outletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setMembershipHistory(data.data);
      }
    } catch (error) {
      console.error("Error while getting package items:", error);
    }
  };

  return (
    <>
      <Header
        title="Membership"
        onBack={() => window.history.back()}
        backgroundColor={"#fff"}
      />
      <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        {/* Header */}
        <div style={headerStyle}>
          <Button style={backButtonStyle} icon={<LeftOutlined />} />
          <Title level={5} style={{ margin: 0 }}>
            Memberships
          </Title>
        </div>

        {/* Tabs */}
        <div style={{ padding: "16px" }}>
          <Tabs
            defaultActiveKey="details"
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          >
            {/* Details Tab */}
            <Tabs.TabPane tab="Details" key="details">
              {membershipDetails && (
                <>
                  <Card
                    style={cardStyle}
                    title={
                      <Row
                        justify="space-between"
                        style={{
                          padding: "10px 0px",
                        }}
                      >
                        <Col>
                          <Title
                            level={5}
                            style={{ margin: 0, fontWeight: 700 }}
                          >
                            {membershipDetails?.membership_name}
                          </Title>
                          <Text type="secondary">
                            {membershipDetails?.membership_code}
                          </Text>
                        </Col>
                      </Row>
                    }
                  >
                    <div style={{ marginBottom: "16px" }}>
                      <Row justify="space-between">
                        <Text>Price</Text>
                        <Text style={{ fontWeight: 700 }}>
                          {membershipDetails?.selling_price}
                        </Text>
                      </Row>
                      <Row justify="space-between" style={{ marginTop: "4px" }}>
                        <Text>Usage remaining</Text>
                        {membershipDetails?.item_details_type == "Discount" ? (
                          <>
                            <Text style={{ fontWeight: 700 }}>
                              {membershipDetails?.remaining_discount}
                            </Text>
                          </>
                        ) : membershipDetails?.item_details_type ==
                          "Prepaid" ? (
                          <>
                            <Text style={{ fontWeight: 700 }}>
                              {membershipDetails?.remaining_prepaid}
                            </Text>
                          </>
                        ) : (
                          <>
                            <Text style={{ fontWeight: 700 }}>
                              {membershipDetails?.remaining_visit}
                            </Text>
                          </>
                        )}
                      </Row>
                      {membershipDetails?.item_details_type !==
                        "Visit-based" && (
                        <>
                          <Row
                            justify="space-between"
                            style={{ marginTop: "4px" }}
                          >
                            <Text>Discount</Text>
                            <Text style={{ fontWeight: 700 }}>
                              {membershipDetails.product_discount}% /
                              {membershipDetails.service_discount}% /
                              {membershipDetails.privilege_discount}%
                            </Text>
                          </Row>
                          <Row
                            justify="space-between"
                            style={{ marginTop: "4px" }}
                          >
                            <Text></Text>
                            <Text
                              style={{
                                color: "#999",
                                fontSize: "12px",
                              }}
                            >
                              (Product / Service / Privilege discount)
                            </Text>
                          </Row>
                        </>
                      )}

                      <Row justify="space-between" style={{ marginTop: "4px" }}>
                        <Text>Validity</Text>
                        {membershipDetails?.valid_count ? (
                          <Text style={{ fontWeight: 700 }}>
                            {membershipDetails?.valid_count}years
                          </Text>
                        ) : (
                          <Text style={{ fontWeight: 700 }}>
                            {membershipDetails?.valid_enddate}
                          </Text>
                        )}
                      </Row>
                      <Row justify="space-between" style={{ marginTop: "4px" }}>
                        <Text>Applicable to</Text>
                      </Row>
                      {membershipDetails?.allproduct_applicable ? (
                        <Row
                          justify="space-between"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "lightgray",
                            marginTop: "5px",
                            borderRadius: "6px",
                          }}
                        >
                          <Text>Products</Text>
                          <Text>All products</Text>
                        </Row>
                      ) : membershipDetails?.product_item_details &&
                        membershipDetails?.product_item_details?.length > 0 ? (
                        <>
                          <Row
                            justify="space-between"
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "lightgray",
                              marginTop: "5px",
                              borderRadius: "6px",
                            }}
                          >
                            <Col>
                              <Text>Product</Text>
                            </Col>
                            <Col>
                              <Text>By items</Text>
                              {membershipDetails?.product_item_details.map(
                                (item: any) => (
                                  <>
                                    <Row>
                                      <Col>{item.name}</Col>
                                    </Row>
                                  </>
                                )
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : membershipDetails?.product_category_details &&
                        membershipDetails?.product_category_details?.length >
                          0 ? (
                        <>
                          <Row
                            justify="space-between"
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "lightgray",
                              marginTop: "5px",
                              borderRadius: "6px",
                            }}
                          >
                            <Col>
                              <Text>Product</Text>
                            </Col>
                            <Col>
                              <Text>By category</Text>
                              {membershipDetails?.product_category_details.map(
                                (item: any) => (
                                  <>
                                    <Row>
                                      <Col>{item.name}</Col>
                                    </Row>
                                  </>
                                )
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                      {membershipDetails?.allservice_applicable ? (
                        <Row
                          justify="space-between"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "lightgray",
                            marginTop: "5px",
                            borderRadius: "6px",
                          }}
                        >
                          <Text>Service</Text>
                          <Text>All services</Text>
                        </Row>
                      ) : membershipDetails?.service_item_details &&
                        membershipDetails?.service_item_details?.length > 0 ? (
                        <>
                          <Row
                            justify="space-between"
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "lightgray",
                              marginTop: "5px",
                              borderRadius: "6px",
                            }}
                          >
                            <Col>
                              <Text>Service</Text>
                            </Col>
                            <Col>
                              <Text>By items</Text>
                              {membershipDetails?.service_item_details.map(
                                (item: any) => (
                                  <>
                                    <Row>
                                      <Col>{item.name}</Col>
                                    </Row>
                                  </>
                                )
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : membershipDetails?.service_category_details &&
                        membershipDetails?.service_category_details?.length >
                          0 ? (
                        <>
                          <Row
                            justify="space-between"
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "lightgray",
                              marginTop: "5px",
                              borderRadius: "6px",
                            }}
                          >
                            <Col>
                              <Text>Service</Text>
                            </Col>
                            <Col>
                              <Text>By category</Text>
                              {membershipDetails?.service_category_details.map(
                                (item: any) => (
                                  <>
                                    <Row>
                                      <Col>{item.name}</Col>
                                    </Row>
                                  </>
                                )
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                      <Row
                        justify="space-between"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "lightgray",
                          marginTop: "5px",
                          borderRadius: "6px",
                        }}
                      >
                        <Text>Store</Text>
                        <Text>All stores</Text>
                      </Row>
                    </div>
                  </Card>
                </>
              )}
            </Tabs.TabPane>

            {/* Usage History Tab */}
            <Tabs.TabPane tab="Usage history" key="usage-history">
              {membershipHistory.length > 0 ? (
                membershipHistory.map((usage, index) => {
                  const formattedDate = new Intl.DateTimeFormat("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }).format(new Date(usage.created_at));
                  return (
                    <Card
                      className="mobile-package-usage-history-card"
                      key={index}
                      style={cardStyle}
                      title={
                        <Row justify="space-between">
                          <Col style={{ color: "#fff" }}>
                            Ref#: {usage.ref_no}
                          </Col>
                          <Col style={{ color: "#fff" }}>{formattedDate}</Col>
                        </Row>
                      }
                      styles={{
                        header: { backgroundColor: "orange", border: 0 },
                      }}
                    >
                      <div>
                        <Text>{usage.item_name}</Text>
                      </div>
                    </Card>
                  );
                })
              ) : (
                <Text style={noDataStyle}>No more data</Text>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default AppMembershipDetail;
