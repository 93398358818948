import React, { useEffect, useState } from "react";
import { Empty, Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { get } from "../../../services/Apiclient";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const membershipbgdPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Store.png`;

const OnlineSalesSection: React.FC = () => {
  const { id } = useParams();
  const [tempMembershipList, setTempMembershipList] = useState<any[]>([]);

  useEffect(() => {
    fetchKIVItem();
  }, []);

  const fetchKIVItem = async () => {
    const outletId = localStorage.getItem("outlet_id");
    try {
      const url = `/billing/onlinesalesitems/${outletId}?customerid=${id}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        // console.log("response....", data.data);
        setTempMembershipList(data.data);
      }
    } catch (error) {
      console.error("Error in fetching membership list", error);
    }
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#f5f7fa",
          borderRadius: "8px",
        }}
      >
        <div>
          {tempMembershipList &&
            tempMembershipList.length > 0 &&
            tempMembershipList.map((onlinesalesitem: any, index: any) => (
              <>
                {onlinesalesitem.remaining_balance > 0 && (
                  <div
                    key={index}
                    style={{
                      color: "rgba(93, 74, 140, 0.8)",
                      position: "relative",
                      width: " 100%",
                      cursor: "pointer",
                      // height: "110px",
                      marginBottom: "16px",
                      backgroundImage: `url(${membershipbgdPrepaid})`,
                      backgroundSize: "100% 100%",
                      transition: "all .2s",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <Row gutter={24} style={{ padding: "16px 24px 0 16px" }}>
                      <Col span={8}>
                        <span>Ref#:{onlinesalesitem?.ref_no}</span>
                      </Col>
                      <Col span={6}>
                        <span>Products</span>
                      </Col>
                      <Col span={2}>
                        <span>Qty</span>
                      </Col>
                      <Col span={2}>
                        <span>KIV</span>
                      </Col>
                      <Col span={2}>
                        <span>Taken</span>
                      </Col>
                    </Row>
                    {onlinesalesitem.items &&
                      onlinesalesitem.items.length > 0 &&
                      onlinesalesitem.items.map(
                        (productitem: any, index: any) => (
                          <Row
                            gutter={24}
                            style={{ padding: "5px 24px 0 16px" }}
                          >
                            <Col span={8}></Col>
                            <Col span={6}>
                              <span>{productitem.name}</span>
                            </Col>
                            <Col span={2}>
                              <span>{productitem.productqty}</span>
                            </Col>
                            <Col span={2}>
                              <span>{productitem.storeqty}</span>
                            </Col>
                            <Col span={2}>
                              <span>{productitem.takenqty}</span>
                            </Col>
                          </Row>
                        )
                      )}
                    <Row
                      gutter={24}
                      style={{
                        padding: "16px 24px 0 16px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <span>
                        Total : RM{" "}
                        {parseFloat(onlinesalesitem.remainamt).toFixed(2)}
                      </span>
                    </Row>
                  </div>
                )}
              </>
            ))}
          {tempMembershipList && tempMembershipList.length == 0 && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={<span>No data available</span>}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OnlineSalesSection;
