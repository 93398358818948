import {
  EnvironmentOutlined,
  GlobalOutlined,
  LeftCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Carousel, Col, Layout, Row, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import Footer from "../headerFooter/footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { get } from "../../services/Apiclient";
import { decryptGoogleApiKey } from "../../constants/encryptionUtils";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;

interface OutletDetail {
  id: string;
  storename: string;
  address: string;
  countrycode: string;
  phone_number: string;
  email: string;
  website: string;
  store_introduction: string;
  storeintroduction: string;
  outletDaysInfo: { dayname: string; starttime: string; endtime: string }[];
  storelogo: string;
  outletshowcase: { id: string; imageUrl: string; name: string }[];
  storelogourl: string;
}

const OutletDetails: React.FC = () => {
  const [outletDetails, setOutletDetails] = useState<OutletDetail | null>(null);
  const { id, merchantId } = useParams();
  const navigate = useNavigate();
  const [googleApiKey, setGoogleApiKey] = useState<string | null>(null);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 0,
    lng: 0,
  });

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);


  useEffect(() => {
      if (outletDetails) {
        // Update the favicon
        const faviconLink = document.querySelector("link[rel='icon']") as HTMLLinkElement;
  
        // Use the timestamp trick to bypass caching
        const updatedFaviconUrl = `${outletDetails.storelogourl}?t=${new Date().getTime()}`;
  
        if (faviconLink) {
          faviconLink.href = updatedFaviconUrl;
        } else {
          const newFavicon = document.createElement("link");
          newFavicon.rel = "icon";
          newFavicon.href = updatedFaviconUrl;
          document.head.appendChild(newFavicon);
        }

        const appfaviconLink = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement;
  
        // Use the timestamp trick to bypass caching
        const updatedappFaviconUrl = `${outletDetails.storelogourl}?t=${new Date().getTime()}`;
  
        if (appfaviconLink) {
          appfaviconLink.href = updatedappFaviconUrl;
        } else {
          const newFavicon = document.createElement("link");
          newFavicon.rel = "icon";
          newFavicon.href = updatedappFaviconUrl;
          document.head.appendChild(newFavicon);
        }
  
        let descriptionMeta = document.querySelector('meta[name="description"]') as HTMLMetaElement;
        if (descriptionMeta) {
          descriptionMeta.content = outletDetails.store_introduction || "Salon Store";
        } else {
          descriptionMeta = document.createElement("meta");
          descriptionMeta.name = "description";
          descriptionMeta.content = outletDetails.store_introduction || "Salon Store";
          document.head.appendChild(descriptionMeta);
        }
  
        // Update Keywords
        let keywordsMeta = document.querySelector('meta[name="keywords"]') as HTMLMetaElement;
        if (keywordsMeta) {
          keywordsMeta.content = outletDetails.storename || "Salon Store";
        } else {
          keywordsMeta = document.createElement("meta");
          keywordsMeta.name = "keywords";
          keywordsMeta.content = outletDetails.storename || "Salon Store";
          document.head.appendChild(keywordsMeta);
        }
  
        // Update Open Graph Tags
        let ogTitleMeta = document.querySelector('meta[property="og:title"]') as HTMLMetaElement;
        if (ogTitleMeta) {
          ogTitleMeta.content = outletDetails.storename || "Salon Store";
        } else {
          ogTitleMeta = document.createElement("meta");
          ogTitleMeta.setAttribute("property", "og:title");
          ogTitleMeta.content = outletDetails.storename || "Salon Store";
          document.head.appendChild(ogTitleMeta);
        }
  
        let ogDescriptionMeta = document.querySelector('meta[property="og:description"]') as HTMLMetaElement;
        if (ogDescriptionMeta) {
          ogDescriptionMeta.content = outletDetails.store_introduction || "Salon Store";
        } else {
          ogDescriptionMeta = document.createElement("meta");
          ogDescriptionMeta.setAttribute("property", "og:description");
          ogDescriptionMeta.content = outletDetails.store_introduction || "Salon Store";
          document.head.appendChild(ogDescriptionMeta);
        }
  
        let ogImageMeta = document.querySelector('meta[property="og:image"]') as HTMLMetaElement;
        if (ogImageMeta) {
          ogImageMeta.content = outletDetails.storelogourl || defaultStoreImage;
        } else {
          ogImageMeta = document.createElement("meta");
          ogImageMeta.setAttribute("property", "og:image");
          ogImageMeta.content = outletDetails.storelogourl || defaultStoreImage;
          document.head.appendChild(ogImageMeta);
        }
  
        let twitterImageMeta = document.querySelector('meta[name="twitter:image"]') as HTMLMetaElement;
        if (twitterImageMeta) {
          twitterImageMeta.content = outletDetails.storelogourl || defaultStoreImage;
        } else {
          twitterImageMeta = document.createElement("meta");
          twitterImageMeta.name = "twitter:image";
          twitterImageMeta.content = outletDetails.storelogourl || defaultStoreImage;
          document.head.appendChild(twitterImageMeta);
        }
  
        document.title = outletDetails.storename || "Salon Store";
      }
    }, [outletDetails]);

  // const merchantId = localStorage.getItem("merchantid");
  // const outletId = localStorage.getItem("outlet_id");

  useEffect(() => {
    const fetchOutletDetails = async () => {
      try {
        if (!id || !merchantId) return; // Ensure id and merchantId are available
        const response = await get(
          `/merchantandoutletmob/mobile/${id}/${merchantId}`
        );
        if (response?.data?.success) {
          setOutletDetails(response.data.data);
          // Decrypt the Google Maps API key

          // Set API key and map coordinates
          setGoogleApiKey(response.data.data.googleMapKey);
          let lat = Number(response.data.data.latitude);
          let lng = Number(response.data.data.longitude);
          setMapCenter({ lat, lng });
          setMarkerPosition({ lat, lng });
        }
      } catch (error) {
        console.error("Error fetching outlet details:", error);
      }
    };
    fetchOutletDetails();
  }, [id, merchantId]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            color: "#000",
          }}
        >
          <button
            style={{
              background: "lightgray",
              border: "none",
              color: "white",
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/app/merchant/details/${merchantId}`)}
          >
            <LeftCircleOutlined />
          </button>
        </div>
      </div>
      <Layout
        style={{
          minHeight: "100vh",
          backgroundColor: "white",
          marginBottom: "10%",
        }}
      >
        {outletDetails && (
          <Content>
            {/* Carousel */}
            <Carousel autoplay>
              {outletDetails.outletshowcase.map((showcase) => (
                <div key={showcase.id}>
                  <img
                    src={showcase.imageUrl}
                    alt={showcase.name}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </Carousel>

            <div style={{ padding: "20px" }}>
              {/* Header */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  src={outletDetails.storelogourl}
                  alt="Logo"
                  style={{
                    width: "60px",
                    marginRight: "10px",
                    borderRadius: "8px",
                  }}
                />
                <Title level={4} style={{ margin: 0 }}>
                  {outletDetails.storename}
                </Title>
              </div>

              {/* Contact Info */}
              <div style={{ marginBottom: "20px" }}>
                <Space direction="vertical" size="middle">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PhoneOutlined style={{ marginRight: "8px" }} />
                    <span>
                      + {outletDetails.countrycode} {outletDetails.phone_number}
                    </span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MailOutlined style={{ marginRight: "8px" }} />
                    <span>{outletDetails.email}</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GlobalOutlined style={{ marginRight: "8px" }} />
                    <a href={outletDetails.website}>{outletDetails.website}</a>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <EnvironmentOutlined style={{ marginRight: "8px" }} />
                    <span>{outletDetails.address}</span>
                  </div>
                </Space>
              </div>

              {/* Static Map */}
              {/* <div style={{ marginBottom: "20px", textAlign: "center" }}>
                <img
                  src="https://via.placeholder.com/600x200?text=Map"
                  alt="Map"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
                <iframe
                  src="https://www.google.com/maps/dir///@23.068672,72.5123072,14z?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D"
                  title="description"
                  style={{ width: "100%", borderRadius: "8px" }}
                ></iframe>
              </div> */}
              {googleApiKey && (
                <LoadScript googleMapsApiKey={googleApiKey}>
                  <GoogleMap
                    mapContainerStyle={{
                      width: "100%",
                      height: "200px",
                      borderRadius: "8px",
                    }}
                    center={mapCenter}
                    zoom={14}
                  >
                    <Marker position={markerPosition} />
                  </GoogleMap>
                </LoadScript>
              )}

              {/* About Us */}
              <div style={{ marginBottom: "20px" }}>
                <Title level={4} style={{ marginBottom: "10px" }}>
                  About Us
                </Title>
                <Paragraph style={{ fontSize: "14px" }}>
                  {outletDetails.storeintroduction}
                </Paragraph>
              </div>

              {/* Operating Hours */}
              {/* <div style={{ marginBottom: "20px" }}>
                <Title level={4} style={{ marginBottom: "10px" }}>
                  Operating Hours
                </Title>
                {outletDetails.outletDaysInfo.map((hours, index) => (
                  <p key={index}>
                    {hours.dayname} - {hours.starttime} - {hours.endtime}
                  </p>
                ))}
              </div> */}
              <div style={{ marginBottom: "20px" }}>
                <Title level={4} style={{ marginBottom: "10px" }}>
                  Operating Hours
                </Title>
                {outletDetails?.outletDaysInfo.map((hours, index) => {
                  const currentDay = new Date().toLocaleString("en-US", {
                    weekday: "long",
                  });
                  const isCurrentDay = hours.dayname === currentDay;

                  return (
                    <Row
                      justify={"space-between"}
                      key={index}
                      style={{
                        marginTop: "10px",
                        fontWeight: isCurrentDay ? "bold" : "normal",
                      }}
                    >
                      <Col>{hours.dayname}</Col>
                      {hours.starttime === "closed" ? (
                        <Col>Closed</Col>
                      ) : (
                        <Col>
                          {hours.starttime} - {hours.endtime}
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </div>
            </div>
          </Content>
        )}
      </Layout>
      <Footer />
    </>
  );
};

export default OutletDetails;
