import React from "react";
import { Modal, Button } from "antd";
import "./ConfirmDeleteModal.css";

interface ConfirmDeleteModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  message: string;
  header?: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  visible,
  onCancel,
  onConfirm,
  message,
  header = "Prompt",
}) => {
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={370}
      bodyStyle={{ textAlign: "center" }}
      maskClosable={false}
      closable={false}
      className="delete-modal"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding:"20px"
        }}
      >
        <h3 className="modal-title">{header}</h3>
        <p className="modal-message">{message}</p>
      </div>
      <div className="modal-divider"></div>
      <div className="modal-footer">
        <Button
          className="cancel-button"
          type="link"
          onClick={onCancel}
          style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}
        >
          Cancel
        </Button>
        <div className="vl"></div>
        <Button
          className="confirm-button"
          type="link"
          onClick={onConfirm}
          style={{ color: "#1890ff", fontSize: "16px", fontWeight: "500" }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
