import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Row, Col, message } from "antd";
import Header from "../headerFooter/Header";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../../services/Apiclient";
import CryptoJS from "crypto-js";

const { Text } = Typography;

const layoutStyles: React.CSSProperties = {
  minHeight: "100vh",
  backgroundColor: "#fff",
  padding: "40px 16px 16px 16px",
};

const logoStyle: React.CSSProperties = {
  display: "block",
  margin: "0 auto 16px",
  width: "80px",
  height: "80px",
};

const formItemStyle: React.CSSProperties = {
  marginBottom: "16px",
};

const signInButtonStyle: React.CSSProperties = {
  backgroundColor: "#FFA500",
  color: "#fff",
  border: "none",
  height: "48px",
  fontSize: "16px",
  width: "100%",
  borderRadius: "8px",
  marginTop: "50px",
};

const linkStyle: React.CSSProperties = {
  color: "#FFA500",
  textDecoration: "underline",
};

const AppForgotPassword: React.FC = () => {
  const { email, merchantId } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const validatePassword = (password: any) => password.length >= 6;
  const encryptPassword = (password: any) => {
    const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  const handleSignIn = async (values: any) => {
    let newPassError = "";
    let confirmPassError = "";

    if (!newPassword) {
      newPassError = validatePassword(newPassword)
        ? ""
        : "Password must be at least 6 characters long.";
    }
    if (!confirmPassword) {
      confirmPassError = validatePassword(confirmPassword)
        ? ""
        : "Password must be at least 6 characters long.";
    }
    if (newPassword !== confirmPassword) {
      confirmPassError = "New password and confirm password must be same.";
    }
    setErrors({
      newPassword: newPassError,
      confirmPassword: confirmPassError,
    });
    if (!newPassError && !confirmPassError) {
      const encryptedPassword = encryptPassword(values.newPassword);

      try {
        const url = `/customerprofilemob/forgotpassword`;
        const response = await post(url, {
          email: email,
          password: encryptedPassword,
        });
        if (response?.data?.success) {
          localStorage.setItem("customer_id", response?.data.data?.customer_id);
          localStorage.setItem("outlet_id", response?.data?.data?.outlet_id);
          navigate(`/app/profile/${merchantId}`);
        } else {
          message.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error while change password:", error);
      }
    }
  };

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);
  return (
    <>
      <div style={layoutStyles}>
        {/* Logo */}
        <img
          src="https://via.placeholder.com/80x80.png?text=Logo"
          alt="Logo"
          style={logoStyle}
        />
        {/* Form */}
        <Form
          layout="vertical"
          onFinish={handleSignIn}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          {/* New password */}
          <Form.Item
            name="newPassword"
            validateStatus={errors.newPassword ? "error" : ""}
            help={errors.newPassword}
            required
            style={formItemStyle}
          >
            <Input.Password
              placeholder="New password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Item>
          {/* Confirm password */}
          <Form.Item
            name="ConfirmPassword"
            validateStatus={errors.confirmPassword ? "error" : ""}
            help={errors.confirmPassword}
            required
            style={formItemStyle}
          >
            <Input.Password
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Item>

          {/* Sign In Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit" style={signInButtonStyle}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default AppForgotPassword;
